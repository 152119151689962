import axios from "axios";
import { BASE_URL } from "../../variables";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";

export const addNewTourWssApi = (sh2Code, sh3Code) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/apexrest/getWSSDetails_api/`,
    method: "post",
    data: {
      sh2Code: sh2Code,
      sh3Code: sh3Code,
    },
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err.code === "ERR_NETWORK") {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
