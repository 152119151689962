import React, { useEffect, useRef } from "react";
import PageHeader from "../Components/Common/PageHeader";
import Query from "../Components/Queries/Query";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

function Queries() {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <>
      <PageHeader title="Service Request" path="/" />
      <div className="container p-0">
        <Query />
      </div>
    </>
  );
}

export default Queries;
