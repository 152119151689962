import ClipLoader from "react-spinners/ClipLoader";
import styles from "./Loader.module.css";

const Loader = ({ loading }) => {
  return (
    <div
      className={loading ? styles.parentDisable : styles.hideLoader}
      width="100%"
    >
      <div className={styles["overlay-box"]}>
        {
          <img
            src={"images/AppIcon_64x64.png"}
            className={styles.loader__loaderImg}
          />
        }
        <ClipLoader
          loading={loading}
          sizeUnit={"px"}
          size={90}
          color="#3901ff"
        />
      </div>
    </div>
  );
};

export default Loader;
