import React from "react";
import Modal from "react-bootstrap/Modal";
import "./ModalPopup.css";

const ModalPopup = ({
  isModalOpen,
  setIsModalOpen,
  title,
  data,
  icon,
  showButton,
  className,
  ModalMainClass,
  backdrop,
  fade,
  subtitle,
  toShowBody=true
}) => {
  return (
    <>
      <div className="modalWrapper">
        <Modal
          className={`modalPopup1 ${ModalMainClass}`}
          size="sm"
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          backdrop={backdrop}
          fade={fade}
        >
          {icon}
          <div className={className}>
            {toShowBody &&
              <Modal.Body>
                <p className="modalPopupTitle">{title}</p>
                <p className="modalPopupSubTitle">{subtitle}</p>
                <span className="modalPopupData">
                  <span style={{ textAlign: "center" ,width: "100%"}}>{data}</span>
                </span>
              </Modal.Body>
            }
            {showButton}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ModalPopup;
