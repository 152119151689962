import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { addPilRecord } from "../../services/pil/addPilRecord";
import { MdOutlineModeEdit } from "react-icons/md";
import ModalPopup from "../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import Message from "../Common/Message";
import Loader from "../Common/Loader";

const PILTableSec = ({
  latestDateData,
  brands,
  latestDate,
  categoryValue,
  dealer,
}) => {
  const userEmail = useSelector((state) => state?.userDetails?.details).Email;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [editCell, setEditCell] = useState(null);
  const [editedData, setEditedData] = useState({
    id: null,
    field: null,
    value: null,
  });

  useEffect(() => {
    const tempData = [];
    brands.forEach((e) => {
      if (latestDateData[e]?.responseId) {
        let obj = {
          Brand: e,
          id: Math.random(),
          "Survey Value": latestDateData[e]?.value,
          "Corrected Value": latestDateData[e]?.value,
          responseId: latestDateData[e]?.responseId,
        };
        tempData.push(obj);
      }
    });
    setData(tempData);
  }, [latestDateData]);

  const handleEdit = (id, field) => {
    setEditCell({ id, field });
    const itemToEdit = data.find((item) => item.id === id);
    setEditedData({ id, field, value: itemToEdit[field] });
  };

  const handleUpdate = () => {
    // Make a PATCH API call here with the updated data
    // For simplicity, let's just log the updated data to the console
    //console.log("Updated data:", data);
    setLoading(true);
    setIsSubmit(true);
    setEditCell(null); // Reset edit cell after updating
    setEditedData({ id: null, field: null, value: null });
    // setIsSubmitModalOpen(true);
  };

  const handleCancel = () => {
    setEditCell(null);
    setEditedData({ id: null, field: null, value: null });
  };

  const handleChange = (value) => {
    const updatedData = data.map((item) =>
      item.id === editCell.id ? { ...item, [editCell.field]: value } : item
    );
    setData(updatedData);
    setEditedData({ ...editedData, value });
  };

  useEffect(() => {
    if (isSubmit) {
      updatePilRecord();
    }
  }, [isSubmit]);

  const updatePilRecord = async () => {
    try {
      const result = await addPilRecord(
        data,
        latestDate,
        userEmail,
        categoryValue,
        dealer
      );
      if(result.status === 200){
        setIsSubmitModalOpen(true);
        setLoading(false);
        setIsSubmit(false);
      }else{
        setIsSubmit(false);
        setLoading(false);
      }
    } catch (error) {
      setIsSubmit(false);
    }
  };

  return (
    <>
      {data.length != 0 ?
        <>
          <Loader loading={loading} />
          <div className="pilTable">
            <div className="pilTable__box">
              <table responsive className="pilTable__table">
                <thead style={{ position: "sticky", top: "-11px" }}>
                  <tr className="pilTable__theadRow">
                    <th className="pilTable__theadRowHeading">Brand</th>
                    <th className="pilTable__theadRowHeading">Survey Value</th>
                    <th className="pilTable__theadRowHeading">Corrected Value <MdOutlineModeEdit /></th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.Brand}</td>
                      <td>{item["Survey Value"]}</td>
                      <td onClick={() => handleEdit(item.id, "Corrected Value")}>
                        {editCell?.id === item.id &&
                          editCell?.field === "Corrected Value" ? (
                          <input
                            type="text"
                            value={editedData.value}
                            onChange={(e) => handleChange(e.target.value)}
                            className="pilTable__input"
                          />
                        ) : (
                          item["Corrected Value"]
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* {editCell && (
            <div>
              <button onClick={handleUpdate}>Update</button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          )} */}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                className="myDealer__navButton"
                onClick={handleCancel}
                disabled={!editCell}
              >
                Cancel
              </Button>
            </div>

            <div className="col">
              <Button
                className="myDealer__navButton"
                disabled={!editCell}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>

            {isSubmitModalOpen && (
              <ModalPopup
                className="ModalPopup_Submitted"
                isModalOpen={isSubmitModalOpen}
                setIsModalOpen={setIsSubmitModalOpen}
                title="Updated Successfully"
                data="Your data is updated successfully."
                backdrop={true}
                fade={false}
                icon={
                  <>
                    <div className="submittedIcons draftIcons">
                      <FaThumbsUp className="submittedSmall" />
                      <FaThumbsUp className="submittedLarge" />
                      <FaThumbsUp className="submittedSmall" />
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
        :
        <>
          <Message
            message={"No data found"}
            className={"tourPlan__messageConatiner"}
          />
        </>
      }

    </>
  );
};

export default PILTableSec;
