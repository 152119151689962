import { getQueryDetialsApi } from "../../services/Queries/getQueryDetailsApi";
import {
  GET_QUERY_DETAILS_FAIL,
  GET_QUERY_DETAILS_SUCCESS,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

export const getQueryDetails = () => async (dispatch) => {
  const response = await getQueryDetialsApi();
  if (response?.status === 200) {
    dispatch({
      type: GET_QUERY_DETAILS_SUCCESS,
      payload: response?.data?.QueryDetails,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_QUERY_DETAILS_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};
