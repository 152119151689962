import React, { useState, useEffect } from "react";
import styles from "./UpcomingTourPlans.module.css";
import { Row, Col, Button } from "react-bootstrap";
import { RiDeleteBin5Line } from "react-icons/ri";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiCalendar } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { green, yellow } from "@mui/material/colors";
import { RiDeleteBinLine } from "react-icons/ri";
import EditFormModal from "./EditFormModal";
import { delUpcomingTourPlans } from "../../services/MonthlyTourPlans/upcomingTourPlansDelete";
import "./UpcomingTourDelModal.css";
import ModalPopup from "../Common/ModalPopup";
import _ from "lodash";
import { connect, useSelector } from "react-redux";
import Message from "../Common/Message";
import CommonSnackbar from "../Common/CommonSnackbar";
import { FcApproval } from "react-icons/fc";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import UpcomigTourDetailsModal from "./UpcomigTourDetailsModal";
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';

const UpcomingTourPlans = ({ delUpcomingTourPlans, showUpcomingTour }) => {
  const initialState = 4;
  const [slicedTourPlanData, setSlicedTourPlanData] = useState();
  const [noOfElement, setnoOfElement] = useState(initialState);
  const [tourData, setTourData] = useState();
  const [allData, setAllData] = useState([]);
  const [updatedTourPlan, setUpdatedTourPlan] = useState(false);
  const [showAllSelected, setShowAllSelected] = useState(true);
  const [isRadioButtonClicked, setIsRadioButtonClicked] = useState(false);
  const [sortUpcomingTourPlansData, setSortUpcomingTourPlansData] = useState([]);
  const [utpLength, setUtpLength] = useState(
    useSelector(
      (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records?.length
    )
  );

  //swipe
  const [active, setActive] = useState(false);
  const [ind, setInd] = useState();
  const [touchStart, setTouchStart] = useState();
  const [filterData, setFilterData] = useState([]);
  const [touchEnd, setTouchEnd] = useState();
  const [successOpen, setSuccessOpen] = useState(false);
  const [failedOpen, setFailesOpen] = useState(false);

  // snackbar
  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailesOpen(false);
    setSuccessOpen(false);
  };

  const upcomingTourPlansData = useSelector(
    (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records
  );

  useEffect(() => {
    var sortedData = [...upcomingTourPlansData].sort((a, b) => new Date(b.MTP_Date__c) - new Date(a.MTP_Date__c));
    setSortUpcomingTourPlansData(sortedData);
  }, [upcomingTourPlansData])

  sortUpcomingTourPlansData?.forEach((tour, index) => {
    const date = new Date(tour?.MTP_Date__c);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    sortUpcomingTourPlansData[index].MTP_Date__c = formattedDate;
  });


  // find Day accordingly date function
  const findDay = useSelector(
    (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records
  );
  findDay?.forEach((tourday, index) => {
    const date = new Date(tourday?.MTP_Date__c);
    let day = date.toLocaleString("en-us", { weekday: "long" });
    findDay[index].day = day;
  });

  const onClickHandler = (e) => {
    e.preventDefault();
    setIsRadioButtonClicked(false);
    setAllData(sortUpcomingTourPlansData);
    setShowAllSelected(true);
    setUtpLength(sortUpcomingTourPlansData?.length);
  };
  

  // load More data functionality
  useEffect(() => {
    if (filterData?.length !== 0) setUtpLength(filterData?.length);

    if (isRadioButtonClicked && !filterData?.length) {
      return setSlicedTourPlanData("");
    }

    if (filterData?.length) {
      return setSlicedTourPlanData(filterData?.slice(0, noOfElement));
    }

    if (!filterData?.length) {
      setUtpLength(sortUpcomingTourPlansData?.length);
      return setSlicedTourPlanData(
        sortUpcomingTourPlansData?.slice(0, noOfElement)
      );
    }
  }, [sortUpcomingTourPlansData, filterData, noOfElement]);

  const loadMore = (e) => {
    e.preventDefault();
    if (noOfElement === initialState) {
      if (filterData?.length) {
        setnoOfElement(filterData?.length);
      } else if (allData?.length) {
        setnoOfElement(allData?.length);
      } else {
        setnoOfElement(sortUpcomingTourPlansData?.length);
      }
    } else setnoOfElement(4);
  };

  // radio button logic
  const [selectedValue, setSelectedValue] = useState();
  const handleChange = (event) => {
    setFilterData([]);
    setIsRadioButtonClicked(true);
    setAllData([]);
    setShowAllSelected(false);
    if (event?.target?.value === "green") {
      sortUpcomingTourPlansData?.map((item) => {
        if (item?.RecordType?.Name === "Monthly Tour Plan") {
          return setFilterData((ls) => [...ls, item]);
        }
      });
    } else if (event?.target?.value === "yellow") {
      sortUpcomingTourPlansData?.map((item) => {
        if (item?.RecordType?.Name === "Draft") {
          return setFilterData((ls) => [...ls, item]);
        }
      });
    }
    setSelectedValue(event.target.value);
    setShowAllSelected(false);
  };

  useEffect(() => {
    if (allData?.length) {
      return (
        setFilterData([]),
        setSlicedTourPlanData(allData?.slice(0, noOfElement)),
        setSelectedValue()
      );
    }
  }, [allData, noOfElement]);

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  //delete functionality

  const deleteTour = (id, town, tourplansDate, tourPlansDay, cluster) => {
    setShow(true);
    setTourData({
      id,
      town,
      tourplansDate,
      tourPlansDay,
      cluster,
    });
  };

  const onConfirmDelete = async (e) => {
    e.preventDefault();
    const response = await delUpcomingTourPlans(tourData?.id);
    if (response?.success) {
      setShow(false);
      setFailesOpen(true);
      onClickHandler(e);
    } else {
      //Todo:set error popup
      if (response?.error?.errorCode === "FIELD_CUSTOM_VALIDATION_EXCEPTION") {
        setSuccessOpen(true);
        setShow(false);
      }
    }
  };

  // swipes Function

  useEffect(() => {
    if (touchEnd < touchStart) {
      setActive(true);
    }
    if (touchEnd >= touchStart) {
      setActive(false);
    }
  }, [touchEnd]);

  const handleTouchStart = (e) => {
    setTouchStart(e.changedTouches[0].screenX);
  };

  const handleTouchEnd = (id, e) => {
    setInd(id);
    setTouchEnd(e.changedTouches[0].screenX);
  };


// Format the wss entries 
  const StringSplitterWss = (inputString) => {
    if (inputString == null) {
      return "";
    } else {
      const modifiedSegments = [];
      const splitWithComma = inputString.split(",");
      if (splitWithComma.length <= 2) {
        const splitSegment = splitWithComma[0].split(':');
        const modifiedSegment = splitSegment.slice(0, 2).join(':');
        return modifiedSegment;
      } else {
        splitWithComma.forEach((segment, index) => {
          if (index % 2 === 0) {
            const splitSegment = segment.split(':');
            const modifiedSegment = splitSegment.slice(0, 2).join(':');
            modifiedSegments.push(modifiedSegment);
          }
        });
        const result = modifiedSegments.join(',');
        const splitWithComma2 = result.split(",");
        const partsToDisplay = splitWithComma2.slice(0, -1);
        return partsToDisplay.join(',');
      }
    }
  }

// Download the excel file 
  const downloadExcel = () => {
    const data = sortUpcomingTourPlansData;

    const wb = XLSX.utils.book_new();

    const mapping = {
      "MTP_Date__c": {
        label: "MTP Date",
        format: value => value
      },
      "Cluster_es__c": {
        label: "Cluster",
        format: value => value
      },
      "Territory_Name_es__c": {
        label: "Territory",
        format: value => value
      },
      "WSS_Name__c": {
        label: "WSS",
        format: value => value
      },
      "Town_Name__c": {
        label: "Town",
        format: value => value
      },
      "Objectives_es__c": {
        label: "Primary Objectives",
        format: value => value
      },
      "Other_Objective__c": {
        label: "Other Primary Objective",
        format: value => value
      }
    };

    const formatData = (data) => {
      const formattedData = [];
      const headers = Object.keys(mapping).map(key => mapping[key].label);

      formattedData.push(headers);

      data.forEach(entry => {
        const row = Object.keys(mapping).map(key => {
          let formattedValue = "";
          const mappedKey = mapping[key].label;
          if (mappedKey === "WSS") {
            const wssData = mapping[key].format(entry[key] || "");
            let test = StringSplitterWss(wssData);
            formattedValue = test;
          } else {
            formattedValue = mapping[key].format(entry[key] || "");
          }
          return formattedValue;
        });
        formattedData.push(row);
      });

      return formattedData;
    };

    const formattedData = formatData(data);
    const ws = XLSX.utils.aoa_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(wb, ws, "Tour Plans");
    XLSX.writeFile(wb, "tour_plans.xlsx");
  };

  return (
    <>
      <div className="upcomingTourPlans">
        <Col>
          <Row style={{ alignItems: "center" }}></Row>
          <Row
            style={{
              alignItems: "center",
            }}
          >
            <div className="col-6">
              <h6 className={styles.upcomingTourPlans__lable}>
                Upcoming Tour Plans
              </h6>
            </div>
            <div className="col-6">
              <button onClick={downloadExcel} className={styles.upcomingTourPlans__downloadBtn} >Download</button>
            </div>
          </Row>
          <Row>
            <FormControl
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                alignItems: "center",
                justifyContent: "end",
                gap: "0.2rem",
              }}
            >
              <div>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{
                    fontSize: "13.3816px",
                  }}
                >
                  Sort by :
                </FormLabel>
              </div>
              <div>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  class="radio-inline"
                >
                  <button
                    onClick={onClickHandler}
                    className={
                      showAllSelected
                        ? styles.upcomingTourPlans__Selected
                        : styles.upcomingTourPlans__allButton
                    }
                  >
                    All
                  </button>

                  <label className={styles.upcomingTourPlans__label}>
                    Saved
                  </label>
                  <Radio
                    style={{ height: "5px", width: "5px" }}
                    {...controlProps("green")}
                    sx={{
                      color: green[300],
                      "&.Mui-checked": {
                        color: green[300],
                      },
                    }}
                  />
                  <label className={styles.upcomingTourPlans__label2}>
                    Draft
                  </label>
                  <Radio
                    {...controlProps("yellow")}
                    sx={{
                      color: yellow[400],
                      "&.Mui-checked": {
                        color: yellow[400],
                      },
                    }}
                  />
                </RadioGroup>
              </div>
            </FormControl>
          </Row>

          {slicedTourPlanData &&
            slicedTourPlanData?.map((item, index) => (
              <>
                <div
                  className={`${styles.upcomingTourPlans__outerBox} ${
                    active &&
                    ind === index &&
                    styles.upcomingTourPlans__outerBoxActive
                  }`}
                >
                  <div
                    className={`${styles.upcomingTourPlans__mainBox} ${
                      active &&
                      ind === index &&
                      styles.upcomingTourPlans__mainBoxActive
                    }`}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={(e) => handleTouchEnd(index, e)}
                    id={index}
                  >
                    <div className={styles.upcomingTourPlans__locationBox}>
                      <div
                        className={styles.upcomingTourPlans__tag}
                        style={{
                          backgroundColor:
                            item?.RecordType?.Name === "Monthly Tour Plan"
                              ? "#A2D7AF"
                              : "#FDF3B5",
                        }}
                      ></div>
                      <div className={styles.upcomingTourPlans__location}>
                        <HiOutlineLocationMarker
                          className={styles.upcomingTourPlans__locationIcon}
                        />
                        <p>
                          {item?.Cluster_es__c
                            ? !_.isEmpty(item?.Town_Name__c)
                              ? item?.Town_Name__c
                              : item?.Cluster_es__c?.split(":")[0]
                            : item?.Town_Name__c}
                        </p>
                      </div>
                    </div>
                    <div className={styles.upcomingTourPlans__dateBox}>
                      <div className={styles.upcomingTourPlans__tourDate}>
                        <FiCalendar
                          className={styles.upcomingTourPlans__calenderIcon}
                        />
                        <p>{item.MTP_Date__c}</p>
                        <span className={styles.upcomingTourPlans__divideLine}>
                          |
                        </span>
                        <p className={styles.upcomingTourPlans__tourDay}>
                          {item.day}
                        </p>
                      </div>
                      <div className={styles.upcomingTourPlans__edit}>
                        <UpcomigTourDetailsModal
                          record={item}
                          setUpdatedTourPlan={setUpdatedTourPlan}
                        />
                      </div>
                      {item.Visit_Status_es__c === "Visited" ? (
                        <div className={styles.upcomingTourPlans__edit}>
                          <FcApproval
                            className={styles.upcomingTourPlans__completed}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Completed"
                          />
                          <ReactTooltip
                            id="my-tooltip"
                            style={{
                              fontSize: "11px",
                              padding: "5px",
                              background: "#2e7d32",
                            }}
                          />
                        </div>
                      ) : (
                        <div className={styles.upcomingTourPlans__edit}>
                          <EditFormModal
                            record={item}
                            setUpdatedTourPlan={setUpdatedTourPlan}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    className={`${styles.upcomingTourPlans__deleteBox} ${
                      active &&
                      ind === index &&
                      styles.upcomingTourPlans__deleteBoxActive
                    }`}
                    onClick={() => {
                      deleteTour(
                        item.Id,
                        item.Town_Name__c,
                        item.MTP_Date__c,
                        item.day,
                        item.Cluster_es__c
                      );
                      setActive(false);
                    }}
                  >
                    <RiDeleteBinLine
                      style={{ color: "#fff" }}
                      className={styles.upcomingTourPlans__EditButton}
                    />
                    <p>Delete</p>
                  </button>
                </div>
              </>
            ))}

          <div
            className="row mt-0"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div
              className="col-3"
              style={{ display: "flex", justifyContent: "end" }}
            >
              {utpLength > 4 ? (
                <>
                  <button
                    className={styles.noTourPlans__bottom_button}
                    onClick={loadMore}
                  >
                    {!_.isEmpty(slicedTourPlanData) ? (
                      noOfElement == initialState ? (
                        <div className={styles.noTourPlans__bottom_section}>
                          <BsChevronDown
                            className={styles.noTourPlans__seeAll_icon}
                          />
                          <p className={styles.noTourPlans__seeAll}>View all</p>
                        </div>
                      ) : (
                        <div className={styles.noTourPlans__bottom_section}>
                          <BsChevronUp
                            className={styles.noTourPlans__seeAll_icon}
                          />
                          <p className={styles.noTourPlans__seeAll}>See less</p>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          {utpLength !== 0 &&
            isRadioButtonClicked &&
            filterData?.length == 0 && (
              // shifted the code in common message component
              <Message
                message={"No upcoming tour plans"}
                className={"tourPlan__messageConatiner"}
              />
            )}

          {utpLength === 0 && (
            <Message
              message={"No upcoming tour plans"}
              className={"tourPlan__messageConatiner"}
            />
          )}
          {/* </div> */}
        </Col>
        <ModalPopup
          isModalOpen={show}
          setIsModalOpen={handleClose}
          backdrop={true}
          fade={false}
          icon={
            <>
              <div className="deleteIcons">
                <RiDeleteBin5Line className="deleteSmall" />
                <RiDeleteBin5Line className="deleteLarge" />
                <RiDeleteBin5Line className="deleteSmall" />
              </div>
            </>
          }
          title="Are you sure you want to delete ?"
          data={
            <div className="modalData__outerBox">
              <div>
                <div className="deleteModal__location">
                  <HiOutlineLocationMarker className="deleteModal__locationIcon" />
                  <p className="deleteModal__townName">
                    {tourData?.cluster
                      ? _.isEmpty(tourData?.town)
                        ? tourData?.cluster?.split(":")[0]
                        : tourData?.town
                      : tourData?.town}
                  </p>
                </div>
              </div>

              <div className="deleteModal__tourDate">
                <FiCalendar className="deleteModal__calenderIcon" />
                <p>{tourData?.tourplansDate}</p>
                <span className="deleteModal__divideLine">|</span>
                <p
                  className={styles.upcomingTourPlans__tourDay}
                  style={{ justifyContent: "start" }}
                >
                  {tourData?.tourPlansDay}
                </p>
              </div>
            </div>
          }
          showButton={
            <>
              <div className="deleteModal__footer">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="deleteModal__deleteBtn"
                  >
                    <span className="deleteModal__cancelBtnText">Cancel</span>
                  </Button>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={onConfirmDelete}
                    className="deleteModal__cancelBtn"
                  >
                    <span className="deleteModal__deleteBtnText">Delete</span>
                  </Button>
                </div>
              </div>
            </>
          }
          className="deleteModel__outerBoxBody"
        />
      </div>
      <CommonSnackbar
        open={successOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"error"}
        message={"You cannot delete submitted tour plan!"}
      />

      <CommonSnackbar
        open={failedOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"success"}
        message={"Deleted upcoming tour plan!"}
      />
    </>
  );
};

export default connect(null, {
  delUpcomingTourPlans,
})(UpcomingTourPlans);
