import React, { useEffect, useState } from "react";
import styles from "./CurrentDayTour.module.css";
import { useSelector } from "react-redux";
import _ from "lodash";
import { RWebShare } from "react-web-share";
import Loader from "../../Components/Common/Loader";
import { getTSIEncryptKeyApi } from "../../services/CurrentDayTour/getTsiEncryptKeyApi";
import { getTSIDataApi } from "../../services/CurrentDayTour/getTSIDataApi";
import Message from "../Common/Message";

const CurrentDayTour = () => {
  const [toShowValue, setToShowValue] = useState([]);
  const [toOrderValue, setToOrderValue] = useState([]);
  const data = useSelector(
    (state) => state?.currentDayTour?.currentDayTourList
  );

  const dataLoading = useSelector((state) => state?.currentDayTour?.loading);
  const [toCheckValue, setToCheckValue] = useState([]);
  const [loading, setLoading] = useState(false);

  //on Click of Order value
  const onClickHandler = (index, TSICode) => {
    if (!toCheckValue[index]) {
      setToShowValue((ls) => [...ls, { [index]: true, TSICode: TSICode }]);
      toCheckValue.splice(index, 1, true);
      setToCheckValue([...toCheckValue]);
    }
  };

  // to set the initial state with false value
  useEffect(() => {
    if (data?.length) {
      // setLoading(false);
      setToCheckValue(
        Array.from({ length: data?.length }, (val, index) => false)
      );
      setToOrderValue(
        Array.from({ length: data?.length }, (val, index) => false)
      );
    }
  }, [data]);

  // api call of order value
  useEffect(() => {
    if (toShowValue?.length) {
      (async () => {
        setLoading(true);
        const response = await getTSIEncryptKeyApi(
          toShowValue?.slice(-1)?.[0]?.TSICode
        );

        if (
          response?.data?.success &&
          !_.isEmpty(
            response?.data?.data?.Salesforce_EncryptDataResult?.[0]?.Result
          )
        ) {
          const res = await getTSIDataApi(
            response?.data?.data?.Salesforce_EncryptDataResult?.[0]?.Result
          );

          const tsiList =
            res?.data?.data?.Salesforce_TSIWiseDataResult?.[0]
              ?.GetTSIWiseDataList;

          let sum = 0;
          for (let i = 0; i < tsiList?.length; i++) {
            sum += parseFloat(tsiList?.[i]?.ORDERValue);
          }
          toOrderValue.splice(
            Object.keys(toShowValue?.slice(-1)?.[0])?.[0],
            1,
            sum
          );
          setLoading(false);
          setToOrderValue([...toOrderValue]);
        }
      })();
    }
  }, [toShowValue]);

  return (
    <>
      <div className="container p-0">
        <Loader loading={loading} />
        <Loader loading={dataLoading} />
        <div className="row m-0 g-0 d-flex">
          {data && !data?.length && !dataLoading ? (
            <Message
              message={"No Data for current Day Tour"}
              className="visitDetails__OuterDiv"
            />
          ) : (
            data?.map((item, index) => (
              <div className={`col-md-6 col-lg-4 ${styles.currentDayTour}`}>
                <div className={styles.currentDayTour__mainBox}>
                  <div className="row m-0 p-0">
                    <div
                      className={`col-12 ${styles.currentDayTour__rightBox}`}
                    >
                      <div className="row">
                        <div className="col-9">
                          <h5 className={styles.currentDayTour__title}>
                            Territory {item?.HL2_TSIDesc}
                          </h5>
                        </div>
                        <div className={`col-3 `}>
                          <RWebShare
                            data={{
                              text: `DRCP Universe :- ${item?.DRCPUniverse},
                          DRCP Compliance:- ${item.DRCPCompPER},
                          DRCP Score FTD:- ${item.DRCPScoreFTD},
                          Non DRCP Calls:- ${item.NONDRCPCalls},
                          Planned Calls:- ${item.PLANNEDCalls},
                          Productive Calls:- ${item.PRODCalls}`,
                              url: window.location.href,
                              title: "Current Day Tour",
                            }}
                            onClick={() => console.log("shared successfully!")}
                          >
                            <button
                              className={styles.currentDayTour__sidecardButton}
                            >
                              Nudge
                            </button>
                          </RWebShare>
                        </div>
                      </div>
                      <p className={styles.currentDayTour__desp}>
                        Market Start Time :{" "}
                        {_.isEmpty(item?.MarketStartTime)
                          ? "----"
                          : item?.MarketStartTime}
                      </p>
                      <div className={styles.currentDayTour__despRow}>
                        <div className={styles.currentDayTour__despBox}>
                          <div className={styles.currentDayTour__despBoxName}>
                            DRCP Universe
                          </div>
                          <div className={styles.currentDayTour__despBoxValue}>
                            {item.DRCPUniverse}
                          </div>
                        </div>
                        <div className={styles.currentDayTour__despBox}>
                          <div className={styles.currentDayTour__despBoxName}>
                            DRCP Compliance
                          </div>
                          <div className={styles.currentDayTour__despBoxValue}>
                            {item.DRCPCompPER}
                          </div>
                        </div>
                      </div>
                      <div className={styles.currentDayTour__despRow}>
                        <div className={styles.currentDayTour__despBox}>
                          <div className={styles.currentDayTour__despBoxName}>
                            DRCP Score FTD
                          </div>
                          <div className={styles.currentDayTour__despBoxValue}>
                            {item.DRCPScoreFTD}
                          </div>
                        </div>
                        <div className={styles.currentDayTour__despBox}>
                          <div className={styles.currentDayTour__despBoxName}>
                            Non DRCP Calls
                          </div>
                          <div className={styles.currentDayTour__despBoxValue}>
                            {item.NONDRCPCalls}
                          </div>
                        </div>
                      </div>
                      <div className={styles.currentDayTour__despRow}>
                        <div className={styles.currentDayTour__despBox}>
                          <div className={styles.currentDayTour__despBoxName}>
                            Planned Calls
                          </div>
                          <div className={styles.currentDayTour__despBoxValue}>
                            {item.PLANNEDCalls}
                          </div>
                        </div>
                        <div className={styles.currentDayTour__despBox}>
                          <div className={styles.currentDayTour__despBoxName}>
                            Productive Calls
                          </div>
                          <div className={styles.currentDayTour__despBoxValue}>
                            {item.PRODCalls}
                          </div>
                        </div>
                      </div>
                      <div className={`row px-2`}>
                        <button
                          className={`col-12 m-0 p-0 ${styles.currentDayTour__buttonBox}`}
                          onClick={() =>
                            onClickHandler(index, item?.HL2_TSICode)
                          }
                        >
                          <>
                            <div
                              className={`${styles.currentDayTour__buttonName}`}
                            >
                              {toShowValue?.map((value) => {
                                return (
                                  Object.keys(value)?.[0] == index &&
                                  `Order Value : ${
                                    toOrderValue[index] !== false
                                      ? toOrderValue[index]?.toFixed(2) + "/-"
                                      : ""
                                  }`
                                );
                              })}

                              {!toCheckValue[index] &&
                                "Click to check to the order value"}
                            </div>
                          </>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentDayTour;
