import axios from "axios";
import { BASE_URL } from "../../variables";
import { arrayData, getUniqueListBy } from "../../Components/Common/Function";

export const getTownApi = async (data) => {
    let sh3Code = [];
    if (data.length > 0) {
        const sh3data = getUniqueListBy(data, "Customer_Code__c");
        sh3data?.map((item) => {
            sh3Code.push(item?.Customer_Code__c);
        });
        let sh3Result = sh3Code;
        const options = {
            url: `${BASE_URL}/services/apexrest/getDealerTown_api/`,
            method: "post",
            data: {
                customerCodes: sh3Result,
            },
        };
        try {
            const response = await axios(options);
            if (response.status === 200) {
                const townData = response?.data?.DealerData;
                const arrayOfTowns = Object.entries(townData).map(([key, value]) => ({
                    id: key,
                    ...value
                }));
                return {
                    success: true,
                    data: arrayOfTowns,
                };
            } else {
                throw Error("Something Went Wrong..!!");
            }
        } catch (err) {
            console.log(err);
            return {
                success: false,
                msg: "Somthing Went Wrong..!!",
            };
        }
    } else {
        return {
            success: true,
            data: { records: [] },
        };
    }
};
