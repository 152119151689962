import { getTownApi } from "../../services/wss/getTownApi";
import { getWssAutoVAlueFtdApi } from "../../services/wss/getWssAutoValueFtdApi";
import { getWssAutoVAlueFtdTrendApi } from "../../services/wss/getWssAutoValueFtdTrendApi";
import { getWssCodeApi } from "../../services/wss/getWssCodeApi";
import { getWssDarpanSyncDaysExceptionApi } from "../../services/wss/getWssDarpanSyncDaysExceptionApi";
import { getWssWssInBlockApi } from "../../services/wss/getWssWssInBlockApi";

import {
  GET_WSS_AUTO_VALUE_FTD_FAIL,
  GET_WSS_AUTO_VALUE_FTD_SUCCESS,
  GET_WSS_AUTO_VALUE_FTD_TREND_FAIL,
  GET_WSS_AUTO_VALUE_FTD_TREND_SUCCESS,
  GET_WSS_CODE_FAIL,
  GET_WSS_CODE_SUCCESS,
  GET_WSS_DARPAN_SYNC_DAYS_EXCEPTION_FAIL,
  GET_WSS_WSS_IN_BLOCK_FAIL,
  GET_WSS_WSS_IN_BLOCK_SUCCESS,
  GET_WSS__DARPAN_SYNC_DAYS_EXCEPTION_SUCCESS,
  GET_TOWN_CODE_SUCCESS,
  GET_TOWN_CODE_FAIL,
  SET_CHECK_IN_FLAG_SUCCESS,
  SET_CHECK_IN_FLAG_FAIL
} from "../constants/action-types";

export const getWssAutoVAlueFtd = (SH_Code__c, Title) => async (dispatch) => {
  const response = await getWssAutoVAlueFtdApi(SH_Code__c, Title);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_WSS_AUTO_VALUE_FTD_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_WSS_AUTO_VALUE_FTD_FAIL });
  }
};

export const getWssAutoValueFtdTrend =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getWssAutoVAlueFtdTrendApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_WSS_AUTO_VALUE_FTD_TREND_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_WSS_AUTO_VALUE_FTD_TREND_FAIL });
    }
  };

export const getWssWssInBlock = () => async (dispatch) => {
  const response = await getWssWssInBlockApi();
  if (response?.data?.success === true) {
    dispatch({
      type: GET_WSS_WSS_IN_BLOCK_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_WSS_WSS_IN_BLOCK_FAIL });
  }
};

export const getWssDarpanSyncDaysException =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getWssDarpanSyncDaysExceptionApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_WSS__DARPAN_SYNC_DAYS_EXCEPTION_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_WSS_DARPAN_SYNC_DAYS_EXCEPTION_FAIL });
    }
  };

export const getWssCodeForHORDM = (data) => async (dispatch) => {
  const response = await getWssCodeApi(data);
  if (response?.success === true) {
    // dispatch(getTownsForHORDM(response?.data));
    dispatch({
      type: GET_WSS_CODE_SUCCESS,
      payload: response?.data,
    });
  } else {
    dispatch({ type: GET_WSS_CODE_FAIL });
  }
};

export const getTownsForHORDM = (data) => async (dispatch) => {
  const response = await getTownApi(data);
  if (response?.success === true) {
    dispatch({
      type: GET_TOWN_CODE_SUCCESS,
      payload: response?.data,
    });
  } else {
    dispatch({ type: GET_TOWN_CODE_FAIL });
  }
};

export const setCheckInFlag = (data) => async (dispatch) => {
  if (data) {
    dispatch({
      type: SET_CHECK_IN_FLAG_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: SET_CHECK_IN_FLAG_FAIL,
    });
  }
};
