import React from "react";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";

const Alerts = ({ alerts }) =>
  alerts !== null &&
  alerts?.length > 0 &&
  alerts?.map((alert) => (
    <Alert key={alert.id} variant="filled" severity={alert?.alertType}>
      {alert.msg}
    </Alert>
  ));

const matchStatetoProps = (state) => ({
  alerts: state.alerts,
});

export default connect(matchStatetoProps)(Alerts);
