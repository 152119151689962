import React, { useEffect, useRef } from "react";
import PageHeader from "./../Components/Common/PageHeader";
import CWP from "../Components/CWP/CWP";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

const Cwp = () => {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <>
      <PageHeader title="Dealer Locator" path="/" />
      <div className="container p-0">
        <CWP />
      </div>
    </>
  );
};

export default Cwp;
