import axios from "axios";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";
import {
  GET_RMDM_TOWN_FAIL,
  GET_RMDM_TOWN_SUCCESS,
} from "../../Redux/constants/action-types";
import { arrayData } from "../../Components/Common/Function";
import { getTownsForHORDM } from "../../Redux/actions/wss-actions";

export const getRmdmTownApi = (rmdmCluster) => async (dispatch) => {
  if (rmdmCluster.length > 0) {
    let Result = arrayData(rmdmCluster);
    const sh3Result = Result.split(",").map(item => item.replace(/'/g, '').trim());
    // const options = {
    //   url: `${BASE_URL}/services/data/v56.0/query?q=Select id, CustomerName__c,WSS_Territory_Code__c,Customer_Code__c,Town__c,WSS_latitude__c,WSS_longitude__c  from Account where RecordType.Name='Customer' and Cluster_Code__c in (${result})`,
    //   method: "get",
    // };
    const options = {
      url: `${BASE_URL}/services/apexrest/getCustomerCode_api/`,
      method: "post",
      data: {
        clusterCodes: sh3Result,
      },
    };
    try {
      const response = await axios(options);
      if (response.status === 200) {
        const wssData = response?.data?.CustomerData;
        const arrayOfWss = Object.entries(wssData).map(([key, value]) => ({
          id: key,
          ...value
        }));
        dispatch(getTownsForHORDM(arrayOfWss));
        dispatch({
          type: GET_RMDM_TOWN_SUCCESS,
          payload: arrayOfWss,
        });
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_REQUEST") {
        sessionExpireTimeout(dispatch);
      } else {
        dispatch({
          type: GET_RMDM_TOWN_FAIL,
          payload: [],
        });
      }
    }
  } else {
    dispatch({
      type: GET_RMDM_TOWN_SUCCESS,
      payload: [],
    });
  }
};
