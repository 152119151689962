import styles from "./Message.module.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import _ from "lodash";


const Message = ({ message, className, icon="" }) => {
  return (
    <div className={styles[className]}>
      <div className={styles.message__icon}>
      {
        !_.isEmpty(icon) ? icon :
       <AiOutlineExclamationCircle
          style={{ color: "#808080", height: "2em", width: "2em" }}
        />}
      </div>
      <p className={styles.message__text}>{message}</p>
    </div>
  );
};

export default Message;
