import React, { useEffect, useRef } from "react";
import PageHeader from "../Components/Common/PageHeader";
import VisitDetails from "../Components/MarketWorking/MyTeam/VisitDetails";
import PbiDashboard from "../Components/MonthlyTourPlan/PbiDashboard";
import InsightsAndQueries from "../Components/MarketWorking/InsightsAndQueries";
import Message from "../Components/Common/Message";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

function MarketWorking() {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <>
      <PageHeader title="Key Information" path="/" />
      <div className="container p-0">
        {/* <VisitDetails /> */}
        <PbiDashboard showDropdown={true} showDealer={false} />
        {/* <InsightsAndQueries /> */}
      </div>
    </>
  );
}

export default MarketWorking;
