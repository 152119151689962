import React, { useState, useEffect, useRef } from "react";
import { useSelector, connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "./PILSurvey.css";
import PILTable from "./PILTable";
import PILTableSec from "./PILTableSec";
import Autocomplete from "@mui/material/Autocomplete";
import { compressImage, getUniqueListBy } from "../Common/Function";
import TextField from "@mui/material/TextField";
import { clusterCodePlaceHolder } from "../Common/Constant";
import styles from "../MarketWorking/MyDiary/NewFeedback.module.css";
import _, { mapValues } from "lodash";
import { getDealerNameApi } from "../../services/rmdm/getDealerNameApi";
import { getRmdmWssApi } from "../../services/rmdm/getRmdmWssApi";
import { getDealerApi } from "../../services/MarketWorking/MyTeam/getDealerApi";
import { getPilDataApi } from "../../services/pil/getPilDataApi";
import { getPilCategoryApi } from "../../services/pil/getPilCategoryApi";
import moment from "moment";
import PieChart from "../Common/PieChart";
import Message from "../Common/Message";

ChartJS.register(ArcElement, Tooltip, Legend);

// const clusterCodePlaceHolder = {
//   SH3_Name__c: null,
//   SH3_Code__c: null,
//   Cluster_Description__c: "",
//   CLUSTER_CODE__c: "",
// };

const territoryAutoCompletePlaceHolder = {
  attributes: null,
  Id: null,
  SH2_Name__c: null,
  SH2_Code__c: null,
  Name: null,
  SH3_Code__c: null,
};

const dealerPlaceHolder = {
  attributes: null,
  Id: null,
  Dealer_Name__c: null,
  DealerClass__c: null,
  DealerType1__c: null,
  DealerLatitude__c: null,
  DealerLongitude__c: null,
  PrimaryTSICode__c: null,
  DealerCode__c: null,
  RecordTypeId: null,
};

const categoryAutoCompletePlaceHolder = {
  CategoryName: null
};

const pieChartInitialData = {
  labels: [],
  values: [],
}

const PILSuervey = (
  checkedInapi,
  getZSMTerritoryApi,
  getRmdmWssApi,
  getDealerNameApi
) => {
  const [dealerOption, setDealerOption] = useState([]);
  const [error, setError] = useState("");
  const [selectedTerritory, setSelectedTerritory] = useState(
    territoryAutoCompletePlaceHolder
  );
  const [selectLatLong, setSelectLatLong] = useState(dealerPlaceHolder);
  const [selectedCategory, setSelectedCategory] = useState(categoryAutoCompletePlaceHolder);
  const [lat, setLat] = useState(18.516726);
  const [long, setLong] = useState(73.856255);
  const [dealer, setDealer] = useState("");
  const [sh3CodeForTerritory, setSH3CodeForTerritory] = useState("");
  const [territoryCheckIn, setTerritoryCheckIn] = useState("");
  const [loading, setLoading] = useState(false);
  const tname = useSelector((state) => state?.territory?.territoryDetails);
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [cluster, setCluster] = useState(clusterCodePlaceHolder);
  const [categoryValue, setCategoryValue] = useState("");
  const [Category, setCategory] = useState([]);
  const [pilData, setPilData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [responseDates, setResponseDates] = useState([]);
  const [latestDateData, setLatestDateData] = useState({});
  const [latestDate, setLatestDate] = useState({});
  const [pieChartData, setPieChartData] = useState(pieChartInitialData);
  const [isCategoryData, setIsCategoryData] = useState("Loading");
  const [isDealerData, setIsDealerData] = useState("Loading");

  const userType = useSelector((state) =>
    !_.isEmpty(state?.userDetails?.details?.MConnect_Role__c)
      ? state?.userDetails?.details?.MConnect_Role__c
      : state?.userDetails?.details?.Title
  );
  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );

  // Get Selected Territory
  useEffect(() => {
    if (selectedTerritory) {
      const checkInTerritory = selectedTerritory?.Id;
      setTerritoryCheckIn(checkInTerritory);
    }
    setPilData([])
    setBrands([])
    setResponseDates([])
    setLatestDateData({})
    setLatestDate({})
  }, [selectedTerritory]);

  useEffect(() => {
    // const date = new Date(
    //   Math.max(
    //     ...responseDates.map((e) => new Date(moment(e, "DD-MM-YYYY").format()))
    //   )
    // )
    //   .toLocaleDateString("en-GB", {
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //   })
    //   .split("/")
    //   .join("-");
    const lastEntry = responseDates[responseDates.length - 1];
    setLatestDate(lastEntry);
    setLatestDateData({ ...pilData[lastEntry] });
  }, [pilData, responseDates]);

  // Dealer Name API call
  useEffect(() => {
    setIsDealerData("Loading")
    if (selectedTerritory?.SH2_Code__c !== null) {
      (async () => {
        let SH2_Code__c = selectedTerritory?.SH2_Code__c;
        const response = await getDealerApi(SH2_Code__c);
        if (response?.success) {
          if (response?.data?.length === 0) {
            setIsDealerData("false")
          } else {
            setDealerOption(response?.data?.Dealer);
            setCategory([]);
            setCategoryValue("");
            setIsDealerData("true")
          }
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [selectedTerritory]);

  // to get Dealer Name when User is RMDM/RBDM
  useEffect(() => {
    if (
      !_.isEmpty(cluster?.CLUSTER_CODE__c) &&
      (userType === "MH3" || userType === "RBDM Collaborator")
    ) {
      (async () => {
        const response = await getRmdmWssApi([cluster?.CLUSTER_CODE__c]);
        if (response?.success) {
          let arr = [];
          await response?.data?.map((item) =>
            arr.push(item?.WSS_Territory_Code__c)
          );
          const res = await getDealerNameApi(arr);
          if (res?.success) {
            setDealerOption(res?.data?.records);
          }
        }
      })();
    }
  }, [cluster]);

  // to get territory picklist when user is ZSM
  useEffect(() => {
    if (!_.isEmpty(sh3CodeForTerritory)) {
      (async () => {
        const response = await getZSMTerritoryApi(sh3CodeForTerritory);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList("");
        }
      })();
    }
  }, [sh3CodeForTerritory]);

  const getPilData = async () => {
    try {
      const result = await getPilDataApi(dealer, categoryValue);
      const { data, uniqueBrands, uniqueDates } = result?.data;
      setPilData(data);
      setBrands(uniqueBrands);
      setResponseDates(uniqueDates);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dealer && categoryValue) {
      getPilData();
    }
  }, [categoryValue, dealer]);

  const getPilCategory = async () => {
    try {
      setIsCategoryData("Loading")
      if (dealer !== null){
        const response = await getPilCategoryApi(dealer);
        if (response?.status === 200) {
          if (response?.data?.length === 0) {
            setIsCategoryData("false")
          } else {
            setCategory(response?.data);
            setIsCategoryData("true")
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dealer) {
      getPilCategory();
    }
    setPilData([])
    setBrands([])
    setResponseDates([])
    setLatestDateData({})
    setLatestDate({})
  }, [dealer]);

  useEffect(() => {
    var data = latestDateData;
    if (!_.isEmpty(data)) {
      const keys = Object.entries(data).filter(([key, value]) => value.value !== '0').map(([key, value]) => key);
      const values = Object.entries(data).filter(([key, value]) => value.value !== '0').map(([key, value]) => value.value);
      // const keys = Object.keys(data);
      // const values = Object.values(data).map(item => item.value);
      const pieChartdata = {
        labels: keys,
        values: values,
      };
      setPieChartData(pieChartdata)
    }
  }, [latestDateData]);

  return (
    <>
      <div className="container">
        <div className="row">
          {(userType === "SH6" ||
            userType === "SH5" ||
            userType === "SH7" ||
            userType === "MH3" ||
            userType === "RBDM Collaborator") && (
              <>
                <Autocomplete
                  id="grouped-demo"
                  value={cluster}
                  className="myDealer__categorySelect"
                  options={
                    clusterCodeOption?.length === 0
                      ? [
                        {
                          SH3_Name__c: "Loading...",
                          SH3_Code__c: "",
                          Cluster_Description__c: "Loading...",
                          CLUSTER_CODE__c: "",
                        },
                      ]
                      : getUniqueListBy(
                        clusterCodeOption,
                        userType === "MH3" || userType === "RBDM Collaborator"
                          ? "CLUSTER_CODE__c"
                          : "SH3_Name__c"
                      )
                  }
                  getOptionLabel={(option) =>
                    userType === "MH3" || userType === "RBDM Collaborator"
                      ? `${option?.Cluster_Description__c}${option?.CLUSTER_CODE__c && `:${option?.CLUSTER_CODE__c}`
                      }`
                      : `${option?.SH3_Name__c}${option?.SH3_Code__c && `:${option?.SH3_Code__c}`
                      }`
                  }
                  onChange={(e, newValue) => {
                    setCluster(newValue);
                    setSH3CodeForTerritory(newValue?.SH3_Code__c);
                    setSelectedTerritory(territoryAutoCompletePlaceHolder);
                    setTerritoryPickList([]);
                    setSelectLatLong(dealerPlaceHolder);
                    setDealerOption([]);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select cluster code" />
                  )}
                />
                {userType !== "MH3" && userType !== "RBDM Collaborator" && (
                  <Autocomplete
                    id="grouped-demo"
                    className="myDealer__categorySelect"
                    options={
                      territoryPickList?.length === 0
                        ? [
                          {
                            SH2_Name__c: "Loading...",
                            SH2_Code__c: "",
                          },
                        ]
                        : getUniqueListBy(territoryPickList, "SH2_Code__c")
                    }
                    getOptionLabel={(option) => {
                      if (option?.SH2_Name__c) {
                        return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
                      } else {
                        return "";
                      }
                    }}
                    value={selectedTerritory}
                    name="dealerId"
                    onChange={(event, newValue) => {
                      setSelectedTerritory(newValue);
                      setSelectLatLong(dealerPlaceHolder);
                      setDealerOption([]);
                      setSelectedCategory({})
                      setCategoryValue("")
                      setPieChartData(pieChartInitialData)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Territory" />
                    )}
                  />
                )}
              </>
            )}

          {userType !== "SH6" &&
            userType !== "SH5" &&
            userType !== "SH7" &&
            userType !== "MH3" &&
            userType !== "RBDM Collaborator" && (
              <Autocomplete
                id="grouped-demo"
                className="myDealer__categorySelect"
                options={
                  !_.isEmpty(tname) ? getUniqueListBy(tname, "SH2_Code__c") : []
                }
                getOptionLabel={(option) => {
                  if (option?.SH2_Name__c) {
                    return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
                  } else {
                    return "";
                  }
                }}
                value={selectedTerritory}
                name="dealerId"
                onChange={(event, newValue) => {
                  setSelectedTerritory(newValue);
                  setSelectLatLong(dealerPlaceHolder);
                  setDealerOption([]);
                  setSelectedCategory({})
                  setCategoryValue("")
                  setPieChartData(pieChartInitialData)
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Territory" />
                )}
              />
            )}

          <Autocomplete
            id="grouped-demo2"
            className="myDealer__categorySelect"
            options={
              isDealerData === "Loading"
                ? [
                  {
                    Dealer_Name__c: "Loading...",
                    DealerCode__c: "",
                  },
                ]
                : isDealerData === "false" ? [
                  {
                    Dealer_Name__c: "No Data",
                    DealerCode__c: "",
                  },
                ] : dealerOption
            }
            getOptionLabel={(option) => {
              if (option?.Dealer_Name__c) {
                return `${option?.Dealer_Name__c} ${option?.DealerCode__c && ":"
                  } ${option?.DealerCode__c}`;
              } else {
                return "";
              }
            }}
            name="subCategory"
            value={selectLatLong}
            onChange={(event, newValue) => {
              setSelectLatLong(newValue);
              setDealer(newValue.DealerCode__c);
              setSelectedCategory({})
              setCategoryValue("")
              setPieChartData(pieChartInitialData)
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Dealer Name" />
            )}
          />
          <Autocomplete
            id="grouped-demo2"
            className="myDealer__categorySelect"
            options={
              isCategoryData === "Loading"
                ? [
                  {
                    CategoryName: "Loading...",
                  },
                ]
                : isCategoryData === "false" ? [
                  {
                    CategoryName: "No Data"
                  },
                ] : Category
            }
            getOptionLabel={(option) => {
              if (option?.CategoryName) {
                return `${option?.CategoryName}`;
              } else {
                return "";
              }
            }}
            name="subCategory"
            value={selectedCategory}
            onChange={(event, newValue) => {
              setSelectedCategory(newValue)
              setCategoryValue(newValue.CategoryName);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Category" />
            )}
          />
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="">
              <div className="pilSurvey_pieBlock_heading">
                <p>PIL Similar Brand Market share(At Cluster level)</p>
              </div>
              <div className="pilSurvey_pieBlock pilSurvey_pieBlock_chart">
                {/* <Pie data={data} options={config.options} /> */}
                {pieChartData.values.length != 0 ?
                  <PieChart data={pieChartData} className="pilSurvey_pieChart" />
                  :
                  <>
                    <Message
                      message={"No data found"}
                      className={"tourPlan__messageConatiner"}
                    />
                  </>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="">
              <div className="pilSurvey_pieBlock_heading">
                <p>Brand wise PIL Similar Value(Rs)/Volume(Kg or Litre)</p>
              </div>
              <div className="pilSurvey_pieBlock">
                <PILTable
                  pilData={pilData}
                  brands={brands}
                  responseDates={responseDates}
                />
              </div>
            </div>
            <div className="">
              <div className=" pilSurvey_pieBlock_heading">
                <p>Brand wise last recorded Value(Rs)/Volume(Kg or Litre)</p>
              </div>
              <div className="pilSurvey_pieBlockend">
                <PILTableSec
                  latestDateData={latestDateData}
                  brands={brands}
                  latestDate={latestDate}
                  categoryValue={categoryValue}
                  dealer={dealer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PILSuervey;
