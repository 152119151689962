import { getDivisionGroupApi } from "../../services/DivisonGroup/getDivisionGroupApi";
import {
  GET_DIVISION_GROUP_FAIL,
  GET_DIVISION_GROUP_SUCCESS,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

export const getDivisionGroup = () => async (dispatch) => {
  const response = await getDivisionGroupApi();
  if (response?.status === 200) {
    dispatch({
      type: GET_DIVISION_GROUP_SUCCESS,
      payload: response?.data,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_DIVISION_GROUP_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};
