import axios from "axios";
import { BASE_URL } from "../../variables";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";

export const addProfileImageApi =
  ({ id, username, Image }) =>
  //   console.log("userName",userName);
  async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/data/v56.0/sobjects/User/${id}`,
      method: "patch",
      data: { Photo__c: Image },
    };
    try {
      const response = await axios(options);
      if (response.status === 204) {
        return {
          success: true,
          data: response?.data,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        sessionExpireTimeout(dispatch);
      } else {
        return {
          success: false,
          msg: "Somthing Went Wrong..!!",
        };
      }
    }
  };
