import axios from "axios";
import { BASE_URL } from "../../../variables";

export const getDealerApi = async (sh2Code, isChecked) => {
  const options = {
    url: `${BASE_URL}/services/apexrest/MyDealer/`,
    method: "post",
    data: {
      code: sh2Code,
      isjointlyadopteddealer: isChecked
    },
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
