import React, { useEffect, useRef } from "react";
import MyDealers from "../Components/MarketWorking/MyTeam/MyDealers";
import MyDiary from "../Components/MarketWorking/MyTeam/MyDiary";
import MySR from "../Components/MarketWorking/MyTeam/MySR";
import PageHeader from "../Components/Common/PageHeader";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

function MyDealer() {
  const location = useLocation();
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (location?.state?.from == true) {
      scrollToBottom();
    }
  }, [location?.state]);

  return (
    <>
      <PageHeader title="Market Working" path="/" />
      <div className="container p-0">
        <MyDealers />
        <MyDiary />
        <MySR />
        <div ref={messagesEndRef} />
      </div>
    </>
  );
}

export default MyDealer;
