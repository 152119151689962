import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import {
  getFirstDayOfPastThreeMonth,
  getLastDateOfPastThreeMonths,
} from "../../Components/Common/Function";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getOutletsBilledFtmTrendApi = async (SH_Code__c, Title) => {
  const lastDates = getLastDateOfPastThreeMonths();
  const firstDates = getFirstDayOfPastThreeMonth();

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/salesOperation/get-outlet-billed-ftm-trend`,
    method: "post",
    headers: headers,
    data: { lastDates: lastDates, firstDates: firstDates, SH_Code__c, Title },
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
