import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import PageHeader from "../Components/Common/PageHeader";
import PILSuervey from "../Components/PILSurvey/PILSuervey";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

const PILSurveyPage = () => {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <div>
      <PageHeader title="PIL Survey" path="/" />
      <Container style={{ backgroundColor: "#F5F5F5" }}>
        <PILSuervey />
      </Container>
    </div>
  );
};

export default PILSurveyPage;
