import React from 'react'
import { Pie, Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js';

const PieChart = ({ data }) => {
    const options = {
        plugins: {
            datalabels: {
                color: '#fff',
                formatter: (value, ctx) => {
                    const intArray = ctx.chart.data.datasets[0].data.map(str => parseInt(str));
                    let sum = intArray.reduce((acc, val) => acc + val, 0);
                    let percentage = ((value / sum) * 100).toFixed(0) + '%';
                    return percentage;
                },
            },
        },
    };

    const chartData = {
        labels: data.labels,
        datasets: [
            {
                data: data.values,
                backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    "rgba(255, 99, 132, 1.5)",
                    "rgba(54, 162, 235, 1.5)",
                    "rgba(255, 206, 86, 1.5)",
                    "rgba(75, 192, 192, 1.5)"
                ],
            },
        ],
    };

    return <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />;
}

export default PieChart