import React from 'react';
import QRCode from 'qrcode.react';
import "./DRMPlanOne.css"
import CryptoJS from 'crypto-js';
import { getNodeServerUrl } from '../../utils/getNodeUrl';

function encryptParam(data, key) {
    return CryptoJS.AES.encrypt(data, key).toString();
}

function createEncryptedUrl(meetingdetails, key) {
    const nodeBaseUrl = getNodeServerUrl();
    let encryptedId = encryptParam(meetingdetails?.Id, key);
    let encryptedDate = encryptParam(meetingdetails?.Actual_Date_of_the_Meet__c, key);   
    let url = `${nodeBaseUrl}/DRMfeedbackForm/?id=${encodeURIComponent(encryptedId)}&date=${encodeURIComponent(encryptedDate)}`;

    return url;
}

const QRCodeGenerator = ({meetingdetails}) => {

    const secretKey = 'ThisIsASecretKey';
    const encryptedUrl = createEncryptedUrl(meetingdetails, secretKey);

    return ( <>
        <div className='QrCode'>
        <QRCode className='QrImage' value={encryptedUrl} />
        </div>  
       
    </> );
}
 
export default QRCodeGenerator;