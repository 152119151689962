import _ from "lodash";
import Avatar from "react-avatar-edit";
import React, { useEffect, useState } from "react";
import { BsCamera } from "react-icons/bs";
import { useSelector } from "react-redux";
import styles from "./Profile.module.css";
import { TfiEmail } from "react-icons/tfi";
import { CgProfile } from "react-icons/cg";
import { FaThumbsUp } from "react-icons/fa";
import ModalPopup from "../Common/ModalPopup";
import Dropdown from "react-bootstrap/Dropdown";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineCamera } from "react-icons/ai";
import { VscDeviceMobile } from "react-icons/vsc";
import { connect, useDispatch } from "react-redux";
import img from "../../Assets/Images/profile11.png";
import { addProfileImageApi } from "../../services/HomePage/addProfileImageApi";
import { getUserDetails } from "../../Redux/actions/utility-actions";

const Profile = ({ addProfileImageApi }) => {
  const userName = useSelector((state) => state?.userDetails?.details);
  const UserType = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title)
  const [imgCrop, setImgCrop] = useState("");
  const [storeImage, setStoreImage] = useState(userName?.Photo__c);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [modalPopupMsg, setModalPopupMsg] = useState("");
  const [validationError, setValidationError] = useState({
    size: "",
  });
  const dispatch = useDispatch();

  // to crop image
  const onCrop = (view) => {
    setImgCrop(view);
  };

  // On close of popup
  const onClose = () => {
    setValidationError({
      size: "",
    });
    setImgCrop(null);
  };

  // to get the file size
  const onBeforeFileLoad = (file) => {
    if (file?.nativeEvent?.srcElement?.files[0]?.size > 5242880) {
      setValidationError({
        size: "Please upload the file whose size is less than 5MB",
      });
    }
  };

  // on save image
  const saveImage = async () => {
    if (_.isEmpty(validationError?.size)) {
      setStoreImage(imgCrop?.split(",")?.[1]);
      setIsSubmitModalOpen(false);
      setImgCrop("");
      setIsSave(true);
      setModalPopupMsg("Uploaded Successfully");
    }
  };

  // to Delete profile image
  const removeProfileImage = async () => {
    setIsSave(true);
    setStoreImage(" ");
    setModalPopupMsg("Removed Successfully");
  };

  // to open modal popup
  const handleShow = () => {
    setIsSubmitModalOpen(true);
  };

  // to set image
  useEffect(() => {
    setStoreImage(userName?.Photo__c)
    setIsUploaded(false);
  }, [userName])

  //post api call of Image upload
  useEffect(() => {
    if (!_.isEmpty(storeImage) && isSave) {
      (async () => {
        const data = {
          id: userName?.Id,
          username: userName?.Email,
          Image: storeImage,
        };
        const { id, username, Image } = data;
        const response = await addProfileImageApi({ id, username, Image });
        if (response?.success) {
          dispatch(getUserDetails({ username }));
          setIsUploaded(true);
          //TODO: to show success msg popup if required
        } else {
          //TODO : to show error msg popup
        }
        setIsSave(false);
      })();
    }
  }, [storeImage]);

  return (
    <>
      <div className={styles.profile_OuterBox}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className={styles.profile_name}>{userName?.Name}</div>
            <div
              className={` col-md-12 text-center  ${styles.profile_InnerBox}`}
            >
              <img
                className={`mb-2 ${styles.profile_image}`}
                src={
                  !_.isEmpty(userName.Photo__c) && !_.isEmpty(storeImage)
                    ? `data:image/png;base64,${storeImage}`
                    : img
                }
                alt=""
              />
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.profile_dropdown}
                >
                  <BsCamera
                    className={styles.profile__menuIcon}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={styles.profile__dropdownMenu}
                >
                  <Dropdown.Item
                    href="#/action-1"
                    className={styles.profile__dropdownItems}
                    onClick={handleShow}
                  >
                    <AiOutlineCamera onClick={removeProfileImage} className={styles.profile_editIcon} /> Upload Image
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item
                    href="#/action-1"
                    className={`${styles.profile__dropdownItems} ${styles.Profile_dropdownLabel}`}
                    onClick={removeProfileImage}
                  >
                    <RiDeleteBinLine onClick={removeProfileImage} className={styles.profile_removeIcon} /> Remove
                    Image
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <ModalPopup
                className="ModalPopup_EditProfileSubmit"
                isModalOpen={isSubmitModalOpen}
                setIsModalOpen={setIsSubmitModalOpen}
                backdrop={true}
                fade={false}
                toShowBody={false}
                icon={
                  <>
                    <div className={`text-center ${styles.profile_model}`}>
                      <Avatar
                        width={200}
                        height={200}
                        onClose={onClose}
                        onCrop={onCrop}
                        onBeforeFileLoad={onBeforeFileLoad}
                      />
                    </div>
                  </>
                }
                showButton={
                  <>
                    <div className="text-center">
                      <p
                        className={
                          _.isEmpty(validationError?.size)
                            ? `${styles.profile_error}`
                            : `${styles.profile_errorActive}`
                        }
                      >
                        {" "}
                        {!_.isEmpty(validationError?.size)
                          ? validationError?.size
                          : "Please upload Professional Image"}
                      </p>
                      <button
                        className={styles.profile_saveButton}
                        onClick={saveImage}
                      >
                        Save
                      </button>
                    </div>
                  </>
                }
              />
            </div>
            <div className={styles.profile__detailsBox}>
              <div className={styles.profile_label}>
                <VscDeviceMobile className={styles.profile__icon} /> Mobile
              </div>
              <div className={styles.profile_entry}>
                {userName?.MobilePhone ? userName?.MobilePhone : "---"}
              </div>
            </div>
            <div className={styles.profile__detailsBox}>
              <div className={styles.profile_label}>
                <TfiEmail className={styles.profile__icon} /> Email ID
              </div>
              <div className={styles.profile_entry}>{userName?.Email}</div>
            </div>
            <div className={styles.profile__detailsBox}>
              <div className={styles.profile_label}>
                <CgProfile className={styles.profile__icon} /> Profile Type
              </div>
              <div className={styles.profile_entry}>
                {UserType}
              </div>
            </div>
          </div>
          {isUploaded && (
            <ModalPopup
              className=""
              isModalOpen={isUploaded}
              setIsModalOpen={setIsUploaded}
              title={modalPopupMsg}
              backdrop={true}
              fade={false}
              icon={
                <>
                  <div className="submittedIcons draftIcons">
                    <FaThumbsUp className="submittedSmall" />
                    <FaThumbsUp className="submittedLarge" />
                    <FaThumbsUp className="submittedSmall" />
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  addProfileImageApi,
})(Profile);
