import React, { useEffect, useState } from "react";
import style from "./TotalFeedback.module.css";
import { FiCalendar } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { addTodaysTourReportApi } from "../../../services/MarketWorking/addTodaysTourReportApi";
import _ from "lodash";
import NewFeedback from "./NewFeedback";
import { connect, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { FiEdit2 } from "react-icons/fi";
import CommonSnackbar from "../../Common/CommonSnackbar";
import { getTodaysDate } from "../../Common/Function";
import Loader from "../../Common/Loader";

const TotalFeedback = ({ addTodaysTourReportApi }) => {
  const [slicedFeedback, setSlicedFeedback] = useState();
  const initialState = 3;
  const [noOfElement, setNoOfElement] = useState(initialState);
  const [showFeedback, setShowFeedback] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onClick, setOnClick] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [observation, setObservation] = useState("");
  const [id, setId] = useState();
  const feedBack = useSelector(
    (state) => state?.getFeedback?.getFeedbackDetails?.Feedback_Today
  );

  // to slice the total feedback
  useEffect(() => {
    setSlicedFeedback(feedBack?.slice(0, noOfElement));
  }, [feedBack, noOfElement]);

  // load more
  const loadMore = (e) => {
    e.preventDefault();
    if (noOfElement === initialState) {
      setNoOfElement(feedBack.length);
    } else setNoOfElement(3);
  };

  // on close snackbar
  const handleOnClose = () => {
    setSuccessOpen(false);
  };

  const onSubmit = async (id) => {
    if (!_.isEmpty(observation)) {
      const data = {
        RecordId: id,
        Observation: observation,
      };
      const { RecordId, Observation } = data;
      setLoading(true);
      const response = await addTodaysTourReportApi({
        RecordId,
        Observation,
      });
      setLoading(false);
      if (response?.success) {
        setSuccessOpen(true);
        setId();
        //TODO: to show success msg popup if required
      } else {
        //TODO : to show error msg popup
      }
      setObservation("");
    }
  };

  return (
    <>
      <>
        <CommonSnackbar
          open={successOpen}
          autoHideDuration={3000}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          severity={"success"}
          message={"Observation edited successfully"}
        />
      </>
      <div className={style.totalFeedback}>
        <h6 className={style.totalFeedback__heading}>Total Observations</h6>
        <Loader loading={loading} />
        <div className={`${style.totalFeedback__box}`}>
          <div className="row m-0">
            <div className={`col-6`}>
              <h6 className={style.totalFeedback__toDay}>Today</h6>
            </div>
            <div className={`col-6`}>
              <h6 className={style.totalFeedback__count}>
                {feedBack && feedBack?.length} Observations
              </h6>
            </div>
          </div>
          <div className="row m-0" style={{ paddingRight: "4.375rem" }}>
            <div className={`col-4 ${style.totalFeedback__date}`}>
              <div className="col-1">
                <FiCalendar />
              </div>
              <div className="col-3">
                <p>{getTodaysDate()}</p>
              </div>
            </div>
          </div>
          <div className={style.totalFeedback__dataOuterDiv}>
            {slicedFeedback &&
              slicedFeedback?.map((item) => {
                return (
                  <div
                    className={`row mx-0 ${style.totalFeedback__FeedbackText}`}
                    // key={item?.id}
                  >
                    <div
                      className={`p-0 ${style.totalFeedback__FeedbackText_p}`}
                    >
                      <textarea
                        type="text"
                        onChange={(e) => setObservation(e.target.value)}
                        disabled={item?.Id === id ? false : true}
                        className={
                          onClick && item.Id === id
                            ? style.totalFeedback__todayInnerBoxEditText
                            : style.totalFeedback__todayInnerBoxText
                        }
                      >
                        {item.Observations__c}
                      </textarea>

                      <div className={style.totalFeedback__edit}>
                        {onClick === true && item.Id === id ? (
                          <button
                            className={style.totalFeedback__todaySubmitBtn}
                            id={item?.Id}
                            onClick={() => {
                              onSubmit(item?.Id);
                              setOnClick(false);
                            }}
                          >
                            Submit
                          </button>
                        ) : (
                          <FiEdit2
                            className={style.totalFeedback__todayEditBtn}
                            onClick={() => {
                              setId(item?.Id);
                              setOnClick(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className="row m-0"
            style={{
              display: "flex",
              justifyContent: "end",
              height: "1.6rem",
              paddingTop: "2px",
            }}
          >
            {" "}
            <div className="col-3">
              {feedBack?.length > 3 ? (
                <>
                  <button
                    className={style.totalFeedback__bottom_button}
                    onClick={loadMore}
                  >
                    {!_.isEmpty(slicedFeedback) ? (
                      noOfElement == initialState ? (
                        <div className={style.totalFeedback__bottom_section}>
                          <p className={style.totalFeedback__seeAll}>
                            View all
                          </p>
                          <BsChevronDown
                            className={style.totalFeedback__seeAll_icon}
                          />
                        </div>
                      ) : (
                        <div className={style.totalFeedback__bottom_section}>
                          <p className={style.totalFeedback__seeAll}>
                            See less
                          </p>
                          <BsChevronUp
                            className={style.totalFeedback__seeAll_icon}
                          />
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewFeedback setShowFeedback={setShowFeedback} />
    </>
  );
};

export default connect(null, {
  addTodaysTourReportApi,
})(TotalFeedback);
