import React, { useEffect, useState } from "react";
import { MdSignalWifiConnectedNoInternet4 } from "react-icons/md";
import CommonSnackbar from "./CommonSnackbar";

const OnlineStatus = () => {
  const [online, setOnline] = useState(true);
  const [successOpen, setSuccessOpen] = useState(false);

  useEffect(() => {
    if (navigator.onLine) {
      setOnline(true);
    } else {
      setOnline(false);
    }

    window.addEventListener("offline", function (e) {
      setOnline(false);
    });
    window.addEventListener("online", function (e) {
      setOnline(true);
      setSuccessOpen(true);
    });
  }, []);

  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  return (
    <div>
      {online === true ? (
        <>
          <CommonSnackbar
            open={successOpen}
            autoHideDuration={3000}
            onClose={handleOnClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            severity={"success"}
            message={"Back online!"}
          />
        </>
      ) : (
        <>
          <div
            style={{
              background: "black",
              height: "1rem",
              width: "100%",
              position: "fixed",
              bottom: "0",
            }}
          >
            <p
              style={{ color: "white", textAlign: "center", fontSize: "12px" }}
            >
              <MdSignalWifiConnectedNoInternet4
                style={{ marginBottom: "3px", marginRight: "2px" }}
              />{" "}
              No internet connection
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default OnlineStatus;
