import axios from "axios";
import { BASE_URL } from "../../variables";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";

export const getDealerNameApiForHOUser =
  (divisionGroup, salesGroup, sh2Code) => async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/data/v56.0/query?q=select id,Dealer_Name__c ,DealerCode__c ,TSICode__c from account where SalesGroupCode__c= '${salesGroup}' AND Division_Code__c = ${divisionGroup}  AND RecordType.Name = 'Dealer' AND TSICode__c ='${sh2Code}'`,
      method: "get",
    };
    try {
      const response = await axios(options);
      if (response.status === 200) {
        return {
          success: true,
          data: response?.data?.records,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        sessionExpireTimeout(dispatch);
      } else {
        return {
          success: false,
          msg: "Somthing Went Wrong..!!",
        };
      }
    }
  };
