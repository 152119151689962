import axios from "axios";
import { BASE_URL } from "../../variables";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";

export const addMarketVisitReportApi = (allData) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/composite/tree/Market_Visit_Report__c/`,
    method: "post",
    data: allData,
  };

  try {
    const response = await axios(options);
    if (
      response.status === 201 ||
      (response?.results?.length > 0 && response?.hasErrors === false)
    ) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
