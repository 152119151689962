import React, { useState, useEffect, useRef } from "react";
import styles from "./NewFeedback.module.css";
import "../../Common/SearchableSelect.css";
import { AiOutlineFile } from "react-icons/ai";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../HomePage/TabComponent.css";
import { Button } from "react-bootstrap";
import { AiOutlineShareAlt } from "react-icons/ai";
import { RWebShare } from "react-web-share";
import { addFeedbackApi } from "../../../services/MarketWorking/MyDiary/addFeedbackApi";
import PreviousObservation from "./PreviousObservation";
import _ from "lodash";
import ModalPopup from "../../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import { useSelector, connect } from "react-redux";
import { addDealerNameApi } from "../../../services/MarketWorking/MyTeam/addDealerNameApi";
import { getDealerNameApiForHOUser } from "../../../services/hoUser/getDealerNameApiForHOUser";
import { addWSSNameApiForHOUser } from "../../../services/hoUser/addWSSNameApiForHOUser";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { compressImage, getUniqueListBy } from "../../Common/Function";
import Loader from "../../Common/Loader";
import { getZSMTerritoryApi } from "../../../services/zsm/getZSMTerritoryApi";
import { getZSMDealerNameApi } from "../../../services/zsm/getZSMDealerNameApi";
import { getZSMWssNameApi } from "../../../services/zsm/getZSMWssNameApi";
import { clusterCodePlaceHolder } from "../../Common/Constant";
import { getRmdmWssApi } from "../../../services/rmdm/getRmdmWssApi";
import { getDealerNameApi } from "../../../services/rmdm/getDealerNameApi";

const territoryAutoCompletePlaceHolder = {
  attributes: {},
  Id: "",
  SH2_Name__c: "",
  SH2_Code__c: "",
  Name: "",
  SH3_Code__c: "",
};

const wssOrDelaerPlaceHoder = {
  Dealer_Name__c: "",
  DealerCode__c: "",
  CustomerName__c: "",
  Customer_Code__c: "",
  Product_Group_3_Description__c: "",
};

const rmdmWssPlaceholder = {
  CustomerName__c: "",
  Customer_Code__c: "",
};

const NewFeedback = ({
  addFeedbackApi,
  setShowFeedback,
  getZSMTerritoryApi,
  getZSMDealerNameApi,
  getZSMWssNameApi,
  getDealerNameApiForHOUser,
  getRmdmWssApi,
  getDealerNameApi,
}) => {
  const tabs = ["Dealer", "WSS", "Product", "Team"];
  const [tab, setTab] = useState("Dealer");
  const [dealerOption, setDealerOption] = useState([]);
  const [error, setError] = useState();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [dealerID, setDealerID] = useState("");
  const [sh2Code, setSh2Code] = useState("");
  const [feedback, setFeedback] = useState({
    territory: "",
    dealerId: "",
    Title: "",
    Observation: "",
  });

  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [recordType, setRecordType] = useState("Dealer");
  const [isSubmit, setIsSubmit] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [wss, setWSS] = useState([]);
  const [sh3CodeForTerritory, setSH3CodeForTerritory] = useState("");
  const formRef = useRef();
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [territory, setTerritory] = useState(territoryAutoCompletePlaceHolder);
  const [cluster, setCluster] = useState(clusterCodePlaceHolder);
  const [wssDealerOrProduct, setWssDealerOrProduct] = useState(
    wssOrDelaerPlaceHoder
  );
  const [dealerLoader, setDealerLoader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [rmdmWssList, setRmdmWssList] = useState([]);
  const [rmdmWssValue, setRmdmWssValue] = useState(rmdmWssPlaceholder);

  const onChange = async (e, newValue, name) => {
    if (e.target.name === "clusterCode") {
      setSH3CodeForTerritory(e?.target?.value);
      setTerritory(territoryAutoCompletePlaceHolder);
      setWssDealerOrProduct(wssOrDelaerPlaceHoder);
      setDealerOption([]);
      setTerritoryPickList([]);
    }
    if (name !== "territory" && name !== "dealerId") {
      setFeedback({
        ...feedback,
        [e.target.name || name]: e.target.value || "",
      });
    }
    if (name === "dealerId") {
      setFeedback({
        ...feedback,
        [e.target.name || name]: e.target.value || `${newValue.Id}`,
      });
    }

    if (name === "rmdmWss") {
      setFeedback({
        ...feedback,
        [e.target.name || name]: newValue,
      });
    }

    if (name === "territory") {
      setWssDealerOrProduct(wssOrDelaerPlaceHoder);
      setDealerOption([]);
      setFeedback({
        ...feedback,
        [name]: `${newValue.SH2_Name__c}:${newValue.SH2_Code__c}`,
      });

      setRecordType("Dealer");
      setSh2Code(newValue.SH2_Code__c);
    }
    if (tab === "Team") {
      setDealerID(feedback?.territory);
    } else {
      setDealerID(feedback?.dealerId);
    }
  };

  //to get territory list from redux
  const territoryOptions = useSelector(
    (state) => state?.territory?.territoryDetails
  );

  // to get cluster Code when user is ZSM
  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );

  // to get list of product
  const products = useSelector((state) => state?.product?.productList);

  const BMWss = useSelector((state) => state?.bmWss?.wssList);

  //to get Division and sales group for Ho user
  const hoDivisionGroup = territoryOptions?.[0]?.Division_Code__c;
  const hoSalesGroup = territoryOptions?.[0]?.Sales_Group__c;

  //to get user Type
  const userType = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);

  //to get Dealer Name when user is Bm (SH3)
  useEffect(() => {
    if (!_.isEmpty(recordType) && recordType !== "Team" && userType === "SH3") {
      (async () => {
        let response;
        if (
          recordType === "Dealer" &&
          recordType !== "Product" &&
          !_.isEmpty(feedback?.territory)
        ) {
          setDealerLoader(true);
          response = await addDealerNameApi(tab, sh2Code);
        } else if (recordType === "WSS") {
          setDealerLoader(true);
          response = await addDealerNameApi(tab);
        }
        if (response?.success) {
          setDealerOption(response?.data);
          setDealerLoader(false);
          if (response?.data?.Name) {
            const FilterWss = getUniqueListBy(
              response?.data?.Name,
              "CustomerName__c"
            );
            setWSS(FilterWss);
            setDealerLoader(false);
          }

          setRecordType("");
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordType, feedback?.territory]);

  //to get Dealer Name when user is  HO Collaborator
  useEffect(() => {
    if (
      !_.isEmpty(recordType) &&
      recordType !== "Team" &&
      userType === "HO Collaborator"
    ) {
      (async () => {
        let response;
        if (recordType === "Dealer" && !_.isEmpty(feedback?.territory)) {
          setDealerLoader(true);
          response = await getDealerNameApiForHOUser(
            hoDivisionGroup,
            hoSalesGroup,
            sh2Code
          );
        }
        if (recordType === "WSS" && !_.isEmpty(feedback?.clusterCode)) {
          setDealerLoader(true);
          response = await addWSSNameApiForHOUser(
            hoDivisionGroup,
            hoSalesGroup,
            feedback?.clusterCode
          );
        }

        if (response?.success) {
          setDealerLoader(false);
          setDealerOption(response?.data);
          if (recordType === "WSS") {
            const FilterWss = getUniqueListBy(
              response?.data,
              "CustomerName__c"
            );
            setDealerLoader(false);
            setWSS(FilterWss);
          }
          // setRecordType("");
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordType, feedback?.clusterCode, feedback?.territory]);

  //to get Dealer Name when user is ZSM
  useEffect(() => {
    if (
      !_.isEmpty(recordType) &&
      recordType !== "Team" &&
      (userType === "SH6" || userType === "SH5" || userType === "SH7")
    ) {
      (async () => {
        let response;
        if (recordType === "Dealer" && !_.isEmpty(feedback?.territory)) {
          setDealerLoader(true);
          response = await getZSMDealerNameApi(
            sh3CodeForTerritory,
            feedback?.territory?.split(":")?.[1]
          );
        }
        if (recordType === "WSS" && !_.isEmpty(feedback?.clusterCode)) {
          setDealerLoader(true);
          response = await getZSMWssNameApi(feedback?.clusterCode);
        }
        if (response?.success) {
          setDealerLoader(false);
          setDealerOption(response?.data);
          if (recordType === "WSS") {
            const FilterWss = getUniqueListBy(
              response?.data,
              "CustomerName__c"
            );
            setDealerLoader(false);
            setWSS(FilterWss);
          }
          // setRecordType("");
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordType, feedback?.clusterCode, feedback?.territory]);

  // to get Wss when user is RMDM/RBDM
  useEffect(() => {
    if (!_.isEmpty(feedback?.clusterCode) && recordType !== "WSS") {
      (async () => {
        const response = await getRmdmWssApi(feedback?.clusterCode);
        if (response?.success) {
          setWSS(response?.data);
          // setRmdmWssList(response?.data);
        }
      })();
    }
  }, [feedback?.clusterCode]);

  // to get Dealer Name when user is RMDM/RBDM
  useEffect(() => {
    if (!_.isEmpty(recordType) && recordType !== "Team" && (userType === "MH3" || userType === "RBDM Collaborator")) {
      (async () => {
        let response;
        if (recordType === "Dealer" && !_.isEmpty(feedback?.rmdmWss)) {
          setDealerLoader(true);
          response = await getDealerNameApi([feedback?.rmdmWss]);
        }
        if (recordType === "WSS" && !_.isEmpty(feedback?.clusterCode)) {
          setDealerLoader(true);
          response = await getRmdmWssApi(feedback?.clusterCode);
        }
        if (response?.success) {
          setDealerLoader(false);
          setDealerOption(response?.data?.records);
          if (recordType === "WSS") {
            const FilterWss = getUniqueListBy(
              response?.data,
              "Customer_Code__c"
            );
            setDealerLoader(false);
            setWSS(FilterWss);
          }
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordType, feedback?.rmdmWss, feedback?.clusterCode]);

  // Attchment File base8=64 Conversion

  const FileHandler = async (e) => {
    setIsUploading(true);
    setFormValidation({});
    if (e.target.files[0]?.size < 5242880) {
      let image = "";
      if (e.target.files[0]?.name?.split(".")?.slice(-1) != "pdf") {
        image = await compressImage(e.target.files[0]);
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFile({
          base64: reader.result.replace("data:", "").replace(/^.+,/, ""),
          fileExtension: e.target.files[0]?.name
            ?.split(".")
            ?.slice(-1)
            ?.toString(),
          fileName: e.target.files[0]?.name,
        });
      };
      if (!_.isEmpty(image)) {
        reader.readAsDataURL(image);
      } else {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      setFile({
        base64: "",
        fileExtension: "",
        fileName: "",
      });
      setFormValidation({
        file: "Please upload the file whose size is less than 5MB",
      });
    }
    setIsUploading(false);
  };

  //validation check
  const checkValidation = (values) => {
    const errors = {};
    if (
      (userType === "SH6" || userType === "SH5" || userType === "SH7") &&
      (tab === "Dealer" || tab === "Team")
    ) {
      if (_.isEmpty(sh3CodeForTerritory)) {
        errors.clusterCode = "Please select the cluster code";
      }
    }
    if (
      (tab === "Dealer" || tab === "Team") &&
      // userType != "HO Collaborator" &&
      (userType != "MH3" && userType != "RBDM Collaborator")
    ) {
      if (!values.territory) {
        errors.territory = "Please select the territory";
      }
    }

    if (tab !== "Team") {
      if (!values.dealerId) {
        errors.dealerId = "Please select the field";
      }
    }

    if (tab === "Dealer" && (userType === "MH3" || userType === "RBDM Collaborator") && !values.rmdmWss) {
      errors.rmdmWss = "Please select the WSS";
    }

    if (!values.Title) {
      errors.Title = "Please enter the Title";
    }
    if (!values.Observation) {
      errors.Observation = "Please enter the Observation";
    }
    if (formValidation.file) {
      errors.file = "Please upload the file whose size is less than 5MB";
    }

    return errors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setShowFeedback(false);
    setFormValidation(checkValidation(feedback));
  };

  //post api call
  useEffect(() => {
    if (Object?.keys(formValidation)?.length === 0 && isSubmit) {
      (async () => {
        const data = {
          dealerId: dealerID,
          Title: feedback?.Title,
          Observation: feedback?.Observation,
          recordType: `My ${tab}`,
          fileExtension: file?.fileExtension,
          fileName: file?.fileName,
          base64: file?.base64,
        };
        const {
          dealerId,
          Title,
          Observation,
          recordType,
          fileExtension,
          fileName,
          base64,
        } = data;
        setLoading(true);
        const response = await addFeedbackApi({
          dealerId,
          Title,
          Observation,
          recordType,
          fileExtension,
          fileName,
          base64,
        });
        setLoading(false);
        if (response?.success) {
          setShowFeedback(true);
          setIsSubmitModalOpen(true);
          setRmdmWssValue(rmdmWssPlaceholder);
          setIsSubmit(false);
          //TODO: to show success msg popup if required
        } else {
          //TODO : to show error msg popup
        }
        setTerritory(territoryAutoCompletePlaceHolder);
        setCluster(clusterCodePlaceHolder);
        setWssDealerOrProduct(wssOrDelaerPlaceHoder);
        setFeedback({
          territory: "",
          dealerId: "",
          Title: "",
          Observation: "",
        });
        formRef.current.value = "";
        setFile({
          base64: "",
          fileExtension: "",
          fileName: "",
        });
        setSH3CodeForTerritory("");
      })();
    }
    if (Object?.keys(formValidation)?.length !== 0) {
      setIsSubmit(false);
    }
    // setLoading(false);
  }, [isSubmit]);

  // to get territory picklist when user is ZSM,NSM or HO Collaborator
  useEffect(() => {
    if (
      !_.isEmpty(sh3CodeForTerritory) &&
      (recordType === "Dealer" || recordType === "Team") &&
      userType !== "SH3" &&
      (userType != "MH3" && userType != "RBDM Collaborator")
    ) {
      (async () => {
        const response = await getZSMTerritoryApi(sh3CodeForTerritory);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList([]);
        }
      })();
    }
  }, [sh3CodeForTerritory]);

  const getNameList = () => {
    if (tab === "Dealer" && userType === "SH3" && dealerOption?.Name?.length)
      return dealerOption?.Name;

    if (
      tab === "Dealer" &&
      (userType === "HO Collaborator" ||
        userType === "SH6" ||
        userType === "SH5" ||
        userType === "SH7") &&
      dealerOption?.length
    )
      return dealerOption;

    if (tab === "Dealer" && (userType === "MH3" || userType === "RBDM Collaborator")) return dealerOption;

    if (tab === "WSS") {
      if(userType === "SH3"){
        return BMWss
      }else return wss
    }

    if (tab === "Product") return products;
  };

  return (
    <>
      <Loader loading={isUploading} />
      <div>
        {isSubmit && Object?.keys(formValidation)?.length === 0 && (
          <Loader loading={loading} />
        )}

        <div className={styles.newFeedback}>
          <div className={styles.newFeedback__heading}>New Observations</div>
          <div className={styles.newFeedback__mainbox}>
            <Tabs
              defaultActiveKey="Dealer"
              id="fill-tab-example"
              className="newFeedback__tabHeader"
              onSelect={(tab) => {
                setFeedback({
                  territory: "",
                  dealerId: "",
                  Title: "",
                  Observation: "",
                });
                setFormValidation({});
                setRecordType(tab);
                setTab(tab);
                setTerritory(territoryAutoCompletePlaceHolder);
                setCluster(clusterCodePlaceHolder);
                setWssDealerOrProduct(wssOrDelaerPlaceHoder);
                setFeedback({
                  territory: "",
                  dealerId: "",
                  Title: "",
                  Observation: "",
                });
                setDealerOption([]);
                setSH3CodeForTerritory("");
                setTerritoryPickList([]);
                setIsSubmit(false);
              }}
              fill
            >
              {tabs?.map((tab) => {
                return (
                  <Tab eventKey={tab} title={tab}>
                    <div className="newFeedback__tabInnerBox">
                      <div className={styles.newFeedback__outerInputBox}>
                        <form>
                          {(tab === "Dealer" || tab === "Team") && (
                            <>
                              {(userType === "SH6" ||
                                userType === "SH5" ||
                                userType === "SH7" ||
                                userType === "HO Collaborator" ||
                                userType === "MH3" || userType === "RBDM Collaborator") && (
                                <Autocomplete
                                  value={cluster}
                                  id="grouped-demo"
                                  className="myDealer__categorySelect"
                                  options={
                                    !clusterCodeOption ||
                                    clusterCodeOption?.length === 0
                                      ? [
                                          {
                                            SH3_Name__c: "Loading...",
                                            SH3_Code__c: "",
                                            Cluster_Description__c:
                                              "Loading...",
                                            CLUSTER_CODE__c: "",
                                          },
                                        ]
                                      : getUniqueListBy(
                                          clusterCodeOption,
                                          (userType === "MH3" || userType === "RBDM Collaborator")
                                            ? "CLUSTER_CODE__c"
                                            : "SH3_Name__c"
                                        )
                                  }
                                  getOptionLabel={(option) =>
                                    (userType !== "MH3" && userType != "RBDM Collaborator")
                                      ? `${option?.SH3_Name__c}${
                                          option?.SH3_Code__c &&
                                          `:${option?.SH3_Code__c}`
                                        }`
                                      : `${option?.Cluster_Description__c}${
                                          option?.CLUSTER_CODE__c &&
                                          `:${option?.CLUSTER_CODE__c}`
                                        }`
                                  }
                                  onChange={(e, newValue) => {
                                    setCluster(newValue);
                                    onChange({
                                      target: {
                                        name: "clusterCode",
                                        value:
                                          (userType === "MH3" || userType === "RBDM Collaborator")
                                            ? newValue?.CLUSTER_CODE__c
                                            : newValue?.SH3_Code__c,
                                      },
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Select Cluster"}
                                    />
                                  )}
                                />
                              )}
                              {!sh3CodeForTerritory &&
                                formValidation?.clusterCode && (
                                  <p className={styles.myDiary__validation}>
                                    {formValidation?.clusterCode}
                                  </p>
                                )}
                              {(userType === "SH6" ||
                                userType === "SH5" ||
                                userType === "SH7" ||
                                userType === "HO Collaborator") && (
                                <Autocomplete
                                  value={territory}
                                  id="grouped-demo"
                                  className="myDealer__categorySelect"
                                  options={
                                    !territoryPickList ||
                                    territoryPickList?.length === 0
                                      ? [
                                          {
                                            SH2_Name__c: "Loading...",
                                            SH2_Code__c: "",
                                          },
                                        ]
                                      : getUniqueListBy(
                                          territoryPickList,
                                          "SH2_Code__c"
                                        )
                                  }
                                  getOptionLabel={(option) =>
                                    `${option?.SH2_Name__c}${
                                      option?.SH2_Code__c
                                        ? `:${option?.SH2_Code__c}`
                                        : ""
                                    }`
                                  }
                                  onChange={(e, newValue) => {
                                    setTerritory(newValue);
                                    onChange(e, newValue, "territory");
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Select Territory"}
                                    />
                                  )}
                                />
                              )}

                              {(userType === "MH3" || userType === "RBDM Collaborator") && (
                                <Autocomplete
                                  value={rmdmWssValue}
                                  id="grouped-demo"
                                  className="myDealer__categorySelect"
                                  options={
                                    !wss || wss?.length === 0
                                      ? [
                                          {
                                            CustomerName__c: "Loading...",
                                            Customer_Code__c: "",
                                          },
                                        ]
                                      : getUniqueListBy(wss, "Customer_Code__c")
                                  }
                                  getOptionLabel={(option) =>
                                    `${option?.CustomerName__c}${
                                      option?.Customer_Code__c
                                        ? `:${option?.Customer_Code__c}`
                                        : ""
                                    }`
                                  }
                                  onChange={(e, newValue) => {
                                    setRmdmWssValue(newValue);
                                    onChange(
                                      e,
                                      newValue?.WSS_Territory_Code__c,
                                      "rmdmWss"
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Select WSS"}
                                    />
                                  )}
                                />
                              )}
                              {(userType === "MH3" || userType === "RBDM Collaborator" )&&
                                !feedback.rmdmWss &&
                                formValidation?.rmdmWss && (
                                  <p className={styles.myDiary__validation}>
                                    {formValidation?.rmdmWss}
                                  </p>
                                )}

                              {userType === "SH3" && (
                                <Autocomplete
                                  id="grouped-demo"
                                  className="myDealer__categorySelect"
                                  options={
                                    !territoryOptions ||
                                    territoryOptions?.length === 0
                                      ? [
                                          {
                                            SH2_Name__c: "Loading...",
                                            SH2_Code__c: "",
                                          },
                                        ]
                                      : getUniqueListBy(
                                          territoryOptions,
                                          "SH2_Code__c"
                                        )
                                  }
                                  getOptionLabel={(option) =>
                                    `${option?.SH2_Name__c}${
                                      option?.SH2_Code__c
                                        ? `:${option?.SH2_Code__c}`
                                        : ""
                                    }`
                                  }
                                  value={territory}
                                  onChange={(e, newValue) => {
                                    setTerritory(newValue);
                                    onChange(e, newValue, "territory");
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Select Territory"}
                                    />
                                  )}
                                />
                              )}
                            </>
                          )}
                          {!feedback.territory && formValidation?.territory && (
                            <p className={styles.myDiary__validation}>
                              {formValidation?.territory}
                            </p>
                          )}
                          {tab === "WSS" &&
                            (userType === "SH6" ||
                              userType === "SH5" ||
                              userType === "SH7" ||
                              userType === "HO Collaborator" ||
                              userType === "MH3" || userType === "RBDM Collaborator") && (
                              <Autocomplete
                                value={cluster}
                                id="grouped-demo"
                                className="myDealer__categorySelect"
                                options={
                                  clusterCodeOption?.length === 0
                                    ? [
                                        {
                                          SH3_Name__c: "Loading...",
                                          SH3_Code__c: "",
                                          Cluster_Description__c: "Loading...",
                                          CLUSTER_CODE__c: "",
                                        },
                                      ]
                                    : getUniqueListBy(
                                        clusterCodeOption,
                                        (userType === "MH3" || userType === "RBDM Collaborator")
                                          ? "CLUSTER_CODE__c"
                                          : "SH3_Name__c"
                                      )
                                }
                                getOptionLabel={(option) =>
                                  (userType === "MH3" || userType === "RBDM Collaborator")
                                    ? `${option?.Cluster_Description__c}${
                                        option?.CLUSTER_CODE__c &&
                                        `:${option?.CLUSTER_CODE__c}`
                                      }`
                                    : `${option?.SH3_Name__c}${
                                        option?.SH3_Code__c &&
                                        `:${option?.SH3_Code__c}`
                                      }`
                                }
                                onChange={(e, newValue) => {
                                  setCluster(newValue);
                                  onChange({
                                    target: {
                                      name: "clusterCode",
                                      value:
                                        (userType === "MH3" || userType === "RBDM Collaborator")
                                          ? newValue?.CLUSTER_CODE__c
                                          : newValue?.SH3_Code__c,
                                    },
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={"Select Cluster"}
                                  />
                                )}
                              />
                            )}
                          {tab === "WSS" &&
                            !sh3CodeForTerritory &&
                            formValidation?.clusterCode && (
                              <p className={styles.myDiary__validation}>
                                {formValidation?.clusterCode}
                              </p>
                            )}

                          {tab !== "Team" && !_.isEmpty(userType) && (
                            <Autocomplete
                              value={wssDealerOrProduct}
                              id="grouped-demos"
                              className="myDealer__categorySelect"
                              options={
                                !dealerLoader
                                  ? _.isEmpty(getNameList())
                                    ? []
                                    : getNameList()
                                  : [
                                      {
                                        Dealer_Name__c: "Loading...",
                                        DealerCode__c: "",
                                        CustomerName__c: "Loading...",
                                        Customer_Code__c: "",
                                        Product_Group_3_Description__c:
                                          "Loading...",
                                      },
                                    ]
                              }
                              getOptionLabel={(option) => {
                                if (tab === "Dealer") {
                                  return `${option?.Dealer_Name__c}${
                                    option?.DealerCode__c
                                      ? `:${option?.DealerCode__c}`
                                      : ""
                                  }`;
                                }
                                if (tab === "WSS") {
                                  return `${option?.CustomerName__c}${
                                    option?.Customer_Code__c
                                      ? `:${option?.Customer_Code__c}`
                                      : ""
                                  }`;
                                }
                                if (tab === "Product") {
                                  return `${option?.Product_Group_3_Description__c}`;
                                }
                              }}
                              onChange={(e, newValue) => {
                                setWssDealerOrProduct(newValue);
                                // if (tab === "Dealer") {
                                //   setTabAutoCompletePlaceHolder(
                                //     `${newValue.Dealer_Name__c}:${newValue.DealerCode__c}`
                                //   );
                                // }
                                // if (tab === "WSS") {
                                //   setTabAutoCompletePlaceHolder(
                                //     newValue.CustomerName__c
                                //   );
                                // }
                                // if (tab === "Product") {
                                //   setTabAutoCompletePlaceHolder(
                                //     newValue.Product_Group_3_Description__c
                                //   );
                                // }
                                onChange(e, newValue, "dealerId");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={`${tab} name`}
                                />
                              )}
                            />
                          )}
                          {!feedback.dealerId && formValidation?.dealerId && (
                            <p className={styles.myDiary__validation}>
                              {formValidation?.dealerId}
                            </p>
                          )}
                          <input
                            type="text"
                            name="Title"
                            value={feedback?.Title}
                            placeholder="Enter title here "
                            className={
                              !feedback.Title && formValidation?.Title
                                ? styles.newFeedback__inputBoxError
                                : styles.newFeedback__inputBox
                            }
                            onChange={(e) => onChange(e)}
                          />
                          {!feedback.Title && formValidation?.Title && (
                            <p className={styles.myDiary__validation}>
                              {formValidation?.Title}
                            </p>
                          )}
                        </form>
                        <textarea
                          name="Observation"
                          rows="8"
                          placeholder="Enter your observation here"
                          value={feedback?.Observation}
                          className={
                            !feedback.Observation && formValidation?.Title
                              ? styles.newFeedback__textareaError
                              : styles.newFeedback__textarea
                          }
                          onChange={(e) => onChange(e)}
                        ></textarea>
                        {!feedback.Observation &&
                          formValidation?.Observation && (
                            <p className={styles.myDiary__validation}>
                              {formValidation?.Observation}
                            </p>
                          )}

                        {!_.isEmpty(file?.fileName) && (
                          <>
                            <div
                              className={styles.newFeedback__displayFileName}
                            >
                              <div>
                                <AiOutlineFile
                                  className={styles.newFeedback__fileIcon}
                                />
                                <span
                                  className={styles.newFeedback__fileUploadName}
                                >
                                  {file?.fileName}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {formValidation?.file && (
                          <p className={styles.myDiary__validation}>
                            {formValidation?.file}
                          </p>
                        )}

                        <div className="row m-0">
                          <div className="col-8  p-0">
                            <RWebShare
                              data={{
                                text: "Checkout below page for new feedback",
                                url: window.location.href,
                                title: "New Feedback",
                              }}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <p
                                className={`p-0 ${styles.newFeedback__shareTitle}`}
                              >
                                <AiOutlineShareAlt
                                  className={styles.newFeedback__shareIcon}
                                />
                                Share
                              </p>
                            </RWebShare>
                          </div>
                          <div className="col-2 p-0">
                            <div className={styles.newFeedback__inputWrapper}>
                              Attach
                              <input
                                className={`${styles.newFeedback__fileInput}`}
                                type="file"
                                accept=".jpg,.png,.pdf, .jpeg"
                                onChange={FileHandler}
                              />
                            </div>
                          </div>
                          <div className="col-2 p-0">
                            <Button
                              className={styles.newFeedback__submitBtn}
                              onClick={(e) => onSubmit(e)}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
            <PreviousObservation />
          </div>
          {isSubmitModalOpen && (
            <ModalPopup
              className="ModalPopup_Submitted"
              isModalOpen={isSubmitModalOpen}
              setIsModalOpen={setIsSubmitModalOpen}
              title="Submitted Successfully"
              data="New Observations form has been submitted successfully."
              backdrop={true}
              fade={false}
              icon={
                <>
                  <div className="submittedIcons draftIcons">
                    <FaThumbsUp className="submittedSmall" />
                    <FaThumbsUp className="submittedLarge" />
                    <FaThumbsUp className="submittedSmall" />
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  addFeedbackApi,
  getZSMTerritoryApi,
  getZSMDealerNameApi,
  getZSMWssNameApi,
  getDealerNameApiForHOUser,
  getRmdmWssApi,
  getDealerNameApi,
})(NewFeedback);
