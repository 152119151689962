import { getClusterCodeApi } from "../../services/zsm/getClusterCodeApi";
import {
  GET_CLUSTER_CODE_FAIL,
  GET_CLUSTER_CODE_SUCCESS,
} from "../constants/action-types";

export const getClusterCode =
  (SH_Code__c, Title, divisionGroup, salesGroup) => async (dispatch) => {
    const response = await getClusterCodeApi(
      SH_Code__c,
      Title,
      divisionGroup,
      salesGroup
    );
    if (response?.success === true) {
      dispatch({
        type: GET_CLUSTER_CODE_SUCCESS,
        payload: response?.data?.records,
      });
      return response;
    } else {
      dispatch({ type: GET_CLUSTER_CODE_FAIL });
    }
  };
