import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import styles from "./EditFormModal.module.css";
import ModalPopup from "../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import { updateUpcomingTourPlansApi } from "../../services/MonthlyTourPlans/updateUpcomingTourPlansApi";
import { addNewTourWssApi } from "../../services/MonthlyTourPlans/addNewTourWssApi";
import _ from "lodash";
import { useSelector, connect } from "react-redux";
import { getCurrentDate, getUniqueListBy } from "../Common/Function";
import { primaryObjectivesOption, tourTypeOption } from "../Common/Constant";
import { Autocomplete, TextField } from "@mui/material";
import { getRmdmWssApi } from "../../services/rmdm/getRmdmWssApi";
import { getZSMTerritoryApi } from "../../services/zsm/getZSMTerritoryApi";
import { getZSMWssNameApi } from "../../services/zsm/getZSMWssNameApi";

const EditFormModal = ({
  updateUpcomingTourPlansApi,
  record,
  setUpdatedTourPlan,
  addNewTourWssApi,
  getRmdmWssApi,
  getZSMTerritoryApi,
  getZSMWssNameApi,
}) => {
  const [show, setShow] = useState(false);
  const [tourPlan, setTourPlan] = useState({});
  const [date, setDate] = useState();
  const [selectedTerritory, setSelectedTerritory] = useState("");
  const [wssPickList, setWssPickList] = useState([]);
  const [error, setError] = useState("");
  const [newTown, setNewTown] = useState("");
  const [newWssCode, setNewWssCode] = useState("");
  const [pidiliteHierarchyId, setPidiliteHierarchyId] = useState();
  const [sh2Name, setSh2Name] = useState();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [visited, setVisited] = useState("");
  const [cluster, setCluster] = useState([]);
  const [wssRmdmOption, setWssRmdmOption] = useState("");
  const [wssOption, setWssOption] = useState([]);
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [zmsWss, setZsmWss] = useState([]);
  const [userWssArr, setUserWssArr] = useState([]);
  const [userObjectivesArr, setUserObjectivesArr] = useState([]);
  const [independentWssOptions, setIndependentWssOptions] = useState([]);
  const [userTownArr, setUserTownArr] = useState([]);
  const [independentTownOptions, setIndependentTownOptions] = useState([]);
  const territory = useSelector((state) => state.territory.territoryDetails);
  const title = useSelector((state) =>
    state?.userType?.type != "Other"
      ? state?.userType?.type
      : state?.userDetails?.details?.Title
  );
  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );
  const townList = useSelector((state) => state?.rmdmTown?.townList);

  const bmWssList = useSelector((state) => state?.bmWss?.wssList);
  const wssOptionsFromRedux = useSelector(
    (state) => state?.wssOptionsData?.wssCodeList
  );

  const townOptionsFromRedux = useSelector(
    (state) => state?.townOptionsData?.townCodeList
  );

  useEffect(() => {
    const date = new Date(record?.MTP_Date__c);
    setDate(date.toLocaleDateString("en-CA"));
    setUserWssArr(StringSplitterWss(record?.WSS_Name__c))
    setUserTownArr(StringSplitterTown(record?.Town_Name__c))
    setUserObjectivesArr(StringSplitterObjective(record?.Objectives_es__c))
  }, [record]);

  const StringSplitterTown = (inputString) => {
    if (inputString == null) {
      return [];
    } else {
      const splitWithComma = inputString.split(',');
      if (splitWithComma.length <= 2) {
        const partsToDisplay = splitWithComma.slice(0, -1).map((part, index) => ({ id: index, Town__c: part }))
        return partsToDisplay;
      } else {
        const partsToDisplay = splitWithComma.slice(0, -1).map((part, index) => ({ id: index, Town__c: part }));
        return partsToDisplay;
      }
    }
  }

  const StringSplitterObjective = (inputString) => {
    if (inputString == null) {
      return [];
    } else {
      const parts = inputString.split(';');
      // if (parts.includes("Other")) {
      //   setShowTextField(true);
      // } else {
      //   setShowTextField(false);
      // }
      return parts;
    }
  }


  const StringSplitterWss = (inputString) => {
    if (typeof inputString !== 'string' || inputString === null) {
      return [];
    } else {
      const modifiedSegments = [];
      var splitWithComma = inputString.split(",");
      if (splitWithComma.length <= 2) {
        const splitSegment = splitWithComma[0].split(':');
        if (splitSegment[0] != null && splitSegment[1] != null && splitSegment[2] != null) {
          const modifiedSegment = {
            "CustomerName__c": splitSegment[0],
            "Customer_Code__c": splitSegment[1],
            "CustomerGroup__c": splitSegment[2].replace(/[()]/g, ''),
            "Town__c": splitWithComma[1]
          };
          modifiedSegments.push(modifiedSegment);
          const result = modifiedSegments;
          return result;
        }
      } else {
        splitWithComma.forEach((segment, index) => {
          if (index % 2 === 0) {
            const splitSegment = segment.split(':');
            if (splitSegment[0] != null && splitSegment[1] != null && splitSegment[2] != null){
              let result = splitSegment[2].replace(/[()]/g, "");
              const modifiedSegment = {
                "CustomerName__c": splitSegment[0],
                "Customer_Code__c": splitSegment[1],
                "CustomerGroup__c": result,
                "Town__c": splitWithComma[index + 1]
              };
              modifiedSegments.push(modifiedSegment);
            }
          }
        });
        const result = modifiedSegments;
        return result;
      }
    }
  };

  // to set data
  useEffect(() => {
    if (title === "HO Collaborator" || title === "SH7") {
      setIndependentTownOptions(townOptionsFromRedux);
      setIndependentWssOptions(wssOptionsFromRedux);
    }
    if (title === "MH3" || title === "RBDM Collaborator") {
      setIndependentTownOptions(townList);
      setIndependentWssOptions(townList);
    }
    setTourPlan({
      date: date,
      town: record?.Town_Name__c,
      territory: !_.isEmpty(record?.MTP_Territory_es__r?.SH2_Name__c)
        ? `${record?.MTP_Territory_es__r?.SH2_Name__c}:${record?.MTP_Territory_es__r?.SH2_Code__c}`
        : "",
      objectives: record?.Objectives_es__c,
      wss: record?.WSS_Name__c,
      type: "Market Visit",
      recordType: "Monthly Tour Plan",
      recordId: record?.Id,
      pidiliteHierarchy: record?.MTP_Territory_es__r?.Id,
      sh2Names: record?.MTP_Territory_es__r?.SH2_Name__c,
      cluster: record?.Cluster_es__c,
    });
    if (title === "MH3" || title === "RBDM Collaborator") {
      setWssRmdmOption(record?.WSS_Name__c);
    }
    // setCluster([record?.Cluster_es__c?.split(":")?.[1]]);
  }, [date]);

  const onChange = async (e) => {
    setTourPlan({
      ...tourPlan,
      [e.target.name]:
        e.target.name === "territory"
          ? `${e.target.value?.split(",")[2]}:${
              e.target.value?.split(",")[0]?.split(" ")[0]
            }`
          : e.target.value,
    });

    if (e.target.name === "wss") {
      setNewWssCode(e.target.value?.split(",")[0]);
      if (title !== "MH3" && title !== "RBDM Collaborator") {
        setNewTown(e.target.value?.split(",")[1]);
      }
    }
    if (e.target.name === "territory") {
      tourPlan.pidiliteHierarchy = "";
      tourPlan.sh2Names = "";
      setSelectedTerritory(e.target.value?.split(",")[0]);
      setPidiliteHierarchyId(e.target.value?.split(",")[1]);
      setSh2Name(e.target.value?.split(",")[2]);
    }
    if (e.target.name === "visited") {
      setVisited(e.target.value);
    }
    if (e.target.name === "territory" && (title === "SH5" || title === "SH6" || title === "HO Collaborator" || title === "SH7")){
      // const TTY = `${e.target.value?.split("~")[2]}:${e.target.value?.split("~")[0]}`
      setSh2Name(e.target.value?.split("~")[2]);
      setPidiliteHierarchyId(e.target.value?.split("~")[3]);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  // on Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    const date = new Date(tourPlan?.date);
    const formatDate = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });

    const data = {
      Objectives: tourPlan?.objectives,
      Sh2_Name: sh2Name ? sh2Name : tourPlan?.sh2Names,
      Pidilite_Hierarchy_Id: pidiliteHierarchyId
        ? pidiliteHierarchyId
        : tourPlan?.pidiliteHierarchy,
      Datefields: formatDate,
      Town: tourPlan?.town,
      // TourType: tourPlan?.type,
      RecordType: tourPlan?.recordType,
      id: tourPlan?.recordId,
      wssCode: tourPlan?.wss,
      status: visited ? visited : "Not Visited",
      cluster: tourPlan?.cluster,
    };

    const {
      Objectives,
      Sh2_Name,
      Pidilite_Hierarchy_Id,
      Datefields,
      // TourType,
      RecordType,
      id,
      wssCode,
      Town,
      status,
      cluster,
    } = data;
    const response = await updateUpcomingTourPlansApi({
      Objectives,
      Sh2_Name,
      Pidilite_Hierarchy_Id,
      Datefields,
      //TourType,
      RecordType,
      id,
      wssCode,
      Town,
      status,
      cluster,
    });
    if (response?.success) {
      setShow(false);
      setIsUpdateModalOpen(true);
      setUpdatedTourPlan(true);
      setNewWssCode();
      setNewTown();
      setPidiliteHierarchyId();
      setSh2Name();
      setUserTownArr([]);
      setUserWssArr([]);
      setUserObjectivesArr([]);
    } else {
    }
  };

  //api call for wss picklist
  useEffect(() => {
    if (selectedTerritory) {
      (async () => {
        const wssData = selectedTerritory?.split("~");
        let SH2_Code__c = wssData[0];
        let SH3_Code__c = wssData[1];
        const response = await addNewTourWssApi(SH2_Code__c, SH3_Code__c);
        if (response?.success) {
          setWssPickList(response?.data?.WSSCode);
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [selectedTerritory]);

  // to get Dealer Name when User is RMDM/RBDM
  useEffect(() => {
    if (cluster?.length && (title === "MH3" || title === "RBDM Collaborator")) {
      (async () => {
        const response = await getRmdmWssApi(cluster);
        if (response?.success) {
          setWssOption(response?.data);
        }
      })();
    }
  }, [cluster]);

  // to get territory picklist when user is HO Collaborator
  useEffect(() => {
    if (cluster?.length && (title === "HO Collaborator" || title === "SH7")) {
      (async () => {
        const response = await getZSMTerritoryApi(cluster);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList([]);
        }
      })();
    }
  }, [cluster]);

  // to get territory picklist when user is ZSM
  useEffect(() => {
    if (
      cluster?.length > 0 &&
      (title === "SH5" || title === "SH6") &&
      !_.isEmpty(cluster?.[cluster?.length - 1])
    ) {
      (async () => {
        const response = await getZSMTerritoryApi([
          cluster?.[cluster?.length - 1],
        ]);
        const res = await getZSMWssNameApi(cluster?.[cluster?.length - 1]);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList([]);
        }

        if (res?.success) {
          setZsmWss(res?.data);
        } else {
          setZsmWss([]);
        }
      })();
    }
  }, [cluster]);

  return (
    <>
      <FiEdit2
        className={styles.upcomingTourPlans__EditIcon}
        onClick={() => handleShow(tourPlan?.recordId)}
      />
      <ModalPopup
        isModalOpen={show}
        setIsModalOpen={handleClose}
        backdrop={true}
        fade={false}
        ModalMainClass="editForm_modal"
        data={
          <>
            <div
              className={`row ${styles.editFormModal__formData}`}
              style={{ textAlign: "left" }}
            >
              <div className="col-12">
                <label>
                  Date<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  type="date"
                  name="date"
                  className={styles.editFormModal__input}
                  value={tourPlan?.date}
                  onChange={(e) => onChange(e)}
                />
              </div>

              {title !== "MH3" && title !== "RBDM Collaborator" ? (
                <>
                  {(title === "HO Collaborator" ||
                    title === "SH5" ||
                    title === "SH6" || 
                    title === "SH7") && (
                    <>
                      <div className="col-12">
                        <label>
                          Cluster<span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <Autocomplete
                          // value={cluster}
                          id="grouped-demo"
                          ListboxProps={{
                            sx: {
                              fontSize: 12,
                              color: "#2cc26a",
                              width: "100%",
                            },
                          }}
                          disableClearable={true}
                          className="newTourPlan__inputs"
                          options={
                            !clusterCodeOption ||
                            clusterCodeOption?.length === 0
                              ? [
                                  {
                                    SH3_Name__c: "Loading...",
                                    SH3_Code__c: "",
                                    Cluster_Description__c: "Loading...",
                                    CLUSTER_CODE__c: "",
                                  },
                                ]
                              : getUniqueListBy(
                                  clusterCodeOption,
                                  "SH3_Name__c"
                                )
                          }
                          getOptionLabel={(option) =>
                            `${option?.SH3_Name__c}${
                              option?.SH3_Code__c && `:${option?.SH3_Code__c}`
                            }`
                          }
                          onChange={(e, newValue) => {
                            setCluster([newValue?.SH3_Code__c]);
                            onChange({
                              target: {
                                name: "cluster",
                                value: `${newValue?.SH3_Name__c}${
                                  newValue?.SH3_Code__c &&
                                  `:${newValue?.SH3_Code__c}`
                                }`,
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={tourPlan?.cluster == null ? "NA" : tourPlan?.cluster }
                            />
                          )}
                        />
                      </div>
                      {(title === "SH5" || title === "SH6") && (
                        <>
                          <div className="col-12">
                            <label>
                              Territory<span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Autocomplete
                              // value={territory}
                              id="grouped-demo"
                              ListboxProps={{
                                sx: {
                                  fontSize: 12,
                                  color: "#2cc26a",
                                  width: "100%",
                                },
                              }}
                              disableClearable={true}
                              className="newTourPlan__inputs"
                              options={
                                !territoryPickList ||
                                territoryPickList?.length === 0
                                  ? [
                                      {
                                        SH2_Name__c: "Loading...",
                                        SH2_Code__c: "",
                                      },
                                    ]
                                  : getUniqueListBy(
                                      territoryPickList,
                                      "SH2_Code__c"
                                    )
                              }
                              getOptionLabel={(option) =>
                                `${option?.SH2_Name__c}${
                                  option?.SH2_Code__c
                                    ? `:${option?.SH2_Code__c}`
                                    : ""
                                }`
                              }
                              onChange={(e, newValue) => {
                                onChange({
                                  target: {
                                    name: "territory",
                                    value: `${newValue.SH2_Code__c}~${newValue.SH3_Code__c}~${newValue.SH2_Name__c}~${newValue.Id}`,
                                  },
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={tourPlan?.territory}
                                />
                              )}
                            />
                          </div>

                          <div className="col-12">
                            <label>
                              WSS<span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Autocomplete
                              // value={wssRmdmOption[index]}
                              id="grouped-demo"
                              ListboxProps={{
                                sx: {
                                  fontSize: 12,
                                  color: "#2cc26a",
                                  width: "100%",
                                },
                              }}
                              disableClearable={true}
                              className="newTourPlan__inputs"
                              options={
                                !zmsWss || zmsWss?.length === 0
                                  ? [
                                      {
                                        CustomerName__c: "Loading...",
                                        Customer_Code__c: "",
                                      },
                                    ]
                                  : getUniqueListBy(zmsWss, "Customer_Code__c")
                              }
                              getOptionLabel={(option) =>
                                `${option?.CustomerName__c}${
                                  option?.Customer_Code__c &&
                                  `:${option?.Customer_Code__c}`
                                }`
                              }
                              onChange={(e, newValue) => {
                                // if (_.isEmpty(wssRmdmOption[index])) {
                                //   setWssRmdmOption((ls) => [...ls, newValue]);
                                // } else {
                                //   wssRmdmOption[index] = newValue;
                                //   setWssRmdmOption([...wssRmdmOption]);
                                // }
                                onChange({
                                  target: {
                                    name: "wss",
                                    value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                                  },
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={tourPlan?.wss}
                                />
                              )}
                            />
                            {/* {!input[`cluster${index}`] &&
                              errors[`cluster${index}`] && (
                                <p>Please select the WSS</p>
                              )}{" "} */}
                          </div>
                        </>
                      )}

                      {(title === "HO Collaborator" || title === "SH7") && (
                        <div className="col-12">
                          <label>Territory</label>
                          <br />
                          <Autocomplete
                            // value={cluster}
                            id="grouped-demo"
                            ListboxProps={{
                              sx: {
                                fontSize: 12,
                                color: "#2cc26a",
                                width: "100%",
                              },
                            }}
                            disableClearable={true}
                            className="newTourPlan__inputs"
                            options={
                              territoryPickList?.length === 0
                                ? [
                                    {
                                      SH2_Name__c: "Loading...",
                                      SH2_Code__c: "",
                                    },
                                  ]
                                : getUniqueListBy(
                                    territoryPickList,
                                    "SH2_Code__c"
                                  )
                            }
                            getOptionLabel={(option) =>
                              `${option?.SH2_Name__c}${
                                option?.SH2_Code__c
                                  ? `:${option?.SH2_Code__c}`
                                  : ""
                              }`
                            }
                            onChange={(e, newValue) => {
                              onChange({
                                target: {
                                  name: "territory",
                                  value: `${newValue.SH2_Code__c}~${newValue.SH3_Code__c}~${newValue.SH2_Name__c}~${newValue.Id}`,
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={
                                  tourPlan?.territory
                                    ? tourPlan?.territory
                                    : "select territory"
                                }
                              />
                            )}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {title !== "HO Collaborator" &&
                    title !== "SH5" &&
                    title !== "SH6" &&
                    title !== "SH7" && (
                      <div className="col-12">
                        <label>
                          Territory
                          {(title !== "HO Collaborator"  || title !== "SH7") && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </label>
                        <br />
                        <select
                          className={styles.editFormModal__input}
                          label=""
                          name="territory"
                          onChange={(e) => onChange(e)}
                        >
                          <option
                            selected={
                              _.isEmpty(tourPlan?.territory) ? true : false
                            }
                          >
                            {tourPlan?.territory}
                          </option>
                          {territory &&
                            territory?.map((item) => {
                              let currentTerritory = `${item?.SH2_Name__c}:${item?.SH2_Code__c}`;
                              return (
                                <option
                                  className="newTourPlan__input"
                                  value={`${item?.SH2_Code__c} ${item?.SH3_Code__c},${item?.Id},${item?.SH2_Name__c}`}
                                >
                                  {currentTerritory}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                </>
              ) : (
                <>
                  <div className="col-12">
                    <label>
                      Cluster<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <Autocomplete
                      // value={cluster}
                      id="grouped-demo"
                      ListboxProps={{
                        sx: {
                          fontSize: 12,
                          color: "#2cc26a",
                          width: "100%",
                        },
                      }}
                      disableClearable={true}
                      className="newTourPlan__inputs"
                      options={
                        !clusterCodeOption || clusterCodeOption?.length === 0
                          ? [
                              {
                                SH3_Name__c: "Loading...",
                                SH3_Code__c: "",
                                Cluster_Description__c: "Loading...",
                                CLUSTER_CODE__c: "",
                              },
                            ]
                          : getUniqueListBy(
                              clusterCodeOption,
                              "CLUSTER_CODE__c"
                            )
                      }
                      getOptionLabel={(option) =>
                        `${option?.Cluster_Description__c}${
                          option?.CLUSTER_CODE__c &&
                          `:${option?.CLUSTER_CODE__c}`
                        }`
                      }
                      onChange={(e, newValue) => {
                        setCluster([newValue?.CLUSTER_CODE__c]);
                        onChange({
                          target: {
                            name: "cluster",
                            value: `${newValue?.Cluster_Description__c}${
                              newValue?.CLUSTER_CODE__c &&
                              `:${newValue?.CLUSTER_CODE__c}`
                            }`,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={tourPlan?.cluster}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12">
                    <label>
                      WSS<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />

                    <Autocomplete
                      id="grouped-demo"
                      multiple
                      value={userWssArr}
                      ListboxProps={{
                        sx: {
                          fontSize: 12,
                          color: "#2cc26a",
                          width: "100%",
                        },
                      }}
                      disableClearable={true}
                      className="newTourPlan__inputs_multiple"
                      options={
                        !independentWssOptions?.length
                          ? [
                              {
                                CustomerName__c: "Loading...",
                                Customer_Code__c: "",
                              },
                            ]
                          : independentWssOptions
                      }
                      getOptionLabel={(option) =>
                        `${option?.CustomerName__c}${
                          option?.Customer_Code__c &&
                          `:${option?.Customer_Code__c}`
                        }`
                      }
                      onChange={(e, newValue) => {
                        setUserWssArr(newValue);
                        // setWssRmdmOption(newValue?.CustomerName__c);
                        let wssValue = "";

                        newValue.forEach((value) => {
                          wssValue =
                            wssValue +
                            `${value?.CustomerName__c}:${value?.Customer_Code__c}:(${value?.CustomerGroup__c}),${value?.Town__c}` +
                            ",";
                        });
                        onChange({
                          target: {
                            name: "wss",
                            value: wssValue,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={""} />
                      )}
                    />
                  </div>

                  <div className="col-12">
                    <label>
                      Town<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <Autocomplete
                      multiple
                      value={userTownArr}
                      id="grouped-demo"
                      ListboxProps={{
                        sx: {
                          fontSize: 12,
                          color: "#2cc26a",
                          width: "100%",
                        },
                      }}
                      disableClearable={true}
                      className="newTourPlan__inputs_multiple"
                      options={independentTownOptions}
                      getOptionLabel={(option) => option?.Town__c}
                      onChange={(e, newValue) => {
                        setUserTownArr(newValue);
                        // if (_.isEmpty(rmdmTown[index])) {
                        //   setRmdmTown((ls) => [...ls, newValue]);
                        // } else {
                        //   rmdmTown[index] = newValue;
                        //   setRmdmTown([...rmdmTown]);
                        // }

                        let userTown = "";
                        newValue.forEach((value) => {
                          userTown = userTown + value?.Town__c + ",";
                        });

                        onChange({
                          target: {
                            name: "town",
                            value: userTown,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={userTownArr.length>0?"":tourPlan?.town} />
                      )}
                    />
                    {/* <Autocomplete
                      // value={tourPlan?.town}
                      // key={townList?.Id}
                      id="grouped-demo"
                      ListboxProps={{
                        sx: {
                          fontSize: 12,
                          color: "#2cc26a",
                          width: "100%",
                        },
                      }}
                      disableClearable={true}
                      className="newTourPlan__inputs"
                      options={townList}
                      getOptionLabel={(option) => option?.Town__c}
                      onChange={(e, newValue) => {
                        // if (_.isEmpty(rmdmTown[index])) {
                        //   setRmdmTown((ls) => [...ls, newValue]);
                        // } else {
                        //   rmdmTown[index] = newValue;
                        //   setRmdmTown([...rmdmTown]);
                        // }

                        onChange({
                          target: {
                            name: "town",
                            value: newValue?.Town__c,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={tourPlan?.town} />
                      )}
                    /> */}
                  </div>

                  <div className="col-12">
                    <label style={{ whiteSpace: "nowrap" }}>
                      Primary Objective<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <Autocomplete
                      multiple
                      value={userObjectivesArr}
                      id="grouped-demo"
                      ListboxProps={{
                        sx: {
                          fontSize: 12,
                          color: "#2cc26a",
                          width: "100%",
                        },
                      }}
                      disableClearable={true}
                      className="newTourPlan__inputs_multiple"
                      options={primaryObjectivesOption}
                      getOptionLabel={(option) => option}
                      onChange={(e, newValue) => {
                        setUserObjectivesArr(newValue);
                        // if (_.isEmpty(wssRmdmOption[index])) {
                        //   setWssRmdmOption((ls) => [...ls, newValue]);
                        // } else {
                        //   wssRmdmOption[index] = newValue;
                        //   setWssRmdmOption([...wssRmdmOption]);
                        // }
                        onChange({
                          target: {
                            name: "objectives",
                            value: newValue.join(";"),
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={userObjectivesArr.length>0?"":tourPlan?.objectives}
                        />
                      )}
                    />
                  </div>
                </>
              )}

              {title !== "MH3" && title !== "RBDM Collaborator" && (
                <>
                  {title !== "SH5" &&
                    title !== "SH6" &&
                    title !== "SH3" &&
                    title !== "HO Collaborator" &&
                    title !== "SH7" && (
                      <div className="col-12">
                        <label>
                          WSS
                        {(title !== "HO Collaborator" || title !== "SH7") && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </label>
                        <br />
                        <select
                          className={styles.editFormModal__input}
                          label=""
                          name="wss"
                          onChange={(e) => onChange(e)}
                        >
                          <option
                            value={tourPlan?.wss}
                            selected={_.isEmpty(wssPickList) ? true : false}
                            className={
                              !_.isEmpty(wssPickList) &&
                              styles.editFormModal__disabledClass
                            }
                          >
                            {tourPlan?.wss}
                          </option>
                          {wssPickList &&
                            wssPickList?.map((item) => {
                              return (
                                <option
                                  className="newTourPlan__input"
                                  value={`${item?.CustomerName__c}:${item?.Customer_Code__c}:(${item?.CustomerGroup__c}),${item?.Town__c}`}
                                >
                                  {item?.CustomerName__c} :{" "}
                                  {item?.Customer_Code__c}
                                  :(
                                  {item?.CustomerGroup__c})
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                  {(title === "HO Collaborator" || title === "SH7") && (
                    <div className="col-12">
                      <label>WSS</label>
                      <br />
                      <Autocomplete
                        multiple
                        value={userWssArr}
                        id="grouped-demo"
                        ListboxProps={{
                          sx: {
                            fontSize: 12,
                            color: "#2cc26a",
                            width: "100%",
                          },
                        }}
                        disableClearable={true}
                        className="newTourPlan__inputs_multiple"
                        options={
                          !independentWssOptions ||
                          independentWssOptions?.length === 0
                            ? [
                                {
                                  CustomerName__c: "Loading...",
                                  Customer_Code__c: "",
                                },
                              ]
                            : getUniqueListBy(
                                independentWssOptions,
                                "Customer_Code__c"
                              )
                        }
                        getOptionLabel={(option) =>
                          `${option?.CustomerName__c}${
                            option?.Customer_Code__c &&
                            `:${option?.Customer_Code__c}`
                          }`
                        }
                        onChange={(e, newValue) => {
                          setUserWssArr(newValue);
                          // if (_.isEmpty(wssRmdmOption[index])) {
                          //   setWssRmdmOption((ls) => [...ls, newValue]);
                          // } else {
                          //   wssRmdmOption[index] = newValue;
                          //   setWssRmdmOption([...wssRmdmOption]);
                          // }
                          let wssValue = "";

                          newValue.forEach((value) => {
                            wssValue =
                              wssValue +
                              `${value?.CustomerName__c}:${value?.Customer_Code__c}:(${value?.CustomerGroup__c}),${value?.Town__c}` +
                              ",";
                          });
                          onChange({
                            target: {
                              name: "wss",
                              value: wssValue,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={""} />
                        )}
                      />
                      {/* {!input[`cluster${index}`] &&
                          errors[`cluster${index}`] && (
                            <p>Please select the WSS</p>
                          )}{" "} */}
                    </div>
                  )}
                  {title === "SH3" && (
                    <div className="col-12">
                      <label>
                        WSS<span style={{ color: "red" }}>*</span>
                      </label>
                      <br />

                      <Autocomplete
                        id="grouped-demo"
                        ListboxProps={{
                          sx: {
                            fontSize: 12,
                            color: "#2cc26a",
                            width: "100%",
                          },
                        }}
                        disableClearable={true}
                        className="newTourPlan__inputs"
                        options={
                          !bmWssList?.length
                            ? [
                                {
                                  CustomerName__c: "Loading...",
                                  Customer_Code__c: "",
                                },
                              ]
                            : bmWssList
                        }
                        getOptionLabel={(option) =>
                          `${option?.CustomerName__c}${
                            option?.Customer_Code__c &&
                            `:${option?.Customer_Code__c}`
                          }`
                        }
                        onChange={(e, newValue) => {
                          onChange({
                            target: {
                              name: "wss",
                              value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={tourPlan?.wss} />
                        )}
                      />
                    </div>
                  )}

                  {title !== "HO Collaborator" && title !== "SH7" && (
                    <div className="col-12">
                      <label>
                        Town
                        {(title !== "HO Collaborator" || title !== "SH7") && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                      <br />
                      <select
                        className={styles.editFormModal__input}
                        name="town"
                        onChange={(e) => onChange(e)}
                      >
                        <option
                          selected={_.isEmpty(newTown) ? true : false}
                          className={
                            !_.isEmpty(newTown) &&
                            styles.editFormModal__disabledClass
                          }
                        >
                          {tourPlan?.town}
                        </option>

                        {newTown && (
                          <option
                            className="newTourPlan__input"
                            value={newTown}
                          >
                            {newTown}
                          </option>
                        )}
                      </select>
                    </div>
                  )}

                  {(title === "HO Collaborator" || title === "SH7") && (
                    <div className="col-12">
                      <label>
                        Town
                        {title !== "HO Collaborator" && title !== "SH7" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                      <br />
                      <Autocomplete
                        multiple
                        value={userTownArr}
                        id="grouped-demo"
                        ListboxProps={{
                          sx: {
                            fontSize: 12,
                            color: "#2cc26a",
                            width: "100%",
                          },
                        }}
                        disableClearable={true}
                        className="newTourPlan__inputs_multiple"
                        options={independentTownOptions}
                        getOptionLabel={(option) => option?.Town__c}
                        onChange={(e, newValue) => {
                          setUserTownArr(newValue);
                          // if (_.isEmpty(rmdmTown[index])) {
                          //   setRmdmTown((ls) => [...ls, newValue]);
                          // } else {
                          //   rmdmTown[index] = newValue;
                          //   setRmdmTown([...rmdmTown]);
                          // }

                          let userTown = "";
                          newValue.forEach((value) => {
                            userTown = userTown + value?.Town__c + ",";
                          });

                          onChange({
                            target: {
                              name: "town",
                              value: userTown,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={userTownArr.length>0? "":tourPlan?.town} />
                        )}
                      />
                    </div>
                  )}

                  {/* <div className="col-12">
                <label>
                  Tour Type<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  className={styles.editFormModal__input}
                  value={tourPlan?.type}
                  name="type"
                  onChange={(e) => onChange(e)}
                >
                  {tourTypeOption?.map((type) => (
                    <option>{type}</option>
                  ))}
                </select>
              </div> */}
                  <div className="col-12">
                    <label style={{ whiteSpace: "nowrap" }}>
                      Primary Objective
                      {(title !== "HO Collaborator" || title !== "SH7") && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <br />
                    <Autocomplete
                      multiple
                      value={userObjectivesArr}
                      id="grouped-demo"
                      ListboxProps={{
                        sx: {
                          fontSize: 12,
                          color: "#2cc26a",
                          width: "100%",
                        },
                      }}
                      disableClearable={true}
                      className="newTourPlan__inputs_multiple"
                      options={primaryObjectivesOption}
                      getOptionLabel={(option) => option}
                      onChange={(e, newValue) => {
                        setUserObjectivesArr(newValue);
                        // if (_.isEmpty(wssRmdmOption[index])) {
                        //   setWssRmdmOption((ls) => [...ls, newValue]);
                        // } else {
                        //   wssRmdmOption[index] = newValue;
                        //   setWssRmdmOption([...wssRmdmOption]);
                        // }
                        onChange({
                          target: {
                            name: "objectives",
                            value: newValue.join(";"),
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={userObjectivesArr.length>0?"":tourPlan?.objectives}
                        />
                      )}
                    />
                  </div>
                  {/* {title === "HO Collaborator" && (
                    <div className="col-12">
                      <label style={{ whiteSpace: "nowrap" }}>
                        Primary Objective
                      </label>
                      <br />
                      <Autocomplete
                        multiple
                        value={userObjectivesArr}
                        id="grouped-demo"
                        ListboxProps={{
                          sx: {
                            fontSize: 12,
                            color: "#2cc26a",
                            width: "100%",
                          },
                        }}
                        disableClearable={true}
                        className="newTourPlan__inputs_multiple"
                        options={primaryObjectivesOption}
                        getOptionLabel={(option) => option}
                        onChange={(e, newValue) => {
                          setUserObjectivesArr(newValue);
                          // if (_.isEmpty(wssRmdmOption[index])) {
                          //   setWssRmdmOption((ls) => [...ls, newValue]);
                          // } else {
                          //   wssRmdmOption[index] = newValue;
                          //   setWssRmdmOption([...wssRmdmOption]);
                          // }
                          onChange({
                            target: {
                              name: "objectives",
                              value: newValue.join(";"),
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={tourPlan?.objectives}
                          />
                        )}
                      />
                    </div>
                  )} */}
                </>
              )}
              {date <= getCurrentDate() && (
                <div class="form-check" className="col-12">
                  <input
                    style={{ border: "1px solid #2cc26a" }}
                    className="form-check-input"
                    type="checkbox"
                    value="Visited"
                    name="visited"
                    onChange={(e) => onChange(e)}
                  />
                  <label
                    class="form-check-label"
                    for="Visited"
                    style={{ marginLeft: "0.3rem", fontSize: "12px" }}
                  >
                    Mark as Completed
                  </label>
                </div>
              )}

              <div className={styles.editFormModal__submitBtnBox}>
                <Button
                  className={styles.editFormModal__submit}
                  onClick={handleClose}
                >
                  <p className={styles.editFormModal__submitBtn}>Cancel</p>
                </Button>
                <Button
                  className={styles.editFormModal__cancel}
                  variant="secondary"
                  type="submit"
                >
                  <p
                    className={styles.editFormModal__cancelBtn}
                    onClick={onSubmit}
                  >
                    Submit
                  </p>
                </Button>
              </div>
            </div>
          </>
        }
      />
      {isUpdateModalOpen && (
        <ModalPopup
          className="ModalPopup_Submitted"
          isModalOpen={isUpdateModalOpen}
          setIsModalOpen={setIsUpdateModalOpen}
          title="Updated Successfully"
          data="Your tour plan has been updated successfully."
          backdrop={true}
          fade={false}
          icon={
            <>
              <div className="submittedIcons draftIcons">
                <FaThumbsUp className="submittedSmall" />
                <FaThumbsUp className="submittedLarge" />
                <FaThumbsUp className="submittedSmall" />
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default connect(null, {
  updateUpcomingTourPlansApi,
  addNewTourWssApi,
  getRmdmWssApi,
  getZSMTerritoryApi,
  getZSMWssNameApi,
})(EditFormModal);
