import React from "react";
import { useState, useEffect} from "react";
import PageHeader from "../Components/Common/PageHeader";
import DRMSummary from "../Components/DRMPlanning/DRM_Summary";
import { getAllMeeting } from "../services/DRM/getAllMeeting";
import { useSelector } from "react-redux";
import { setAlert } from "../Redux/actions/alert-action";
import { sessionExpireTimeout } from "../Redux/actions/utility-actions";





const DRM = () => {
    const[meetingsummary, setMeetingSummary] = useState([])
    const [loading, setLoading] = useState(false);
    const [feedbackloading, setfeedbackLoading] = useState(false);
    const [failedOpen, setFailesOpen] = useState(false);
    const userEmailid = useSelector((state) => state?.userDetails?.details?.Email);
    const [snackerror, setSnackError] = useState("");
    const [noMeeting, setNoMeetings] = useState(false)

    const handleOnClose = (reason) => {
      if (reason === "clickaway") {
        return;
      }
      setFailesOpen(false);
    };

    useEffect(() => {
        console.log("emailid",userEmailid)
        const fetchMeetings = async () => {
          try {
            setLoading(true)
            const response = await getAllMeeting({userEmailid});
            console.log('response getAllMeeting-->>', response.data);
            if(response?.success)
              {
                console.log("get all meeting array->",response.data.records)
                setMeetingSummary(response.data.records)
                setLoading(false)
               
              } 
              else {
                setSnackError(response?.msg);
                setLoading(false)
                // setMeetingSummary()
                setFailesOpen(true)
              } 
            
            
          } catch (error) {
            console.error('Error fetching meetings:', error);
            setSnackError(error);
          }
        };
    
        fetchMeetings();
      },[userEmailid]);

    return ( <><PageHeader title="DRM Summary" path="/" />
    <DRMSummary noMeeting = {noMeeting} meetingsummary={meetingsummary} setLoading={setLoading} setFailesOpen={setFailesOpen} feedbackloading={feedbackloading} setfeedbackLoading={setfeedbackLoading} setSnackError={setSnackError} loading={loading} failedOpen={failedOpen} handleOnClose={handleOnClose} snackerror={snackerror}/>
    </> );
}
 
export default DRM;