import React from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import styles from "./PageHeader.module.css";
import { Link } from "react-router-dom";

const PageHeader = ({ title, path }) => {
  return (
    <div className={styles.pageHeader}>
      <Link to={path}>
        <span>
          <MdOutlineArrowBackIosNew className={styles.pageHeader__backIcon} />
        </span>
      </Link>
      {title}
    </div>
  );
};

export default PageHeader;
