import React, { useEffect, useRef } from "react";
import PageHeader from "./../Components/Common/PageHeader";
import CurrentDayTour from "../Components/CurrentDayTour/CurrentDayTour";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

const CurrentDay = () => {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <>
      <PageHeader title="Current Day Tour" path="/" />
      <div className="container p-0">
        <CurrentDayTour />
      </div>
    </>
  );
};

export default CurrentDay;
