import React, { useEffect, useState } from 'react';
import './FeedbackForm.css';
import { submitFeedback } from '../../services/DRM/submitFeedback';
import { useLocation } from 'react-router-dom';
import pidilite_logo from "../../Assets/Images/pidilite_logo.png"
import { getMeetingDetails } from '../../services/DRM/getMeetingDetails';
import CommonSnackbar from '../Common/CommonSnackbar';
import CryptoJS from 'crypto-js';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function decryptParam(encryptedData, key) {
    let bytes = CryptoJS.AES.decrypt(encryptedData, key);
    return bytes.toString(CryptoJS.enc.Utf8);
}



const FeedbackForm = () => {
    const [decryptedParams, setDecryptedParams] = useState({ id: '', date: '' });
    const query = useQuery();
    const secretKey = 'ThisIsASecretKey';

    useEffect(() => {
       
        const meetid = query.get('id');
        const meetingDay = query.get('date')

        if (meetid && meetingDay) {
            const decryptedId = decryptParam(meetid, secretKey);
            const decryptedDate = decryptParam(meetingDay, secretKey);

            setDecryptedParams({ id: decryptedId, date: decryptedDate });
        }

    },[])

    const feedbackobject = {
        dealerCode: '',
        phone: '',
        feedback: '',
        meetingid: decryptedParams?.id
    }
    const [formData, setFormData] = useState(feedbackobject);
    const [successMessage, setSuccessMessage] = useState('');
    const [failedOpen, setFailesOpen] = useState(false);
    const [snackerror, setSnackError] = useState("");
    const [errors, setErrors] = useState({});
    const [meetingid, setMeetingId] = useState();
    const [DeactivateFeedback, setDeactivateFeedback] = useState(true);







    const handleOnClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setFailesOpen(false);
    };

    useEffect(() => {
   
        const today = new Date();
        const specificDate = new Date(decryptedParams?.date);
        console.log("decrypted params",decryptedParams)
        if (today.getDate() === specificDate.getDate()) {
            setDeactivateFeedback(false);
        } else {
            setDeactivateFeedback(true);
        }


    }, [decryptedParams]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.dealerCode) newErrors.dealerCode = "Dealer Name is required";
        if (!formData.phone) {
            newErrors.phone = "Phone Number is required";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = "Phone Number must be exactly 10 digits";
        }
        // if (!formData.feedback) newErrors.feedback = "Feedback is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("inside form submit")
        if (validateForm()) {
            try {
                const response = await submitFeedback({ formData });
                console.log("create feedback response", response)
                if (response?.status == 200) {
                    setFormData(feedbackobject);
                    setSuccessMessage('Feedback submitted successfully!');
                    setTimeout(() => setSuccessMessage(''), 10000);
                }
                else {
                    setSnackError("Something went wrong.. please try again later..")
                    setFailesOpen(true)
                }

            }
            catch (error) {
                console.error("Error during API call:", error);
                setSnackError("Something went wrong..")
                setFailesOpen(true)

            }


            // Add your form submission logic here
        }
    };

    return (
        <div className="feedback-form-container">
            <CommonSnackbar
                open={failedOpen}
                autoHideDuration={7000}
                onClose={handleOnClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                severity={"error"}
                message={snackerror}
            />
            <div className='feedbackForm-logo'>
                <img src={pidilite_logo} alt="header_logo" className="header__logo" />
            </div>

            <h2>Meeting Feedback</h2>
            {DeactivateFeedback && <h6 className="success">Currently Feedback is not available..!</h6>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="dealerCode">Dealer Name <span style={{ color: "red" }}>*</span></label>
                    <input
                        type="text"
                        id="dealerCode"
                        name="dealerCode"
                        value={formData.dealerCode}
                        onChange={handleChange}
                        disabled={DeactivateFeedback}

                    />
                    {errors.dealerCode && <p className="error">{errors.dealerCode}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number <span style={{ color: "red" }}>*</span></label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        disabled={DeactivateFeedback}


                    />
                    {errors.phone && <p className="error">{errors.phone}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="feedback">Remarks/Feedback on how to improve DRM </label>
                    <textarea
                        id="feedback"
                        name="feedback"
                        value={formData.feedback}
                        onChange={handleChange}
                        disabled={DeactivateFeedback}
                    />

                </div>
                <button type="submit" className='submitFeedback' disabled={DeactivateFeedback}>Submit</button>
                {successMessage && <p className="success">{successMessage}</p>}
            </form>
        </div>
    );
};

export default FeedbackForm;
