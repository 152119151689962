import axios from "axios";
import { BASE_URL } from "../../variables";
import { arrayData } from "../../Components/Common/Function";

export const getBmWssApi = async (sh2Code, sh3Code) => {
  let sh3Result = arrayData(sh3Code);
  let sh2Result = arrayData(sh2Code);

  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=SELECT Id,Customer_Code__c, CustomerName__c,CustomerClassification__c,CustomerGroup__c,BillingBlock__c,TSI_Territory_Code__c,TSI_Territory_Name__c,Town__c FROM Account WHERE TSI_Territory_Code__c in (${sh2Result}) AND BillingBlock__c NOT IN ('ZN','Z7','Z8') AND Cluster_Code__c in (${sh3Result}) AND RecordType.name ='Customer' AND DiscontinueFlag__c = false`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};