import React, { useEffect, useRef } from "react";
import PageHeader from '../Components/Common/PageHeader'
import Surveys from '../Components/KeyInformation/Surveys'
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

function Survey() {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <>
        <PageHeader title="Survey" path="/" />
        <div className='container p-0'>
        <Surveys/>
        </div>
    </>
  )
}

export default Survey