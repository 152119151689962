import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import Message from "../../Common/Message";
import styles from "../MyTeam/MyDealerStatus.module.css";
import { getDealerPhysicalVerificationStatusApi } from "../../../services/Teams/getDealerPhysicalVerificationStatusApi";

const MyDealerStatus = ({ reloadList, afterReloadFlag }) => {
    const [error, setError] = useState("");
    const [dealerStatusData, setDealerStatusData] = useState([]);
    const territoryList = useSelector((state) => state?.territory?.territoryDetails);

    // --------------------------------------------------------- Date Formatting ------------------------------------------------------------

    // Shifted code to useEffect
    dealerStatusData &&
        dealerStatusData?.forEach((visit, index) => {
            const dateTime = new Date(visit?.physicalverificationdate);
            const formattedDate = dateTime?.toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
            const isoString = dateTime.toISOString();
            const [date, timeWithMs] = isoString.split('T');
            const time = timeWithMs.split('.')[0];
            dealerStatusData[index].physicalverificationdate = `${date} ${time}`;
        });

    // useEffect(() => {
    //     if (dealerStatusData?.length) {
    //         dealerStatusData &&
    //             dealerStatusData?.forEach((visit, index) => {
    //                 const date = new Date(visit?.physicalverificationdate);
    //                 const formattedDate = date?.toLocaleString("en-US", {
    //                     timeZone: "Asia/Kolkata",
    //                     day: "numeric",
    //                     month: "numeric",
    //                     year: "numeric",
    //                     hour: "numeric",
    //                     minute: "numeric",
    //                 });
    //                 dealerStatusData[index].physicalverificationdate = formattedDate;
    //             });
    //     }
    // }, [dealerStatusData]);

    // --------------------------------------------------------- Reload List ---------------------------------------------------------------

    // API call for Dealer Physical Verification status
    const getDealerPhysicalVerificationStatus = () => {
        (async () => {
            if (!_.isEmpty(territoryList)) {
                const response = await getDealerPhysicalVerificationStatusApi(territoryList);
                if (response?.status === 200) {
                    setDealerStatusData(response?.data);
                    afterReloadFlag();
                } else {
                    setError(response?.msg);
                    afterReloadFlag();
                }
            }
        })();
    }

    //  Reload List after Dealer Physical Verification 
    useEffect(() => {
        if (reloadList == true) {
            getDealerPhysicalVerificationStatus();
        }
    }, [reloadList])

    // Reload List after refresh the page
    useEffect(() => {
        getDealerPhysicalVerificationStatus();
    }, [territoryList])

    // -------------------------------------------------------------- End --------------------------------------------------------------------

    return (
        <>
            <div className={styles.myDealerStatus}>
                <div className={styles.myDealerStatus__heading}>Dealer Verification History</div>
                <div className={styles.myDealerStatus__mainBox}>
                    <div className="row m-0 p-0">
                        <div
                            className={` ${dealerStatusData?.length > 0
                                ? styles.myDealerStatus__rightBox
                                : "mt-2"
                                }`}
                        >
                            {/* <div className={styles.titleBox}>
                                <div className={`${styles.visitDetails_title}`}>
                                    {" "}
                                    Dealer validation history
                                </div>
                            </div> */}
                            {dealerStatusData?.length > 0 ? (
                                <>
                                    <div className={styles.myDealerStatus__headerBox}>
                                        <div className={styles.myDealerStatus__headerValue}>
                                            Dealer Name
                                        </div>
                                        <div className={styles.myDealerStatus__headerValue}>
                                            Verification Date
                                        </div>
                                        <div className={styles.myDealerStatus__headerValue}>
                                            Verification Status
                                        </div>
                                    </div>
                                    <hr className="m-1" />
                                </>
                            ) : (
                                <Message
                                    message={"No visit details"}
                                    className="visitDetails__OuterDiv"
                                />
                            )}

                            <div className={styles.myDealerStatus__plansBox}>
                                {dealerStatusData &&
                                    dealerStatusData?.map((item) => (
                                        <>
                                            <div className={styles.myDealerStatus__despBox}>
                                                <div className={styles.myDealerStatus__despBoxValue}>
                                                    {item?.dealername} : {item?.dealercode}
                                                </div>
                                                <div className={styles.myDealerStatus__despBoxValue}>
                                                    {item?.physicalverificationdate}
                                                </div>
                                                <div className={styles.myDealerStatus__despBoxValue}>
                                                    {item?.physicalverificationstatus} <br />
                                                </div>
                                            </div>
                                            <hr className="m-1" />
                                        </>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyDealerStatus;
