import { getUniqueListBy } from "../../Components/Common/Function";
import { getBmWssApi } from "../../services/Bms/getBmWssApi";
import { GET_BM_WSS_FAIL, GET_BM_WSS_SUCCESS } from "../constants/action-types";
import { sessionExpireTimeout } from "./utility-actions";

//Api call for barGraph
export const getBmWss = (data) => async (dispatch) => {
  let sh2Code = [];
  let sh3Code = [];
  const sh2data = getUniqueListBy(data, "SH2_Code__c");
  sh2data?.map((item) => {
    sh2Code.push(item?.SH2_Code__c);
  });
  const sh3data = getUniqueListBy(data, "SH3_Code__c");
  sh3data?.map((item) => {
    sh3Code.push(item?.SH3_Code__c);
  });
  const response = await getBmWssApi(sh2Code, sh3Code);
  if (response?.success) {
    dispatch({
      type: GET_BM_WSS_SUCCESS,
      payload: response?.data?.records,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_BM_WSS_FAIL,
    });
  }
};