import axios from "axios";
import { BASE_URL } from "../../variables";

export const getMvrFormDateApi = async (userId) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,Owner.name,SaveFormDate__c,IsSavedDraft__c  from Market_Visit_Report__c  where SaveFormDate__c=LAST_90_DAYS and OwnerId='${userId}'`,
    method: "get",
  };
  try {
    const res = await axios(options);
    return res?.data?.records;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
