import axios from "axios";
import { BASE_URL } from "../variables";

export const getUserTypeApi = async (emailID) => {
    const options = {
        url: `${BASE_URL}/services/data/v56.0/query?q=Select id,Employee_Name__c, Position_Structure_Id__c, HO_Email__c, HO_Role__c from Role_Mapping__c where HO_Email__c ='${emailID}' `,
        method: "get",
    };
    try {
        const response = await axios(options);
        if (response.status === 200) {
            return {
                success: true,
                status: response?.status,
                data: response?.data,
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};
