import axios from "axios";
import { sessionExpireTimeout } from "../../../Redux/actions/utility-actions";
import { BASE_URL } from "../../../variables";
import { getFeedbackDetails } from "../../../Redux/actions/getFeedback-action";
import { getMyUserFeedback } from "../../../Redux/actions/myuser-actions";

export const addUserDetailsApi =
  ({
    RecordType,
    Observation,
    UserName,
    UserMobile,
    Category,
    SubCategory,
    Territory,
    filename,
    Question1,
    Question2,
    fileExtension,
    base64,
  }) =>
  async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/apexrest/MyUser/`,
      method: "post",
      data: {
        RecordType,
        Observation,
        UserName,
        UserMobile,
        Category,
        SubCategory,
        Territory,
        filename,
        Question1,
        Question2,
        fileExtension,
        base64,
      },
    };
    try {
      const response = await axios(options);
      if (response?.status === 200) {
        dispatch(getFeedbackDetails());
        dispatch(getMyUserFeedback());
        return {
          success: true,
          data: response?.data,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        sessionExpireTimeout(dispatch);
      } else {
        return {
          success: false,
          msg: "Somthing Went Wrong..!!",
        };
      }
    }
  };
