import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import {
  getCurrentQuarterDates,
  getOneDayBeforeDate,
  // getFirstDayOfPastThreeMonth,
  // getLastDateOfCurrentMonth,
} from "../../Components/Common/Function";
import { getCurrentDate } from "../../Components/Common/Function";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getAverageTsiScoreApi = async (SH_Code__c, Title) => {
  // const currentQuaretrDate = getCurrentQuarterDates();
  // const currentQuarterFirstDate = currentQuaretrDate[0];
  // const currentQuarterLastDate = currentQuaretrDate[1];
  // const oneDayBefore = getOneDayBeforeDate();
  const currentDate = getCurrentDate();

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/teams/get-teams-average-tsi-score`,
    method: "post",
    headers: headers,
    data: {
      currentDate,
      SH_Code__c,
      Title,
    },
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
