import React, { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { MdFileDownload } from "react-icons/md";
import { TbFileOff } from "react-icons/tb";
import "./QueryDetails.css";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import QueryUpdate from "./QueryUpdate";
import { useSelector } from "react-redux";
import PageHeader from "../Common/PageHeader";
import { getUploadedFileName } from "../../services/Queries/getUploadedFileName";

const QueryDetails = () => {
  const userAccessToken = useSelector((state) => state?.tokens?.accessToken);

  const [uploadedFile, setUpoadedFile] = useState("");
  const params = useParams();
  const queryInfo = useSelector((state) =>
    state?.query?.queryDetails?.find((info) => {
      if (info?.Id === params?.id) {
        return info;
      }
    })
  );

  // get Uploaded file name

  useEffect(() => {
    (async () => {
      const response = await getUploadedFileName(params?.id);
      if (response?.success) {
        setUpoadedFile(response?.data?.records);
      } else {
        // setError(response?.msg);
      }
    })();
  }, []);

  // fetch api call for download attached file

  const getFileName = (id) => {
    const url = `https://pidilitecwp.my.salesforce.com/services/data/v56.0/sobjects/ContentVersion/${id}/VersionData`;

    fetch(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${userAccessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", uploadedFile[0]?.ContentDocument?.Title);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      <PageHeader title="Service Request" path="/Queries" />
      <div className="queryDetails">
        <h6 className="queryDetails__name">Query Details</h6>
        <div className="queryDetails__box">
          <div className="queryDetails__header">
            <>
              <CgProfile className="queryDetails__profile" />
              <div className="queryDetails__detailName">
                <div className="queryDetails__profileName">
                  {queryInfo?.recType === "Dealer"
                    ? queryInfo?.dealerName
                    : queryInfo?.customerName}
                </div>
                <p className="queryDetails__profileSubName">
                  {queryInfo?.CaseNumber}
                </p>
              </div>
            </>
          </div>
          <Table style={{ marginTop: "10px" }}>
            <>
              <tbody
                className="queryDetails__details"
                style={{ borderStyle: "hidden" }}
              >
                <tr className="queryDetails__subjectSection">
                  <td>Subject:</td>
                  <td>{queryInfo?.Subject}</td>
                </tr>
                <tr>
                  <td>Description: </td>
                  <td>{queryInfo?.Description}</td>
                </tr>
                {queryInfo?.issueResolutionComments && (
                  <tr>
                    <td>Issue Resolution Comments:</td>
                    <td>{queryInfo?.issueResolutionComments}</td>
                  </tr>
                )}
              </tbody>
            </>
          </Table>
          <h6 className="queryDetails__orderDetailsName">Other Details</h6>
          <div className="queryDetails__orderDetailsSection">
            <>
              <div className="queryDetails__orderDetailsTable">
                <div className="queryDetails__orderDetailsProfileSection">
                  {uploadedFile.length > 0 ? (
                    uploadedFile?.map((item) => {
                      return (
                        <>
                          <button
                            onClick={() => {
                              getFileName(
                                item?.ContentDocument?.LatestPublishedVersionId
                              );
                            }}
                            className="queryDetails__orderDetailsProfileRelated"
                          >
                            <p
                              style={{
                                fontSize: "9px",
                                margin: "0px",
                                color: "#808080",
                              }}
                            >
                              Download
                            </p>
                            <MdFileDownload
                              style={{
                                height: "2.1em",
                                width: "2.1em",
                                color: "#3901FF",
                              }}
                            />

                            {item?.ContentDocument?.Title}
                          </button>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <TbFileOff
                        style={{
                          color: "#3901FF",
                        }}
                      />

                      <p className="queryDetails__orderDetailsProfileRelated">
                        No file attached
                      </p>
                    </>
                  )}
                </div>
                <div className="queryDetails__datetime">
                  <p className="queryDetails__orderDetailsTitle">Date</p>
                  <p className="queryDetails__orderDetailsData">
                    {queryInfo?.createdDate?.split(" ")?.[0]}
                  </p>
                  <p className="queryDetails__orderDetailsTitle">Time</p>
                  <p className="queryDetails__orderDetailsData">
                    {queryInfo?.createdDate?.split(" ")?.[1]}{" "}
                    {queryInfo?.createdDate?.split(" ")?.[2]}
                  </p>
                </div>
                <div className="queryDetails__statusRelated">
                  <p className="queryDetails__orderDetailsTitle">Status</p>
                  <p
                    className="queryDetails__orderDetailsData"
                    style={{ color: "#2CC26A" }}
                  >
                    {queryInfo?.Status}
                  </p>
                  <p className="queryDetails__orderDetailsTitle">Related to </p>
                  <p className="queryDetails__orderDetailsData">
                    {queryInfo?.recType}
                  </p>
                </div>
              </div>
            </>
          </div>
          <div className="row m-3">
            {/* <div className="col-3  p-0">
              <RWebShare
                data={{
                  text: "Checkout below page for query details",
                  url: window.location.href,
                  title: "Query details",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <p className="queryDetails__shareTitle p-0">
                  <AiIcons.AiOutlineShareAlt className="queryDetails__shareIcon" />
                  Share
                </p>
              </RWebShare>
            </div> */}
            <div className="col-6 p-0"></div>
            {/* <div className="col-3 p-0 queryDetails__FollowUp">
              <Dropdown>
                <Dropdown.Toggle className="queryDetails__submitBtn">
                  Follow up
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <HiOutlineRefresh className="queryDetails__followIcons" />
                    Auto
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <FiSend className="queryDetails__followIcons" />
                    Mail
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    <TbPhoneCall className="queryDetails__followIcons" />
                    Call
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-4">
                    <FiMessageSquare className="queryDetails__followIcons" />
                    Message
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </div>
        </div>
      </div>
      <QueryUpdate recId={queryInfo?.Id} />
    </>
  );
};

export default QueryDetails;
