import axios from "axios";
import { getMarketWorking } from "../../../Redux/actions/homePage-actions";
import { getFeedbackDetails } from "../../../Redux/actions/getFeedback-action"
import { sessionExpireTimeout } from "../../../Redux/actions/utility-actions";
import { BASE_URL } from "../../../variables";

export const addFeedbackApi =
  ({
    dealerId,
    Title,
    Observation,
    recordType,
    fileExtension,
    fileName,
    base64,
  }) =>
  async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/apexrest/Feedback/`,
      method: "post",
      data: {
        dealerId,
        Title,
        Observation,
        recordType,
        fileExtension,
        fileName,
        base64,
      },
    };

    try {
      const response = await axios(options);
      if (response.status === 200) {
        dispatch(getMarketWorking());
        dispatch(getFeedbackDetails());
        return {
          success: true,
          data: response?.data,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        sessionExpireTimeout(dispatch);
      } else {
        return {
          success: false,
          msg: "Somthing Went Wrong..!!",
        };
      }
    }
  };
