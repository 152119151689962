import axios from "axios";
import { BASE_URL } from "../../variables";

export const getRBDMCodeApi = async (Email) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id, SH3_Code__c,Sales_Group__c from Pidilite_Hierarchy__c where RecordType.name = 'Marketing Hierarchy' and SH3_Email__c != null and Sales_Group__c = '105' and SH3_Email__c = '${Email}'`,
    method: "get",
  };
  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
