import axios from "axios";
import { isLocalServer } from "../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../utils/getNodeUrl";

export const getGlobalAccessTokenApi = async () => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();

  console.log("isLocalhost --> ", isLocalServer());
  const options = {
    url: `${nodeBaseUrl}/user/get-global-access-token`,
    method: "get",
    headers: headers,
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
