import axios from "axios";
import { BASE_URL } from "../../variables";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";

export const getAttachedFileUrl = (mvrFormId) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/${mvrFormId}`,
    method: "get",
    responseType: "arraybuffer"
  };

  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
