import React, { useState, useEffect } from "react";
import styles from "./MyDiary.module.css";
import _ from "lodash";
import { AiOutlineFile } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { addMyDiaryApi } from "../../../services/MarketWorking/MyTeam/addMyDiaryApi";
import ModalPopup from "../../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import { connect } from "react-redux";
import Loader from "../../Common/Loader";
import { compressImage } from "../../Common/Function";

const MyDiary = ({ addMyDiaryApi }) => {
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [diaryData, setDiaryData] = useState({
    Title: "",
    Observation: "",
  });
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const FileHandler = async (e) => {
    setIsUploading(true);
    setFormValidation({});
    if (e.target.files[0]?.size < 5242880) {
      let image = "";
      if (e.target.files[0]?.name?.split(".")?.slice(-1) != "pdf") {
        image = await compressImage(e.target.files[0]);
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFile({
          base64: reader.result.replace("data:", "").replace(/^.+,/, ""),
          fileExtension: e.target.files[0]?.name
            ?.split(".")
            ?.slice(-1)
            ?.toString(),
          fileName: e.target.files[0]?.name,
        });
      };
      if (!_.isEmpty(image)) {
        reader.readAsDataURL(image);
      } else {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      setFile({
        base64: "",
        fileExtension: "",
        fileName: "",
      });
      setFormValidation({
        file: "Please upload the file whose size is less than 5MB",
      });
    }
    setIsUploading(false);
  };

  const onChange = async (e) => {
    setDiaryData({ ...diaryData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setFormValidation(checkValidation(diaryData));
  };

  //validation check
  const checkValidation = (values) => {
    const errors = {};
    if (!values.Title) {
      errors.Title = "Title is required";
    }
    if (!values.Observation) {
      errors.Observation = "Observation is required";
    }
    if (formValidation.file) {
      errors.file = "Please upload the file whose size is less than 5MB";
    }
    return errors;
  };

  //post api call of my Diary
  useEffect(() => {
    if (Object?.keys(formValidation)?.length === 0 && isSubmit) {
      (async () => {
        const data = {
          Title: diaryData.Title,
          Observation: diaryData.Observation,
          fileExtension: file?.fileExtension,
          fileName: file?.fileName,
          base64: file?.base64,
        };

        const { Title, Observation, fileExtension, fileName, base64 } = data;
        setLoading(true);
        const response = await addMyDiaryApi({
          Title,
          Observation,
          fileExtension,
          fileName,
          base64,
        });
        setLoading(false);
        if (response?.success) {
          setIsSubmitModalOpen(true);
          setIsSubmit(false);
          //TODO: to show success msg popup if required
        } else {
          //TODO : to show error msg popup
        }
        setDiaryData({
          Title: "",
          Observation: "",
        });
        setFile({
          base64: "",
          fileExtension: "",
          fileName: "",
        });
      })();
    }
    if (Object?.keys(formValidation)?.length !== 0) {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  return (
    <div className={styles.myDiary__outerBox}>
      <Loader loading={isUploading} />
      <div className={styles.myDiary__heading}>My Diary</div>
      <div>
        {isSubmit && Object?.keys(formValidation)?.length === 0 && (
          <Loader loading={loading} />
        )}
        <div className={styles.myDiary__innerBox}>
          <form className={styles.myDiary__form}>
            <input
              type="text"
              name="Title"
              placeholder="Enter title here"
              value={diaryData?.Title}
              className={
                !diaryData?.Title && formValidation?.Title
                  ? styles.myDiary__inputBoxError
                  : styles.myDiary__inputBox
              }
              onChange={(e) => onChange(e)}
            />
          </form>
          {!diaryData?.Title && formValidation?.Title && (
            <p className={styles.myDiary__validation}>
              {formValidation?.Title}
            </p>
          )}
          <textarea
            name="Observation"
            rows="8"
            value={diaryData?.Observation}
            placeholder="Enter your observation here"
            className={
              !diaryData?.Observation && formValidation?.Observation
                ? styles.myDiary__textareaError
                : styles.myDiary__textarea
            }
            onChange={(e) => onChange(e)}
          ></textarea>
          {!diaryData?.Observation && formValidation?.Observation && (
            <p className={styles.myDiary__validation}>
              {formValidation?.Observation}
            </p>
          )}
        </div>
      </div>

      {!_.isEmpty(file?.fileName) && (
        <>
          <div className={`mb-3 ${styles.myDiary__displayFileName}`}>
            <AiOutlineFile className={styles.myDiary__fileIcon} />
            <span className={styles.myDiary__fileUploadName}>
              {file?.fileName}
            </span>
          </div>
        </>
      )}
      {formValidation?.file && (
        <p className={styles.myDiary__validation}>{formValidation?.file}</p>
      )}

      <div className="row">
        <div className="col-6"></div>
        <div className="col-3">
          <div className={styles.myDiary__inputWrapper}>
            Add file
            <input
              className={`${styles.myDiary__fileInput}`}
              type="file"
              accept=".jpg,.png,.pdf, .jpeg"
              onChange={FileHandler}
            />
          </div>
        </div>
        <div className="col-3">
          <Button
            className={styles.myDiary__submitBtn}
            onClick={(e) => onSubmit(e)}
          >
            Submit
          </Button>
        </div>
      </div>

      {isSubmitModalOpen && (
        <ModalPopup
          className="ModalPopup_Submitted"
          isModalOpen={isSubmitModalOpen}
          setIsModalOpen={setIsSubmitModalOpen}
          title="Submitted Successfully"
          data="My Diary has been submitted successfully."
          backdrop={true}
          fade={false}
          icon={
            <>
              <div className="submittedIcons draftIcons">
                <FaThumbsUp className="submittedSmall" />
                <FaThumbsUp className="submittedLarge" />
                <FaThumbsUp className="submittedSmall" />
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default connect(null, {
  addMyDiaryApi,
})(MyDiary);
