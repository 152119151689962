import React from "react";
import Bargraph from "./BarGraph";
import styles from "./MonthlyTourPlanning.module.css";

const MonthlyTourPlanning = () => {
  return (
    <>
      <div className={styles.monthlyTour}>
        <div className={`${styles.monthlyTour__header} row`}>
          <div className={`col-6 ${styles.monthlyTour__title}`}>
            Monthly Tour Planning
          </div>
          <div className="col-6 p-0">
            <div className={styles.monthlyTour__label}>
              <span className={styles.label__box2}></span>
              <span className={styles.monthlyTour_labelMark}>Planned</span>
              <span className={styles.label__box3}></span>
              <span className={styles.monthlyTour_labelMark}>Completed</span>
            </div>
          </div>
        </div>
        <div className={styles.monthlyTour__days}>
          <div className={styles.monthlyTour__15day}>15 Days</div>
          <div className={styles.monthlyTour__dashed}></div>
        </div>
        <div>
          <Bargraph />
        </div>
      </div>
    </>
  );
};

export default MonthlyTourPlanning;
