import axios from "axios";

import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";

export const getZSMDealerNameApi = (sh3Code, sh2Code) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,Dealer_Name__c,DealerCode__c from account where RecordType.Name ='Dealer' AND HierarchyLevel3ASMTerritory__c='${sh3Code}' AND TSICode__c='${sh2Code}'`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data?.records,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_REQUEST") {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
