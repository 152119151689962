import React, { useState, useEffect } from "react";
import styles from "./Queries.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import _ from "lodash";
import { MdFilterList } from "react-icons/md";
import { getKeyInformationQueries } from "../../services/KeyInformation/getKeyInformationQueries";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";

const Queries = ({ getKeyInformationQueries }) => {
  const queriesEmptyData = [
    { status: "Open", expr0: 0 },
    { status: "Overdue", expr0: 0 },
    { status: "Unassigned", expr0: 0 },
    { status: "Completed", expr0: 0 },
    { status: "In progress", expr0: 0 },
    { status: "Assigned", expr0: 0 },
  ];

  const [keyInformationQueries, setKeyInformationQueries] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getKeyInformationQueries();
      if (response?.success) {
        setKeyInformationQueries(response?.data);
        setLoading(false);
      } else {
        setError(response?.msg);
      }
    })();
  }, []);

  const option = ["Dealer", "MyDealer", "WSS", "Product", "User", "MyTeam"];
  const [selectedOption, setSelectedOption] = useState("Dealer");
  const DropdownHandler = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <div className={loading && "showLoader"}>
        <div className="loaderOuterBox">
          <div className="loaderInnerBox">
            {loading && <ClipLoader loading={loading} color="#3901ff" />}
          </div>
        </div>

        <div className={styles.keyInformationQueries}>
          <div className={styles.keyInformationQueries__headingBox}>
            <div className={styles.keyInformationQueries__heading}>Queries</div>
            <div className={styles.keyInformationQueries__dropdown}>
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.keyInformationQueries__dropDownButton}
                >
                  {selectedOption}
                  <MdFilterList
                    className={styles.keyInformationQueries__menuIcon}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={styles.keyInformationQueries__dropdownMenu}
                >
                  {option.map((item) => {
                    return (
                      <Dropdown.Item
                        href="#/action-1"
                        className={styles.keyInformationQueries__dropdownItems}
                        onClick={() => DropdownHandler(item)}
                      >
                        <input
                          type="radio"
                          class="with-gap"
                          name="fav_language"
                          value="HTML"
                        />
                        <p
                          for="html"
                          className={
                            styles.keyInformationQueries__dropDownLabel
                          }
                        >
                          {item}
                        </p>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={styles.keyInformationQueries__outerBox}>
            <div className={styles.keyInformationQueries__innerBox}>
              {keyInformationQueries &&
                Object.keys(keyInformationQueries)?.map((option) => {
                  if (option === selectedOption) {
                    if (_.isEmpty(keyInformationQueries[option])) {
                      return queriesEmptyData?.map((value) => {
                        return (
                          <>
                            <div
                              className={`${styles.keyInformationQueries__innerBoxMainData}`}
                            >
                              <div
                                className={`${styles.keyInformationQueries__leftText}`}
                              >
                                {value.status}
                              </div>
                              <div
                                className={` ${styles.keyInformationQueries__rightNumber}`}
                              >
                                {value.expr0}
                              </div>
                            </div>
                          </>
                        );
                      });
                    } else {
                      return keyInformationQueries[option]?.map((value) => {
                        return (
                          <>
                            <div
                              className={`${styles.keyInformationQueries__innerBoxMainData}`}
                            >
                              <div
                                className={`${styles.keyInformationQueries__leftText}`}
                              >
                                {value.Status}
                              </div>
                              <div
                                className={` ${styles.keyInformationQueries__rightNumber}`}
                              >
                                {value.expr0}
                              </div>
                            </div>
                          </>
                        );
                      });
                    }
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getKeyInformationQueries,
})(Queries);
