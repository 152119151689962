import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import _ from "lodash";
import ModalPopup from "../../Common/ModalPopup";
import { useForm } from 'react-hook-form';
import { GrCircleInformation } from "react-icons/gr";
import styles from "./MyWssFeedbackForm.module.css";
import Button from "react-bootstrap/Button";
import { useSelector, connect } from "react-redux";
import Loader from "../../Common/Loader";
import { updateCheckInQuaterlyForm } from "../../../services/wss/updateCheckInQuaterlyForm";
import { updateCheckInDailyForm } from "../../../services/wss/updateCheckInDailyForm";
import { checkedInapi } from "../../../services/MarketWorking/MyTeam/checkInApi";
// import styles from "./EditFormModal.module.css";

const MyWssFeedbackForm = ({ record, formSuccess, formError, checkedInapi, QuaterlyFlag }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [error, setError] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkInId, setCheckInId] = useState("");
    const [quaterlyForm, setQuaterlyForm] = useState(false);
    const [checkinPayLoad, setCheckinPayLoad] = useState({});
    const [dailyFormData, setDailyFormData] = useState(false);
    const [quaterlyFormData, setQuaterlyFormData] = useState(true);

    // To open Modal after data is received 
    useEffect(() => {
        if (!_.isEmpty(record)){
            // setQuaterlyForm(!QuaterlyFlag)
            setCheckinPayLoad(record);
            handleShow(record);
        }
    },[record])

    // Modal Popup Controller Functions
    const handleClose = () => {
        setQuaterlyForm(false);
        setShow(false);
    }

    // To open the form 
    const handleShow = () => setShow(true);

    // WSS Check-In api call after form is get filled 
    const onClickYes = async () => {
        setLoading(true);
        if (checkinPayLoad) {
            try {
                let response;
                if (checkinPayLoad.territory !== "") {
                    response = await checkedInapi(
                        checkinPayLoad.RecordtypeName,
                        checkinPayLoad.territory,
                        checkinPayLoad.dealer,
                        checkinPayLoad.dealercode,
                        checkinPayLoad.customer,
                        checkinPayLoad.customercode,
                        checkinPayLoad.Transaction_lat,
                        checkinPayLoad.Transaction_long,
                        checkinPayLoad.IsValidCheckIn,
                        checkinPayLoad.Master_lat,
                        checkinPayLoad.Master_long,
                        checkinPayLoad.Trasanction_distance,
                        checkinPayLoad.Master_Accuracy,
                        checkinPayLoad.Current_Accuracy,
                        checkinPayLoad.Is_Correct_CheckIn
                    );
                }
                if (response?.success == true) {
                    // Assuming setCheckInId is a function you have defined
                    setCheckInId(response?.data.id);
                    //setQuaterlyForm(false)
                    return response?.data.id;
                } else {
                    setShow(false);
                    reset();
                    //setQuaterlyForm(false)
                    formError()
                    setLoading(false);
                }
            } catch (error) {
                console.error("API call failed", error);
                setError("An error occurred while checking in.");
            }
        }
        setLoading(false);
        return null;
    };

    // API call for wss check-in Quaterly Form
    // const onSubmitFirstForm = async (data, recordId) => {
    //     let formData = [
    //         {
    //             id: recordId,
    //             QuarterlyFormFlag : "true",
    //             ...data
    //         }
    //     ];
    //     const response = await updateCheckInQuaterlyForm(formData);
    //     if (response?.success) {
    //         //console.log("updateCheckInQuaterlyForm success")
    //     } else {
    //         setError(response?.msg);
    //     }
    // };

    // API call daily wss check-in Form
    const onSubmitSecondForm = async (data,recordId) => {
        let formData = [
            {
                id: recordId,
                DailyFormFlag: "true",
                ...data
            }
        ];
        const response = await updateCheckInDailyForm(formData);
        if (response?.success) {
            setLoading(false);
            setShow(false);
            formSuccess()
        } else {
            setLoading(false);
            setShow(false);
            reset();
            setQuaterlyForm(false)
            formError()
        }
    };

    // Function to get seperate form data while click on submit
    const separateData = (data) => {
        const secondForm = {};
        const firstForm = {};
        for (let i = 1; i <= 10; i++) {
            const key = `Q${i}`;
            if (data[key] !== undefined) {
                secondForm[key] = data[key];
            }
        }
        for (const key in data) {
            if (!key.startsWith('Q')) {
                firstForm[key] = data[key];
            }
        }
        setQuaterlyFormData(firstForm);
        setDailyFormData(secondForm);
        // return { secondForm, firstForm };
    };

    // On submit function call
    const onSubmitForm = (data) => {
        separateData(data);
        onClickYes(checkinPayLoad)
    };

    // Submit call for feedback Forms after successfull wss checkin 
    useEffect(() => {
        if (checkInId) {
            const recordId = checkInId;
            // if (QuaterlyFlag == false){
            //     onSubmitFirstForm(quaterlyFormData, recordId)
            // }
            onSubmitSecondForm(dailyFormData, recordId)
            reset();
        }
    }, [checkInId])

    const dropdownData1 = [
        {
            id: 'SettlingDamagesInMarket',
            label: 'Settling damages in market',
            options: ['Never settles genuine damages.',
                      'Sometimes settles genuine damages.',
                      'Always settles genuine damages.']
        },
        {
            id: 'RightCreditExtensionToMarket',
            label: 'Right Credit Extension to Market in line with PIL&#39;s credit policy',
            options: ['Market Credit Index < 0.9 (Not Invested)',
                      'Market Credit Index > 1.25 (Highly Invested)',
                      'Market Credit Index 0.9 - 1.25 (Okay Invested)']
        },
        {
            id: 'SelfWorkingOnSystems',
            label: 'Self-Working on Systems',
            options: ['Fully independent and does not require TSI help.',
                      'Partially dependent on TSI for system working.',
                      'Fully dependent on TSI for system working.']
        },
        {
            id: 'IsWSSProvidingOnlyDarpanBill',
            label: 'Is WSS Providing only Darpan bill (one bill) to market (Darpan bill as Tax invoice)?',
            options: ['Yes',
                'No',]
        },
        {
            id: 'IsHavingRetailOutlet',
            label: 'Is having retail outlet?',
            options: ['Yes',
                'No',]
        }
    ];

    const dropdownData2 = [
        {
            id: 'Q1',
            label: 'Able to self-finance adequate working capital to meet market requirement and capable to support business growth for next 5 years.',
            options: ['WSS has financial constraints for expansion',
                'Need additional support (Channel Finance etc) to expand business',
                'Has own sources of fund to grow business']
        },
        {
            id: 'Q2',
            label: 'PIL share to Total Turnover',
            options: ['< 25% of Total turnover',
                '25 - 50% of Total turnover',
                '> 50% of Total turnover']
        },
        {
            id: 'Q3',
            label: 'Average market credit extended.',
            options: ['< 30 days',
                '30 days - 45 days',
                '45 days - 60 days']
        },
        {
            id: 'Q4',
            label: 'Proper storage and timely utilization of POSM',
            options: ['Not aware',
                    'Only aware, not involved in placement.',
                    'Involved & proactive in placement.']
        },
        {
            id: 'Q5',
            label: 'Wholesaler to retailer ratio',
            options: ['Wholesale contribution more than 40% by value',
                'Wholesale contribution between 20% to 40% by value',
                'Wholesale contribution less than 20% by value']
        },
        {
            id: 'Q6',
            label: 'Ability to regularly bill and support smaller outlets.',
            options: ['50% Delivery within 24 hours of billing',
                '50% - 80% Delivery within 24 hours of billing',
                '80% - 100% Delivery within 24 hours of billing']
        },
        {
            id: 'Q7',
            label: 'Key Dealer Engagement:',
            options: ['Maintains a personal rapport with < 25% key dealers.',
                'Maintains a personal rapport with < 50% key dealers.',
                'Maintains a personal rapport with majority of the key dealers.']
        },
        // {
        //     id: 'Q8',
        //     label: 'WSS Stock Norms:',
        //     options: ['Stock more than 60 days.',
        //         'Stock more than 30 days.',
        //         'Stock as per TOC norms.']
        // },
        {
            id: 'Q8',
            label: 'Succession Plan for Private Business',
            options: ['Succession plan with immediate family (Son, Daughter, Brother only) ',
                'Ambiguity',]
        }
        // {
        //     id: 'Q10',
        //     label: 'Any Other remarks feedback which BM needs to share.',
        //     options: ['Yes',
        //         'No',]
        // }
    ];

  return (
      <>
          <ModalPopup
              isModalOpen={show}
              setIsModalOpen={handleClose}
              ModalMainClass="myWssFeedbackForm"
              backdrop={true}
              fade={false}
              data={
                  <>
                    <Loader loading={loading} />
                    <div className={styles.myWssFeedbackForm__MainBox} style={{ textAlign: "left" }}>
                          <form onSubmit={handleSubmit(onSubmitForm)} >
                            {quaterlyForm == true && 
                                <>
                                    <div className={`${styles.myWssFeedbackForm__title}`}>
                                        Quaterly Check-In Feedback Form
                                    </div>
                                    {dropdownData1.map((dropdown,index) => (
                                        <div key={dropdown.id}>
                                            <label htmlFor={dropdown.id} className={styles.myWssFeedbackForm__Label}>{dropdown.label}</label>
                                            <select className={styles.myWssFeedbackForm__input}
                                                id={dropdown.id}
                                                {...register(dropdown.id, { required: `${dropdown.label} is required` })}
                                            >
                                                <option value="" className={styles.myWssFeedbackForm__defaultOption}>Select option ...</option>
                                                {dropdown.options.map((option, index) => (
                                                    <option key={index} value={option} className={styles.myWssFeedbackForm__Option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors[dropdown.id] && <p className={styles.myWssFeedbackForm__error}>{errors[dropdown.id].message}</p>}
                                        </div>
                                    ))}
                                    <hr />
                                </>
                            }
                            <>
                                <div className={`${styles.myWssFeedbackForm__title}`}>
                                    WSS Feedback Form
                                </div>
                                {dropdownData2.map((dropdown, index) => (
                                    <div key={dropdown.id}>
                                        <label htmlFor={dropdown.id} className={styles.myWssFeedbackForm__Label}>{dropdown.label} </label>
                                        <select className={styles.myWssFeedbackForm__input}
                                            id={dropdown.id}
                                            {...register(dropdown.id, { required: `${dropdown.label} is required` })}
                                        >
                                            <option value="" className={styles.myWssFeedbackForm__defaultOption}>Select option ...</option>
                                            {dropdown.options.map((option, index) => (
                                                <option key={index} value={option} className={styles.myWssFeedbackForm__Option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        {errors[dropdown.id] && <p className={styles.myWssFeedbackForm__error}>{errors[dropdown.id].message}</p>}
                                    </div>
                                ))}
                                <div>
                                    <label
                                        htmlFor="Q9"
                                        className={styles.myWssFeedbackForm__Label}
                                    >
                                    Any Other remarks feedback which BM needs to share.
                                    </label>
                                    <textarea
                                        className={styles.myWssFeedbackForm__input}
                                        id="Q9"
                                        {...register("Q9", {
                                            required: "Feedback is required",
                                            maxLength: {
                                                value: 500,
                                                message: "Feedback cannot exceed 500 characters",
                                            },
                                        })}
                                    />
                                    {errors.Q9 && (
                                          <p className={styles.myWssFeedbackForm__error}>{errors.Q9.message}</p>
                                    )}
                                </div>
                            </>
                            <div className={styles.myWssFeedbackForm__submitBtnBox}>
                                <Button
                                    className={styles.myWssFeedbackForm__submit}
                                    onClick={handleClose}
                                >
                                    <p className={styles.myWssFeedbackForm__submitBtn}>Cancel</p>
                                </Button>
                                <Button
                                    className={styles.myWssFeedbackForm__cancel}
                                    variant="secondary"
                                    type="submit"
                                >
                                    <p
                                        className={styles.myWssFeedbackForm__cancelBtn}
                                    >
                                        Submit
                                    </p>
                                </Button>
                            </div>
                        </form>
                    </div>
                  </>
              }
          />
      </>
  )
}

export default connect(null, {
    checkedInapi
})(MyWssFeedbackForm);