import axios from "axios";
import { BASE_URL } from "../../variables";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";

export const getZSMTerritoryApi = (sh3CodeForTerritory) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,TXNType__c,SH6_Code__c,SH3_Name__c  from Pidilite_Hierarchy__c where SH3_Code__c ='${sh3CodeForTerritory}' AND RecordType.Name In('Sales Hierarchy','Key Information')`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_REQUEST") {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
