import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import { getPastFiveDates } from "../../Components/Common/Function";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getWssAutoVAlueFtdTrendApi = async (SH_Code__c, Title) => {
  const pastFiveDates = getPastFiveDates();

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/wss/get-wss-auto-value-ftd-trend`,
    method: "post",
    headers: headers,
    data: { dates: pastFiveDates, SH_Code__c, Title },
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
