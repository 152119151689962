import React, { useEffect, useState } from "react";
import PowerBi from "../../Pages/PowerBi";
import styles from "./Surveys.module.css";
import Form from "react-bootstrap/Form";
import _ from "lodash";

const Surveys = () => {
  // const data = [
  //   {
  //     PageName:"PIL Similar Survey",
  //     ReportId: "d0edce23-f508-4788-aa6e-37390e4457c3",
  //     ReportSection: "ReportSection35284f9f0053c002b67d",
  //   },
  //   {
  //     PageName:"Last Response Filtered",
  //     ReportId: "d0edce23-f508-4788-aa6e-37390e4457c3",
  //     ReportSection: "ReportSection35284f9f0053c002b67d",
  //   },
  //   {
  //     PageName:"Completion Status",
  //     ReportId: "d0edce23-f508-4788-aa6e-37390e4457c3",
  //     ReportSection: "ReportSectionf971665620af169fae76",
  //   },
  //   {
  //     PageName:"HO Dealer Download",
  //     ReportId: "d0edce23-f508-4788-aa6e-37390e4457c3",
  //     ReportSection: "ReportSection5f2ec026e038e65eb183",
  //   },
  //   {
  //     PageName:"Completion Status-view1",
  //     ReportId: "d0edce23-f508-4788-aa6e-37390e4457c3",
  //     ReportSection: "ReportSectionf9389e7401ba7d21a14c",
  //   },
  //   {
  //     PageName:"Category PIL Similar View",
  //     ReportId: "d0edce23-f508-4788-aa6e-37390e4457c3",
  //     ReportSection: "ReportSectionf504b3cb674885e41bfa",
  //   },

  // ];

  // const [section, setSection] = useState("ReportSection163c81fbe088d0db95a6");

  // const onChangeHandler = (e) => {
  //   setSection({value:e.target.value});
  // };
  // console.log("report",section);

  return (
    <>
      <div className={styles.keyInformation__surveys}>
      <div className={styles.surveys__heading}>Surveys</div>
      {/* <table className={styles.surveys__table}>
        <thead>
          <tr className={styles.surveys__theadRow}>
            <th className={styles.surveys__theadRowHeading}>Survey Name</th>
            <th className={styles.surveys__theadRowHeading}>Status</th>
            <th className={styles.surveys__theadRowHeading}>Due Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <>
              <tr className={styles.surveys__tbodyDataRow}>
                <td
                  className={`${
                    item?.Status === "Y" && styles.surveys__tbodyDataColor
                  } ${styles.surveys__tbodyTableData}`}
                >
                  {item.SurveyName}
                </td>
                <td
                  className={
                    item?.Status === "Y" && styles.surveys__tbodyDataColor
                  }
                >
                  {item.Status}
                </td>
                <td
                  className={
                    item?.Status === "Y" && styles.surveys__tbodyDataColor
                  }
                >
                  {item.Date}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table> */}
      {/* <Form.Select
        aria-label="Default select example"
        className={`form-select ${styles.mySR__categorySelect}`}
        name="dealerId"
        onChange={(e) => onChangeHandler(e)}
      >
        <option
          value=""
          // selected={_.isEmpty(srData?.dealerId) ? true : false}
          className={styles.mySR__defaultOption}
        >
          Index-PIL Similar Survey
        </option>
        {data?.map((name) => {
          return <option value={name?.ReportSection}>{name?.PageName}</option>;
        })}
      </Form.Select> */}
      <PowerBi id="d0edce23-f508-4788-aa6e-37390e4457c3" pageName="ReportSection163c81fbe088d0db95a6"/>
      </div>
    </>
  );
};

export default Surveys;
