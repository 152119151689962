import React, { useState, useEffect } from "react";
import style from "../../MarketWorking/MyDiary/PreviousObservation.module.css";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { useSelector } from "react-redux";
import _ from "lodash";

const PreviousObservation = () => {
  const initialState = 3;
  const [noOfElement, setnoOfElement] = useState(initialState);
  const [slicedFeedback, setSlicedFeedback] = useState();

  const feedBack = useSelector(
    (state) => state?.getFeedback?.getFeedbackDetails?.Previous_Feedback
  );

  useEffect(() => {
    setSlicedFeedback(feedBack?.slice(0, noOfElement));
  }, [feedBack, noOfElement]);

  //load more
  const loadMore = (e) => {
    e.preventDefault();
    if (noOfElement === initialState) {
      setnoOfElement(feedBack.length);
    } else setnoOfElement(3);
  };

  return (
    <div className={style.previousObservation}>
      <div className={style.previousObservation__box}>
        <div className="row m-0">
          <div className={`col-8`}>
            <h6 className={style.previousObservation__toDay}>
              Previous Observations
            </h6>
          </div>
          <div className={`col-4 `}>
            <h6 className={style.previousObservation__count}>
              {feedBack?.length || 0} Observations
            </h6>
          </div>
        </div>
        <div className="row m-0" style={{ paddingRight: "4.375rem" }}>
          <div
            className={`col-4 mb-2 ${style.previousObservation__location}`}
          ></div>
        </div>

        <div className={style.previousObservation__dataDiv}>
          {slicedFeedback?.map((item) => {
            return (
              <div
                className={`row mx-0 ${style.previousObservation__FeedbackText}`}
              >
                <div className={style.previousObservation__FeedbackText_p}>
                  {item?.Observations__c}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="row m-0"
          style={{
            display: "flex",
            justifyContent: "end",
            height: "1.6rem",
            paddingTop: "2px",
          }}
        >
          <div className="col-3">
            {feedBack?.length > 3 ? (
              <>
                <button
                  className={style.previousObservation__bottom_button}
                  onClick={loadMore}
                >
                  {!_.isEmpty(slicedFeedback) ? (
                    noOfElement == initialState ? (
                      <div
                        className={style.previousObservation__bottom_section}
                      >
                        <p className={style.previousObservation__seeAll}>
                          View all
                        </p>
                        <BsChevronDown
                          className={style.previousObservation__seeAll_icon}
                        />
                      </div>
                    ) : (
                      <div
                        className={style.previousObservation__bottom_section}
                      >
                        <p className={style.previousObservation__seeAll}>
                          See less
                        </p>
                        <BsChevronUp
                          className={style.previousObservation__seeAll_icon}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousObservation;
