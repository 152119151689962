import React from "react";
import styles from "./CurrentDayInformation.module.css";

const CurrentDayInformation = () => {
  const data = [
    {
      first: "DRCP",
      second: "#TC!85<%",
      third: "2/6",
    },
    {
      first: "Tab Productivity",
      second: "#TC!85<%",
      third: "2/6",
    },
    {
      first: "Order Value FTD",
      second: "INR (In Lac)",
      third: "1.3",
    },
  ];

  return (
    <>
      <div className={styles.currentDayInformation}>
      <div className={styles.currentDayInformation__heading}>
        Current Day Information
      </div>
      <div className={styles.currentDayInformation__outerBox}>
        <div className={styles.currentDayInformation__dataMainBox}>
          {data.map((item) => (
            <>
              <div className={styles.currentDayInformation__data}>
                <div className={styles.currentDayInformation__leftData}>
                  <div className={styles.currentDayInformation__leftDataFirst}>
                    {item.first}
                  </div>
                  <div className={styles.currentDayInformation__leftDataSecond}>
                    {item.second}
                  </div>
                </div>
                <div className={styles.currentDayInformation__rightData}>
                  {item.third}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      </div>
    </>
  );
};

export default CurrentDayInformation;
