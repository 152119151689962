import React, { useState, useEffect } from "react";
import styles from "./DealerLocatorForm.module.css";
import "../Common/SearchableSelect.css";
import Form from "react-bootstrap/Form";
import { RiDirectionFill } from "react-icons/ri";
import "./DealerTimeline.css";
import { addDealerLocatorZoneApi } from "../../services/DealerLocator/addDealerLocatorZoneApi";
import { addDealerLocatorClusterApi } from "../../services/DealerLocator/addDealerLocatorClusterApi";
import { addDealerLocatorTerritoryApi } from "../../services/DealerLocator/addDealerLocatorTerritoryApi";
import GoogleMap from "../MarketWorking/MyTeam/GoogleMap";
import { useSelector } from "react-redux";
import { getUniqueListBy } from "../Common/Function";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";
import Message from "../Common/Message";
import { MdLocationOff } from "react-icons/md";
import { connect } from "react-redux";
import { getDealerLocatorDetailsApi } from "../../services/Cwp/getDealerLocatorDetailsApi";
import { getRmdmWssApi } from "../../services/rmdm/getRmdmWssApi";
import { getDealerNameApi } from "../../services/rmdm/getDealerNameApi";

const territoryAutoCompletePlaceHolder = {
  territory: null,
  territoryName: null,
};

const dealerPlaceHolder = {
  longitude: null,
  latitude: null,
  distance: null,
  CustomerName: null,
  OutletType7: null,
  OutletType1: null,
  OutletClass: null,
  DealerCode: null,
  territoryName: null,
  territory: null,
  DealerId: null,
  name: null,
};

const rmdmClusterCodePlaceHolder = {
  Cluster_Description__c: "",
  CLUSTER_CODE__c: "",
};

const rmdmDealerPlaceHolder = {
  Dealer_Name__c: "",
  DealerCode__c: "",
};

const DealerLocatorForm = ({
  getDealerLocatorDetailsApi,
  getRmdmWssApi,
  getDealerNameApi,
}) => {
  const [salesGroup, setSalesGroup] = useState("");
  const [error, setError] = useState("");
  const [selectedSalesGroup, setSelectedSalesGroup] = useState("");
  const [zone, setZone] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [cluster, setCluster] = useState("");
  const [selectedCluster, setSelectedCluster] = useState("");
  const [uniqueTerritory, setUniqueTerritory] = useState([]);
  const [uniqueTerritoryCWP, setUniqueTerritoryCWP] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState("");
  const [latLong, setLatLong] = useState(null);
  const [mapZoom, setMapZoom] = useState(12);
  const [allTerritory, setAllTerritory] = useState("");
  const [outlet1, setOutlet1] = useState("");
  const [dealerSearch, setDealerSearch] = useState([]);
  const [locationError, setLocationError] = useState(false);
  const [failedOpen, setFailedOpen] = useState(false);
  const [dealer, setDealer] = useState(dealerPlaceHolder);
  const [dealerCodeValue, setDealerCodeValue] = useState("");
  const [territory, setTerritory] = useState(territoryAutoCompletePlaceHolder);
  const [rmdmCluster, setRmdmCluster] = useState(rmdmClusterCodePlaceHolder);
  const [rmdmDealerNameList, setRmdmDealerNameList] = useState([]);
  const [rmdmDealer, setRmdmDealer] = useState(rmdmDealerPlaceHolder);
  const userType = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);

  const userTitle = useSelector(
    (state) => state?.userDetails?.details?.Profile?.Name
  );

  const rmdmClusterList = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );

  

  // to get the current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatLong({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          setLocationError(true);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  // to get Dealer Locator Sales group api
  useEffect(() => {
    (async () => {
      const response = await getDealerLocatorDetailsApi();
      if (response?.success) {
        setSalesGroup(response?.data?.Sales_Group);
      } else {
        setError(response?.msg);
      }
    })();
  }, []);

  // on change of Sales group
  const onChangeSalesGroup = (e) => {
    if (e.target.name === "salesGroup") {
      setSelectedSalesGroup(e.target.value);
      setCluster("");
      setUniqueTerritory([]);
    }
    if (e.target.name === "zone") {
      setSelectedZone(e.target.value);
      setUniqueTerritory([]);
    }
    if (e.target.name === "cluster") {
      setOutlet1("");
      setDealer(dealerPlaceHolder);
      setTerritory(territoryAutoCompletePlaceHolder);
      setUniqueTerritory([]);
      setSelectedCluster(e.target.value);
      if (locationError === true) {
        setFailedOpen(true);
      }
    }
    if (e.target.name === "territory") {
      setOutlet1("");
      setDealer(dealerPlaceHolder);
      var temp = allTerritory;
      temp = temp.filter((item) => item?.territory === e.target.value);
      setOutlet1(getUniqueListBy(temp, "OutletType1"));
    }

    if (e.target.name === "outlet1") {
      setDealer(dealerPlaceHolder);
      var temp1 = outlet1;
      const data = temp1?.find((item) => item?.OutletType1 === e.target.value);
      var temp1 = allTerritory;
      temp1 = temp1.filter((item) => item?.OutletType1 === e.target.value);
      setDealerCodeValue(getUniqueListBy(temp1, "name"));
    }
  };

  useEffect(() => {
    if (selectedDealer) {
      setLatLong({
        lat: selectedDealer?.latitude,
        long: selectedDealer?.longitude,
      });
    }
  }, [selectedDealer]);

  // to get Zone api
  useEffect(() => {
    if (selectedSalesGroup) {
      (async () => {
        const response = await addDealerLocatorZoneApi(selectedSalesGroup);
        if (response?.success) {
          setZone(response?.data?.Zone);
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [selectedSalesGroup]);

  // to get Territroy api
  useEffect(() => {
    if (
      !_.isEmpty(selectedCluster) &&
      (userType === "SH6" ||
        userType === "SH5" ||
        userType === "HO Collaborator" ||
        userType === "SH7")
    ) {
      (async () => {
        const temp = selectedCluster?.split(":");
        var temp0 = temp[1];
        const response = await addDealerLocatorTerritoryApi(
          temp0,
          latLong.lat,
          latLong.long
        );
        if (response?.success) {
          var tempArray = response?.data?.dealerName;
          tempArray = tempArray?.filter(
            (ele, ind) =>
              ind ===
              tempArray?.findIndex((elem) => elem?.territory === ele?.territory)
          );
          setAllTerritory(response?.data?.dealerName);
          setUniqueTerritory(tempArray);
          setDealerSearch(response?.data?.dealerName);
        } else {
          setError(response?.msg);
        }
      })();
    } else if (!selectedCluster && userType === "SH3") {
      if (locationError === false) {
        (async () => {
          const temp0 = "";
          const response = await addDealerLocatorTerritoryApi(
            temp0,
            latLong.lat,
            latLong.long
          );
          if (response?.success) {
            var tempArray = response?.data?.dealerName;
            tempArray = tempArray?.filter(
              (ele, ind) =>
                ind ===
                tempArray?.findIndex(
                  (elem) => elem?.territory === ele?.territory
                )
            );
            setAllTerritory(response?.data?.dealerName);
            setUniqueTerritoryCWP(tempArray);
            setDealerSearch(response?.data?.dealerName);
          } else {
            setError(response?.msg);
          }
        })();
      } else if (locationError === true) {
        setFailedOpen(true);
      }
    }
  }, [selectedCluster, latLong, locationError]);

  // to get Cluster api
  useEffect(() => {
    if (selectedZone) {
      (async () => {
        const zoneData = selectedZone?.split(":");
        let zone1 = zoneData[1];

        const response = await addDealerLocatorClusterApi(zone1);
        if (response?.success) {
          setCluster(response?.data?.cluster);
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [selectedZone]);

  const naviagteMap = () => {
    window.open(
      "https://maps.google.com?q=" +
      parseFloat(latLong.lat) +
      "," +
      parseFloat(latLong.long)
    );
  };

  // to get Dealer name when user is RMDM/RBDM
  useEffect(() => {
    if (!_.isEmpty(rmdmCluster?.CLUSTER_CODE__c)) {
      (async () => {
        const response = await getRmdmWssApi(rmdmCluster?.CLUSTER_CODE__c);
        if (response?.success) {
          let arr = [];
          await response?.data?.map((item) =>
            arr.push(item?.WSS_Territory_Code__c)
          );
          const res = await getDealerNameApi(arr);
          if (res?.success) {
            setRmdmDealerNameList(res?.data?.records);
          }
        }
      })();
    }
  }, [rmdmCluster]);

  return (
    <>
      <div className={styles.dealerLocatorForm}>
        <div className={`row m-0 ${styles.DealerLocatorForm__headingBox}`}>
          <div className={`col p-0 ${styles.DealerLocatorForm__heading}`}>
            Locate Dealer ( {userTitle} )
          </div>
        </div>
        {/* for HO users , salesgroup, cluster, zone ,territory must be selected */}
        <div className={styles.DealerLocatorForm__mainBox}>
          {(userType === "HO Collaborator" ||
            userType === "SH6" ||
            userType === "SH5" ||
            userType === "SH7") && (
              <>
                <div className={styles.DealerLocatorForm__selectableRows}>
                  <label className={styles.DealerLocatorForm__selectLabel}>
                    Sales Group
                  </label>
                  <Form.Select
                    size="sm"
                    name="salesGroup"
                    className={`required ${styles.DealerLocatorForm__selectFieldLarge}`}
                    onChange={(e) => onChangeSalesGroup(e)}
                  >
                    <option selected="true" disabled>
                      Select Sales Group
                    </option>
                    {salesGroup &&
                      salesGroup?.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                  </Form.Select>
                </div>
                <div className={styles.DealerLocatorForm__selectableRows}>
                  <label className={styles.DealerLocatorForm__selectLabel}>
                    Zone
                  </label>
                  <Form.Select
                    aria-label="Default select example"
                    size="sm"
                    name="zone"
                    className={`required ${`required ${styles.DealerLocatorForm__selectField}`}`}
                    onChange={(e) => onChangeSalesGroup(e)}
                  >
                    <option selected="true" disabled>
                      Select Zone (*Select Sales Group First)
                    </option>
                    {zone &&
                      zone?.map((item) => {
                        return item.includes("null") ? null : (
                          <option value={item}>{item}</option>
                        );
                      })}
                  </Form.Select>
                </div>
                <div className={styles.DealerLocatorForm__selectableRows}>
                  <label className={styles.DealerLocatorForm__selectLabel}>
                    Cluster
                  </label>
                  <Form.Select
                    aria-label="Default select example"
                    size="sm"
                    name="cluster"
                    className={`required ${styles.DealerLocatorForm__selectField}`}
                    onChange={(e) => onChangeSalesGroup(e)}
                  >
                    <option selected="true" disabled>
                      Select Cluster (*Select Zone First)
                    </option>
                    {cluster &&
                      cluster?.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                  </Form.Select>
                </div>
                <div className={styles.DealerLocatorForm__selectableRows}>
                  <label className={styles.DealerLocatorForm__selectLabel}>
                    Territory
                  </label>

                  <Autocomplete
                    value={territory}
                    id="grouped-demo"
                    className="myDealer__categorySelect"
                    // options={
                    //   uniqueTerritory?.length === 0
                    //     ? [{ territory: "Loading...", territoryName: "" }]
                    //     : failedOpen && uniqueTerritory?.length === 0 ? [{territory:"For territory selection please grant location permission!", territoryName : ""}] : uniqueTerritory
                    // }
                    options={
                      failedOpen && uniqueTerritory?.length === 0
                        ? [
                          {
                            territory:
                              "For territory selection please grant location permission!",
                            territoryName: "",
                          },
                        ]
                        : uniqueTerritory?.length === 0
                          ? [{ territory: "Loading...", territoryName: "" }]
                          : uniqueTerritory
                    }
                    getOptionLabel={(item) => {
                      if (item?.territory) {
                        return `${item?.territory} ${item?.territoryName ? ":" : ""
                          } ${item?.territoryName}`;
                      } else {
                        return "";
                      }
                    }}
                    onChange={(e, newValue) => {
                      onChangeSalesGroup({
                        target: {
                          name: "territory",
                          value: newValue && newValue.territory,
                        },
                      });
                      setTerritory(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Territory" />
                    )}
                  />
                </div>
              </>
            )}

          {/* for BM users get territory based on current lat long */}
          {userType === "SH3" && (
            <div className={styles.DealerLocatorForm__selectableRows}>
              <label className={styles.DealerLocatorForm__selectLabel}>
                Territory
              </label>
              <Autocomplete
                id="grouped-demo"
                value={territory}
                className="myDealer__categorySelect"
                options={
                  failedOpen && !uniqueTerritoryCWP.length
                    ? [
                      {
                        territory:
                          "For territory selection please grant location permission!",
                        territoryName: "",
                      },
                    ]
                    : uniqueTerritoryCWP
                }
                getOptionLabel={(item) => {
                  if (item?.territory) {
                    return `${item?.territory} ${item?.territoryName ? ":" : ""
                      } ${item?.territoryName}`;
                  } else {
                    return "";
                  }
                }}
                onChange={(e, newValue) => {
                  setTerritory(newValue);
                  onChangeSalesGroup({
                    target: { name: "territory", value: newValue.territory },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Territory" />
                )}
              />
            </div>
          )}

          {(userType !== "MH3" && userType !== "RBDM Collaborator") && (
            <>
              <div className="">
                <div className="">
                  <div className={styles.DealerLocatorForm__selectableRows}>
                    <label className={styles.DealerLocatorForm__selectLabel}>
                      Outlet Category
                    </label>
                    <Form.Select
                      aria-label="Default select example"
                      size="sm"
                      name="outlet1"
                      className={`required ${styles.DealerLocatorForm__selectFieldLarge}`}
                      onChange={(e) => onChangeSalesGroup(e)}
                    >
                      <option>Select Outlet Category</option>
                      {outlet1 &&
                        outlet1?.map((item) => {
                          return (
                            <option value={item?.OutletType1}>
                              {item?.OutletType1}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                </div>
                <div className="">
                  <div className={styles.DealerLocatorForm__selectableRows}>
                    <label className={styles.DealerLocatorForm__selectLabel}>
                      Dealer Code
                    </label>

                    <Autocomplete
                      id="grouped-demo"
                      value={dealer}
                      className="myDealer__categorySelect"
                      options={
                        !_.isEmpty(dealerCodeValue) ? dealerCodeValue : []
                      }
                      getOptionLabel={(option) => {
                        if (option?.name) {
                          return `${option?.name} : ${option?.DealerCode}`;
                        } else {
                          return "";
                        }
                      }}
                      name="dealerCode"
                      onChange={(event, newValue) => {
                        setLatLong({
                          lat: newValue?.latitude,
                          long: newValue?.longitude,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Dealer code"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <p
                className="mt-3"
                style={{
                  textAlign: "center",
                }}
              >
                <b>---------------OR---------------</b>
              </p>
              <div className={styles.DealerLocatorForm__selectableRows}>
                <label className={styles.DealerLocatorForm__selectLabel}>
                  Dealer Search
                </label>

                <Autocomplete
                  id="grouped-demo"
                  className="myDealer__categorySelect"
                  options={dealerSearch && dealerSearch}
                  getOptionLabel={(option) =>
                    `${option?.name}:${option?.DealerCode}`
                  }
                  onChange={(e, newValue) => {
                    setSelectedDealer(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Dealer" />
                  )}
                />
              </div>
            </>
          )}

          {(userType === "MH3" || userType === "RBDM Collaborator") && (
            <>
              <div className={styles.DealerLocatorForm__selectableRows}>
                <label className={styles.DealerLocatorForm__selectLabel}>
                  Cluster Code
                </label>
                <Autocomplete
                  value={rmdmCluster}
                  id="grouped-demo"
                  className="myDealer__categorySelect"
                  options={
                    !rmdmClusterList || rmdmClusterList?.length === 0
                      ? [
                        {
                          Cluster_Description__c: "Loading...",
                          CLUSTER_CODE__c: "",
                        },
                      ]
                      : getUniqueListBy(rmdmClusterList, "CLUSTER_CODE__c")
                  }
                  getOptionLabel={(option) =>
                    `${option?.Cluster_Description__c}${option?.CLUSTER_CODE__c && `:${option?.CLUSTER_CODE__c}`
                    }`
                  }
                  onChange={(e, newValue) => {
                    setRmdmDealer(rmdmDealerPlaceHolder);
                    setRmdmCluster(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Select Cluster"} />
                  )}
                />
              </div>

              <div className={styles.DealerLocatorForm__selectableRows}>
                <label className={styles.DealerLocatorForm__selectLabel}>
                  Dealer
                </label>
                <Autocomplete
                  value={rmdmDealer}
                  id="grouped-demo"
                  className="myDealer__categorySelect"
                  options={
                    !rmdmDealerNameList || rmdmDealerNameList?.length === 0
                      ? [
                        {
                          Dealer_Name__c: "Loading...",
                          DealerCode__c: "",
                        },
                      ]
                      : getUniqueListBy(rmdmDealerNameList, "Dealer_Name__c")
                  }
                  getOptionLabel={(option) =>
                    `${option?.Dealer_Name__c}${option?.DealerCode__c && `:${option?.DealerCode__c}`
                    }`
                  }
                  onChange={(e, newValue) => {
                    setRmdmDealer(newValue);
                    setLatLong({
                      lat: newValue?.DealerLatitude__c,
                      long: newValue?.DealerLongitude__c,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Select Dealer"} />
                  )}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="dealerTimeline">
        <div className="row m-0 DealerTimeline__headingBox">
          <div className={`col-8 p-0 mb-2 DealerTimeline__heading`}>Map</div>
        </div>

        <div></div>

        <div className={styles.DealerLocatorForm__mapSection}>
          {locationError ? (
            <Message
              message={
                "Location access denied. Please update your browser settings to grant location permission."
              }
              className="DealerLocatorForm__mapAccess"
              icon=<MdLocationOff
                style={{ color: "#808080", height: "2em", width: "2em" }}
              />
            />
          ) : (
            <>
              {latLong && (
                <GoogleMap
                  lat={parseFloat(latLong.lat)}
                  long={parseFloat(latLong.long)}
                  zoom={mapZoom}
                />
              )}
            </>
          )}
        </div>
        <button
          type="button"
          className={`btn btn-secondary mt-4 ${styles.navigate_btn}`}
          style={{ backgroundColor: "#2CC26A", color: "white" }}
          onClick={naviagteMap}
        >
          Navigate
          <RiDirectionFill style={{ marginLeft: "4px" }} />
        </button>
        {/* <div className={`mt-3 DealerTimeline__setRange`}>Set Range(km)</div>
        <Box>
          <Slider
            aria-label="Temperature"
            defaultValue={2}
            // value={value}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={2}
            marks
            min={0}
            max={10}
            onChange={(e) => sliderMap(e)}
          />
        </Box> */}
      </div>
    </>
  );
};

export default connect(null, {
  getDealerLocatorDetailsApi,
  getRmdmWssApi,
  getDealerNameApi,
})(DealerLocatorForm);
