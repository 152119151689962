import React, { useEffect, useRef } from "react";
import TotalFeedback from "./../Components/MarketWorking/MyDiary/TotalFeedback";
import PageHeader from "./../Components/Common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

function MyDiary() {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <>
      <PageHeader title="Market Working" path="/" />
      <div className="container p-0" style={{ backgroundColor: "#F5F5F5" }}>
        <TotalFeedback />
      </div>
    </>
  );
}

export default MyDiary;
