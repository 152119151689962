import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BsClockHistory } from "react-icons/bs";
// import {CiCalendar} from "react-icons/ci";
import "./../HomePage/TabComponent.css";
import styles from "./../HomePage/TabComponent.module.css";
import Linegraph from "./Linegraph";
import { useSelector } from "react-redux";
import {
  convertNumToWords,
  getPastThreeMonths,
  getTodaysDate,
  getYesterdayDate,
} from "../Common/Function";
import _ from "lodash";
import BeatLoader from "react-spinners/BeatLoader";
import CommonSnackbar from "../Common/CommonSnackbar";

const TabComponent = () => {
  const [secondarySalesData, setSecondarySalesData] = useState([]);
  const [salesOperationData, setSalesOperationData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [wssData, setWssData] = useState([]);
  const [count, setCount] = useState(0);
  const [failedOpen, setFailedOpen] = useState(false);
  const secondarySales = useSelector(
    (state) => state?.dashBoardDetails?.secondarySalesAcheived?.[0]?.sum
  );

  const plannedData = useSelector((state) => state?.dashBoardDetails?.trend);
  const targetAcheived = useSelector(
    (state) => state?.dashBoardDetails?.secondarySalesTagetAcheived?.[0]?.per
  );
  const targetPlannedData = useSelector(
    (state) => state?.dashBoardDetails?.targetTrend
  );

  const teamsAvgTsiScore = useSelector(
    (state) => state?.dashBoardDetails?.teamsAverageTsiScore?.[0]?.avg
  );

  // const teamsAvgTsiScoreTrend = useSelector(
  //   (state) => state?.dashBoardDetails?.teamsAverageTsiScoreTrend
  // );

  const teamsAvgTsrScore = useSelector(
    (state) => state?.dashBoardDetails?.teamsAverageTsrScore?.[0]?.avg
  );

  // const teamsAvgTsrScoreTrend = useSelector(
  //   (state) => state?.dashBoardDetails?.teamsAverageTsrScoreTrend
  // );

  const teamIncentive = useSelector(
    (state) => state?.dashBoardDetails?.teamsIncentive
  );

  // const teamIncentiveTrend = useSelector(
  //   (state) => state?.dashBoardDetails?.teamsIncentiveTrend
  // );

  const teamSop = useSelector((state) => state?.dashBoardDetails?.teamsSop);

  // const teamSopTrend = useSelector(
  //   (state) => state?.dashBoardDetails?.teamsSopTrend
  // );
  const wssAutoValueFtdSum = useSelector((state) => {
    if (state?.dashBoardDetails?.wssAutoValueFtd?.[0]) {
      return state?.dashBoardDetails?.wssAutoValueFtd?.[0].sum;
    }
  });

  const wssDarpanSyncDayExceptionCount = useSelector((state) => {
    if (state?.dashBoardDetails?.wssDarpanSyncDayExceptionCount?.[0]) {
      return state?.dashBoardDetails?.wssDarpanSyncDayExceptionCount?.[0]
        ?.count;
    }
  });

  const wssWssInBlockCount = useSelector((state) => {
    if (state?.dashBoardDetails?.wssWssInBlockCount?.[0]) {
      return state?.dashBoardDetails?.wssWssInBlockCount?.[0]?.count;
    }
  });
  const wssAutoValueFtdSumTrend = useSelector(
    (state) => state?.dashBoardDetails?.wssAutoValueFtdTrend
  );

  const outletsBilledFtmCount = useSelector(
    (state) => state?.dashBoardDetails?.outletsBilledFtmCount?.[0]?.count
  );

  const outletsBilledFtmCountTrend = useSelector(
    (state) => state?.dashBoardDetails?.outletsBilledFtmCountTrend
  );

  const rsmFtmSum = useSelector(
    (state) => state?.dashBoardDetails?.rsmFtmSum?.[0]?.sum
  );

  const rsmFtmSumTrend = useSelector(
    (state) => state?.dashBoardDetails?.rsmFtmSumTrend
  );

  const upcomingTourPlan = useSelector(
    (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records
  );

  const rsmFtmRsi = parseInt(
    parseInt(outletsBilledFtmCount) / parseInt(rsmFtmSum)?.toFixed(1)
  );

  const loader = <BeatLoader loading={true} color="#3901ff" size={10} />;

  // get data for Secondary Sales dashboard
  useEffect(() => {
    if (
      !_.isEmpty(secondarySales) &&
      plannedData?.length &&
      targetAcheived &&
      targetPlannedData?.length
    ) {
    }
    setSecondarySalesData([
      {
        name: "Secondary Sales Achieved",
        wave: (
          <Linegraph plannedData={plannedData} labels={getPastThreeMonths()} />
        ),
        value: !_.isEmpty(secondarySales)
          ? convertNumToWords(secondarySales)
          : loader,
      },
      {
        name: "Target Achieved",
        wave: (
          <Linegraph
            plannedData={targetPlannedData}
            labels={getPastThreeMonths()}
          />
        ),
        value: !_.isEmpty(targetAcheived)
          ? Math.floor(targetAcheived) + "%"
          : loader,
      },
    ]);
  }, [
    targetAcheived,
    targetPlannedData,
    secondarySales,
    plannedData,
    teamsAvgTsiScore,
  ]);

  //get data for sales operation dashboard
  useEffect(() => {
    setSalesOperationData([
      {
        name: "Outlets Billed FTM",
        wave: (
          <Linegraph
            plannedData={outletsBilledFtmCountTrend}
            labels={getPastThreeMonths()}
          />
        ),
        value: !_.isEmpty(outletsBilledFtmCount)
          ? outletsBilledFtmCount
          : loader,
      },
      {
        name: "RSM FTM",
        wave: (
          <Linegraph
            plannedData={rsmFtmSumTrend}
            labels={getPastThreeMonths()}
          />
        ),
        rsi: _.isEmpty(rsmFtmSum) ? `RSI:${rsmFtmRsi}` : "RSI:0",
        value: !_.isEmpty(rsmFtmSum) ? rsmFtmSum : loader,
      },
    ]);
  }, [
    outletsBilledFtmCount,
    outletsBilledFtmCountTrend,
    rsmFtmSum,
    rsmFtmSumTrend,
    rsmFtmRsi,
  ]);

  // to show popup when MTP is not marked as Visited
  useEffect(() => {
    if (upcomingTourPlan?.length) {
      let count = 0;
      upcomingTourPlan?.map((record) => {
        if (
          new Date(record?.MTP_Date__c)?.toLocaleDateString("en-CA") <=
            getYesterdayDate() &&
          record?.Visit_Status_es__c !== "Visited"
        ) {
          setFailedOpen(true);
          count++;
        }
        setCount(count);
        return "";
      });
    }
  }, [upcomingTourPlan]);

  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailedOpen(false);
  };

  // get data for team dashbaord
  useEffect(() => {
    // if (
    //   teamsAvgTsiScore &&
    //   teamsAvgTsiScoreTrend?.length &&
    //   teamsAvgTsrScoreTrend &&
    //   teamsAvgTsrScore &&
    //   teamIncentiveTrend?.length &&
    //   teamSop &&
    //   teamSopTrend?.length
    // ) {
    // }
    setTeamsData([
      {
        name: "Avg TSI Score",
        // wave: (
        //   <Linegraph
        //     plannedData={teamsAvgTsiScoreTrend}
        //     labels={getPastThreeMonths()}
        //   />
        // ),
        value: !_.isEmpty(teamsAvgTsiScore)
          ? Math.floor(teamsAvgTsiScore)
          : loader,
      },
      {
        name: "Avg TSR Score",
        // wave: (
        //   <Linegraph
        //     plannedData={teamsAvgTsrScoreTrend}
        //     labels={getPastThreeMonths()}
        //   />
        // ),
        value: !_.isEmpty(teamsAvgTsrScore)
          ? Math.floor(teamsAvgTsrScore)
          : loader,
      },
      {
        name: "Qualifying for incentive",
        // wave: (
        //   <Linegraph
        //     plannedData={teamIncentiveTrend}
        //     labels={getPastThreeMonths()}
        //   />
        // ),
        value: !_.isEmpty(teamIncentive)
          ? teamIncentive?.[0]?.total === "0"
            ? "0"
            : `${teamIncentive?.[0]?.counts}/${teamIncentive?.[0]?.total}`
          : loader,
      },
      {
        name: "Qualifying for SOP",
        // wave: (
        //   <Linegraph plannedData={teamSopTrend} labels={getPastThreeMonths()} />
        // ),
        value: !_.isEmpty(teamSop)
          ? teamSop?.[0]?.total === "0"
            ? "0"
            : `${teamSop?.[0]?.counts}/${teamSop?.[0]?.total}`
          : loader,
      },
    ]);
  }, [
    teamsAvgTsiScore,
    // teamsAvgTsiScoreTrend,
    // teamsAvgTsrScoreTrend,
    teamsAvgTsrScore,
    teamIncentive,
    // teamIncentiveTrend,
    teamSop,
    // teamSopTrend,
  ]);

  // get data for wss dashboard
  useEffect(() => {
    if (
      !_.isEmpty(wssAutoValueFtdSum) &&
      wssAutoValueFtdSumTrend?.length &&
      !_.isEmpty(wssWssInBlockCount)
    ) {
    }

    setWssData([
      {
        name: "Auto Value FTD",
        wave: (
          <Linegraph
            plannedData={wssAutoValueFtdSumTrend}
            labels={getPastThreeMonths()}
          />
        ),
        value: !_.isEmpty(wssAutoValueFtdSum) ? wssAutoValueFtdSum : loader,
      },
      {
        name: "WSS in Block",
        wave: null,
        value: !_.isEmpty(wssWssInBlockCount) ? wssWssInBlockCount : loader,
      },
      {
        name: "Sync Days Exception",
        wave: null,
        value: !_.isEmpty(wssDarpanSyncDayExceptionCount)
          ? wssDarpanSyncDayExceptionCount
          : loader,
      },
    ]);
  }, [wssAutoValueFtdSum, wssAutoValueFtdSumTrend, wssWssInBlockCount]);

  return (
    <>
      {/* {count > 0 && (
        <CommonSnackbar
          open={failedOpen}
          autoHideDuration={6000}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          severity={"warning"}
          message={`Please Mark as complete your ${count} tour plan `}
        />
      )} */}
      <div className={styles.tabComponent__box}>
        <Tabs
          defaultActiveKey="home"
          id="fill-tab-example"
          className="tabComponent__header"
          fill
        >
          <Tab eventKey="home" title="Secondary Sales">
            <>
              <div className={styles.tabComponent__calender}>
                {/* <CiCalendar
                  className={styles.tabComponent__calenderSvgColor}
                  style={{ color: "black", height: "12", width: "12" }}
                />
                {getPastThreeMonths()?.[2]} - {getPastThreeMonths()?.[0]}{" "}
                {getTodaysDate()} */}
              </div>
              <div className={styles.tabComponent__contentBox}>
                {secondarySalesData?.map((item) => (
                  <div className={styles.tabComponent__detailsRows}>
                    <div className="row">
                      <div className={`col-4 ${styles.tabComponent__info}`}>
                        <div className={styles.tabComponent__infoName}>
                          {item.name}
                        </div>
                      </div>
                      {/* <div className={`col-2 ${styles.tabComponent__info2}`}>
                        <span>{item.rsi}</span>
                        <br />
                        <span>{item.per}</span>
                      </div> */}
                      <div className={`col-3 ${styles.tabComponent__info}`}>
                        {item.wave}
                      </div>
                      <div
                        className={`col-5 ${styles.tabComponent__infoValue}`}
                      >
                        {item.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          </Tab>
          <Tab eventKey="profile" title="Sales Operations">
            <>
              <div className={styles.tabComponent__calender}>
                {/* <CiCalendar
                  className={styles.tabComponent__calenderSvgColor}
                  style={{ color: "black", height: "12", width: "12" }}
                />
                January 2022 */}
              </div>
              <div className={styles.tabComponent__contentBox}>
                {salesOperationData.map((item) => (
                  <div className={styles.tabComponent__detailsRows}>
                    <div className="row">
                      <div className={`col-4 ${styles.tabComponent__info}`}>
                        <div className={styles.tabComponent__infoName}>
                          {item.name}
                          {item.rsi && (
                            <div className={`${styles.tabComponent__info2}`}>
                              <span>{item.rsi}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* {item.rsi && (
                        <div className={`col-1 ${styles.tabComponent__info2}`}>
                          <span>{item.rsi}</span>
                          <br />
                        </div> */}
                      {/* )} */}

                      <div className={`col-3 ${styles.tabComponent__info}`}>
                        {item.wave}
                      </div>
                      <div
                        className={`col-5 ${styles.tabComponent__infoValue}`}
                      >
                        {item.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4rem",
                }}
              >
                <BsClockHistory
                  style={{ color: "grey", height: "2em", width: "2em" }}
                />
              </div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "0.5rem",
                  fontWeight: "500",
                  color: "grey",
                }}
              >
                Coming soon ...
              </p>
            </div> */}
          </Tab>
          <Tab eventKey="longer-tab" title="Team">
            <>
              <div className={styles.tabComponent__calender}>
                {/* <CiCalendar
                  className={styles.tabComponent__calenderSvgColor}
                  style={{ color: "black", height: "12", width: "12" }}
                />
                January 2022 */}
              </div>
              <div className={styles.tabComponent__contentBox}>
                {teamsData.map((item) => (
                  <div className={styles.tabComponent__detailsRows}>
                    <div className="row">
                      <div className={`col-8 ${styles.tabComponent__info}`}>
                        <div className={styles.tabComponent__infoName}>
                          {item.name}
                        </div>
                      </div>
                      {/* <div className={`col-2 ${styles.tabComponent__info2}`}>
                        <span>{item.rsi}</span>
                        <br />
                        <span>{item.per}</span>
                      </div> */}
                      {/* <div className={`col-3 ${styles.tabComponent__info}`}>
                        {item.wave}
                      </div> */}
                      <div
                        className={`col-4 ${styles.tabComponent__infoValue}`}
                      >
                        {item.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4rem",
                }}
              >
                <BsClockHistory
                  style={{ color: "grey", height: "2em", width: "2em" }}
                />
              </div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "0.5rem",
                  fontWeight: "500",
                  color: "grey",
                }}
              >
                Coming soon ...
              </p>
            </div> */}
          </Tab>
          <Tab eventKey="contact" title="WSS">
            <>
              <div className={styles.tabComponent__calender}>
                {/* <CiCalendar
                  className={styles.tabComponent__calenderSvgColor}
                  style={{ color: "black", height: "12", width: "12" }}
                />
                {getPastThreeMonths()?.[2]} - {getPastThreeMonths()?.[0]}{" "}
                {getTodaysDate()} */}
              </div>
              <div className={styles.tabComponent__contentBox}>
                {wssData.map((item) => (
                  <div className={styles.tabComponent__detailsRows}>
                    <div className="row">
                      <div className={`col-4 ${styles.tabComponent__info}`}>
                        <div className={styles.tabComponent__infoName}>
                          {item.name}
                        </div>
                      </div>
                      {/* <div className={`col-2 ${styles.tabComponent__info2}`}>
                        <span>{item.rsi}</span>
                        <br />
                        <span>{item.per}</span>
                      </div> */}
                      <div className={`col-3 ${styles.tabComponent__info}`}>
                        {item.wave && item.wave}
                      </div>
                      <div
                        className={`col-5 ${styles.tabComponent__infoValue}`}
                      >
                        {item.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default TabComponent;
