import axios from "axios";
import { BASE_URL } from "../../variables";
import { getUniqueListBy } from "../../Components/Common/Function";

export const getWssCheckInList = async (data, divisionFlag) => {
    let TTYCodes = [];
    if (data?.length > 0) {
        const TTYCodeData = getUniqueListBy(data, "SH2_Code__c");
        TTYCodeData?.map((item) => {
            TTYCodes.push(item?.SH2_Code__c);
        });
        const options = {
            url: `${BASE_URL}/services/apexrest/WSSDetailsList/`,
            method: "post",
            data: {
                territoryCodes: TTYCodes,
                RborstiUser: `${divisionFlag}`
            },
        };
        try {
            const response = await axios(options);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response?.data,
                };
            } else {
                throw Error("Something Went Wrong..!!");
            }
        } catch (err) {
            console.error(err);
            return {
                success: false,
                msg: "Somthing Went Wrong..!!",
            };
        }
    } else {
        return {
            success: true,
            data: { records: [] },
        };
    }
};