import _ from "lodash";
import React, { useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import ModalPopup from "../../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import styles from "./MyDealerSummary.module.css";
import { getUniqueListBy } from "../../Common/Function";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Message from "../../Common/Message";
import { getDealerPhysicalVerificationDataApi } from "../../../services/Teams/getDealerPhysicalVerificationDataApi.js";
import { getDealerPhysicalVerificationDetailsApi } from "../../../services/Teams/getDealerPhysicalVerificationDetailsApi.js";

const territoryAutoCompletePlaceHolder = {
    attributes: null,
    Id: null,
    SH2_Name__c: null,
    SH2_Code__c: null,
    Name: null,
    SH3_Code__c: null,
};

const dealerPlaceHolder = {
    attributes: null,
    Id: null,
    Dealer_Name__c: null,
    DealerClass__c: null,
    DealerType1__c: null,
    DealerLatitude__c: null,
    DealerLongitude__c: null,
    PrimaryTSICode__c: null,
    DealerCode__c: null,
    RecordTypeId: null,
};

const MyDealerSummary = ({ CheckIndata, getCheckInStatus, reloadList , isSubmittedFlag}) => {

    const [ind, setInd] = useState();
    const [error, setError] = useState(""); 
    const [remarks, setRemarks] = useState('');
    const [PhysicalVerificationStatus, setPhysicalVerificationStatus] = useState('');
    const [isDealerData, setIsDealerData] = useState("Loading");
    const [dealerOption, setDealerOption] = useState([]);
    const [selectedModelItem, setSelectedModelItem] = useState({});
    const [selectLatLong, setSelectLatLong] = useState(dealerPlaceHolder);
    const [selectedTerritory, setSelectedTerritory] = useState(territoryAutoCompletePlaceHolder);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [remarksError, setRemarksError] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [PhysicalVerificationFlag, setPhysicalVerificationFlag] = useState(false);
    const divisionCode = useSelector((state) => state?.userDetails?.details?.Division);
    const [divisionFlag, setDivisionFlag] = useState(divisionCode === 70 ? true : false); 
    const userType = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);
    const territoryOption = useSelector((state) => state?.territory?.territoryDetails);
    const PhysicalVerifiedBy = useSelector((state) => state?.userDetails?.details?.Email);

    // ----------------------------------------------------- Territory ----------------------------------------------------------------
    // API call to get Wss list against Territory
    const getList = async (TerritoryList) => {
        setLoading(true);
        const response = await getDealerPhysicalVerificationDataApi( TerritoryList );
        if (response?.status === 200) {
            if (response?.data?.length === 0) {
                setDealerOption([]);
                setIsDealerData("false")
                setLoading(false)
            } else {
                setDealerOption(response?.data);
                // setSelectedTerritory("");
                setIsDealerData("true")
                setLoading(false)
            }
        } else {
            setDealerOption([]);
            setError(response?.msg);
            setLoading(false)
        }
    }

    // To set territory 
    useEffect(() => {
        setIsDealerData("Loading")
        setLoading(true)
        if (selectedTerritory?.SH2_Code__c !== null && !_.isEmpty(selectedTerritory)) {
            var list = [selectedTerritory];
            getList(list);
            setLoading(false);
        } else {
            setLoading(false)
        }
    }, [selectedTerritory]);

    // To get All territory wss list when we open the page / component
    useEffect(() => {
        if (divisionCode === 70 ){
            setDivisionFlag(true);
        }
        if (!_.isEmpty(territoryOption)){
            getList(territoryOption);
        }
    },[])

    // To get territory wss list after successfull check-in Call
    useEffect(() => {
        if (!_.isEmpty(reloadList)) {
            setSelectedTerritory(reloadList)
        }else if(!_.isEmpty(selectedTerritory)){
            setSelectedTerritory(selectedTerritory);
        }else if(!_.isEmpty(territoryOption)){
            getList(territoryOption);
        }
    }, [reloadList])

    // -------------------------------------------------- Auto Populate Check-In Data -----------------------------------------------------

    // To achieve direct check-in functionality 
    const handleAutoPopulate = (e ,item) => {
        e.preventDefault();
        setSelectedModelItem(item);
        setIsApproveModalOpen(true);
    };

    // -------------------------------------------------- Modal Popul Functions -----------------------------------------------------

    // Function to calculate due date
    const calculateDueDate = (createdDate) => {
        const created = new Date(createdDate);
        const currentDate = new Date();
        const differenceInTime = currentDate.getTime() - created.getTime();
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }

    const Approve = () => {
        //setLoading(true)
        CheckIndata(selectedModelItem);
        setPhysicalVerificationStatus("Yes");
        setIsRejectModalOpen(false);
        setIsApproveModalOpen(false);
    }

    const reject = () => {
        setIsApproveModalOpen(false);
        setPhysicalVerificationStatus("No");
        setIsRejectModalOpen(true);
    }

    const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
        setRemarksError(false);
    };

    const handleSubmitRemark = () => {
        if (remarks.trim() === '') {
            setRemarksError(true);
            return;
        }else{
            // After Remark submission logic
            setLoading(true);
            setLoading(false);
            setIsRejectModalOpen(false);
            updateDealerPhysicalVerificationStatus()
            setRemarks("");
        }
    };

    // -------------------------------------------------- Dealer Physical verification API call -----------------------------------------------------

    // API call to Update entry in Dealer Physical Verification
    const updateDealerPhysicalVerificationStatus = () =>{
        var currentDate = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
        (async () => {
            if (!_.isEmpty(PhysicalVerificationStatus)) {
                const response = await getDealerPhysicalVerificationDetailsApi(currentDate, PhysicalVerificationStatus, 
                    PhysicalVerifiedBy, remarks, selectedModelItem.tsicode, selectedModelItem.dealercode);
                if (response?.status === 200) {
                    setPhysicalVerificationFlag(true);
                    setLoading(false);
                } else {
                    setError(response?.msg);
                }
            }
        })();
    } 

    // Trigger Point to Physical Verification Api After successfull Check-In
    useEffect(() => {
        updateDealerPhysicalVerificationStatus()
    }, [getCheckInStatus])

    // Reload the list after Physical Verification API is get called successfully
    useEffect(() => {
        if (PhysicalVerificationFlag === true){
            isSubmittedFlag(true);
            if (selectedTerritory?.SH2_Code__c !== null && !_.isEmpty(selectedTerritory)) {
                var list = [selectedTerritory];
                getList(list);
            } else {
                getList(territoryOption);
            }
            setPhysicalVerificationFlag(false);
            setIsSubmitModalOpen(true);
        }
    }, [PhysicalVerificationFlag])

    // --------------------------------------------------------- End ----------------------------------------------------------------------
    
  return (
    <>
          <Loader loading={loading} />
          <div>
              {isSubmit && (
                  <Loader loading={loading} />
              )}

              <div className={styles.myDealerSummary}>
                  <div className={styles.myDealerSummary__heading}>Dealer Validation Summary</div>
                    <div className={styles.myDealerSummary__Outermainbox}>
                        <div className={styles.myDealerSummary__dropdownSelection}>
                          {userType === "SH7" &&
                              <Autocomplete
                                  id="grouped-demo"
                                  className="myDealer__categorySelect"
                                  options={
                                      territoryOption?.length === 0
                                          ? [
                                              {
                                                  SH2_Name__c: "Loading...",
                                                  SH2_Code__c: "",
                                              },
                                          ]
                                          : getUniqueListBy(territoryOption, "SH2_Code__c")
                                  }
                                  getOptionLabel={(option) => {
                                      if (option?.SH2_Name__c) {
                                          return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
                                      } else {
                                          return "";
                                      }
                                  }}
                                  value={selectedTerritory}
                                  name="dealerId"
                                  onChange={(event, newValue) => {
                                      setSelectedTerritory(newValue);
                                      setSelectLatLong(dealerPlaceHolder);
                                      setDealerOption([]);
                                  }}
                                  renderInput={(params) => (
                                      <TextField {...params} placeholder="Select Territory" />
                                  )}
                              />
                          }

                          {userType !== "SH7" && (
                              <Autocomplete
                                  id="grouped-demo"
                                  className="myDealer__categorySelect"
                                  options={
                                      !_.isEmpty(territoryOption) ? getUniqueListBy(territoryOption, "SH2_Code__c") : []
                                  }
                                  getOptionLabel={(option) => {
                                      if (option?.SH2_Name__c) {
                                          return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
                                      } else {
                                          return "";
                                      }
                                  }}
                                  value={selectedTerritory}
                                  name="dealerId"
                                  onChange={(event, newValue) => {
                                      setSelectedTerritory(newValue);
                                      setSelectLatLong(dealerPlaceHolder);
                                      setDealerOption([]);
                                  }}
                                  renderInput={(params) => (
                                      <TextField {...params} placeholder="Select Territory" />
                                  )}
                              />
                          )}
                        </div>
                        <div className="newFeedback__tabInnerBox">
                            <div className={styles.myDealerSummary__outerInputBox}>
                            { dealerOption?.length !== 0 ?
                                <>
                                    {dealerOption.length > 0 ?
                                          dealerOption?.map((item, index) => (
                                        <div
                                            className={`${styles.myDealerSummary__mainBox} ${active &&
                                                ind === index &&
                                                styles.myDealerSummary__mainBoxActive
                                                }`}
                                            id={index}
                                        >
                                            <div className={styles.myDealerSummary__locationBox}>
                                                <div
                                                    className={styles.myDealerSummary__tag}
                                                    style={{ backgroundColor:"#A2D7AF" }}
                                                ></div>
                                                <div className={styles.myDealerSummary__location}>
                                                    <HiOutlineLocationMarker
                                                        className={styles.myDealerSummary__locationIcon}
                                                    />
                                                    <p>
                                                        {item?.dealername} : {item?.dealercode}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={styles.myDealerSummary__dateBox}>
                                                <div className={styles.myDealerSummary__tourDate}>
                                                    <p className={styles.myDealerSummary__tourDay}>
                                                        Due Days: {calculateDueDate(item.createddate)}
                                                    </p>
                                                </div>
                                                <span className={styles.myDealerSummary__divideLine}>
                                                    |
                                                </span>
                                                <div className={styles.myDealerSummary__edit}>
                                                        <button className={styles.myDealerSummary__downloadBtn} 
                                                        onClick={(e) => handleAutoPopulate(e, item)}
                                                          >Validate</button>
                                                </div>
                                            </div>
                                        </div>
                                        )):
                                        <Message
                                            message={"No data found"}
                                            className={"tourPlan__messageConatiner"}
                                        />
                                    }
                                </> :
                                <Message
                                    message={"No data found"}
                                    className={"tourPlan__messageConatiner"}
                                />
                            }
                            </div>
                        </div>
                    </div>
                    
                  {isSubmitModalOpen && (
                      <ModalPopup
                          className="ModalPopup_Submitted"
                          isModalOpen={isSubmitModalOpen}
                          setIsModalOpen={setIsSubmitModalOpen}
                          title="Submitted Successfully"
                          data="Dealer Physical verification has been submitted successfully."
                          backdrop={true}
                          fade={false}
                          icon={
                              <>
                                  <div className="submittedIcons draftIcons">
                                      <FaThumbsUp className="submittedSmall" />
                                      <FaThumbsUp className="submittedLarge" />
                                      <FaThumbsUp className="submittedSmall" />
                                  </div>
                              </>
                          }
                      />
                  )}

                  {isApproveModalOpen && (
                      <ModalPopup
                          className="ModalPopup_Submitted"
                          isModalOpen={isApproveModalOpen}
                          ModalMainClass="MyDealerSummaryModal"
                          setIsModalOpen={setIsApproveModalOpen}
                          data={
                            <>
                            <div className={`${styles.myDealerSummary__title}`}>
                                Dealer Details
                            </div>
                            <div className="col-12 d-flex p-1">
                                <label className={styles.myDealerSummary_label} style={{ whiteSpace: "nowrap" }}>Dealer Code : -</label>
                                <p className={styles.myDealerSummary_value}>{selectedModelItem.dealercode}</p>
                            </div>
                            <div className="col-12 d-flex p-1">
                                <label className={styles.myDealerSummary_label} style={{ whiteSpace: "nowrap" }}>Dealer Name : -</label>
                                <p className={styles.myDealerSummary_value}>{selectedModelItem.dealername}</p>
                            </div>
                            {/* <div className="col-12 d-flex p-1">
                                <label className={styles.myDealerSummary_label} style={{ whiteSpace: "nowrap" }}> RTR ID : - </label>
                                <p className={styles.myDealerSummary_value}>{selectedModelItem.dealercode}</p>
                            </div>
                            <div className="col-12 d-flex p-1">
                                <label className={styles.myDealerSummary_label} style={{ whiteSpace: "nowrap" }}> RTR Code : -</label>
                                <p className={styles.myDealerSummary_value}>{selectedModelItem.dealercode}</p>
                            </div>
                            <div className="col-12 d-flex p-1">
                                <label className={styles.myDealerSummary_label} style={{ whiteSpace: "nowrap" }}> RTR Name : -</label>
                                <p className={styles.myDealerSummary_value}>{selectedModelItem.dealercode}</p>
                            </div> */}
                            <div className="col-12 d-flex p-1">
                                <label className={styles.myDealerSummary_label} style={{ whiteSpace: "nowrap" }}> Address : -</label>
                                <p className={styles.myDealerSummary_value}>{selectedModelItem.address}</p>
                            </div>
                            <div className="col-12 d-flex p-1">
                                <label className={styles.myDealerSummary_label} style={{ whiteSpace: "nowrap" }}> Town : -</label>
                                <p className={styles.myDealerSummary_value}>{selectedModelItem.townname} : {selectedModelItem.towncode} </p>
                            </div>
                            </>
                          }
                          backdrop={true}
                          fade={false}
                          showButton={
                              <>
                                  <Loader loading={loading} />
                                  <div className={styles.myWss__saveBox}>
                                      <button className={styles.myWss__saveButtonNo} onClick={() => { reject() }}> Reject </button>
                                      <button className={styles.myWss__saveButtonYes} onClick={() => { Approve() }}> Approve </button>
                                  </div>
                              </>
                          }
                      />
                  )}

                  {isRejectModalOpen && (
                      <ModalPopup
                          className="ModalPopup_Submitted"
                          isModalOpen={isRejectModalOpen}
                          ModalMainClass="MyDealerSummaryModal"
                          setIsModalOpen={setIsRejectModalOpen}
                          data={
                              <>
                                  <div className={`${styles.myDealerSummary__rejectTitle}`}>
                                      Do you want to reject ?
                                  </div>
                                  <div className="col-12 d-grid">
                                    <label className={styles.myDealerSummary__rejectLabel} 
                                        style={{ whiteSpace: "nowrap" }}> Remarks </label>
                                    <textarea className={styles.myDealerSummary__remarkBox}
                                        value={remarks} onChange={handleRemarksChange}></textarea>
                                    {remarksError && (
                                        <div className={styles.myDealerSummary__remarkError}>
                                            Remarks cannot be empty.
                                        </div>
                                    )}
                                  </div>
                              </>
                          }
                          backdrop={true}
                          fade={false}
                          showButton={
                              <>
                                  <Loader loading={loading} />
                                  <div className={styles.myWss__saveBox}>
                                      <button className={styles.myDealerSummary__cancel} onClick={() => { setIsRejectModalOpen(false) }}> cancel </button>
                                      <button className={styles.myDealerSummary__submit} onClick={() => { handleSubmitRemark() }}> Submit </button>
                                  </div>
                              </>
                          }
                      />
                  )}
              </div>
          </div>
    </>
  )
}

export default MyDealerSummary