import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getDivisionGroupApi = async () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/hoUser/get-division-group`,
    method: "get",
    headers: headers,
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
