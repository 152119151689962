import axios from "axios";
import { BASE_URL } from "../../variables";

export const getTerritoryForHOUserApi = async ({
  divisionGroup,
  salesGroup,
}) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,SH2_Name__c, SH2_Code__c,Division_Code__c , Name,SH3_Code__c,Sales_Group__c from Pidilite_Hierarchy__c where Division_Code__c  ='${divisionGroup}' AND Sales_Group__c='${salesGroup}' AND SH2_Name__c !=null`,
    method: "get",
  };
  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
