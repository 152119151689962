import { getAverageTsiScoreApi } from "../../services/Teams/getAverageTsiScore";
import { getAverageTsiScoreTrendApi } from "../../services/Teams/getAverageTsiScoreTrend";
import { getAverageTsrScoreApi } from "../../services/Teams/getAverageTsrScore";
import { getAverageTsrScoreTrendApi } from "../../services/Teams/getAverageTsrScoreTrend";
import { getTeamsIncentiveApi } from "../../services/Teams/getTeamsIncentiveApi";
import { getTeamsIncentiveTrendApi } from "../../services/Teams/getTeamsIncentiveTrendApi";
import { getTeamsSopApi } from "../../services/Teams/getTeamsSopApi";
import { getTeamsSopTrendApi } from "../../services/Teams/getTeamsSopTrendApi";
import {
  GET_TEAMS_AVERAGE_TSI_SCORE_FAIL,
  GET_TEAMS_AVERAGE_TSI_SCORE_SUCCESS,
  GET_TEAMS_AVERAGE_TSI_SCORE_TREND_FAIL,
  GET_TEAMS_AVERAGE_TSI_SCORE_TREND_SUCCESS,
  GET_TEAMS_AVERAGE_TSR_SCORE_FAIL,
  GET_TEAMS_AVERAGE_TSR_SCORE_SUCCESS,
  GET_TEAMS_AVERAGE_TSR_SCORE_TREND_FAIL,
  GET_TEAMS_AVERAGE_TSR_SCORE_TREND_SUCCESS,
  GET_TEAMS_INCENTIVE_FAIL,
  GET_TEAMS_INCENTIVE_SUCCESS,
  GET_TEAMS_INCENTIVE_TREND_FAIL,
  GET_TEAMS_INCENTIVE_TREND_SUCCESS,
  GET_TEAMS_SOP_FAIL,
  GET_TEAMS_SOP_SUCCESS,
  GET_TEAMS_SOP_TREND_FAIL,
  GET_TEAMS_SOP_TREND_SUCCESS,
} from "../constants/action-types";

export const getTeamsAverageTsiScore =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getAverageTsiScoreApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_TEAMS_AVERAGE_TSI_SCORE_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_TEAMS_AVERAGE_TSI_SCORE_FAIL });
    }
  };

export const getTeamsAverageTsiScoreTrend =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getAverageTsiScoreTrendApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_TEAMS_AVERAGE_TSI_SCORE_TREND_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_TEAMS_AVERAGE_TSI_SCORE_TREND_FAIL });
    }
  };

export const getTeamsAverageTsrScore =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getAverageTsrScoreApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_TEAMS_AVERAGE_TSR_SCORE_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_TEAMS_AVERAGE_TSR_SCORE_FAIL });
    }
  };

export const getTeamsAverageTsrScoreTrend =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getAverageTsrScoreTrendApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_TEAMS_AVERAGE_TSR_SCORE_TREND_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_TEAMS_AVERAGE_TSR_SCORE_TREND_FAIL });
    }
  };

export const getTeamsIncentive = (SH_Code__c, Title) => async (dispatch) => {
  const response = await getTeamsIncentiveApi(SH_Code__c, Title);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_TEAMS_INCENTIVE_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_TEAMS_INCENTIVE_FAIL });
  }
};

export const getTeamsIncentiveTrend =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getTeamsIncentiveTrendApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_TEAMS_INCENTIVE_TREND_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_TEAMS_INCENTIVE_TREND_FAIL });
    }
  };

export const getTeamsSop = (SH_Code__c, Title) => async (dispatch) => {
  const response = await getTeamsSopApi(SH_Code__c, Title);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_TEAMS_SOP_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_TEAMS_SOP_FAIL });
  }
};

export const getTeamsSopTrend = (SH_Code__c, Title) => async (dispatch) => {
  const response = await getTeamsSopTrendApi(SH_Code__c, Title);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_TEAMS_SOP_TREND_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_TEAMS_SOP_TREND_FAIL });
  }
};
