import { getMarketWorkingApi } from "../../services/HomePage/getMarketWorkingApi";
import { getMonthlyTourPlanningApi } from "../../services/HomePage/getMonthlyTourPlanningApi";
import {
  GET_MARKET_WORKING_SUCCESS,
  GET_MARKET_WORKING_FAIL,
  GET_BAR_GRAPH_FAIL,
  GET_BAR_GRAPH_SUCCESS,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

//Api call for barGraph
export const getBarGraph = () => async (dispatch) => {
  const response = await getMonthlyTourPlanningApi();
  if (response?.status === 200) {
    dispatch({
      type: GET_BAR_GRAPH_SUCCESS,
      payload: response?.data,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_BAR_GRAPH_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};

export const getMarketWorking = () => async (dispatch) => {
  const response = await getMarketWorkingApi();
  if (response?.status === 200) {
    dispatch({
      type: GET_MARKET_WORKING_SUCCESS,
      payload: response?.data,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_MARKET_WORKING_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};
