import React, { useState, useEffect, useCallback, useMemo } from "react";
import ModalPopup from "../Common/ModalPopup";
import styles from "./MarketVisitReport.module.css";
import { RiDraftFill } from "react-icons/ri";
import { connect, useSelector } from "react-redux";
import { addNewTourWssApi } from "../../services/MonthlyTourPlans/addNewTourWssApi";
import { addMarketVisitReportApi } from "../../services/MarketVisitReport/addMarketVisitReportApi";
import { FiArrowRight } from "react-icons/fi";
import { FaThumbsUp } from "react-icons/fa";
import MarketVisitTabs from "./MarketVisitTabs";
import { getZSMTerritoryApi } from "../../services/zsm/getZSMTerritoryApi";
import _ from "lodash";
import Loader from "../Common/Loader";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MentionsInput, Mention } from "react-mentions";
import { clusterCodePlaceHolder, currentDate } from "../Common/Constant";
import { addDealerNameApi } from "../../services/MarketWorking/MyTeam/addDealerNameApi";
import { getUniqueListBy, isValidString } from "../Common/Function";
import { getZSMWssNameApi } from "../../services/zsm/getZSMWssNameApi";
import { addWSSNameApiForHOUser } from "../../services/hoUser/addWSSNameApiForHOUser";
import defaultStyle from "./defaultStyle";
import defaultMentionStyle from "./defaultMentionStyle";
import { getMVRDealerNameApi } from "../../services/MarketVisitReport/getDealerNameApi";
import { getMVRDealerNameForHOApi } from "../../services/MarketVisitReport/getMVRDealerNameForHOApi";
import {
  getMVRDraftDetails,
  removeMVRDraftDetails,
} from "../../Redux/actions/mvr-action";
import { getMarketVisitReportApi } from "../../services/MarketVisitReport/getMarketVisitReportApi";
import { addMySrApi } from "../../services/MarketWorking/MyTeam/addMySrApi";
import { getSalesGroupUserApi } from "../../services/MarketVisitReport/getRMDMSalesGroupUser";
import { getVlcUsersApi } from "../../services/MarketVisitReport/getVLCsUserApi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { addMarketVisitReportFileApi } from "../../services/MarketVisitReport/addMarketVisitReportFileApi";
import { updateMarketVisitReportApi } from "../../services/MarketVisitReport/updateMarketVisitReportApi";
import { getMvrAttachedFile } from "../../services/MarketVisitReport/getMvrAttachedFile";
import { getAttachedFileUrl } from "../../services/MarketVisitReport/getAttachedFileUrl";
import { MdFileDownload } from "react-icons/md";
import { MdOutlineAttachment } from "react-icons/md";
import { MdOutlineSmsFailed } from "react-icons/md";
import { Buffer } from "buffer";
import MyDatePicker from "../Common/DatePicker";
import { getMvrFormDateApi } from "../../services/MarketVisitReport/getMvrFormDate";
import { deleteSaveAsDraftForMVRApi } from "../../services/MarketVisitReport/deleteSaveAsDraftForMVRAPI";
import { addEscalationServiceRequestApi } from "../../services/MarketVisitReport/addEscalationServiceRequestApi";
import { getMvrSrAttachedFiles } from "../../services/MarketVisitReport/getSrAttachedFiles";
import { HiOutlineX } from "react-icons/hi";


// const userTypePlaceholder = {
//   user_name: "",
// };
const vlcUserPlaceholder = {
  name: "",
  bdecode__c: "",
};

const MarketVisitReportTemp = ({
  getZSMWssNameApi,
  getMVRDealerNameApi,
  getMVRDealerNameForHOApi,
  addMarketVisitReportApi,
  getMVRDraftDetails,
  getMarketVisitReportApi,
  addMySrApi,
  addMarketVisitReportFileApi,
  getMvrAttachedFile,
  getAttachedFileUrl,
  updateMarketVisitReportApi,
  deleteSaveAsDraftForMVRApi,
  addEscalationServiceRequestApi
}) => {
  const [cluster, setCluster] = useState(clusterCodePlaceHolder);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [productList, setProductList] = useState([]);
  const [wssList, setWssList] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [wssName, setWssName] = useState([]);
  const [dealerName, setDealerName] = useState([]);
  const [userName, setUserName] = useState([]);
  const [disable, setDisable] = useState(false);
  const [characterLimitValidation, setCharacterLimitValidation] = useState({});
  const [isSubmitedSuccessfully, setIsSubmitedSuccessfully] = useState(false);
  const [selectUserType, setSelectUserType] = useState([]);
  const [vlcListType, setVlcListType] = useState([]);
  const [vlcList, setVlcList] = useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [fileValidation, setFileValidation] = useState("");
  const [file, setFile] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [versionData, setVersionData] = useState([]);
  const [imageBaseData, setImageBaseData] = useState([]);
  const [imageBase64, setImageBase64] = useState([]);
  const [submittedHighlightedDates, setSubmittedHighlightedDates] = useState([]);
  const [draftHighlightedDates, setDraftHighlightedDates] = useState([]);
  const [userType, setUserType] = useState([]);
  const [downloadPDFDate, setDownloadPDFDate] = useState([]);
  const [isDraftSave, setIsDraftSave] = useState(false);
  const [formDraftId, setFormDraftId] = useState(null);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [isEditDisable, setIsEditDisable] = useState(false);
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [draftedForm, setDraftedForm] = useState([]);
  const [currentSelectedDate, setCurrentSelectedDate] = useState("");
  const salesGroupCode = useSelector((state) => state?.userDetails?.details?.Sales_Group_Code__c !== null ?
    state?.userDetails?.details?.Sales_Group_Code__c : state?.SelectedSalesGroupCode?.SalesGroupCode);
  const selectedInsights = useSelector(
    (state) => state?.insights?.selectedInsights
  );

  //to get territory list from redux
  const territoryOptions = useSelector(
    (state) => state?.territory?.territoryDetails
  );

  // to get user Type
  const HoUserType = useSelector((state) =>
    state?.userType?.type != "Other"
      ? state?.userType?.type
      : state?.userDetails?.details?.Title
  );

  const tourPlanDefaultData = useSelector(
    (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records
  );

  // to get User Id
  const userId = useSelector((state) => state?.userDetails?.details?.Id);

  // to get cluster Code when user is ZSM,NSM ,HO
  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );

  // to get Sh3Code of BM User
  const shCode = useSelector(
    (state) => state?.userDetails?.details?.SH_Code__c
  );

  // to get list of product
  const products = useSelector((state) => state?.product?.productList);

  // to get Wss Name List
  const wssNameList = useSelector(
    (state) => state?.visitDetails?.checkInWSSDetails
  );

  // to get Dealer Name List
  const dealerNameList = useSelector(
    (state) => state?.visitDetails?.checkInDetails
  );

  // to get User Name List
  const userNameList = useSelector((state) => state?.myUserFeedback?.userList);

  // to get Drafted MVR form
  let drafts = useSelector((state) => state?.mvrDraft?.draft);

  // to get user name and email id
  const userEmail = useSelector((state) => state?.userDetails?.details?.Email);
  const userDetailName = useSelector((state) => state?.userDetails?.details?.Name);

  // to get Sales group wise User type for RMDM
  const salesGroupUser = useSelector(
    (state) => state?.salesgroupUser?.salesgroupUserList
  );

  const rbdmCode = useSelector(
    (state) => state?.RBDMCode?.RBDMCode[0]?.SH3_Code__c
  );

  //to get Division and sales group for Ho user
  const hoDivisionGroup = territoryOptions?.[0]?.Division_Code__c;
  const hoSalesGroup = territoryOptions?.[0]?.Sales_Group__c;

  const [rows, setRows] = useState([{ srId: "101", assigned_to: "", issue: "", files: [], validationError: { file: '', issue: '' } }]);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  //--------------------------------------------- Auto Populate cluster data -------------------------------------------------//

  const formatDate = (date) => {
    const d = new Date(date);
    const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
    return d.toLocaleDateString('en-US', options);
  };

  const setDefaultValues = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    var clusterFilterData = getUniqueListBy(clusterCodeOption, userType === "MH3" || userType === "RBDM Collaborator" ? "CLUSTER_CODE__c" : "SH3_Name__c");
    setCurrentSelectedDate(formattedDate);
    setFormData({ marketVisitDate: formattedDate })
    if (tourPlanDefaultData.length != 0) {
      const mtpCurrentDateData = tourPlanDefaultData.filter(item => formatDate(item.MTP_Date__c) === formatDate(new Date()));
      if (userType === "SH6" || userType === "SH5" || userType === "SH7" || userType === "MH3" || userType === "RBDM Collaborator" || userType === "HO Collaborator") {
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].Cluster_es__c !== null) {
            const originalString = mtpCurrentDateData[0].Cluster_es__c;
            const parts = originalString.split(':');
            const data = clusterFilterData[0];
            if (data.hasOwnProperty('CLUSTER_CODE__c')){
              var defaultCluster = clusterFilterData.filter(item => item.CLUSTER_CODE__c === parts[1]);
            }else{
              var defaultCluster = clusterFilterData.filter(item => item.SH3_Code__c === parts[1]);
            }
            setCluster(defaultCluster[0]);
            setFormData({
              marketVisitDate: formattedDate,
              clusterCode: defaultCluster[0]?.SH3_Code__c
            })
        }else{
          setFormData({
            marketVisitDate: formattedDate
          })
        }
      }
    }
  }

  useEffect(() => {
    setDefaultValues();
  }, []);

  // to set user Type
  useEffect(() => {
    setUserType(HoUserType);
  }, [HoUserType]);

  useEffect(() => {
    getMvrFormDate();
  }, []);

  useEffect(() => {
    if (currentSelectedDate){
      checkFormData(currentSelectedDate);
    }
  }, [currentSelectedDate]);

  //------------------------------------------------------------------------------------------------------------// 

  //--------------------------------- Functions to fetch Data -------------------------------------------------// 

  // Functions to get Seperate List for Draft & Submitted dates
  function separateDates(records) {
    const draftDates = [];
    const otherDates = [];

    records.forEach(record => {
      if (record.IsSavedDraft__c === "True") {
        draftDates.push(record.SaveFormDate__c);
      } else {
        otherDates.push(record.SaveFormDate__c);
      }
    });

    return {
      draftDates,
      otherDates
    };
  }

  // Function to get the created & drafted MVR date for current Months 
  const getMvrFormDate = async () => {
    setLoading(true);
    try {
      const result = await getMvrFormDateApi(userId);
      const filterResult = separateDates(result);
      setDraftHighlightedDates(filterResult.draftDates);
      setSubmittedHighlightedDates(filterResult.otherDates);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      if (_.isEmpty(drafts) || !drafts.hasOwnProperty(userId)) {
        drafts[userId] = [];
      }
      setDraftedForm(drafts[userId]);
    }
  }, [userId, drafts]);

  // to create a array of wss name and dealer name
  useEffect(() => {
    wssNameList.map((item) => {
      if (
        new Date(
          item?.Check_In_Date_Time__c?.split(",")?.[0]
        ).toLocaleDateString("en-CA", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }) == formData?.marketVisitDate
      ) {
        setWssName((ls) => [...ls, item?.WSS_Name__c]);
      }
    });

    dealerNameList.map((item) => {
      if (
        new Date(
          item?.Check_In_Date_Time__c?.split(",")?.[0]
        ).toLocaleDateString("en-CA", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }) == formData?.marketVisitDate
      ) {
        setDealerName((ls) => [...ls, item?.CheckIn_Dealer__c]);
      }
    });

    userNameList?.map((item) => {
      if (
        new Date(item?.CreatedDate?.split("T")?.[0]).toLocaleDateString(
          "en-CA",
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }
        ) == formData?.marketVisitDate
      ) {
        setUserName((ls) => [...ls, item?.User_Name_es__c]);
      }
    });
  }, [formData?.marketVisitDate]);

  // to create a list of products for intelligent tagging
  useEffect(() => {
    products.map((item) =>
      setProductList((ls) => [
        ...ls,
        {
          id: item?.Id,
          display: item?.Product_Group_3_Description__c,
        },
      ])
    );
  }, [products]);

  // to get the Wss Name List and Dealer Name List when user is BM for intelligent Tagging
  useEffect(() => {
    if (userType === "SH3") {
      (async () => {
        const response = await addDealerNameApi("WSS");
        response?.data?.Name?.map((item) =>
          setWssList((ls) => [
            ...ls,
            {
              id: item?.Id,
              display: `${item?.CustomerName__c}:${item?.Customer_Code__c}`,
            },
          ])
        );
      })();
    }
    if (userType === "SH3" && !_.isEmpty(shCode)) {
      (async () => {
        const res = await getMVRDealerNameApi(shCode);
        res?.data?.records?.map((item) =>
          setDealerList((ls) => [
            ...ls,
            {
              id: item?.Id,
              display: `${item?.Dealer_Name__c}:${item?.DealerCode__c}`,
            },
          ])
        );
      })();
    }
  }, [userType]);

  //to get the WSS Name List when user is ZSM or NSM or HO
  useEffect(() => {
    if (
      !_.isEmpty(formData?.clusterCode) &&
      (userType === "SH6" || userType === "SH5" || userType === "SH7")
    ) {
      (async () => {
        const response = await getZSMWssNameApi(formData?.clusterCode);
        response?.data?.map((item) =>
          setWssList((ls) => [
            ...ls,
            {
              id: item?.Id,
              display: `${item?.CustomerName__c}:${item?.Customer_Code__c}`,
            },
          ])
        );

        const res = await getMVRDealerNameApi(formData?.clusterCode);
        res?.data?.records?.map((item) =>
          setDealerList((ls) => [
            ...ls,
            {
              id: item?.Id,
              display: `${item?.Dealer_Name__c}:${item?.DealerCode__c}`,
            },
          ])
        );
      })();
    }
    if (!_.isEmpty(formData?.clusterCode) && userType === "HO Collaborator") {
      (async () => {
        const response = await addWSSNameApiForHOUser(
          hoDivisionGroup,
          hoSalesGroup,
          formData?.clusterCode
        );
        response?.data?.map((item) =>
          setWssList((ls) => [
            ...ls,
            {
              id: item?.Id,
              display: `${item?.CustomerName__c}:${item?.Customer_Code__c}`,
            },
          ])
        );

        const res = await getMVRDealerNameForHOApi(
          hoDivisionGroup,
          hoSalesGroup,
          formData?.clusterCode
        );
        res?.data?.records?.map((item) =>
          setDealerList((ls) => [
            ...ls,
            {
              id: item?.Id,
              display: `${item?.Dealer_Name__c}:${item?.DealerCode__c}`,
            },
          ])
        );
      })();
    }
  }, [userType, formData?.clusterCode, cluster]);

  const fetchDataForVLC = async (rbdmCode) => {
    try {
      setLoading(true);
      const response = await getVlcUsersApi(rbdmCode);
      setLoading(false);
      setVlcList(response?.data);
    } catch (error) {
      console.error("Error fetching VLC users:", error);
    }
  };

  //------------------------------------------------------------------------------------------------------------//

  //--------------------------------------------- On change ------------------------------------------------------// 

  const onChange = async (e) => {
    const { value, name } = e.target;
    if (name === "marketVisitDate") {
      setDealerName([]);
      setWssName([]);
      setUserName([]);
    }
    if (name === "clusterCode") {
      setWssList([]);
      setDealerList([]);
    }

    setFormData({ ...formData, [name]: value });
    //console.log("data", [name], value, e.target);
    if (name !== "marketVisitDate") {
      debouncedApiCall({ ...formData, rows, [name]: value });
    }

    const charLimit = {
      wssVisited: 255,
      keyObservationWss: 2000,
      darpanDealer: 500,
      dealerVisited: 255,
      issueFacedDealer: 2000,
      deliveryFeedback: 2000,
      schemeComments: 500,
      userVisited: 255,
      keyObsUser: 2000,
      issuesFacedByBank: 2000,
      productQuality: 2000,
      newProductOpportunity: 500,
      productOtherFeedback: 500,
      competitionActivity: 2000,
      competitionProducts: 500,
      urgentEscalation: 2000,
      feedbackOnUserLoyalty: 500,
      feedbackOnUserApp: 500,
      feedbackAtUserMeeting: 500,
      obsFromRedemptionCenter: 500,
    };

    if (
      value?.length > charLimit[name] &&
      name !== "marketVisitDate" &&
      name !== "visitDealerRadio" &&
      name !== "productWssRadio" &&
      name !== "darpanBillRadio"
    ) {
      setCharacterLimitValidation({
        ...characterLimitValidation,
        [name]: `Character limit exceeded (${charLimit[name]} characters max)`,
      });
    } else {
      setCharacterLimitValidation({
        ...characterLimitValidation,
        [name]: "",
      });
    }

    if (name === "marketVisitDate") {
      setDisable(false);
      checkFormData(value);
    }
    if (value === "VLC" && name === "salesGroupUserType") {
      fetchDataForVLC(rbdmCode);
    } else if (value !== "VLC" && name === "salesGroupUserType") {
      setVlcList([]);
    }
  };

  //------------------------------------------------------------------------------------------------------------// 

  //------------------------------------- Add new escalation Logic --------------------------------------------// 

  const maxLimit = 5;
  const staticOptions = [ "Category Head", "Trade Marketing Manager", "Product Manager", "Field Marketing Manager", "Dealer helpline", "WSS helpline", "My Pidilite"
    , "ZSM", "DSOM/SDM", "NSOM", "NSM", "DDM",];

  // Auto save Rows Data
  const autoSaveEscalationData = (updatedRows) => {                         
    const newPayload = updatedRows.map(({ srId, assigned_to, issue, files }) => ({
      srId,
      assigned_to,
      issue,
      files
    }));
    console.log("pay", newPayload)
    debouncedApiCall({ ...formData, rows: newPayload });
  }

  // Handle change in dropdown or text input
  const handleInputChange = (index, field, value) => {
    //console.log("test",index,field,value);
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
    autoSaveEscalationData(updatedRows);

    if (field === "assigned_to" && formValidation[`rows.${index}.assigned_to`]) {
      const updatedErrors = { ...formValidation };
      delete updatedErrors[`rows.${index}.assigned_to`];
      setFormValidation(updatedErrors);
    }
  };

  // Function call to add new escalation
  const addRow = (e) => {
    e.preventDefault();
    if (rows.length < maxLimit) {

      const srId = `${rows.length + 101}`;
      setRows([...rows, { srId, assigned_to: "", issue: "", files: [], validationError: { file: '', issue: '' } }]);
    }
  };

  // Function call to remove the escalation
  const removeRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows); 
    // console.log("pay", updatedRows)
    autoSaveEscalationData(updatedRows)
  };
  //------------------------------------------------------------------------------------------------------------// 

  //------------------------------------------ MVR Submit API call --------------------------------------------// 

  //validation check
  const checkValidation = (data) => {
    const errors = {};
    const todayDate = new Date();

    // if (userType !== "SH3" && userType !== "MH3" && !data?.clusterCode) {
    //   errors.clusterCode = "Please select the cluster code";
    // }
    // if (!data?.marketVisitDate) {
    //   errors.marketVisitDate = "Please Select the date";
    // }

    if (!data?.marketVisitDate) {
      errors.marketVisitDate = "Please Select the date";
    } else if (
      data?.marketVisitDate > todayDate?.toISOString()?.split("T")?.[0]
    ) {
      errors.marketVisitDate = "Please don't select the future date";
    }
    // if (!data?.visitedWssRadio) {
    //   errors.visitedWssRadio = "Please Select the option";
    // }
    // if (wssEnable) {
    //   if (!data?.wssVisited) {
    //     errors.wssVisited = "Please Enter the name of Wss visited";
    //   }
    //   if (!data?.keyObservationWss) {
    //     errors.keyObservationWss = "Please Enter the observation";
    //   }
    //   if (!data?.productWssRadio) {
    //     errors.productWssRadio = "Please Select the option";
    //   }
    //   if (!data?.darpanBillRadio) {
    //     errors.darpanBillRadio = "Please Select the option";
    //   }
    //   if (!data?.darpanDealer) {
    //     errors.darpanDealer = "Please Enter the Dealers name";
    //   }
    // }

    // User Meeting Section for RMDM
    if (userType === "RBDM Collaborator" || userType === "MH3") {
      //   if (!data?.salesGroupUserType) {
      //     errors.salesGroupUserType = "Please select the user type";
      //   }
      if (!data?.feedbackOnUserLoyalty) {
        errors.feedbackOnUserLoyalty = "Please enter the field";
      }
      //   if (!data?.feedbackOnUserApp) {
      //     errors.feedbackOnUserApp = "Please enter the field";
      //   }
      if (!data?.feedbackAtUserMeeting) {
        errors.feedbackAtUserMeeting = "Please enter the field";
      }
      //   if (!data?.obsFromRedemptionCenter) {
      //     errors.obsFromRedemptionCenter = "Please enter the field";
      //   }
    }
    // SECTION B
    if (userType != "MH3" && userType != "RBDM Collaborator") {
      if (!data?.dealerVisited && !dealerName?.length) {
        errors.dealerVisited = "Please Enter the Key Dealer";
      }
      if (!data?.issueFacedDealer) {
        errors.issueFacedDealer = "Please Enter the observation";
      }
      if (!data?.deliveryFeedback) {
        errors.deliveryFeedback = "Please Enter the feedback";
      }
      // if (!data?.visitDealerRadio) {
      //   errors.visitDealerRadio = "Please Select the option";
      // }
      if (!data?.schemeComments) {
        errors.schemeComments = "Please enter the feedback";
      }
    }
    //SECTION D
    // if (userType != "MH3" && userType != "RBDM Collaborator") {
    //   if (!data?.productQuality) {
    //     errors.productQuality = "Please enter the field";
    //   }
    // }
    // if (!data?.newProductOpportunity) {
    //   errors.newProductOpportunity = "Please enter the field";
    // }
    // if (!data?.productOtherFeedback) {
    //   errors.productOtherFeedback = "Please enter the field";
    // }
    //SECTION E
    if (userType != "MH3" && userType != "RBDM Collaborator") {
      if (!data?.competitionActivity) {
        errors.competitionActivity = "Please enter the field";
      }
      if (!data?.competitionProducts) {
        errors.competitionProducts = "Please enter the field";
      }
    }

    // SECTION F
    // if (userType === "RBDM Collaborator" || userType === "MH3") {
    //   if (!data?.urgentEscalation) {
    //     errors.urgentEscalation = "Please enter the field";
    //   }
    // }

    // Validate rows for certain user types
    if (
      ["SH6", "SH5", "SH7", "HO Collaborator", "RBDM Collaborator", "MH3"].includes(userType)
    ) {
      const isAssignedToValid = rows.some(
        (row) => row.assigned_to && row.assigned_to.trim() !== ""
      );
      console.log("isAssignedToValid", isAssignedToValid);
      if (isAssignedToValid && !data?.clusterCode) {
        errors.clusterCode = "Please select the cluster code";
      }
    }

    rows.forEach((row, index) => {
      if (!row.assigned_to && row.issue?.trim().length > 0) {
        errors[`rows.${index}.assigned_to`] = "Please select a value from the dropdown before entering text";
      }
    });

    return errors;
  };

  // Prepare payload for service request
  const preparePayloadForServiceRequest = (response) => {
    const data = {
      "recordType": "Dealer",
      "subject": formData?.productQuality,
      "description": formData?.productQuality,
      "chapterName": "Dealer Feedback",
      "category": "",
      "subCategory": "",
      "dealerId": "",
      "origin": "Mconnect Local Issues",
      "MVRId": response?.data?.results[0]?.id,
      "BMName": userDetailName,
      "BMEmail": userEmail,
      "salesgroupcode": `${salesGroupCode}`,
      "SR_list": rows,
      "ClusterCode": formData?.clusterCode || shCode,
    };
    return data;
  }

  // Function will get trigger On click of submit button 
  const validateInput = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setFormValidation(checkValidation(formData));
  };

  // Function to submit mvr form
  useEffect(() => {
    const hasErrors = Object.values(characterLimitValidation).some(
      (error) => error !== ""
    );
    setIsSubmitedSuccessfully(false);
    if (
      Object?.keys(formValidation)?.length === 0 &&
      !hasErrors &&
      isSubmit &&
      _.isEmpty(fileValidation)
    ) {
      (async () => {
        const records = [];
        if (selectedInsights?.length > 0) {
          selectedInsights?.map((insightId, index) => {
            records?.push({
              attributes: {
                type: "Market_Visit_Report__c",
                referenceId: `ref${index + 1}`,
              },
              SaveFormDate__c: formData?.marketVisitDate,
              Cluster__c: formData?.clusterCode,
              Have_you_visited_a_WSS__c: formData?.visitedWssRadio,
              WSS_Name_Visited__c: wssName?.length
                ? wssName?.toString()
                : formData?.wssVisited,
              Key_Observations_Issues_faced_by_WSS__c:
                formData?.keyObservationWss,
              Are_competition_products_WSS__c: formData?.productWssRadio,
              Darpan_bills_dealers_visited__c: formData?.darpanBillRadio,
              Names_Dealers_Darpan_B_unavail__c: formData?.darpanDealer,
              Dealer_Name_Visited__c: dealerName?.length
                ? dealerName?.toString()
                : formData?.dealerVisited,
              Key_Issues_faced_by_Dealer__c: formData?.issueFacedDealer,
              Service_Or_Delivery_feedback__c: formData?.deliveryFeedback,
              schemes_at_dealer_by_WSS__c: formData?.visitDealerRadio,
              Scheme_settlement_comments__c: formData?.schemeComments,
              User_Name_Visited__c: userName?.length
                ? userName?.toString()
                : formData?.userVisited,
              Key_Issues_faced_by_User__c: formData?.keyObsUser,
              Issue_Faced_While_Banking__c: formData?.issuesFacedByBank,
              Product_Quality_Issues__c: formData?.productQuality,
              Product_Related_Opportunities__c: formData?.newProductOpportunity,
              Any_Other_Feedback_Product__c: formData?.productOtherFeedback,
              Observation_competition_activity_M_Visit__c:
                formData?.competitionActivity,
              Competition_product_affecting_sales__c:
                formData?.competitionProducts,
              Urgent_Escalation_Points__c: formData?.urgentEscalation,
              FeedbackOnUserLoyalty__c: formData?.feedbackOnUserLoyalty,
              FeedbackOnUserApp__c: formData?.feedbackOnUserApp,
              FeedbackAtUserMeeting__c: formData?.feedbackAtUserMeeting,
              ObsFromRedemptionCenter__c: formData?.obsFromRedemptionCenter,
              User_Type__c: formData?.salesGroupUserType,
              IsSavedDraft__c: false,
              MVR_Insight_Id__c: insightId,
              Local_Issues_1__c: rows[0]?.assigned_to ?? null,
              Local_Issues_2__c: rows[1]?.assigned_to ?? null,
              Local_Issues_3__c: rows[2]?.assigned_to ?? null,
              Local_Issues_4__c: rows[3]?.assigned_to ?? null,
              Local_Issues_5__c: rows[4]?.assigned_to ?? null,
              Local_Issues_value1__c: rows[0]?.issue ?? null,
              Local_Issues_value2__c: rows[1]?.issue ?? null,
              Local_Issues_value3__c: rows[2]?.issue ?? null,
              Local_Issues_value4__c: rows[3]?.issue ?? null,
              Local_Issues_value5__c: rows[4]?.issue ?? null,
              Large_Improvement_Areas__c: formData?.largeImproveAreas,
              Miscellaneous_section__c: formData?.miscellaneousSection,
            });
          });
        } else {
          records.push({
            attributes: {
              type: "Market_Visit_Report__c",
              referenceId: `ref1`,
            },
            SaveFormDate__c: formData?.marketVisitDate,
            Cluster__c: formData?.clusterCode,
            Have_you_visited_a_WSS__c: formData?.visitedWssRadio,
            WSS_Name_Visited__c: wssName?.length
              ? wssName?.toString()
              : formData?.wssVisited,
            Key_Observations_Issues_faced_by_WSS__c:
              formData?.keyObservationWss,
            Are_competition_products_WSS__c: formData?.productWssRadio,
            Darpan_bills_dealers_visited__c: formData?.darpanBillRadio,
            Names_Dealers_Darpan_B_unavail__c: formData?.darpanDealer,
            Dealer_Name_Visited__c: dealerName?.length
              ? dealerName?.toString()
              : formData?.dealerVisited,
            Key_Issues_faced_by_Dealer__c: formData?.issueFacedDealer,
            Service_Or_Delivery_feedback__c: formData?.deliveryFeedback,
            schemes_at_dealer_by_WSS__c: formData?.visitDealerRadio,
            Scheme_settlement_comments__c: formData?.schemeComments,
            User_Name_Visited__c: userName?.length
              ? userName?.toString()
              : formData?.userVisited,
            Key_Issues_faced_by_User__c: formData?.keyObsUser,
            Issue_Faced_While_Banking__c: formData?.issuesFacedByBank,
            Product_Quality_Issues__c: formData?.productQuality,
            Product_Related_Opportunities__c: formData?.newProductOpportunity,
            Any_Other_Feedback_Product__c: formData?.productOtherFeedback,
            Observation_competition_activity_M_Visit__c:
              formData?.competitionActivity,
            Competition_product_affecting_sales__c:
              formData?.competitionProducts,
            Urgent_Escalation_Points__c: formData?.urgentEscalation,
            FeedbackOnUserLoyalty__c: formData?.feedbackOnUserLoyalty,
            FeedbackOnUserApp__c: formData?.feedbackOnUserApp,
            FeedbackAtUserMeeting__c: formData?.feedbackAtUserMeeting,
            ObsFromRedemptionCenter__c: formData?.obsFromRedemptionCenter,
            User_Type__c: formData?.salesGroupUserType,
            IsSavedDraft__c: false,
            Local_Issues_1__c: rows[0]?.assigned_to ?? null,
            Local_Issues_2__c: rows[1]?.assigned_to ?? null,
            Local_Issues_3__c: rows[2]?.assigned_to ?? null,
            Local_Issues_4__c: rows[3]?.assigned_to ?? null,
            Local_Issues_5__c: rows[4]?.assigned_to ?? null,
            Local_Issues_value1__c: rows[0]?.issue ?? null,
            Local_Issues_value2__c: rows[1]?.issue ?? null,
            Local_Issues_value3__c: rows[2]?.issue ?? null,
            Local_Issues_value4__c: rows[3]?.issue ?? null,
            Local_Issues_value5__c: rows[4]?.issue ?? null,
            Large_Improvement_Areas__c: formData?.largeImproveAreas,
            Miscellaneous_section__c: formData?.miscellaneousSection,
          });
        }

        const finalData = {
          records: records,
        };
        setLoading(true);
        const response = await addMarketVisitReportApi(finalData);
        if (response?.success === true) {
          const updatedDraft = draftedForm?.filter(
            (item) => item?.marketVisitDate !== formData?.marketVisitDate
          );
          // console.log("data 3", updatedDraft)
          setDraftedForm(updatedDraft);

          // API call to delete draft MVR Entry
          if (formDraftId){
            const deleteResponse = await deleteSaveAsDraftForMVRApi(formDraftId);
          }

          // setLoading(true);
          const payload = preparePayloadForServiceRequest(response);
          if (payload){
            
            const serviceReqResult = await addEscalationServiceRequestApi(payload);
            if (serviceReqResult?.status == 200){
              //setLoading(false);
            }
          }

          // // APi call for Upload file
          // if (_.isEmpty(fileValidation)) {
          //   const fileArray = {
          //     fileName1: file?.[0]?.fileName,
          //     fileExtension1: file?.[0]?.fileExtension,
          //     base64One: file?.[0]?.base64,
          //     fileName2: file?.[1]?.fileName,
          //     fileExtension2: file?.[1]?.fileExtension,
          //     base64Two: file?.[1]?.base64,
          //     fileName3: file?.[2]?.fileName,
          //     fileExtension3: file?.[2]?.fileExtension,
          //     base64Three: file?.[2]?.base64,
          //     fileName4: file?.[3]?.fileName,
          //     fileExtension4: file?.[3]?.fileExtension,
          //     base64Four: file?.[3]?.base64,
          //     fileName5: file?.[4]?.fileName,
          //     fileExtension5: file?.[4]?.fileExtension,
          //     base64Five: file?.[4]?.base64,
          //     fileName6: file?.[5]?.fileName,
          //     fileExtension6: file?.[5]?.fileExtension,
          //     base64Six: file?.[5]?.base64,
          //     fileName7: file?.[6]?.fileName,
          //     fileExtension7: file?.[6]?.fileExtension,
          //     base64Seven: file?.[6]?.base64,
          //     mvrRecordId: response?.data?.results?.[0]?.id,
          //   };
          //   const result = await addMarketVisitReportFileApi(fileArray);
          // }
          // Api call for Create SR
          // if (isValidString(formData?.productQuality)) {
          //   const data = {
          //     recordType: "Dealer",
          //     subject: formData?.productQuality,
          //     description: formData?.productQuality,
          //     dealerId: "",
          //     chapterName: "Dealer Feedback",
          //     category: "",
          //     subCategory: "",
          //     fileExtension: "",
          //     fileName: "",
          //     base64: "",
          //     origin: "Mconnect Product Complaints",
          //     MVRId: response?.data?.results[0]?.id,
          //     BMName: userDetailName,
          //     BMEmail: userEmail,
          //   };
          //   const {
          //     recordType,
          //     subject,
          //     description,
          //     dealerId,
          //     chapterName,
          //     category,
          //     subCategory,
          //     fileExtension,
          //     fileName,
          //     base64,
          //     origin,
          //     MVRId,
          //     BMName,
          //     BMEmail,
          //   } = data;
          //   setLoading(true);
          //   const response1 = await addMySrApi({
          //     recordType,
          //     subject,
          //     description,
          //     dealerId,
          //     chapterName,
          //     category,
          //     subCategory,
          //     fileExtension,
          //     fileName,
          //     base64,
          //     origin,
          //     MVRId,
          //     BMName,
          //     BMEmail,
          //   });
          //   setLoading(false);
          // }
          setIsSubmitedSuccessfully(true);
          setIsSubmitModalOpen(true);
          setFormData({
            marketVisitDate: "",
            visitedWssRadio: "",
            visitDealerRadio: "",
            productWssRadio: "",
            darpanBillRadio: "",
            wssVisited: "",
            keyObservationWss: "",
            darpanDealer: "",
            dealerVisited: "",
            issueFacedDealer: "",
            deliveryFeedback: "",
            schemeComments: "",
            userVisited: "",
            keyObsUser: "",
            issuesFacedByBank: "",
            productQuality: "",
            newProductOpportunity: "",
            productOtherFeedback: "",
            competitionActivity: "",
            competitionProducts: "",
            urgentEscalation: "",
            createdDate: "",
            feedbackOnUserLoyalty: "",
            feedbackOnUserApp: "",
            feedbackAtUserMeeting: "",
            obsFromRedemptionCenter: "",
            salesGroupUserType: "",
            largeImproveAreas: "",
            miscellaneousSection: "",
          });
          setIsSubmit(false);
          setDealerName([]);
          setUserName([]);
          setWssName([]);
          setCluster(clusterCodePlaceHolder);
          setSelectUserType([]);
          setVlcListType([]);
          setFile([]);
          checkFormData(formData?.marketVisitDate, "Submit")
          getMvrFormDate();
        } else {
          setFormData({
            marketVisitDate: formData?.marketVisitDate,
            visitedWssRadio: "",
            visitDealerRadio: "",
            productWssRadio: "",
            darpanBillRadio: "",
            wssVisited: "",
            keyObservationWss: "",
            darpanDealer: "",
            dealerVisited: "",
            issueFacedDealer: "",
            deliveryFeedback: "",
            schemeComments: "",
            userVisited: "",
            keyObsUser: "",
            issuesFacedByBank: "",
            productQuality: "",
            newProductOpportunity: "",
            productOtherFeedback: "",
            competitionActivity: "",
            competitionProducts: "",
            urgentEscalation: "",
            createdDate: currentDate,
            feedbackOnUserLoyalty: "",
            feedbackOnUserApp: "",
            feedbackAtUserMeeting: "",
            obsFromRedemptionCenter: "",
            salesGroupUserType: "",
            selectUserType: "",
            vlcListType: "",
            largeImproveAreas: "",
            miscellaneousSection: "",
          });
          setIsSubmit(false);
          setDealerName([]);
          setUserName([]);
          setWssName([]);   
          setCluster(clusterCodePlaceHolder);
          setSelectUserType([]);
          setVlcListType([]);
          setFile([]);
          checkFormData(formData?.marketVisitDate,"Submit")
        }
        setLoading(false);
      })();
    }
    if (Object?.keys(formValidation)?.length !== 0 || hasErrors) {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  //Function to remove from draft if report is submitted successfully
  useEffect(() => {
    if (drafts[userId] && isSubmitedSuccessfully) {
      drafts = { ...drafts, [userId]: draftedForm };
      removeMVRDraftDetails(drafts);
    }
  }, [isSubmitedSuccessfully]);

  //------------------------------------------------------------------------------------------------------------//

  //----------------------------------------- To Auto save Data ------------------------------------------------// 

  const sendRequest = useCallback(
    (value) => {
      (async () => {
        const index = draftedForm.findIndex(
          (obj) => obj.marketVisitDate === value.marketVisitDate
        );
        if (index !== -1) {
          draftedForm[index] = value;
          setDraftedForm((prevDataArray) => {
            const updatedArray = [...prevDataArray];
            updatedArray[index] = value;
            return updatedArray;
          });
        } else {
          setDraftedForm((prevDataArray) => [...prevDataArray, value]);
        }
      })();
    },
    [draftedForm]
  );

  useEffect(() => {
    (async () => {
      if (drafts.hasOwnProperty(userId)) {
        drafts[userId] = draftedForm;
      }
      await getMVRDraftDetails(drafts);
    })();
  }, [draftedForm]);

  // memoize the debounce call with useMemo
  const debouncedApiCall = useMemo(() => {
    return _.debounce(sendRequest, 3000);
  }, [sendRequest]);

  //------------------------------------------------------------------------------------------------------------// 

  //------------------------------ Get Market Visit Report Data -----------------------------------------------//

  // Function to map the rows data fetch by api call
  const MappedFetchSR = (fetchedData) => {
    if (fetchedData) {
      const mappedRows = Array.from({ length: 5 }, (_, i) => ({
        srId: `SRID-${i + 1}`,
        assigned_to: fetchedData[`Local_Issues_${i + 1}__c`] || "",
        issue: fetchedData[`Local_Issues_value${i + 1}__c`] || "",
        files: [],
      })).filter(row => row.assigned_to || row.issue); // Keep only rows with data

      setRows(mappedRows.length > 0 ? mappedRows : [{ srId: "101", assigned_to: "", issue: "", files: [] }]);
    } else {
      setRows([{ srId: "101", assigned_to: "", issue: "", files: [] }]);
    }
  };

  // Function to map the rows data for autosave entries
  const MapAutoSaveData = (rows) => {
    console.log("MapAutoSaveData", rows);

    const mappedRows = rows?.map((row, index) => ({
      srId: `SRID-${index + 1}`,
      assigned_to: row.assigned_to || "",
      issue: row.issue || "",
      files: [],
    })).filter(row => row.assigned_to || row.issue) || [];

    setRows(mappedRows.length > 0
      ? mappedRows
      : [{ srId: "101", assigned_to: "", issue: "", files: [] }]);
  };

  // Function to map the cluster code in autocomplete dropdown
  const setClusterCode = (clustCode) =>{
    var clusterFilterData = getUniqueListBy(clusterCodeOption, userType === "MH3" || userType === "RBDM Collaborator" ? "CLUSTER_CODE__c" : "SH3_Name__c");
    //var clustCode = data.Cluster__c ? data.Cluster__c : data.clusterCode;
    if (clustCode) {
      if (userType === "MH3" || userType === "RBDM Collaborator") {
        var defaultCluster = clusterFilterData.filter(item => item.CLUSTER_CODE__c === clustCode);
        setCluster(defaultCluster[0]);
      } else {
        var defaultCluster = clusterFilterData.filter(item => item.SH3_Code__c === clustCode);
        setCluster(defaultCluster[0]);
      }
    } else {
      setCluster(clusterCodePlaceHolder);
    }
  }

  // Function to map Initial data after date selection 
  const initializeDefaultForm = (selectedDate) => {
    setFormData({
      marketVisitDate: selectedDate,
      visitedWssRadio: "",
      visitDealerRadio: "",
      productWssRadio: "",
      darpanBillRadio: "",
      wssVisited: "",
      keyObservationWss: "",
      darpanDealer: "",
      dealerVisited: "",
      issueFacedDealer: "",
      deliveryFeedback: "",
      schemeComments: "",
      userVisited: "",
      keyObsUser: "",
      issuesFacedByBank: "",
      productQuality: "",
      newProductOpportunity: "",
      productOtherFeedback: "",
      competitionActivity: "",
      competitionProducts: "",
      urgentEscalation: "",
      createdDate: currentDate,
      feedbackOnUserLoyalty: "",
      feedbackOnUserApp: "",
      feedbackAtUserMeeting: "",
      obsFromRedemptionCenter: "",
      salesGroupUserType: "",
      clusterCode: "",
      largeImproveAreas: "",
      miscellaneousSection: "",
    });
    setFormDraftId("");
    setCluster(clusterCodePlaceHolder);
    setSelectUserType([]);
    setVlcListType([]);
    setVersionData([]);
    setRows([{ srId: "101", assigned_to: "", issue: "", files: [] }]);
    setDisable(false);
    setIsButtonDisable(false);
    setLoading(false);
  };

  // Function to map fetch data from Get api 
  const populateFormData = (data, selectedDate) => {
    const myArray = data.User_Type__c?.split(",") || [];
    const myVlcListArray = data.User_Name_Visited__c?.split(",") || [];

    setFormData({
      marketVisitDate: selectedDate,
      visitedWssRadio: data.Have_you_visited_a_WSS__c,
      visitDealerRadio: data.schemes_at_dealer_by_WSS__c,
      productWssRadio: data.Are_competition_products_WSS__c,
      darpanBillRadio: data.Darpan_bills_dealers_visited__c,
      wssVisited: data.WSS_Name_Visited__c,
      keyObservationWss: data.Key_Observations_Issues_faced_by_WSS__c,
      darpanDealer: data.Names_Dealers_Darpan_B_unavail__c,
      dealerVisited: data.Dealer_Name_Visited__c,
      issueFacedDealer: data.Key_Issues_faced_by_Dealer__c,
      deliveryFeedback: data.Service_Or_Delivery_feedback__c,
      schemeComments: data.Scheme_settlement_comments__c,
      userVisited: data.User_Name_Visited__c,
      keyObsUser: data.Key_Issues_faced_by_User__c,
      issuesFacedByBank: data.Issue_Faced_While_Banking__c,
      productQuality: data.Product_Quality_Issues__c,
      newProductOpportunity: data.Product_Related_Opportunities__c,
      productOtherFeedback: data.Any_Other_Feedback_Product__c,
      competitionActivity: data.Observation_competition_activity_M_Visit__c,
      competitionProducts: data.Competition_product_affecting_sales__c,
      urgentEscalation: data.Urgent_Escalation_Points__c,
      createdDate: currentDate,
      feedbackOnUserLoyalty: data.FeedbackOnUserLoyalty__c,
      feedbackOnUserApp: data.FeedbackOnUserApp__c,
      feedbackAtUserMeeting: data.FeedbackAtUserMeeting__c,
      obsFromRedemptionCenter: data.ObsFromRedemptionCenter__c,
      largeImproveAreas: data.Large_Improvement_Areas__c,
      miscellaneousSection: data.Miscellaneous_section__c,
    });
    setSelectUserType(myArray.map(item => ({ user_name: item })));
    setVlcListType(myVlcListArray.map(item => ({ name: item })));
  };

  // function to fetch the data & check if on selectedDate data is present or not  
  const checkFormData = (selectedDate, Type) => {
    setLoading(true);
    if (selectedDate) {
      (async () => {
        const draftIndex = draftedForm?.findIndex((object) => object.marketVisitDate === selectedDate);
        console.log("draftIndex  -->", draftIndex);

        let response;
        response = await getMarketVisitReportApi(selectedDate, userId);
        console.log("response  -->", response);

        if (draftIndex == -1){
          if (response?.success === true && response?.data?.length >= 1 && response?.data[0]?.IsSavedDraft__c == "false") {
            console.log("Inside Submit -->")
            const formData = response.data[0];

            if (formData.SaveFormDate__c === selectedDate) {
              setDownloadPDFDate(formData.SaveFormDate__c);
              setPdfData(formData);
            }

            setIsButtonDisable(formData.IsSavedDraft__c === "false" || formData.IsSavedDraft__c === null);
            setDisable(formData.IsSavedDraft__c === "false" || formData.IsSavedDraft__c === null);
            setFormDraftId(formData.Id);
            setClusterCode(formData.Cluster__c);
            populateFormData(formData, selectedDate);
            MappedFetchSR(formData);

            const responseId = await getMvrSrAttachedFiles(formData.Id);
            setVersionData(responseId?.success ? responseId.data : []);
            setLoading(false);
          } else if (response?.data[0]?.IsSavedDraft__c == "True"){
            console.log("Inside Draft -->")
            const formData = response.data[0];
            setIsButtonDisable(formData.IsSavedDraft__c === "false" || formData.IsSavedDraft__c === null);
            setDisable(formData.IsSavedDraft__c === "false" || formData.IsSavedDraft__c === null);
            setFormDraftId(formData.Id);
            setClusterCode(formData.Cluster__c);
            populateFormData(formData, selectedDate);
            MappedFetchSR(formData);
            setLoading(false);
          }else{
            console.log("Inside new record -->")
            initializeDefaultForm(selectedDate);
          }
        }
        else{
          if (draftIndex !== -1 && Type !== "Submit") {
            if (response?.success === true && response?.data?.length >= 1) {
              const formData = response.data[0];
              setFormDraftId(formData.Id);
            }else{
              setFormDraftId('');
            }
            console.log("Inside auto save -->")
            setClusterCode(draftedForm[draftIndex].clusterCode)
            const userTypeStr = draftedForm[draftIndex].salesGroupUserType;
            const myArray = userTypeStr ? userTypeStr.split(',') : [];
            setSelectUserType(myArray?.map((item) => ({ user_name: item })))
            setFormData(draftedForm[draftIndex]);
            var rowData = draftedForm[draftIndex].rows;
            MapAutoSaveData(rowData);
            setDisable(false);
            setIsButtonDisable(false);
            setVersionData([]);
            setLoading(false);
          }
          else{
            console.log("Inside draft -->")
            if (response?.success === true && response?.data?.length >= 1) {
              const formData = response.data[0];
              setIsButtonDisable(formData.IsSavedDraft__c === "false" || formData.IsSavedDraft__c === null);
              setDisable(formData.IsSavedDraft__c === "false" || formData.IsSavedDraft__c === null);
              setFormDraftId(formData.Id);
              setClusterCode(formData.Cluster__c);
              populateFormData(formData, selectedDate);
              MappedFetchSR(formData);
              setLoading(false);
            }
          }
        }
      })();
    } else {
      initializeDefaultForm(selectedDate);
    }
  };

  // ------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------- MVR Attach File ----------------------------------------------- // 
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (index, event) => {
    event.preventDefault();
    const files = event.target.files;
    const filesArray = Array.from(files);

    // Copy rows array
    const updatedRows = [...rows];

    // Ensure validationError exists
    if (!updatedRows[index].validationError) {
      updatedRows[index].validationError = { file: '', issue: '' };
    }

    // File count validation
    if (filesArray.length > 7) {
      updatedRows[index].validationError.file = "Please select a maximum of 7 files";
      setRows(updatedRows);
      return;
    }

    // File size validation (e.g., 2MB limit)
    const maxSize = 2 * 1024 * 1024; // 2MB
    for (let file of filesArray) {
      if (file.size > maxSize) {
        updatedRows[index].validationError.file = `File "${file.name}" exceeds the 2MB size limit.`;
        setRows(updatedRows);
        return;
      }
    }

    // Clear file validation error if validation passes
    updatedRows[index].validationError.file = "";

    // Convert files to base64 and update files
    const fileDataPromises = filesArray.map(async (file) => {
      const base64 = await convertBase64(file);
      return {
        base64: base64.replace("data:", "").replace(/^.+,/, ""),
        fileExtension: file.name.split(".").pop(),
        fileName: file.name,
      };
    });

    const fileData = await Promise.all(fileDataPromises);
    updatedRows[index].files = fileData;
    setRows(updatedRows);
  };

  const getFileName = (id, name, versionData) => {
    const base64Data = versionData;

    if (base64Data) {
      const downloadUrl = `data:image/png;base64,${base64Data}`;
      setImageBase64(downloadUrl);

      // Create a download link
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error("No version data available");
    }
  };

  useEffect(() => {
    setVersionData(versionData);
  }, [versionData]);

  //-------------------------------------------------------------------------------------------------------------//
  
  //------------------------------------- MVR save as Draft Functions -------------------------------------------//

  // Function to enable Edit functionality
  const HandleEdit = () => {
    setDisable(false);
  }

  // Function to trigger save as draft
  const HandleSaveAsDraft = () => {
    setIsDraftSave(true);
  }

  // API Call for Save as Draft
  useEffect(() => {
    setLoading(true);

    // Check if the text area is in limit (text area validation)
    const hasErrors = Object.values(characterLimitValidation).some(
      (error) => error !== ""
    );
    if (!hasErrors && isDraftSave) {
      console.log("Draft ID -", formDraftId);
      console.log("Form Data -", formData);
      (async () => {
        const draftPayload = {
          "id": formDraftId ? formDraftId : "",
          // "IsSavedDraft__c": "",
          "SaveFormDate": formData?.marketVisitDate,
          "HaveYouVisitedAWSS": formData?.visitedWssRadio,
          "WSSNameVisited": wssName?.length ? wssName?.toString() : formData?.wssVisited,
          "KeyObservationsIssuesFacedByWSS": formData?.keyObservationWss,
          "AreCompetitionProductsWSS": formData?.productWssRadio,
          "DarpanBillsDealersVisited": formData?.darpanBillRadio,
          "NamesDealersDarpanBUnavail": formData?.darpanDealer,
          "DealerNameVisited": dealerName?.length ? dealerName?.toString() : formData?.dealerVisited,
          "KeyIssuesFacedByDealer": formData?.issueFacedDealer,
          "ServiceOrDeliveryFeedback": formData?.deliveryFeedback,
          "SchemesAtDealerByWSS": formData?.visitDealerRadio,
          "SchemeSettlementComments": formData?.schemeComments,
          "UserNameVisited": userName?.length ? userName?.toString() : formData?.userVisited,
          "KeyIssuesFacedByUser": formData?.keyObsUser,
          "IssueFacedWhileBanking": formData?.issuesFacedByBank,
          "ProductQualityIssues": formData?.productQuality,
          "ProductRelatedOpportunities": formData?.newProductOpportunity,
          "AnyOtherFeedbackProduct": formData?.productOtherFeedback,
          "ObservationCompetitionActivityMVisit": formData?.competitionActivity,
          "CompetitionProductAffectingSales": formData?.competitionProducts,
          "UrgentEscalationPoints": formData?.urgentEscalation,
          "FeedbackOnUserLoyalty": formData?.feedbackOnUserLoyalty,
          "FeedbackOnUserApp": formData?.feedbackOnUserApp,
          "FeedbackAtUserMeeting": formData?.feedbackAtUserMeeting,
          "ObsFromRedemptionCenter": formData?.obsFromRedemptionCenter,
          "UserType": formData?.salesGroupUserType,
          "LocalIssues1": rows[0]?.assigned_to ?? null,
          "LocalIssues2": rows[1]?.assigned_to ?? null,
          "LocalIssues3": rows[2]?.assigned_to ?? null,
          "LocalIssues4": rows[3]?.assigned_to ?? null,
          "LocalIssues5": rows[4]?.assigned_to ?? null,
          "LocalIssuesValue1": rows[0]?.issue ?? null,
          "LocalIssuesValue2": rows[1]?.issue ?? null,
          "LocalIssuesValue3": rows[2]?.issue ?? null,
          "LocalIssuesValue4": rows[3]?.issue ?? null,
          "LocalIssuesValue5": rows[4]?.issue ?? null,
          "LargeImprovementAreas": formData?.largeImproveAreas,
          "MiscellaneousSection": formData?.miscellaneousSection,
          "ClusterCode": formData?.clusterCode
        }
        if (formData && isDraftSave == true) {
          const response = await updateMarketVisitReportApi(draftPayload);
          if (response?.success === true){
            console.log("d-id", response, response?.data?.id)
            setFormDraftId(response?.data?.id)
            debouncedApiCall({ ...formData, rows, draftId: response?.data?.id });
            //setDisable(true);
            setIsDraftSave(false);
            setLoading(false);
            setIsDraftModalOpen(true);
            checkFormData(formData?.marketVisitDate)
            setIsSubmit(false);
            getMvrFormDate()
          }else{
            //setDisable(true);
            setIsDraftSave(false);
            setLoading(false);
            setIsErrorModalOpen(true);
            setIsSubmit(false);
            checkFormData(formData?.marketVisitDate)
            getMvrFormDate()
          }
        }
      })();
    }else{
      setLoading(false);
      setIsDraftSave(false);
    }
    setIsButtonDisable(false) 
  }, [isDraftSave])

  // console.log("draft Id --->",draftedForm, formDraftId);

  //------------------------------------------------------------------------------------------------------------// 

  //--------------------------------------- Download PDF Logic -------------------------------------------------// 

  const downloadPDF = () => {

    // Create a new jsPDF instance
    const pdf = new jsPDF();
    const title = "Market Visit Report";
    const pageWidth = pdf.internal.pageSize.getWidth();
    const titleX = pageWidth / 2 - (pdf.getTextWidth(title) / 2);

    // Set text color for the title
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor("#3901ff");
    pdf.text(title, titleX, 20);
    pdf.setFontSize(12);
    pdf.setTextColor("#000000");

    let y = 30; // Start position for the text

    const rbdmData = {
      Date: {
        Name: "Date",
        SaveFormDate__c: {
          label: "Save Date",
          format: value => value,
        }
      },

      // User Meeting / Visit Feedback 11-15
      UserMeeting: {
        Name: "User Meeting / Visit Feedback 11-15",
        User_Type__c: {
          label: "User Types",
          format: value => value || "Not provided"
        },
        User_Name_Visited__c: {
          label: "Name(s) of key User(s) visited where any issue(s) reported",
          format: value => value
        },
        FeedbackOnUserLoyalty__c: {
          label: "Feedback on User Loyalty programm",
          format: value => value || "Not provided"
        },
        FeedbackOnUserApp__c: {
          label: "Feedback on User App",
          format: value => value || "Not provided"
        },
        FeedbackAtUserMeeting__c: {
          label: "Other Key Observations/Feedbacks at the User Meeting/Visit",
          format: value => value || "Not provided"
        },
        ObsFromRedemptionCenter__c: {
          label: "Observations from Redemption center visit (if any)",
          format: value => value || "Not provided",
          comment: "WSS"
        }
      },

      // WSS 1-5
      wss: {
        Name: "WSS",
        WSS_Name_Visited__c: {
          label: "Name(s) of WSS(s) visited where any issue(s) reported",
          format: value => value
        },
        Key_Observations_Issues_faced_by_WSS__c: {
          label: "Key Observations/Issues faced by WSS(s)",
          format: value => value,
          comment: "Dealer"
        }
      },

      // Dealer 6-10
      Dealer: {
        Name: "Dealer",
        Dealer_Name_Visited__c: {
          label: "Name(s) of Dealer(s) visited where any issue(s) reported",
          format: value => value
        },
        Key_Issues_faced_by_Dealer__c: {
          label: "Key Observations/Issues faced by Dealer(s)",
          format: value => value
        },
        Service_Or_Delivery_feedback__c: {
          label: "Service/Delivery related feedback",
          format: value => value
        }
      },

      // Product Complaints
      Product_Complaints: {
        Name: "Product Complaints",
        Product_Quality_Issues__c: {
          label: "Complaints - Product Quality/Packaging Related Complaints",
          format: value => value
        }
      },

      // Other Product Related Feedbacks

      Other_Product_Related_Feedbacks: {
        Name: "Other Product Related Feedbacks",
        Product_Related_Opportunities__c: {
          label: "New opportunity - New Products/Application related opportunities identified",
          format: value => value
        },
        Any_Other_Feedback_Product__c: {
          label: "Any other Feedback/Insights:",
          format: value => value
        }
      },

      // Competition
      Competition: {
        Name: "Competition",
        Observation_competition_activity_M_Visit__c: {
          label: "Key Observations regarding competition activity in overall market visited",
          format: value => value
        },
        Competition_product_affecting_sales__c: {
          label: "Key Competition products which are affecting our sales",
          format: value => value
        }
      },

      // Escalations/Immediate Support Required
      Escalations_Immediate_Support_Required: {
        Name: "Escalate To (select from dropdown)",
        Local_Issues_1__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value1__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_2__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value2__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_3__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value3__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_4__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value4__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_5__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value5__c: {
          label: "Issue",
          format: value => value
        },
        Large_Improvement_Areas__c: {
          label: "Suggestions which might require creation of a new policy or change in existing ones",
          format: value => value
        }
      },

      // Miscellaneous Section
      Miscellaneous_Section: {
        Name: "Miscellaneous Section",
        Miscellaneous_section__c: {
          label: "Observations not falling under any of the above sections",
          format: value => value
        }
      }
    }

    const bmData = {
      Date: {
        Name: "Date",
        SaveFormDate__c: {
          label: "Save Date",
          format: value => value
        }
      },

      // WSS 1-5
      WSS: {
        Name: "WSS",
        WSS_Name_Visited__c: {
          label: "Name(s) of WSS(s) visited where any issue(s) reported",
          format: value => value
        },
        Key_Observations_Issues_faced_by_WSS__c: {
          label: "Key Observations/Issues faced by WSS(s)",
          format: value => value
        },
        Are_competition_products_WSS__c: {
          label: "Are competition products (PIL Similar) available at the WSS?",
          format: value => value
        },
        Darpan_bills_dealers_visited__c: {
          label: "Are Darpan bills available at all the dealers visited today?",
          format: value => value
        },
        Names_Dealers_Darpan_B_unavail__c: {
          label: "Names of Dealers where Darpan bills unavailable",
          format: value => value
        }
      },

      // Dealer 6-10
      Dealer: {
        Name: "Dealer",
        Dealer_Name_Visited__c: {
          label: "Name(s) of Dealer(s) visited where any issue(s) reported",
          format: value => value
        },
        Key_Issues_faced_by_Dealer__c: {
          label: "Key Observations/Issues faced by Dealer(s)",
          format: value => value
        },
        Service_Or_Delivery_feedback__c: {
          label: "Service/Delivery related feedback",
          format: value => value
        },
        schemes_at_dealer_by_WSS__c: {
          label: "Are all the schemes settled at the visited dealers by the respective WSS?",
          format: value => value
        },
        Scheme_settlement_comments__c: {
          label: "Scheme settlement related comments",
          format: value => value
        }
      },

      //  User
      User: {
        Name: "User",
        User_Name_Visited__c: {
          label: "Name(s) of key User(s) visited where any issue(s) reported",
          format: value => value
        },
        Key_Issues_faced_by_User__c: {
          label: "Key Observations/Issues faced by User(s)",
          format: value => value
        },
        Issue_Faced_While_Banking__c: {
          label: "Mention the issues faced by them while banking/redeeming",
          format: value => value
        }
      },

      // Product Complaints
      Product_Complaints: {
        Name: "Product Complaints",
        Product_Quality_Issues__c: {
          label: "Complaints - Product Quality/Packaging Related Complaints",
          format: value => value
        }
      },

      // Other Product Related Feedbacks
      Other_Product_Related_Feedbacks: {
        Name: "Other Product Related Feedbacks",
        Product_Related_Opportunities__c: {
          label: "New opportunity - New Products/Application related opportunities identified",
          format: value => value
        },
        Any_Other_Feedback_Product__c: {
          label: "Any other Feedback/Insights:",
          format: value => value
        }
      },

      // Competition
      Competition: {
        Name: "Competition",
        Observation_competition_activity_M_Visit__c: {
          label: "Key Observations regarding competition activity in overall market visited",
          format: value => value
        },
        Competition_product_affecting_sales__c: {
          label: "Key Competition products which are affecting our sales",
          format: value => value
        }
      },

      // Escalations/Immediate Support Required
      Escalations_Immediate_Support_Required: {
        Name: "Escalate To (select from dropdown)",
        Local_Issues_1__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value1__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_2__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value2__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_3__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value3__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_4__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value4__c: {
          label: "Issue",
          format: value => value
        },
        Local_Issues_5__c: {
          label: "Assigned to",
          format: value => value
        },
        Local_Issues_value5__c: {
          label: "Issue",
          format: value => value
        },
        Large_Improvement_Areas__c: {
          label: "Suggestions which might require creation of a new policy or change in existing ones",
          format: value => value
        }
      },

      // Miscellaneous Section
      Miscellaneous_Section: {
        Name: "Miscellaneous Section",
        Miscellaneous_section__c: {
          label: "Observations not falling under any of the above sections",
          format: value => value
        }
      }
    };

    const BMmappedSection = []
    const bmSection = Object.keys(bmData).reduce((acc, sectionKey) => {
      const section = bmData[sectionKey];
      BMmappedSection.push(section)
      return BMmappedSection;
    }, []);

    const RBDMmappedSection = []
    const rbdmSection = Object.keys(rbdmData).reduce((acc, sectionKey) => {
      const section = rbdmData[sectionKey];
      RBDMmappedSection.push(section)
      return RBDMmappedSection;
    }, []);

    function addTextWithWrapping(pdf, text, x, y, maxWidth) {
      const lines = pdf.splitTextToSize(text, maxWidth);
      lines.forEach(line => {
        if (y > 280) {
          pdf.addPage();
          y = 20;
        }
        pdf.text(line, x, y);
        y += 5;
      });
      return y;
    }

    const sectionData = ((userType == "MH3" || userType == "RBDM Collaborator") ? rbdmSection : bmSection);
    if (sectionData) {
      Object.keys(sectionData).forEach(sectionKey => {
        const section = sectionData[sectionKey];
        if (y + 15 > 280) {
          pdf.addPage();
          y = 20;
        }
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor("#3901ff");
        pdf.text(section.Name, 20, y);
        y += 10;

        let labelPrinted = false; // Track if the label has been printed
        let issueCount = 0; // Count Local Issues entries

        Object.keys(section).forEach((fieldKey, index) => {
          if (fieldKey !== 'Name') {
            if (fieldKey.startsWith("Local_Issues_") && issueCount < 5 && !fieldKey.startsWith("Local_Issues_value")) {
              // Grouping Local Issues fields
              let issueIndex = fieldKey.split('_')[2]; // Extract the index from Local_Issues_1, Local_Issues_2, etc.
              const assignedKey = `Local_Issues_${issueIndex}__c`;
              const issueKey = `Local_Issues_value${issueIndex}__c`;

              // Get labels and values for both "Assigned to" and "Issue" fields
              const assignedValue = pdfData[assignedKey] || "Not provided";
              const issueValue = pdfData[issueKey] || "Not provided";

              // Only print the label once
              if (!labelPrinted) {
                const combinedLabel = "1. Issues which are specific to a TTY/WSS/Dealer/TSI- Auto SR will be raised."; // Change this if needed
                pdf.setFontSize(12);
                pdf.setTextColor("#000000");
                pdf.setFont("helvetica", "bold");
                pdf.text(combinedLabel, 20, y);
                y += 10;
                labelPrinted = true;
              }

              // Add values for Assigned to and Issue
              pdf.setFontSize(12);
              pdf.setFont("helvetica", "normal");
              pdf.setTextColor("#808080");
              const formattedText = `${issueIndex}. Assigned to: ${assignedValue}\n   Issue: ${issueValue}`;
              y = addTextWithWrapping(pdf, formattedText, 25, y, 170);
              y += 5;

              // Check if need to add a new page
              if (y > 280) {
                pdf.addPage();
                y = 20;
              }

              issueCount++; // Increment issue count
            } else if (fieldKey.startsWith("largeImproveAreas")) {
              // Regular field handling
              const field = section[fieldKey];
              const label = `2. ${field.label}`;
              const format = field.format;

              const value = pdfData[fieldKey];

              pdf.setFontSize(12);
              pdf.setTextColor("#000000");
              pdf.setFont("helvetica", "bold");
              pdf.text(label, 20, y);
              y += 5;

              pdf.setFontSize(12);
              pdf.setFont("helvetica", "normal");
              pdf.setTextColor("#808080");
              const formattedValue = format ? format(value) : value || "N/A";
              y = addTextWithWrapping(pdf, formattedValue, 25, y, 170);
              y += 5;

              // Check if need to add a new page
              if (y > 280) {
                pdf.addPage();
                y = 20;
              }

            } else if (!fieldKey.startsWith("Local_Issues_")) {
              // Regular field handling
              const field = section[fieldKey];
              const label = `${index}. ${field.label}`;
              const format = field.format;

              const value = pdfData[fieldKey];

              pdf.setFontSize(12);
              pdf.setTextColor("#000000");
              pdf.setFont("helvetica", "bold");
              pdf.text(label, 20, y);
              y += 5;

              pdf.setFontSize(12);
              pdf.setFont("helvetica", "normal");
              pdf.setTextColor("#808080");
              const formattedValue = format ? format(value) : value || "N/A";
              y = addTextWithWrapping(pdf, formattedValue, 25, y, 170);
              y += 5;

              // Check if need to add a new page
              if (y > 280) {
                pdf.addPage();
                y = 20;
              }
            }
          }
        });

        pdf.setLineWidth(0.5);
        pdf.line(20, y, 190, y); // (x1, y1, x2, y2)
        y += 5; // Move y position down after the line

        y += 10;
      });
      pdf.save("market_visit_report.pdf");
    }
  };

  //------------------------------------------------------------------------------------------------------------// 

  return (
    <>
      <div className={styles.marketVisitReport}>
        <Loader loading={loading} />
        <div className="row">
          <div className="col-8">
            {disable && (
              <p className={styles.MarketVisitReport__downloadPDFDate}><b>FILE :</b>{` MVR Form - ${downloadPDFDate}`}</p>
            )}
          </div>
          <div className={`col-4 ${styles.MarketVisitReport__downpdf_div}`}>
            {disable && (
            <>
              <button
                onClick={downloadPDF}
                className={styles.MarketVisitReport__down_pdf}
              >
                Download
              </button>
              </>
            )}
          </div>
        </div>
        {disable && (
          <>
            <hr className="m-2"/>
          </>
        )}
        <form>
          <div className={styles.MarketVisitReport__mainBox}>
            <div>
              <label className={styles.MarketVisitReport__selectLabel}>
                Date of Visit<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <MyDatePicker
                createdDates={submittedHighlightedDates}
                draftDates={draftHighlightedDates}
                setDate={onChange}
                selectedDate={formData.marketVisitDate}
                className={styles.MarketVisitReport__dateField}
              />
              {/* <input
                type="date"
                className={styles.MarketVisitReport__dateField}
                name="marketVisitDate"
                value={formData.marketVisitDate}
                onChange={(e) => onChange(e)}
                max={currentDate}
              /> */}
              {!formData?.marketVisitDate &&
                formValidation?.marketVisitDate && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {formValidation?.marketVisitDate}
                  </p>
                )}
            </div>
            <div>
              {(userType === "SH6" ||
                userType === "SH5" ||
                userType === "SH7" ||
                userType === "HO Collaborator" ||
                userType === "RBDM Collaborator" ||
                userType === "MH3" ) && (
                  <Autocomplete
                    value={cluster}
                    id="grouped-demo"
                    className="myDealer__categorySelect"
                    options={
                      !clusterCodeOption || clusterCodeOption?.length === 0
                        ? [
                          {
                            SH3_Name__c: "Loading...",
                            SH3_Code__c: "",
                            Cluster_Description__c: "Loading...",
                            CLUSTER_CODE__c: "",
                          },
                        ]
                        : getUniqueListBy(
                          clusterCodeOption,
                          userType === "MH3" || userType === "RBDM Collaborator"
                            ? "CLUSTER_CODE__c"
                            : "SH3_Name__c"
                        )
                    }
                    getOptionLabel={(option) =>
                      userType !== "MH3" && userType != "RBDM Collaborator"
                        ? `${option?.SH3_Name__c}${option?.SH3_Code__c && `:${option?.SH3_Code__c}`
                        }`
                        : `${option?.Cluster_Description__c}${option?.CLUSTER_CODE__c &&
                        `:${option?.CLUSTER_CODE__c}`
                        }`
                    }
                    onChange={(e, newValue) => {
                      setCluster(newValue);
                      onChange({
                        target: {
                          name: "clusterCode",
                          value:
                            userType === "MH3" || userType === "RBDM Collaborator"
                              ? newValue?.CLUSTER_CODE__c
                              : newValue?.SH3_Code__c,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={"Select Cluster"} />
                    )}
                  />
                )}
              {!formData?.clusterCode && formValidation?.clusterCode && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {formValidation?.clusterCode}
                </p>
              )}
            </div>
            {(userType == "MH3" || userType == "RBDM Collaborator") && (
              <>
                <div
                  className={`row m-0 ${styles.MarketVisitReport__headingBox}`}
                >
                  <div
                    className={`col-8 p-0 ${styles.MarketVisitReport__heading}`}
                  >
                    User Meeting/Visit feedback
                  </div>
                </div>
                <label className={styles.MarketVisitReport__selectLabel}>
                  Select User Type
                </label>
                <Autocomplete
                  multiple
                  value={selectUserType}
                  name="userType"
                  id="grouped-demo"
                  className="myDealer__categorySelect"
                  options={
                    !salesGroupUser || salesGroupUser?.length === 0
                      ? [
                        {
                          user_name: "Loading...",
                        },
                      ]
                      : getUniqueListBy(salesGroupUser, "user_name")
                  }
                  getOptionLabel={(option) => `${option?.user_name}`}
                  onChange={(e, newValue) => {
                    setSelectUserType(newValue);
                    onChange({
                      target: {
                        name: "salesGroupUserType",
                        value: newValue
                          ?.map((item) => item?.user_name)
                          .join(","),
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={""} />
                  )}
                  disabled={disable}
                />
                {!formData?.salesGroupUserType &&
                  formValidation?.salesGroupUserType && (
                    <p className={styles.MarketVisitReport__validationErrors}>
                      {formValidation?.salesGroupUserType}
                    </p>
                  )}

                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Name(s) of key User(s) visited where any issue(s) reported
                  </label>
                  {!_.isEmpty(vlcList) ? (
                    <>
                      <Autocomplete
                        multiple
                        value={vlcListType}
                        id="grouped-demo"
                        className="myDealer__categorySelect"
                        options={
                          !vlcList || vlcList?.length === 0
                            ? [
                              {
                                name: "Loading...",
                                bdecode__c: "",
                              },
                            ]
                            : getUniqueListBy(vlcList, "name")
                        }
                        getOptionLabel={(option) =>
                          option?.name

                        }
                        onChange={(e, newValue) => {
                          setVlcListType(newValue);
                          onChange({
                            target: {
                              name: "userVisited",
                              value: newValue?.map((item) => `${item?.name}:${item?.bdecode__c}`).join(',')
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={""}
                          />
                        )}
                        disabled={disable}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        className={` ${styles.MarketVisitReport__selectField}`}
                        style={{ paddingLeft: "8px" }}
                        name="userVisited"
                        value={
                          userName?.length ? userName : formData?.userVisited
                        }
                        onChange={(e) => onChange(e)}
                        maxLength={256}
                        disabled={disable}
                      />
                    </>
                  )}
                </div>

                <div className={styles.MarketVisitReport__selectableRows}>
                  <div className={styles.MarketVisitReport__selectableRows}>
                    <label className={styles.MarketVisitReport__selectLabel}>
                      Feedback on User Loyalty programm
                      <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                    </label>
                    <textarea
                      name="feedbackOnUserLoyalty"
                      value={formData?.feedbackOnUserLoyalty}
                      rows="3"
                      className={` ${styles.MarketVisitRepor__textArea}`}
                      style={{ paddingLeft: "8px" }}
                      onChange={(e) => onChange(e)}
                      disabled={disable}
                      maxLength={501}
                    />
                    {characterLimitValidation?.feedbackOnUserLoyalty && (
                      <p className={styles.MarketVisitReport__validationErrors}>
                        {characterLimitValidation?.feedbackOnUserLoyalty}
                      </p>
                    )}
                    {!formData?.feedbackOnUserLoyalty &&
                      formValidation?.feedbackOnUserLoyalty && (
                        <p
                          className={styles.MarketVisitReport__validationErrors}
                        >
                          {formValidation?.feedbackOnUserLoyalty}
                        </p>
                      )}
                  </div>
                </div>

                <div className={styles.MarketVisitReport__selectableRows}>
                  <div className={styles.MarketVisitReport__selectableRows}>
                    <label className={styles.MarketVisitReport__selectLabel}>
                      Feedback on User App
                      {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
                    </label>
                    <textarea
                      name="feedbackOnUserApp"
                      value={formData?.feedbackOnUserApp}
                      rows="3"
                      className={` ${styles.MarketVisitRepor__textArea}`}
                      style={{ paddingLeft: "8px" }}
                      onChange={(e) => onChange(e)}
                      disabled={disable}
                      maxLength={501}
                    />
                    {characterLimitValidation?.feedbackOnUserApp && (
                      <p className={styles.MarketVisitReport__validationErrors}>
                        {characterLimitValidation?.feedbackOnUserApp}
                      </p>
                    )}
                    {!formData?.feedbackOnUserApp &&
                      formValidation?.feedbackOnUserApp && (
                        <p
                          className={styles.MarketVisitReport__validationErrors}
                        >
                          {formValidation?.feedbackOnUserApp}
                        </p>
                      )}
                  </div>
                </div>

                <div className={styles.MarketVisitReport__selectableRows}>
                  <div className={styles.MarketVisitReport__selectableRows}>
                    <label className={styles.MarketVisitReport__selectLabel}>
                      Other Key Observations/Feedbacks at the User Meeting/Visit
                      <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                    </label>
                    <textarea
                      name="feedbackAtUserMeeting"
                      value={formData?.feedbackAtUserMeeting}
                      rows="3"
                      className={` ${styles.MarketVisitRepor__textArea}`}
                      style={{ paddingLeft: "8px" }}
                      onChange={(e) => onChange(e)}
                      disabled={disable}
                      maxLength={501}
                    />
                    {characterLimitValidation?.feedbackAtUserMeeting && (
                      <p className={styles.MarketVisitReport__validationErrors}>
                        {characterLimitValidation?.feedbackAtUserMeeting}
                      </p>
                    )}
                    {!formData?.feedbackAtUserMeeting &&
                      formValidation?.feedbackAtUserMeeting && (
                        <p
                          className={styles.MarketVisitReport__validationErrors}
                        >
                          {formValidation?.feedbackAtUserMeeting}
                        </p>
                      )}
                  </div>
                </div>
                <div className={styles.MarketVisitReport__selectableRows}>
                  <div className={styles.MarketVisitReport__selectableRows}>
                    <label className={styles.MarketVisitReport__selectLabel}>
                      Observations from Redemption center visit (if any)
                      {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
                    </label>
                    <textarea
                      name="obsFromRedemptionCenter"
                      value={formData?.obsFromRedemptionCenter}
                      rows="3"
                      className={` ${styles.MarketVisitRepor__textArea}`}
                      style={{ paddingLeft: "8px" }}
                      onChange={(e) => onChange(e)}
                      disabled={disable}
                      maxLength={501}
                    />
                    {characterLimitValidation?.obsFromRedemptionCenter && (
                      <p className={styles.MarketVisitReport__validationErrors}>
                        {characterLimitValidation?.obsFromRedemptionCenter}
                      </p>
                    )}
                    {!formData?.obsFromRedemptionCenter &&
                      formValidation?.obsFromRedemptionCenter && (
                        <p
                          className={styles.MarketVisitReport__validationErrors}
                        >
                          {formValidation?.obsFromRedemptionCenter}
                        </p>
                      )}
                  </div>
                </div>
              </>
            )}

            {/* Sec A: WSS */}
            <div className={`row m-0 ${styles.MarketVisitReport__headingBox}`}>
              <div className={`col-8 p-0 ${styles.MarketVisitReport__heading}`}>
                WSS
              </div>
            </div>
            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Name(s) of WSS(s) visited where any issue(s) reported
              </label>
              <input
                className={` ${styles.MarketVisitReport__selectField}`}
                style={{ paddingLeft: "8px" }}
                name="wssVisited"
                value={wssName?.length ? wssName : formData?.wssVisited}
                onChange={(e) => onChange(e)}
                disabled={disable}
                maxLength={256}
              />
            </div>
            {characterLimitValidation?.wssVisited && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {characterLimitValidation?.wssVisited}
              </p>
            )}
            {!formData?.wssVisited && formValidation?.wssVisited && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {formValidation?.wssVisited}
              </p>
            )}

            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Key Observations/Issues faced by WSS(s)
              </label>
              <MentionsInput
                value={
                  _.isEmpty(formData?.keyObservationWss)
                    ? ""
                    : formData?.keyObservationWss
                }
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "keyObservationWss",
                      value: e.target.value,
                    },
                  });
                }}
                className={styles.MarketVisitRepor__input}
                style={defaultStyle}
                disabled={disable}
                maxLength={2001}
              >
                <Mention
                  trigger="@p"
                  data={productList}
                  style={defaultMentionStyle}
                />
                <Mention
                  trigger="@w"
                  data={wssList}
                  style={defaultMentionStyle}
                />
                <Mention
                  trigger="@d"
                  data={dealerList}
                  style={defaultMentionStyle}
                />
              </MentionsInput>
              <p className={styles.MarketVisitReport__selectAlertMsg}> *Use @w for wss Selection</p>
            </div>
            {characterLimitValidation?.keyObservationWss && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {characterLimitValidation?.keyObservationWss}
              </p>
            )}
            {!formData?.keyObservationWss &&
              formValidation?.keyObservationWss && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {formValidation?.keyObservationWss}
                </p>
              )}
            {userType != "MH3" && userType != "RBDM Collaborator" && (
              <>
                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Are competition products (PIL Similar) available at the WSS?
                  </label>
                  <div className={`mb-1 ${styles.myUserDetails__radioOption}`}>
                    <div class="form-check">
                      <input
                        className={`form-check-input ${styles.myUserDetails__option}`}
                        type="radio"
                        name="productWssRadio"
                        id="flexRadioDefault1"
                        checked={
                          formData.productWssRadio === "yes" ||
                          formData.productWssRadio === "Yes"
                        }
                        value="yes"
                        onChange={(e) => onChange(e)}
                        disabled={disable}
                      />
                      <label
                        class="form-check-label"
                        className={styles.marketVisitReport__radioLabel}
                        htmlFor="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        className={`form-check-input ${styles.myUserDetails__option}`}
                        type="radio"
                        name="productWssRadio"
                        id="flexRadioDefault2"
                        checked={
                          formData.productWssRadio === "no" ||
                          formData.productWssRadio === "No"
                        }
                        value="no"
                        onChange={(e) => onChange(e)}
                        disabled={disable}
                      />
                      <label
                        class="form-check-label"
                        className={styles.marketVisitReport__radioLabel}
                        htmlFor="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {!formData?.productWssRadio &&
                    formValidation?.productWssRadio && (
                      <p className={styles.MarketVisitReport__validationErrors}>
                        {formValidation?.productWssRadio}
                      </p>
                    )}
                </div>

                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Are Darpan bills available at all the dealers visited today?
                  </label>
                  <div className={`mb-1 ${styles.myUserDetails__radioOption}`}>
                    <div class="form-check">
                      <input
                        className={`form-check-input ${styles.myUserDetails__option}`}
                        type="radio"
                        name="darpanBillRadio"
                        id="flexRadioDefault"
                        checked={
                          formData.darpanBillRadio === "yes" ||
                          formData.darpanBillRadio === "Yes"
                        }
                        value="yes"
                        onChange={(e) => onChange(e)}
                        disabled={disable}
                      />
                      <label
                        class="form-check-label"
                        className={styles.marketVisitReport__radioLabel}
                        htmlFor="flexRadioDefault"
                      >
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        className={`form-check-input ${styles.myUserDetails__option}`}
                        type="radio"
                        name="darpanBillRadio"
                        id="flexRadioDefault3"
                        checked={
                          formData.darpanBillRadio === "no" ||
                          formData.darpanBillRadio === "No"
                        }
                        value="no"
                        onChange={(e) => onChange(e)}
                        disabled={disable}
                      />
                      <label
                        class="form-check-label"
                        className={styles.marketVisitReport__radioLabel}
                        htmlFor="flexRadioDefault3"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {!formData?.darpanBillRadio &&
                    formValidation?.darpanBillRadio && (
                      <p className={styles.MarketVisitReport__validationErrors}>
                        {formValidation?.darpanBillRadio}
                      </p>
                    )}
                </div>

                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Names of Dealers where Darpan bills unavailable
                  </label>
                  <textarea
                    name="darpanDealer"
                    value={formData?.darpanDealer}
                    rows="3"
                    className={` ${styles.MarketVisitRepor__textArea}`}
                    style={{ paddingLeft: "8px" }}
                    placeholder="Enter the Names separated by comma in case of multiple dealers"
                    onChange={(e) => onChange(e)}
                    disabled={disable}
                    maxLength={501}
                  />
                </div>
                {characterLimitValidation?.darpanDealer && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {characterLimitValidation?.darpanDealer}
                  </p>
                )}
                {!formData?.darpanDealer && formValidation?.darpanDealer && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {formValidation?.darpanDealer}
                  </p>
                )}
              </>
            )}
            {/* Sec B: Dealers */}

            <div className={`row m-0 ${styles.MarketVisitReport__headingBox}`}>
              <div className={`col-8 p-0 ${styles.MarketVisitReport__heading}`}>
                Dealers
              </div>
            </div>
            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Name(s) of Dealer(s) visited where any issue(s) reported
                {userType != "MH3" && userType != "RBDM Collaborator" && (
                  <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                )}
              </label>
              <input
                className={` ${styles.MarketVisitReport__selectField}`}
                style={{ paddingLeft: "8px" }}
                name="dealerVisited"
                value={
                  dealerName?.length ? dealerName : formData?.dealerVisited
                }
                placeholder="Enter key dealers visited today separated by comma "
                onChange={(e) => onChange(e)}
                disabled={disable}
                maxLength={256}
              />
            </div>
            {characterLimitValidation?.dealerVisited && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {characterLimitValidation?.dealerVisited}
              </p>
            )}
            {!formData?.dealerVisited && formValidation?.dealerVisited && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {formValidation?.dealerVisited}
              </p>
            )}

            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Key Observations/Issues faced by Dealer(s)
                {userType != "MH3" && userType != "RBDM Collaborator" && (
                  <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                )}
              </label>
              <MentionsInput
                className={styles.MarketVisitRepor__input}
                value={
                  _.isEmpty(formData?.issueFacedDealer)
                    ? ""
                    : formData?.issueFacedDealer
                }
                maxLength={2001}
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "issueFacedDealer",
                      value: e.target.value,
                    },
                  });
                }}
                style={defaultStyle}
                disabled={disable}
              >
                <Mention
                  trigger="@p"
                  data={productList}
                  style={defaultMentionStyle}
                />
                <Mention
                  trigger="@w"
                  data={wssList}
                  style={defaultMentionStyle}
                />
                <Mention
                  trigger="@d"
                  data={dealerList}
                  style={defaultMentionStyle}
                />
              </MentionsInput>
              <p className={styles.MarketVisitReport__selectAlertMsg}> *Use @d for dealer Selection</p>
            </div>
            {characterLimitValidation?.issueFacedDealer && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {characterLimitValidation?.issueFacedDealer}
              </p>
            )}
            {!formData?.issueFacedDealer &&
              formValidation?.issueFacedDealer && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {formValidation?.issueFacedDealer}
                </p>
              )}

            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Service/Delivery related feedback
                {userType != "MH3" && userType != "RBDM Collaborator" && (
                  <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                )}
              </label>
              <MentionsInput
                className={styles.MarketVisitRepor__input}
                value={
                  _.isEmpty(formData?.deliveryFeedback)
                    ? ""
                    : formData?.deliveryFeedback
                }
                maxLength={2001}
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "deliveryFeedback",
                      value: e.target.value,
                    },
                  });
                }}
                style={defaultStyle}
                disabled={disable}
              >
                <Mention
                  trigger="@w"
                  data={wssList}
                  style={defaultMentionStyle}
                />
                <Mention
                  trigger="@d"
                  data={dealerList}
                  style={defaultMentionStyle}
                />
              </MentionsInput>
            </div>
            {characterLimitValidation?.deliveryFeedback && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {characterLimitValidation?.deliveryFeedback}
              </p>
            )}
            {!formData?.deliveryFeedback &&
              formValidation?.deliveryFeedback && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {formValidation?.deliveryFeedback}
                </p>
              )}
            {userType != "MH3" && userType != "RBDM Collaborator" && (
              <>
                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Are all the schemes settled at the visited dealers by the
                    respective WSS?
                    {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
                  </label>
                  <div className={`mb-1 ${styles.myUserDetails__radioOption}`}>
                    <div class="form-check">
                      <input
                        className={`form-check-input ${styles.myUserDetails__option}`}
                        type="radio"
                        name="visitDealerRadio"
                        id="flexRadioDefault4"
                        checked={
                          formData.visitDealerRadio === "yes" ||
                          formData.visitDealerRadio === "Yes"
                        }
                        value="yes"
                        onChange={(e) => onChange(e)}
                        disabled={disable}
                      />
                      <label
                        class="form-check-label"
                        className={styles.marketVisitReport__radioLabel}
                        htmlFor="flexRadioDefault4"
                      >
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        className={`form-check-input ${styles.myUserDetails__option}`}
                        type="radio"
                        name="visitDealerRadio"
                        id="flexRadioDefault5"
                        checked={
                          formData.visitDealerRadio === "no" ||
                          formData.visitDealerRadio === "No"
                        }
                        value="no"
                        onChange={(e) => onChange(e)}
                        disabled={disable}
                      />
                      <label
                        class="form-check-label"
                        className={styles.marketVisitReport__radioLabel}
                        htmlFor="flexRadioDefault5"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {/* {!formData?.visitDealerRadio &&
                    formValidation?.visitDealerRadio && (
                      <p className={styles.MarketVisitReport__validationErrors}>
                        {formValidation?.visitDealerRadio}
                      </p>
                    )} */}
                </div>

                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Scheme settlement related comments
                    <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                  </label>
                  <MentionsInput
                    className={styles.MarketVisitRepor__input}
                    value={
                      _.isEmpty(formData?.schemeComments)
                        ? ""
                        : formData?.schemeComments
                    }
                    maxLength={501}
                    onChange={(e) => {
                      onChange({
                        target: {
                          name: "schemeComments",
                          value: e.target.value,
                        },
                      });
                    }}
                    style={defaultStyle}
                    disabled={disable}
                  >
                    <Mention
                      trigger="@p"
                      data={productList}
                      style={defaultMentionStyle}
                    />
                    <Mention
                      trigger="@w"
                      data={wssList}
                      style={defaultMentionStyle}
                    />
                    <Mention
                      trigger="@d"
                      data={dealerList}
                      style={defaultMentionStyle}
                    />
                  </MentionsInput>
                </div>
                {characterLimitValidation?.schemeComments && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {characterLimitValidation?.schemeComments}
                  </p>
                )}
                {!formData?.schemeComments &&
                  formValidation?.schemeComments && (
                    <p className={styles.MarketVisitReport__validationErrors}>
                      {formValidation?.schemeComments}
                    </p>
                  )}
              </>
            )}
            {/* Sec C: Users */}
            {userType != "MH3" && userType != "RBDM Collaborator" && (
              <>
                <div
                  className={`row m-0 ${styles.MarketVisitReport__headingBox}`}
                >
                  <div
                    className={`col-8 p-0 ${styles.MarketVisitReport__heading}`}
                  >
                    Users
                  </div>
                </div>
                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Name(s) of key User(s) visited where any issue(s) reported
                  </label>
                  <input
                    className={` ${styles.MarketVisitReport__selectField}`}
                    style={{ paddingLeft: "8px" }}
                    name="userVisited"
                    value={userName?.length ? userName : formData?.userVisited}
                    onChange={(e) => onChange(e)}
                    maxLength={256}
                    disabled={disable}
                  />
                </div>
                {characterLimitValidation?.userVisited && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {characterLimitValidation?.userVisited}
                  </p>
                )}

                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Key Observations/Issues faced by User(s)
                  </label>
                  <MentionsInput
                    className={styles.MarketVisitRepor__input}
                    value={
                      _.isEmpty(formData?.keyObsUser)
                        ? ""
                        : formData?.keyObsUser
                    }
                    maxLength={2001}
                    onChange={(e) => {
                      onChange({
                        target: {
                          name: "keyObsUser",
                          value: e.target.value,
                        },
                      });
                    }}
                    style={defaultStyle}
                    disabled={disable}
                  >
                    <Mention
                      trigger="@p"
                      data={productList}
                      style={defaultMentionStyle}
                    />
                    <Mention
                      trigger="@w"
                      data={wssList}
                      style={defaultMentionStyle}
                    />
                    <Mention
                      trigger="@d"
                      data={dealerList}
                      style={defaultMentionStyle}
                    />
                  </MentionsInput>
                </div>
                {characterLimitValidation?.keyObsUser && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {characterLimitValidation?.keyObsUser}
                  </p>
                )}

                <div className={styles.MarketVisitReport__selectableRows}>
                  <label className={styles.MarketVisitReport__selectLabel}>
                    Mention the issues faced by them while banking/redeeming:
                  </label>
                  <textarea
                    name="issuesFacedByBank"
                    value={formData.issuesFacedByBank}
                    rows="3"
                    className={` ${styles.MarketVisitRepor__textArea}`}
                    style={{ paddingLeft: "8px" }}
                    onChange={(e) => onChange(e)}
                    disabled={disable}
                    maxLength={2001}
                  />
                </div>
                {characterLimitValidation?.issuesFacedByBank && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {characterLimitValidation?.issuesFacedByBank}
                  </p>
                )}
              </>
            )}
            {/* Sec D: Product */}

            <div className={`row m-0 ${styles.MarketVisitReport__headingBox}`}>
              <div className={`col-8 p-0 ${styles.MarketVisitReport__heading}`}>
                Product Complaints
              </div>
            </div>
            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Complaints - Product Quality/Packaging Related Complaints:
                {/* {userType != "MH3" && userType != "RBDM Collaborator" && <span style={{ color: "red", fontSize: "1rem" }}>*</span>} */}
              </label>
              <MentionsInput
                className={styles.MarketVisitRepor__input}
                value={
                  _.isEmpty(formData?.productQuality)
                    ? ""
                    : formData?.productQuality
                }
                maxLength={2001}
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "productQuality",
                      value: e.target.value,
                    },
                  });
                }}
                style={defaultStyle}
                disabled={disable}
              >
                <Mention
                  trigger="@p"
                  data={productList}
                  style={defaultMentionStyle}
                />
              </MentionsInput>
              <p className={styles.MarketVisitReport__selectAlertMsg}> *Use @p for product Selection</p>
              {!formData?.productQuality && formValidation?.productQuality && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {formValidation?.productQuality}
                </p>
              )}
            </div>
            {characterLimitValidation?.productQuality && (
              <p className={styles.MarketVisitReport__validationErrors}>
                {characterLimitValidation?.productQuality}
              </p>
            )}

            <div className={`row m-0 ${styles.MarketVisitReport__headingBox}`}>
              <div
                className={`col-12 p-0 mt-2 ${styles.MarketVisitReport__heading}`}
              >
                Other Product Related Feedbacks
              </div>
            </div>
            <div className={styles.MarketVisitReport__selectableRows}>
              <div className={styles.MarketVisitReport__selectableRows}>
                <label className={styles.MarketVisitReport__selectLabel}>
                  New opportunity - New Products/Application related
                  opportunities identified:
                  {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
                </label>
                <textarea
                  name="newProductOpportunity"
                  value={formData?.newProductOpportunity}
                  rows="3"
                  className={` ${styles.MarketVisitRepor__textArea}`}
                  style={{ paddingLeft: "8px" }}
                  onChange={(e) => onChange(e)}
                  disabled={disable}
                  maxLength={501}
                />
                {characterLimitValidation?.newProductOpportunity && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {characterLimitValidation?.newProductOpportunity}
                  </p>
                )}
                {!formData?.newProductOpportunity &&
                  formValidation?.newProductOpportunity && (
                    <p className={styles.MarketVisitReport__validationErrors}>
                      {formValidation?.newProductOpportunity}
                    </p>
                  )}
              </div>

              <div className={styles.MarketVisitReport__selectableRows}>
                <label className={styles.MarketVisitReport__selectLabel}>
                  Any other Feedback/Insights:
                  {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
                </label>
                <textarea
                  name="productOtherFeedback"
                  value={formData?.productOtherFeedback}
                  rows="3"
                  className={` ${styles.MarketVisitRepor__textArea}`}
                  style={{ paddingLeft: "8px" }}
                  onChange={(e) => onChange(e)}
                  disabled={disable}
                  maxLength={501}
                />
                {characterLimitValidation?.productOtherFeedback && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {characterLimitValidation?.productOtherFeedback}
                  </p>
                )}
                {!formData?.productOtherFeedback &&
                  formValidation?.productOtherFeedback && (
                    <p className={styles.MarketVisitReport__validationErrors}>
                      {formValidation?.productOtherFeedback}
                    </p>
                  )}
              </div>
            </div>

            {/* Sec E: Competition */}

            <div className={`row m-0 ${styles.MarketVisitReport__headingBox}`}>
              <div className={`col-8 p-0 ${styles.MarketVisitReport__heading}`}>
                Competition
              </div>
            </div>

            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Key Observations regarding competition activity in overall
                market visited:
                {userType != "MH3" && userType != "RBDM Collaborator" && (
                  <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                )}
              </label>
              <MentionsInput
                className={styles.MarketVisitRepor__input}
                value={
                  _.isEmpty(formData?.competitionActivity)
                    ? ""
                    : formData?.competitionActivity
                }
                maxLength={2001}
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "competitionActivity",
                      value: e.target.value,
                    },
                  });
                }}
                style={defaultStyle}
                disabled={disable}
              >
                <Mention
                  trigger="@p"
                  data={productList}
                  style={defaultMentionStyle}
                />
              </MentionsInput>

              {characterLimitValidation?.competitionActivity && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {characterLimitValidation?.competitionActivity}
                </p>
              )}
              {!formData?.competitionActivity &&
                formValidation?.competitionActivity && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {formValidation?.competitionActivity}
                  </p>
                )}
            </div>

            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Key Competition products which are affecting our sales:
                {userType != "MH3" && userType != "RBDM Collaborator" && (
                  <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                )}
              </label>
              <MentionsInput
                className={styles.MarketVisitRepor__input}
                value={
                  _.isEmpty(formData?.competitionProducts)
                    ? ""
                    : formData?.competitionProducts
                }
                maxLength={501}
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "competitionProducts",
                      value: e.target.value,
                    },
                  });
                }}
                style={defaultStyle}
                disabled={disable}
              >
                <Mention
                  trigger="@p"
                  data={productList}
                  style={defaultMentionStyle}
                />
              </MentionsInput>
              {characterLimitValidation?.competitionProducts && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {characterLimitValidation?.competitionProducts}
                </p>
              )}
              {!formData?.competitionProducts &&
                formValidation?.competitionProducts && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {formValidation?.competitionProducts}
                  </p>
                )}
            </div>

            {/* Sec F: Escalations/Immediate Support Required */}
            <div className={`row m-0 ${styles.MarketVisitReport__headingBox}`}>
              <div
                className={`col-12 p-0 ${styles.MarketVisitReport__heading}`}
              >
                Escalations/Immediate Support Required
              </div>
            </div>
            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Local Issues: Issues which are specific to a TTY/WSS/Dealer/TSI- Auto SR will be raised.
              </label>

              {rows.map((row, index) => (
                <div>
                  <p className="d-none">{row.srId}</p>
                  {index != 0 && disable != true && 
                    <HiOutlineX style={{ color: "#ef5464" }} className={styles.MarketVisitReport__removeButton} onClick={() => removeRow(index)} />
                  }
                  <select
                    value={row.assigned_to}
                    className={styles.MarketVisitReport__selection}
                    onChange={(e) => handleInputChange(index, "assigned_to", e.target.value)}
                    disabled={disable}
                  >
                    <option className={styles.MarketVisitReport__selectOptions} value="">Escalate To:  (Select From dropdown)</option>
                    {staticOptions.map((option, i) => (
                      <option key={i} className={styles.MarketVisitReport__selectOptions} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {formValidation[`rows.${index}.assigned_to`] && (
                    <p className={`mb-2 ${styles.MarketVisitReport__validationErrors}`}>
                      {formValidation[`rows.${index}.assigned_to`]}
                    </p>
                  )}

                  <p className={styles.MarketVisitReport__selectAlertMsg}> *Min 20 characters input needed for service request creation </p>
                  <MentionsInput
                    className={styles.MarketVisitRepor__input}
                    value={_.isEmpty(row.issue) ? "" : row.issue} // Update this for each row
                    maxLength={2001}
                    onChange={(e) => handleInputChange(index, "issue", e.target.value)} // Update on change
                    style={defaultStyle}
                    disabled={disable}
                  >
                    <Mention
                      trigger="@p"
                      data={productList}
                      style={defaultMentionStyle}
                    />
                    <Mention
                      trigger="@w"
                      data={wssList}
                      style={defaultMentionStyle}
                    />
                    <Mention
                      trigger="@d"
                      data={dealerList}
                      style={defaultMentionStyle}
                    />
                  </MentionsInput>
                  {characterLimitValidation?.urgentEscalation && (
                    <p className={styles.MarketVisitReport__validationErrors}>
                      {characterLimitValidation?.urgentEscalation}
                    </p>
                  )}

                  <div className={"row m-0"}>
                    <div className="col-5 d-flex p-0">
                      {index === rows.length - 1 && rows.length < maxLimit && disable != true && (
                        <>
                        <button className={`${styles.MarketVisitReport__addMore}`} onClick={addRow}> + Add more escalation</button>
                        </>
                      )}
                    </div>
                    <div className="col-3 p-0">
                      {row.files.length > 0 && (
                        <div className={`row ${styles.MarketVisitReport_selectedFiles}`}>
                          {row.files.length === 1
                            ? `File: ${row.files[0].fileName}`
                            : `Selected Files: ${row.files.length}`}
                        </div>
                      )}
                    </div>

                    <div className="col-4 p-0">
                      {disable != true &&
                        <div className={`${styles.MarketVisitReport__inputWrapper}`}>
                          <MdOutlineAttachment
                            style={{
                              height: "1.5em",
                              width: "1.5em",
                              color: "#2cc26a",
                              marginRight: "5px",
                            }}
                          />
                          <label className={styles.MarketVisitReport_fileInputLabel}>
                            Attach File
                            <input
                              className={`${styles.MarketVisitReport_fileInput}`}
                              type="file"
                              accept=".jpg,.png,.pdf, .jpeg"
                              multiple
                              key={formData.marketVisitDate} 
                              onChange={(e) => handleFileChange(index, e)}
                              disabled={disable}
                            />
                          </label>
                        </div>
                      }
                    </div>
                  </div>
                  {row.validationError?.file && (
                    <p className={styles.MarketVisitReport__validationErrors}>
                      {row.validationError.file}
                    </p>
                  )}
                </div>
              ))}
              {/* Limit Reached Message */}
              {/* {rows.length >= maxLimit && (
                <p className={`mt-1 ${styles.MarketVisitReport__validationErrors}`}>You have reached the maximum limit of {maxLimit} entries.</p>
              )} */}
            </div>
            
            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Large Improvement Areas: Suggestions which might require creation of a new policy or change in existing ones
                {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
              </label>
              <textarea
                name="largeImproveAreas"
                value={formData?.largeImproveAreas}
                rows="3"
                className={` ${styles.MarketVisitRepor__textArea}`}
                style={{ paddingLeft: "8px" }}
                onChange={(e) => onChange(e)}
                disabled={disable}
                maxLength={501}
              />
              {characterLimitValidation?.largeImproveAreas && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {characterLimitValidation?.largeImproveAreas}
                </p>
              )}
              {!formData?.largeImproveAreas &&
                formValidation?.largeImproveAreas && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {formValidation?.largeImproveAreas}
                  </p>
                )}
            </div>

            <div className={`row m-0 ${styles.MarketVisitReport__headingBox}`}>
              <div className={`col-12 p-0 ${styles.MarketVisitReport__heading}`}>
                Miscellaneous Section
              </div>
            </div>
            <div className={styles.MarketVisitReport__selectableRows}>
              <label className={styles.MarketVisitReport__selectLabel}>
                Observations not falling under any of the above sections
                {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
              </label>
              <textarea
                name="miscellaneousSection"
                value={formData?.miscellaneousSection}
                rows="3"
                className={` ${styles.MarketVisitRepor__textArea}`}
                style={{ paddingLeft: "8px" }}
                onChange={(e) => onChange(e)}
                disabled={disable}
                maxLength={501}
              />
              {characterLimitValidation?.miscellaneousSection && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {characterLimitValidation?.miscellaneousSection}
                </p>
              )}  
              {!formData?.miscellaneousSection &&
                formValidation?.miscellaneousSection && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {formValidation?.miscellaneousSection}
                  </p>
                )}
            </div>
            
            
          </div>
{/* 
          {versionData.length > 0 ? null : (
            <>
              <div className={"flex p-2"}>
                <div className={`${styles.MarketVisitReport__inputWrapper}`}>
                  <MdOutlineAttachment
                    style={{
                      height: "1.5em",
                      width: "1.5em",
                      color: "#2cc26a",
                      marginRight: "5px",
                    }}
                  />
                  Attach File
                  <label className={styles.MarketVisitReport_fileInputLabel}>
                    <input
                      className={`${styles.MarketVisitReport_fileInput}`}
                      type="file"
                      accept=".jpg,.png,.pdf, .jpeg"
                      multiple
                      onChange={(e) => handleFileChange(e)}
                    />
                  </label>
                </div>
                {selectedFiles?.length > 0 && (
                  <div className={styles.MarketVisitReport_selectedFiles}>
                    {selectedFiles?.length === 1
                      ? `File: ${selectedFiles[0].name}`
                      : `Selected Files: ${selectedFiles.length}`}
                  </div>
                )}

                {fileValidation && (
                  <p className={styles.MarketVisitReport__validationErrors}>
                    {fileValidation}
                  </p>
                )}
              </div>
              <MarketVisitTabs />
            </>
          )} */}
          <div className="d-flex">
            {isButtonDisable == false ?
            <button
              type="button"
              className={`btn mt-3 ${styles.MarketVisitReport__EditButton}`}
              onClick={HandleSaveAsDraft}
              disabled={isButtonDisable}
            >
              Save As draft
            </button> : <></>
            }
            <button
              type="button"
              className={`btn mt-3 ${styles.MarketVisitReport__SubmitButton}`}
              disabled={isButtonDisable}
              onClick={(e) => validateInput(e)}
            >
              Submit <FiArrowRight style={{ marginLeft: "2px" }} />
            </button>
          </div>
          {isSubmitModalOpen && (
            <ModalPopup
              className="ModalPopup_Submitted"
              isModalOpen={isSubmitModalOpen}
              setIsModalOpen={setIsSubmitModalOpen}
              title="Submitted Successfully"
              data="Your market visit report form has been submitted successfully."
              backdrop={true}
              fade={false}
              icon={
                <>
                  <div className="submittedIcons draftIcons">
                    <FaThumbsUp className="submittedSmall" />
                    <FaThumbsUp className="submittedLarge" />
                    <FaThumbsUp className="submittedSmall" />
                  </div>
                </>
              }
            />
          )}
          {isDraftModalOpen && (
            <ModalPopup
              className="ModalPopup_Submitted"
              isModalOpen={isDraftModalOpen}
              setIsModalOpen={setIsDraftModalOpen}
              title="Saved as Draft"
              data="Your market visit report form has been saved successfully."
              backdrop={true}
              fade={false}
              icon={
                <>
                  <div className="submittedIcons draftIcons">
                    <RiDraftFill className="draftSmall" />
                    <RiDraftFill className="draftLarge" />
                    <RiDraftFill className="draftSmall" />
                  </div>
                </>
              }
            />
          )}
          {isErrorModalOpen && (
            <ModalPopup
              className="ModalPopup_Submitted"
              isModalOpen={isErrorModalOpen}
              setIsModalOpen={setIsErrorModalOpen}
              title="Failed"
              data="Your market visit report form has been not saved As Draft successfully, Please try Again."
              backdrop={true}
              fade={false}
              icon={
                <>
                  <div className="submittedIcons errorIcons">
                    <MdOutlineSmsFailed className="errorSmall" />
                    <MdOutlineSmsFailed className="errorLarge" />
                    <MdOutlineSmsFailed className="errorSmall" />
                  </div>
                </>
              }
            />
          )}
        </form>

        {versionData.length > 0 ? (
          <>
            <p className={styles.MarketVisitReport__FileLabel}>
              Attached Files
            </p>
            <div>
              {versionData?.map((item) => {
                return (
                  <>
                    <button
                      onClick={() => {
                        getFileName(
                          item?.ContentDocumentId,
                          item?.Title,
                          item.VersionData
                        );
                      }}
                      className={styles.MarketVisitReport__downloadImage}
                    >
                      <p
                        style={{
                          fontSize: "9px",
                          margin: "0px",
                          color: "#808080",
                        }}
                      >
                        Download
                      </p>
                      <MdFileDownload
                        style={{
                          height: "2.1em",
                          width: "2.1em",
                          color: "#3901FF",
                        }}
                      />
                      {item?.Title}
                    </button>
                  </>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default connect(null, {
  getZSMTerritoryApi,
  addNewTourWssApi,
  getMVRDealerNameApi,
  getMVRDealerNameForHOApi,
  getZSMWssNameApi,
  addMarketVisitReportApi,
  getMVRDraftDetails,
  getMarketVisitReportApi,
  removeMVRDraftDetails,
  addMySrApi,
  addMarketVisitReportFileApi,
  getMvrAttachedFile,
  getAttachedFileUrl,
  updateMarketVisitReportApi,
  deleteSaveAsDraftForMVRApi,
  addEscalationServiceRequestApi
})(MarketVisitReportTemp);
