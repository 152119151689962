import axios from "axios";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getDealerPhysicalVerificationDetailsApi = async ( physicalverificationdate, physicalverificationstatus, physicalverifiedby, remarks, tsicode, dealercode ) => {
    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };
    const nodeBaseUrl = getNodeServerUrl();

    const options = {
        url: `${nodeBaseUrl}/dealer/updateDealerPhysicalVerificationDetails`,
        method: "patch",
        headers: headers,
        data: { physicalverificationdate, physicalverificationstatus, physicalverifiedby, remarks, tsicode, dealercode }
    };

    try {
        const res = await axios(options);
        return res;
    } catch (error) {
        const errors = error?.response?.data?.error?.msg;
        if (errors) {
            return { error: error?.response?.data?.error?.msg };
        } else {
            return { error: "Something Went Wrong" };
        }
    }
};
