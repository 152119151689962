import React, { useState, useEffect } from "react";
import "./PbiDashboard.css";
import { BsChevronDown } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import PowerBi from "../../Pages/PowerBi";
import _ from "lodash";
import Message from "../Common/Message";

const PbiDashboard = ({ showDropdown, showDealer = false }) => {
  const [selectedValue, setSelectedValue] = useState("Team");
  const [id, setId] = useState(0);
  const [pageName, setPageName] = useState();
  const data = [
    {
      dropdownItems: "Team",
      id: 0,
    },
    {
      dropdownItems: "WSS",
      id: 1,
    },
    {
      dropdownItems: "Dealer",
      id: 2,
    },
    // {
    //   dropdownItems: "Exception Deal",
    //   id: 1,
    // },
    // {
    //   dropdownItems: "User",
    //   id: 2,
    // },

    // {
    //   dropdownItems: "Diary",
    //   id: 4,
    // },
    // {
    //   dropdownItems: "Product",
    //   id: 5,
    // },

    // {
    //   dropdownItems: "Tour Report",
    //   id: 7,
    // },
  ];

  const onChangeHandler = (e) => {
    setSelectedValue(e.target.value);
  };

  //to set pageNumber value
  useEffect(() => {
    switch (selectedValue) {
      case "Team":
        setPageName("ReportSection5503edccfcd9c5405c5c");
        break;
      case "Exception Deal":
        setPageName("0120l000001GzOEAA0");
        break;
      case "WSS":
        setPageName("ReportSectionaff09e82ef2ddea135bb");
        break;
      case "User":
        setPageName("0120l000001GzO4AAK");
        break;
      case "Diary":
        setPageName("0120l000001GzO4AAK");
        break;
      case "Product":
        setPageName("0120l000001GzOEAA0");
        break;
      case "Dealer":
        setPageName("ReportSection");
        break;
      case "Tour Report":
        setPageName("0120l000001GzOEAA0");
        break;
    }
  }, [selectedValue]);

  return (
    <div className="pbiDashboard">
      <div className="pbiDashboard__headerBox">
        <div className="pbiDashboard__headingTitle">PBI Dashboard</div>

        {showDropdown && (
          <Dropdown className="pbiDashboard__dropdownBox">
            <Dropdown.Toggle id="dropdown-basic" className="pbiDashboard__drop">
              <span className="pbiDashboard__selectedValue">
                {selectedValue}
              </span>
              <BsChevronDown style={{ marginLeft: "6px" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="pbiDashboard__dropdownMenu"
              style={{ minWidth: "5rem" }}
            >
              <div className="pbiDashboard__inputradioBox">
                {data.map((item, index) => {
                  return (
                    <>
                      <div>
                        <Dropdown.Item
                          className="pbiDashboard__dropdownItem"
                          href="#/action-1"
                        >
                          <Form.Check
                            type="radio"
                            name="group"
                            aria-label="radio 1"
                            className="pbiDashboard_inputRadioButton"
                            checked={
                              selectedValue && index === id ? true : false
                            }
                            value={item?.dropdownItems}
                            id={index + 1}
                            onChange={(e) => {
                              setSelectedValue(e.target.value);
                              setId(item?.id);
                            }}
                          />
                          <label for={index + 1}>{item?.dropdownItems}</label>
                        </Dropdown.Item>
                      </div>
                    </>
                  );
                })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {/* <div className="visitDetails__mainBox">
        <Message message={"Coming Soon"} className="visitDetails__OuterDiv" />
      </div> */}

      <div className="pbiDashboard__box">
        {showDealer ? (
          <PowerBi
            id="d4345e64-7c35-46f7-b844-8f2f5443dd2d"
            pageName="ReportSection"
          />
        ) : (
          <PowerBi
            id="d4345e64-7c35-46f7-b844-8f2f5443dd2d"
            pageName={pageName}
          />
        )}
      </div>
    </div>
  );
};

export default PbiDashboard;
