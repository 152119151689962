import { getUniqueListBy } from "../../Components/Common/Function";
import { getRBDMCodeApi } from "../../services/MarketVisitReport/getRBDMCodeApi";
import {
  GET_RBDM_CODE_SUCCESS,
  GET_RBDM_CODE_FAIL,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

export const getRBDMCode = (Email) => async (dispatch) => {
  const response = await getRBDMCodeApi(Email);
  ;
  if (response?.status === 200) {
    dispatch({
      type: GET_RBDM_CODE_SUCCESS,
      payload: getUniqueListBy(
        response?.data?.records,
        "SH3_Code__c"
      ),
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_RBDM_CODE_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};