import _ from "lodash";
import { getUserTypeApi } from "../../services/getUserTypeApi";
import {
    GET_USER_TYPE_FAIL,
    GET_USER_TYPE_SUCCESS,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

// export const getUserType = (emailID) => async (dispatch) => {
//     const response = await getUserTypeApi(emailID);
//     if (response?.status === 200) {
//         dispatch({
//             type: GET_USER_TYPE_SUCCESS,
//             payload: response?.data,
//         });
//     } else if (response?.error === "ERR_NETWORK") {
//         sessionExpireTimeout(dispatch);
//     } else {
//         dispatch({
//             type: GET_USER_TYPE_FAIL,
//         });
//         dispatch(setAlert(response?.error, "error"));
//     }
// };

export const getUserType = (dispatch, response) => {
    var test = "";
    if (response?.data?.records?.length > 0) {
        const data = response?.data?.records?.[0]?.HO_Role__c;
        if (data === null){
            test = "Other"
        }else{
            test = data;
        }
    } else {
        test = "Other"
    }

    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_USER_TYPE_SUCCESS,
            payload: test,
        });

        resolve([test]);
    });
};