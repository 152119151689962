import axios from "axios";

import { BASE_URL } from "../../../variables";

export const getProductNameApi = async (divisionGroup, salesGroup) => {
  const options = {
    url:
      salesGroup == 901 || salesGroup == 902
        ? `${BASE_URL}/services/data/v56.0/query?q=SELECT id, Product_Group_3_Description__c,Division_Code__c,Sales_Group__c  FROM Pidilite_Hierarchy__c WHERE RecordType.Name='Material Master' AND Division_Code__c ='${divisionGroup}'  AND Product_Group_3_Description__c!=null ORDER BY Product_Group_3_Description__c`
        : `${BASE_URL}/services/data/v56.0/query?q=SELECT id, Product_Group_3_Description__c,Division_Code__c,Sales_Group__c FROM Pidilite_Hierarchy__c WHERE RecordType.Name='Material Master' AND Division_Code__c ='${divisionGroup}'   AND Sales_Group__c='${salesGroup}' AND Product_Group_3_Description__c!=null ORDER BY Product_Group_3_Description__c`,
    method: "get",
  };
  // try {
  //   const response = await axios(options);
  //   if (response.status === 200) {
  //     return {
  //       success: true,
  //       data: response?.data?.records,
  //     };
  //   } else {
  //     throw Error("Something Went Wrong..!!");
  //   }
  // } catch (err) {
  //   // if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_REQUEST") {
  //   //   sessionExpireTimeout(dispatch);
  //   // } else {
  //   return {
  //     success: false,
  //     msg: "Somthing Went Wrong..!!",
  //   };
  //   // }
  // }
  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
