import axios from "axios";
import { BASE_URL } from "../../variables";

export const getMonthlyTourPlanningApi = async () => {
  const options = {
    url: `${BASE_URL}/services/apexrest/BarGraph/`,
    method: "get",
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.[0].message;
    if (errors) {
      return { error: error?.response?.data?.[0].message };
    } else if (error?.code === "ERR_NETWORK") {
      return { error: error?.code };
    } else {
      return { error: "Something went wrong" };
    }
  }
};
