import React, { useEffect, useState } from "react";
import "./InsightsAndQueries.css";
import { getInsightsAndQueriesApi } from "../../services/MarketWorking/getInsightsAndQueriesApi";
import { connect } from "react-redux";
import Loader from "../../Components/Common/Loader";

const InsightsAndQueries = ({ getInsightsAndQueriesApi }) => {
  const [insightQueries, setInsightQueries] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getInsightsAndQueriesApi();
      setLoading(false);
      if (response?.success) {
        setInsightQueries(response?.data);
        setLoading(false);
      } else {
        setError(response?.msg);
      }
    })();
  }, []);

  return (
    <>
      <div className="insightsAndQueries__heading">Observations & Queries</div>
      <Loader loading={loading} />
      <div className="insightsAndQueries">
        <div className="insightsAndQueries__box">
          <table responsive className="insightsAndQueries__table">
            <thead style={{ position: "sticky", top: "-11px" }}>
              <tr className="insightsAndQueries__theadRow">
                <th className="insightsAndQueries__theadRowHeading">
                  Feedback Type
                </th>
                <th className="insightsAndQueries__theadRowHeading">
                  Related To
                </th>
                <th className="insightsAndQueries__theadRowHeading">
                  Acc name/code
                </th>
                <th className="insightsAndQueries__theadRowHeading">Status</th>
                <th className="insightsAndQueries__theadRowHeading">
                  Age (days)
                </th>
                <th className="insightsAndQueries__theadRowHeading">
                  Case Owner
                </th>
              </tr>
            </thead>

            <tbody style={{ textAlign: "center" }}>
              {insightQueries &&
                Object?.keys(insightQueries)?.map((itemm) => {
                  return insightQueries[itemm]?.map((item) => {
                    return (
                      <>
                        <tr
                          id={item?.id}
                          className="insightsAndQueries__tbodyDataRow"
                        >
                          <td className="insightsAndQueries__tbodyTableData">
                            {item?.attributes?.type === "Case"
                              ? "SR"
                              : "Insights"}
                          </td>
                          <td className="insightsAndQueries__tbodyTableData">
                            {item?.RecordType?.Name}
                          </td>
                          <td className="insightsAndQueries__tbodyTableData">
                            {item?.Account?.Name == null ? (
                              <b>-</b>
                            ) : (
                              item?.Account?.Name
                            )}
                          </td>
                          <td className="insightsAndQueries__tbodyTableData">
                            {item?.Status}
                          </td>
                          <td className="insightsAndQueries__tbodyTableData">
                            {item?.Age == null ? <b>-</b> : item?.Age}
                          </td>
                          <td className="insightsAndQueries__tbodyTableData">
                            {item?.Owner?.Name}
                          </td>
                        </tr>
                      </>
                    );
                  });
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getInsightsAndQueriesApi,
})(InsightsAndQueries);
