import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import {
  getCurrentDate,
  getOneDayBeforeDate,
} from "../../Components/Common/Function";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getWssAutoVAlueFtdApi = async (SH_Code__c, Title) => {
  const currentDate = getCurrentDate();
  // const currentDate = getOneDayBeforeDate();

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/wss/get-wss-auto-value-ftd`,
    method: "post",
    headers: headers,
    data: { currentDate, SH_Code__c, Title },
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
