import React from "react";
import styles from "./MarketWorking.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { marketWorkingLabel } from "../Common/Constant";

const MarketWorking = () => {
  const navigate = useNavigate();

  const marketWorkingCount = useSelector(
    (state) => state?.marketWorking?.marketWorkingDetails
  );

  // on click of Count (Number)
  const onClickCountHandler = (item) => {
    if (item === "InsightToday" || item === "InsightMonth") {
      navigate("/my-diary");
    } else if (item?.heading === "Insight") {
      navigate("/my-diary");
    } else {
      navigate("/queries");
    }
  };

  return (
    <>
      <div className={styles.marketWorking}>
        <div className={`${styles.marketWorking__header} row`}>
          <div className={`col-6 ${styles.marketWorking__title}`}>
            Market Working
          </div>
          <div className={`col-6 ${styles.marketWorking__label}`}>
            <span className={styles.marketWorking__activeButton}></span>
            <span className={styles.marketWorking__labelData}>
              Today's Visit Planned
            </span>
          </div>
        </div>
        <div className={`row ${styles.marketWorking__dataBox}`}>
          <div className={`row ${styles.marketWorking__data}`}>
            <>
              {marketWorkingCount &&
                Object?.keys(marketWorkingCount)?.map((item) => {
                  return marketWorkingCount[item]?.map((itemm) => {
                    return (
                      <div
                        onClick={() => onClickCountHandler(item)}
                        className={`col-3 ${styles.marketWorking__dataNumber}`}
                      >
                        {itemm.expr0}
                      </div>
                    );
                  });
                })}
              {marketWorkingLabel?.map((items) => (
                <>
                  <div
                    className="col-3"
                    onClick={() => onClickCountHandler(items)}
                  >
                    <div className={styles.marketWorking__dataHeading}>
                      {items.heading}
                    </div>
                    <div className={styles.marketWorking__dataBottomHeading}>
                      {items.heading_second}
                    </div>
                  </div>
                </>
              ))}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketWorking;
