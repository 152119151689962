import axios from "axios";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";

export const getSalesGroupCodeApi = ({salesGroupCode}) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=SELECT SH2_Employee_Name__c,SH2_Name__c,SH3_Name__c,SH2_Phone__c,SH2_Code__c,SH2_Head_Quarter__c,SH3_Code__c,SH5_Code__c,SH5_Name__c,SH6_Code__c,SH6_Name__c,Sales_Group__c FROM Pidilite_Hierarchy__c WHERE RecordType.Name ='Sales Hierarchy' and SH6_Name__c != null AND Sales_Group__c ='${salesGroupCode}'`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err.code === "ERR_NETWORK") {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
