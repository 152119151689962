import React from 'react';
import { Link } from 'react-router-dom';
import './DRM_Summary.css';
import { FaPlusCircle } from "react-icons/fa";
import { useState, useEffect} from "react";
import ModalPopup from "../Common/ModalPopup"
import Message from "../Common/Message";
import Loader from '../Common/Loader';
import CommonSnackbar from '../Common/CommonSnackbar';
import { getRejectFeedback } from '../../services/DRM/getRejectFeedback';
import { getMeetingDetails } from '../../services/DRM/getMeetingDetails';
import { format, parseISO ,parse} from 'date-fns';


const DRMSummary = ({meetingsummary,noMeeting, loading,feedbackloading,setfeedbackLoading,setLoading,failedOpen,setFailesOpen,handleOnClose,snackerror,setSnackError}) => {
    const [show, setShow] = useState(false);
    
    const [feedback, setFeedback] = useState([])
    const [zsmfeedback, setZSMFeedback] = useState([])
    const meetings = [
        { id: '0001', date: '01-05-2024', status: 'Approved' },
        { id: '0002', date: '05-05-2024', status: 'Rejected' }
    ];
  const sortedMeetingsummary = [...meetingsummary].sort((a, b) => new Date(b.Actual_Date_of_the_Meet__c) - new Date(a.Actual_Date_of_the_Meet__c));

  const handleClose = () => setShow(false);

  const handleShow = async(index) => {
    try {
      console.log("inside handle",index)
    
    const currentmeetingid = sortedMeetingsummary[index]?.Id;
    console.log("currentmeetingid-->",currentmeetingid)
    setfeedbackLoading(true)
    const response = await getRejectFeedback({currentmeetingid});

    console.log("getRejectFeedback abc",response)
    if(response?.success)
      {
        /////// format 2024-06-20  to 20 jun /////////////
    // const formatDateToDayMonth = (dateStr) => {
    //   const date = parseISO(dateStr);
    //   return format(date, 'dd MMM');

    // }
    const formatDateToDayMonth = (dateStr) => {
      const date = parse(dateStr, 'd/M/yyyy', new Date());
      return format(date, 'dd MMM');
    };

    const testResponse = [{
      Id: "a0yIn000000L5NjIAK",
      NSM : {
                NSM_Rejected_Date__c: "2024-07-23",
                NSM_Feedback_Id__c: null,
                NSM_Feedback__c: "Test Reject NSM:  a0yIn000000L5NjIAK",
                NSM_Status__c: "Rejected",
             
               },
      ZSM : {
                ZSM_Rejected_Date__c: "2024-07-23",
                ZSM_Feedback_Id__c: null,
                ZSM_Feedback__c: "ZSM Reject test 2:  a0yIn000000L5NjIAK",
                ZSM_Status__c: "Approved",
 
               }
 }, {
  Id: "a0yIn000000L5NjIAK",
  NSM : {
            NSM_Rejected_Date__c: "2024-07-23",
            NSM_Feedback_Id__c: null,
            NSM_Feedback__c: "Test Reject NSM:  a0yIn000000L5NjIAK",
            NSM_Status__c: "Rejected",
         
           },
  ZSM : {
            ZSM_Rejected_Date__c: "2024-07-23",
            ZSM_Feedback_Id__c: null,
            ZSM_Feedback__c: "ZSM Reject test 2:  a0yIn000000L5NjIAK",
            ZSM_Status__c: "Approved",

           }
}]

console.log("inside response success",response?.data)
const formattedData = response?.data?.map(item => ({
  ...item,
  NSM: {
    ...item?.NSM,
    NSM_Rejected_Date__c: item?.NSM.NSM_Rejected_Date__c ? formatDateToDayMonth(item.NSM.NSM_Rejected_Date__c) : "",
  },
  ZSM: {
    ...item?.ZSM,
    ZSM_Rejected_Date__c: item?.ZSM.ZSM_Rejected_Date__c ? formatDateToDayMonth(item.ZSM.ZSM_Rejected_Date__c) : "",
  }
}));

    console.log("hello",formattedData)
    const NSMFeedback = [];
    const ZSMFeedback = [];
      formattedData?.forEach((data, idx) => {
        if (data?.NSM?.NSM_Feedback__c) {
          NSMFeedback.push({ key: data?.NSM?.NSM_Rejected_Date__c , value: data?.NSM?.NSM_Feedback__c });
        }
      });
      formattedData?.forEach((data, idx) => {
        if (data?.ZSM?.ZSM_Feedback__c) {
          ZSMFeedback.push({ key: data?.ZSM?.ZSM_Rejected_Date__c , value: data?.ZSM?.ZSM_Feedback__c });
        }
      });
    console.log("See NSMFeedback",NSMFeedback)  
    console.log("See ZSMFeedback",ZSMFeedback) 
    setFeedback(NSMFeedback);
    setZSMFeedback(ZSMFeedback)
    setShow(true);
    setfeedbackLoading(false)

      }
    else{
      console.log("iniside api",response)
      setSnackError(response?.msg)
      setfeedbackLoading(false)
      setFailesOpen(true)
    }  
    
     }
    catch (error) {
      console.error('Error fetching Feedback:', error);
      setfeedbackLoading(false)
    }
    
  };

  
  console.log("sorted meeting array",sortedMeetingsummary)
    return (
        <div className="drm-summary">
         <CommonSnackbar
        open={failedOpen}
        autoHideDuration={7000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"error"}
        message={ snackerror }
      /> 
        {/* <h2>DRM Summary</h2> */}
        <Loader loading={loading} />
        <Loader loading={feedbackloading} />
        {!loading && (sortedMeetingsummary?.length === 0 ? 
        (
          <Message
            message={"No DRM Planned Till Now. Click on the + icon to create new meeting"}
            className={"tourPlan__messageConatiner"}
          />
        ) : 
        (
          <table className="drm-table">
            <thead>
              <tr>
                <th>Meeting Code</th>
                <th>Meeting Date</th>
                <th>Status</th>
                <th>Feedback</th>
              </tr>
            </thead>
            <tbody className='summary_Drm'>
              {sortedMeetingsummary.map((meeting, index) => (
                <tr key={meeting.Id}>
                  <td className='meetingID'>
                    <Link to={`/DRMplanning?id=${meeting.Id}`}>{meeting?.Id}</Link>
                  </td>
                  <td className='DateOfMeet'>{meeting?.Actual_Date_of_the_Meet__c}</td>
                  {meeting?.ZSM_Status__c === "Pending" && meeting?.NSM_Status__c === "Pending" ? (<td className="status-pending">
                    <>Pending(ZSM)</> 
                  </td>) : (<td className={meeting?.NSM_Status__c === 'Rejected' ? 'status-rejected' : 'status-approved'}>
                    {meeting?.ZSM_Status__c == "Rejected" && meeting?.NSM_Status__c === "Pending" ? (<td className='status-rejected'>Rework(ZSM)</td>) : (<>
                      {meeting?.ZSM_Status__c == "Approved" && meeting?.NSM_Status__c === "Pending" ? (<td className="status-pending">
                        Pending(NSM)
                  </td>) : (<>
                        {meeting?.ZSM_Status__c == "Approved" && meeting?.NSM_Status__c === "Rejected" ? "Rework(NSM)" : (<td>{meeting?.NSM_Status__c == 'Completed' ? 'Completed' : 'Approved'}</td>) }
                      </>) }
                    </>)}
                  </td>)}
                  
                  <td>
                    {(meeting?.NSM_Status__c === 'Rejected' || meeting?.ZSM_Status__c === 'Rejected')  && (
                      <button className="feedback-button" onClick={() => handleShow(index)}>View</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
        <ModalPopup
          isModalOpen={show}
          setIsModalOpen={handleClose}
          ModalMainClass="UpcomigTourDetailsModal"
          backdrop={true}
          fade={false}
          data={ <>
          <div>
            <h6 style={{ textAlign: 'center', marginBottom: "5px" }}>ZSM Feedback</h6>
            {zsmfeedback.length > 0 ? (
              <div>
                {zsmfeedback.map((item, index) => (
                  <div className="feedbackWrapper" key={index}>
                    <strong className='col-3'>{item.key}</strong>: <p>{item.value}</p>
                  </div>
                ))}
          </div>
            ) : (<><p style={{ textAlign: 'center', marginBottom: "10px" }}>Currently No feedback </p></>)}
          </div>
          <div>
            <h6 style={{ textAlign: 'center', marginBottom: "5px" }}>NSM Feedback</h6>
            {feedback.length > 0 ? (
              <div>
                {feedback.map((item, index) => (
                  <div className="feedbackWrapper" key={index}>
                    <strong className='col-3'>{item.key}</strong>: <p>{item.value}</p>
                  </div>
                ))}
          </div>
            ) : (<p style={{ textAlign: 'center', marginBottom: "10px" }}>Currently No feedback </p>)}
          </div>
          </> 
          
          }
        />
        <div>
          {/* <Link to={`/DRMplanning`}><FaPlusCircle className="add-button" style={{ fontSize: '10px' }}/></Link> */}
          <Link to={`/DRMplanning`}><button class="plus-button"></button></Link>
        </div>
      </div>
    );
};

export default DRMSummary;
