import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
