import React, { useState, useEffect, useRef } from "react";
import PowerBi from "../../Pages/PowerBi";
import styles from "./MyUserDetails.module.css";
// import styles from "./MyTeam/MyDiary.module.css";
import _ from "lodash";
import { AiOutlineFile } from "react-icons/ai";
import { Button } from "react-bootstrap";
import ModalPopup from "../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
// import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import Loader from "../Common/Loader";
import Form from "react-bootstrap/Form";
import { getMyUserApi } from "../../services/MarketWorking/MyTeam/getMyUserApi";
import { useSelector } from "react-redux";
import { addUserDetailsApi } from "../../services/MarketWorking/MyTeam/addUserDetailsApi";
import { FiFile } from "react-icons/fi";
import { getUserNameApi } from "../../services/myUserForm/getUserNameApi";
import Autocomplete from "@mui/material/Autocomplete";
import { getUniqueListBy } from "../Common/Function";
import TextField from "@mui/material/TextField";

const territoryAutoCompletePlaceHolder = {
  SH2_Name__c: "",
  SH2_Code__c: "",
};

const MyUserDetails = ({ addUserDetailsApi }) => {
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [error, setError] = useState();
  const [id, setId] = useState();
  const [userDetails, setUserDetails] = useState({
    userName: "",
    mobileNumber: "",
    territory: "",
    category: "",
    subCategory: "",
    Observation: "",
    question1: "",
    question2: "",
  });
  const [file, setFile] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [otherFeedback, setOtherFeedback] = useState("");
  const [queSelect, setQueSelect] = useState("no");
  const [nameCheck, setNameCheck] = useState("");
  const [territoryValue, setTerritoryValue] = useState(territoryAutoCompletePlaceHolder);
  const formCategoryRef = useRef();
  const formSubCategoryRef = useRef();


  //to get territory list from redux
  const territoryOptions = useSelector(
    (state) => state?.territory?.territoryDetails
  );

  useEffect(() => {
    (async () => {
      const response = await getMyUserApi();
      if (response?.success) {
        setCategories(response?.data?.picklistFieldValues?.Category_es__c);
        setSubCategories(
          response?.data?.picklistFieldValues?.Sub_Category_es__c
        );
      } else {
        setError(response?.msg);
      }
    })();
  }, []);

  const onChange = async (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    if (e.target.name === "mobileNumber") {
      userDetails.mobileNumber = e.target.value;
      userDetails.userName = "";
      setUserDetails({ ...userDetails });
      if (e.target.value?.split("")?.length === 10) {
        const response = await getUserNameApi(e.target.value);
        setNameCheck(response?.data?.[0]?.name);
        userDetails.mobileNumber = e.target.value;
        if (response?.data?.[0]?.name === "Unknown") {
          userDetails.userName = "";
        } else {
          userDetails.userName = response?.data?.[0]?.name;
        }
        setUserDetails({ ...userDetails });
      }
    }
    if (e.target.name === "category") {
      setOtherFeedback(e.target.value?.split(":")[1]);
      setId(e.target.value?.split(":")[0]);
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.value?.split(":")[1],
      });
    }
    if (e.target.name === "subCategory") {
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.value?.split(":")[1],
      });
    }

    if (e.target.name === "question1") {
      setQueSelect(e?.target?.value);
    }
  };

  const handleFileRead = async (event) => {
    const file = event?.target?.files[0];
    const base64 = await convertBase64(file);
    setFile({
      base64: base64.replace("data:", "").replace(/^.+,/, ""),
      fileExtension: event.target.files[0]?.name?.split(".")[0],
      fileName: event.target.files[0]?.name,
    });
    event.target.value = "";
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setFormValidation(checkValidation(userDetails));
  };

  //validation check
  const checkValidation = (values) => {
    const errors = {};
    if (!values.userName) {
      errors.userName = "Please enter userName";
    }

    if (!values.mobileNumber) {
      errors.mobileNumber = "Please enter your mobile number";
    } else if (values.mobileNumber?.split("")?.length != 10) {
      errors.mobileNumber = "Please enter the valid mobile number ";
    }
    if (!values.territory) {
      errors.territory = "Please select the territory";
    }
    if (!values.category) {
      errors.category = "Please select the category";
    }
    // if (!values.subCategory) {
    //   errors.subCategory = "Please select the sub-category";
    // }
    if (!values.Observation) {
      errors.Observation = "Please select the Observation";
    }
    if (!values.question1) {
      errors.question1 = "Please select the question 1";
    }
    // if (!values.question2) {
    //   errors.question2 = "Please select the question 2";
    // }
    
    return errors;
  };

  //post api call of my Diary
  useEffect(() => {
    if (Object?.keys(formValidation)?.length === 0 && isSubmit) {
      (async () => {
        const userDetailsData = {
          RecordType: "My User",
          Observation: userDetails?.Observation,
          UserName: userDetails?.userName,
          UserMobile: userDetails?.mobileNumber,
          Category: userDetails?.category,
          SubCategory:
            otherFeedback === "Any Other Feedback"
              ? ""
              : userDetails?.subCategory,
          Territory: userDetails?.territory,
          filename: file?.fileName,
          Question1: userDetails?.question1,
          Question2: queSelect === "yes" ? userDetails?.question2 : "",
          fileExtension: file?.fileExtension,
          base64: file?.base64,
        };

        const {
          RecordType,
          Observation,
          UserName,
          UserMobile,
          Category,
          SubCategory,
          Territory,
          filename,
          Question1,
          Question2,
          fileExtension,
          base64,
        } = userDetailsData;
        setLoading(true);
        const response = await addUserDetailsApi({
          RecordType,
          Observation,
          UserName,
          UserMobile,
          Category,
          SubCategory,
          Territory,
          filename,
          Question1,
          Question2,
          fileExtension,
          base64,
        });
        setLoading(false);
        if (response?.success) {
          setIsSubmitModalOpen(true);
          setUserDetails({
            userName: "",
            mobileNumber: "",
            territory: "",
            category: "",
            subCategory: "",
            Observation: "",
            question1: "",
            question2: "",
          });
          setTerritoryValue(
            territoryAutoCompletePlaceHolder
          )
          setFile({
            base64: "",
            fileExtension: "",
            fileName: "",
          });
          formCategoryRef.current.value = "";
          formSubCategoryRef.current.value = "";
          setIsSubmit(false);
        } else {
          setIsSubmit(false);
          console.log("Something went to wrong!");
          setTerritoryValue(
            territoryAutoCompletePlaceHolder
          )
        }
      })();
    }
    if (Object?.keys(formValidation)?.length !== 0) {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  return (
    <>
      <div className={styles.myUserDetails__outerBox}>
        {/* <PowerBi
          id="d4345e64-7c35-46f7-b844-8f2f5443dd2d"
          pageName="ReportSectionaff09e82ef2ddea135bb"
        /> */}
        {isSubmit && Object?.keys(formValidation)?.length === 0 && (
          <Loader loading={loading} />
        )}
        <div className={styles.myUserDetails__heading}>User Visit Feedback</div>
        <div className={styles.myUserDetails__innerBox}>
          <div className={styles.myUserDetails__detailsBox}>
            <div>
              <form className={styles.myUserDetails__form}>
                <input
                  type="number"
                  name="mobileNumber"
                  placeholder="Enter Mobile Number"
                  className={
                    !userDetails.mobileNumber && formValidation?.mobileNumber
                      ? styles.myUserDetails__inputBoxError
                      : styles.myUserDetails__inputBox
                  }
                  value={userDetails?.mobileNumber}
                  onChange={(e) => onChange(e)}
                />
              </form>
              {(!userDetails.mobileNumber ||
                userDetails.mobileNumber?.split("")?.length != 10) &&
                formValidation?.mobileNumber && (
                  <p>{formValidation?.mobileNumber}</p>
                )}

              <form className={styles.myUserDetails__form}>
                <input
                  type="text"
                  name="userName"
                  placeholder="Enter Username"
                  className={
                    !userDetails.userName && formValidation?.userName
                      ? styles.myUserDetails__inputBoxError
                      : styles.myUserDetails__inputBox
                  }
                  value={userDetails?.userName}
                  onChange={(e) => onChange(e)}
                  disabled={nameCheck != null && nameCheck != "Unknown"}
                />
              </form>
              {!userDetails.userName && formValidation?.userName && (
                <p>{formValidation?.userName}</p>
              )}
      

              <Autocomplete
                value={territoryValue}
                id="grouped-demo"
                className="myDealer__categorySelect"
                options={
                  territoryOptions?.length === 0
                    ? [
                        {
                          SH2_Name__c: "Loading...",
                          SH2_Code__c: "",
                        },
                      ]
                    : getUniqueListBy(territoryOptions, "SH2_Code__c")
                }
                getOptionLabel={(option) =>
                  `${option?.SH2_Name__c}${
                    option?.SH2_Code__c && `:${option?.SH2_Code__c}`
                  }`
                }
                onChange={(e, newValue) => {
                  setTerritoryValue(newValue);
                  onChange({
                    target: {
                      name: "territory",
                      value: `${newValue?.SH2_Name__c}${
                    newValue?.SH2_Code__c && `:${newValue?.SH2_Code__c}`
                  }`,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder={"Select Territory"} />
                )}
              />
                {!userDetails?.territory &&
                            formValidation?.territory && (
                              <p className={styles.myDiary__validation}>
                                {formValidation?.territory}
                              </p>
                            )}

              {/* <Form.Select
                aria-label="Default select example"
                className={
                  !userDetails.territory && formValidation?.territory
                    ? styles.myUserDetails__categorySelectError
                    : styles.myUserDetails__categorySelect
                }
                name="territory"
                value={userDetails?.territory}
                onChange={(e) => onChange(e)}
              >
                <option
                  value=""
                  className={styles.myUserDetails__defaultOption}
                >
                  Select TTY Name
                </option>
                {territoryOptions &&
                  territoryOptions?.map((name) => {
                    return (
                      <option
                        value={`${name?.SH2_Name__c}:${name?.SH2_Code__c}`}
                      >
                        {name?.SH2_Name__c} : {name?.SH2_Code__c}
                      </option>
                    );
                  })}
              </Form.Select> 
               {!userDetails.territory && formValidation?.territory && (
                <p>{formValidation?.territory}</p>
              )} */}

              <Form.Select
                aria-label="Default select example"
                className={
                  !userDetails.category && formValidation?.category
                    ? styles.myUserDetails__categorySelectError
                    : styles.myUserDetails__categorySelect
                }
                name="category"
                ref={formCategoryRef}
                // value={userDetails?.category}
                onChange={(e) => onChange(e)}
              >
                <option
                  value=""
                  className={styles.myUserDetails__defaultOption}
                >
                  Category
                </option>
                {categories &&
                  categories?.values.map((name, key) => {
                    return (
                      <option value={`${key}:${name?.label}`}>
                        {name?.label}
                      </option>
                    );
                  })}
              </Form.Select>
              {!userDetails.category && formValidation?.category && (
                <p>{formValidation?.category}</p>
              )}
              {otherFeedback !== "Any Other Feedback" && (
                <>
                  <Form.Select
                    aria-label="Default select example"
                    className={
                      !userDetails.subCategory && formValidation?.subCategory
                        ? styles.myUserDetails__categorySelectError
                        : styles.myUserDetails__categorySelect
                    }
                    name="subCategory"
                    ref={formSubCategoryRef}
                    onChange={(e) => onChange(e)}
                  >
                    <option
                      value=""
                      className={styles.myUserDetails__defaultOption}
                    >
                      Sub-category
                    </option>
                    {subCategories &&
                      subCategories?.values.map((name, key) => {
                        return name.validFor?.map((index, key) => {
                          if (index.toString() === id) {
                            return (
                              <option value={`${key}:${name?.label}`}>
                                {name?.label}
                              </option>
                            );
                          }
                        });
                      })}
                  </Form.Select>
                  {!userDetails.subCategory && formValidation?.subCategory && (
                    <p>{formValidation?.subCategory}</p>
                  )}
                </>
              )}

              <textarea
                name="Observation"
                rows="8"
                placeholder="Enter your observation here"
                className={
                  !userDetails.Observation && formValidation?.Observation
                    ? styles.myUserDetails__textareaError
                    : styles.myUserDetails__textarea
                }
                value={userDetails?.Observation}
                onChange={(e) => onChange(e)}
              ></textarea>
              {!userDetails.Observation && formValidation?.Observation && (
                <p>{formValidation?.Observation}</p>
              )}

              <hr></hr>

              <div className={styles.myUserDetails__radioButton}>
                <label className={styles.myUserDetails__questionLabel}>
                  Is the user able to bank and redeem on his / her own ?{" "}
                  <sup className={styles.myUserDetails__sup}>*</sup>
                </label>
                <div className={`mb-1 ${styles.myUserDetails__radioOption}`}>
                  <div class="form-check">
                    <input
                      className={styles.myUserDetails__option}
                      class="form-check-input"
                      type="radio"
                      name="question1"
                      id="flexRadioDefault1"
                      value="yes"
                      onChange={(e) => onChange(e)}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Yes
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      className={styles.myUserDetails__option}
                      class="form-check-input"
                      type="radio"
                      name="question1"
                      id="flexRadioDefault2"
                      value="no"
                      onChange={(e) => onChange(e)}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      No
                    </label>
                  </div>
                </div>
                {!userDetails.question1 && formValidation?.question1 && (
                  <p>{formValidation?.question1}</p>
                )}
              </div>
              {queSelect === "yes" && (
                <div className={styles.myUserDetails__radioButton}>
                  <label className={styles.myUserDetails__questionLabel}>
                    Has the user banked or redeemed himself/herself in last 3
                    months ?<sup className={styles.myUserDetails__sup}>*</sup>
                  </label>
                  <div className={styles.myUserDetails__radioOption}>
                    <div class="form-check">
                      <input
                        className={styles.myUserDetails__option}
                        class="form-check-input"
                        type="radio"
                        name="question2"
                        id="flexRadioDefault1"
                        value="yes"
                        onChange={(e) => onChange(e)}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        className={styles.myUserDetails__option}
                        class="form-check-input"
                        type="radio"
                        name="question2"
                        id="flexRadioDefault2"
                        value="no"
                        onChange={(e) => onChange(e)}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        No
                      </label>
                    </div>
                  </div>
                  {!userDetails.question2 && formValidation?.question2 && (
                    <p>{formValidation?.question2}</p>
                  )}
                </div>
              )}

              {/* <div className={styles.myUserDetails__checkboxButton}>
                <div className={styles.myUserDetails__sectionHeading}>
                  Assign to
                </div>
                <Form.Check
                  aria-label="option 1"
                  label="Self"
                  className={styles.myUserDetails__option}
                />
                <Form.Check
                  aria-label="option 1"
                  label="Saathi Helpline"
                  className={styles.myUserDetails__option}
                />
              </div> */}
              <hr></hr>
            </div>
            <div className="row">
              {!_.isEmpty(file?.fileName) && (
                <>
                  <div className={styles.myUserDetails__fileUploadTitle}>
                    File uploads
                  </div>
                  <div
                    className={`mb-1 ${styles.myUserDetails__displayFileName}`}
                  >
                    <div>
                      <FiFile className={styles.myUserDetails__fileIcon} />
                      <span className={styles.myUserDetails__fileUploadName}>
                        {file?.fileName}
                      </span>
                    </div>
                    <Button
                      className={styles.myUserDetails__removeUploadFile}
                      onClick={() => setFile()}
                    >
                      Remove
                    </Button>
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div className="col-6"></div>
              <div className="col-3">
                <div className={styles.myUserDetails__inputWrapper}>
                  Add file
                  <input
                    className={`${styles.myUserDetails__fileInput}`}
                    type="file"
                    name="fileName"
                    accept=".jpg,.png,.pdf, .jpeg"
                    onChange={(e) => handleFileRead(e)}
                  />
                </div>
              </div>
              <div className="col-3">
                <Button
                  className={styles.myUserDetails__submitBtn}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSubmitModalOpen && (
        <ModalPopup
          className="ModalPopup_Submitted"
          isModalOpen={isSubmitModalOpen}
          setIsModalOpen={setIsSubmitModalOpen}
          title="Submitted Successfully"
          data="User visit feedback has been submitted successfully."
          backdrop={true}
          fade={false}
          icon={
            <>
              <div className="submittedIcons draftIcons">
                <FaThumbsUp className="submittedSmall" />
                <FaThumbsUp className="submittedLarge" />
                <FaThumbsUp className="submittedSmall" />
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default connect(null, {
  addUserDetailsApi,
})(MyUserDetails);
