import React, { useState } from "react";
import { BsFilterRight } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import "./PastSrInsight.css";
import PageHeader from "../Common/PageHeader";
import { useSelector } from "react-redux";

const PastSrInsights = ({ isComponent }) => {
  // const [selectedValue, setSelectedValue] = React.useState("a");
  const [toShowPast, setToShowPast] = useState(true);
  const [toShowInsigth, setToShowInsigth] = useState(true);

  const pastSr = useSelector(
    (state) => state?.pastInsight?.pastInsightDetails
  );

  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  // };

  return (
    <>
      <PageHeader title="Market Working" path="/my-dealer" />
      <div className="container p-0">
        <div className="pastSrInsights">
          <div className="pastSrInsights__titleSearch">
            <h6 className="pastSrInsights__label">Past SR/Insights</h6>
            {/* <div className="pastSrInsights__searchBox">
              <FiIcons.FiSearch className="pastSrInsights__searchIcon" />
              <input
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                type="search"
              />
            </div> */}
          </div>

            <div className="pastSrInsights__userDetailCount">
              <div className="pastSrInsights__userDetail">
                <p>User Detail</p>
              </div>

              <Dropdown>
                <Dropdown.Toggle className="pastSrInsights__filterBtn">
                  <BsFilterRight className="pastSrInsights__filterIcon" />
                  Filter
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="pastSrInsights__filterDropDown">
                    <p className="pastSrInsights__categoryTitle">Category</p>
                    <div className="list-unstyled pastSrInsights__categoryOptions">
                      <button
                        className={`${"pastSrInsights__filterButton"} ${
                          toShowPast &&
                          toShowInsigth &&
                          "pastSrInsights__filterButtonActive"
                        }`}
                        onClick={() => {
                          setToShowPast(true);
                          setToShowInsigth(true);
                        }}
                      >
                        All
                      </button>
                      <button
                        className={`${"pastSrInsights__filterButton"} ${
                          !toShowInsigth && "pastSrInsights__filterButtonActive"
                        }`}
                        onClick={() => {
                          setToShowPast(true);
                          setToShowInsigth(false);
                        }}
                      >
                        Past Sr
                      </button>
                      <button
                        className={`${"pastSrInsights__filterButton"} ${
                          !toShowPast && "pastSrInsights__filterButtonActive"
                        }`}
                        onClick={() => {
                          setToShowPast(false);
                          setToShowInsigth(true);
                        }}
                      >
                        Past Insights
                      </button>
                    </div>

                    {/* <p className="pastSrInsights__sortTitle">Sort by</p>
                  <div className="pastSrInsights__sortTypes">
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={handleChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      size="small"
                      style={{ color: "#FF4457" }}
                    />
                    Lorem ipsum
                    <Radio
                      checked={selectedValue === "b"}
                      onChange={handleChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      size="small"
                      style={{ color: "#5AEA95" }}
                    />
                    Lorem ipsum
                    <Radio
                      checked={selectedValue === "c"}
                      onChange={handleChange}
                      value="c"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      size="small"
                      style={{ color: "#FFEE87" }}
                    />
                    Lorem ipsum
                    <Radio
                      checked={selectedValue === "d"}
                      onChange={handleChange}
                      value="d"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      size="small"
                      style={{ color: "#8AC0FF" }}
                    />
                    Lorem ipsum
                  </div> */}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="pastSrInsights__tabs">
              <>
                {toShowInsigth &&
                  pastSr?.Previous_Feedback?.map((item) => (
                    <div className="pastSrInsights__alerts">
                      <span>{item.Observations__c}</span>
                    </div>
                  ))}
                {toShowPast &&
                  pastSr?.past_Sr?.map((item) => (
                    <div className="pastSr__alerts">
                      <span>{item.Description}</span>
                    </div>
                  ))}
              </>
            </div>

        </div>
      </div>
    </>
  );
};

export default PastSrInsights;
