import React from "react";
import styles from "./MyTeams.module.css";
// import {FiSearch} from "react-icons/fi";
import { Button } from "react-bootstrap";
import PowerBi from "../../../Pages/PowerBi";
import PbiDashboard from "../../MonthlyTourPlan/PbiDashboard";
import Message from "../../Common/Message";

const MyTeams = () => {
  return (
    <div className={`${styles.myTeam__outerBox}`}>
      <div className={styles.myTeam__heading}>My Team</div>
      {/* <div className={styles.visitDetails__mainBox}>
        <Message
          message={"PBI Dashboard Coming Soon"}
          className="visitDetails__OuterDiv"
        />
      </div> */}

      {/* <div className={`row ${styles.myTeam__innerBoxRow}`}>
        <div className={`col-lg-10 col-8 ${styles.myTeam__search}`}>
          <span className={styles.myTeam__searchIcon}>
            <FiIcons.FiSearch />
          </span>
          <input
            type="text"
            className={`form-control ${styles.myTeam__searchInput}`}
            placeholder="Search Territory"
          />
        </div>
        <div className={`col-lg-2 col-4 ${styles.myTeam__btn}`}>
          <Button className={styles.myTeam__insightBtn}>SR/ Insights</Button>
        </div>
      </div> */}
      <div className="myTeam__innerBox">
        <PowerBi
          id="dd6b49e0-3b63-4e27-a36d-25bfdc232607"
          pageName="95eb8e4966fa813d05dd"
        />
      </div>
    </div>
  );
};

export default MyTeams;
