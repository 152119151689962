import { getSH7CodeHoUserApi } from "../../services/hoUser/getSH7CodeHoUserApi";
import {
  GET_HO_SH7_Code_FAIL,
  GET_HO_SH7_Code_SUCCESS,
} from "../constants/action-types";
import { getHoNsmEmailApi } from "../../services/PowerBi/getHoNsmEmailApi";
import {
  GET_HO_Nsm_Email_FAIL,
  GET_HO_Nsm_Email_SUCCESS,
} from "../constants/action-types";

export const getSH7CodeHoUser =
  (divisionGroup, salesGroup) => async (dispatch) => {
    const response = await getSH7CodeHoUserApi(divisionGroup, salesGroup);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_HO_SH7_Code_SUCCESS,
        payload: response?.data?.data?.[0]?.sh7code,
      });
    } else {
      dispatch({ type: GET_HO_SH7_Code_FAIL });
    }
  };

export const getHoNsmEmail = (salesGroup) => async (dispatch) => {
  const response = await getHoNsmEmailApi(salesGroup);
if (response?.success === true) {
    dispatch({
      type: GET_HO_Nsm_Email_SUCCESS,
      payload: response?.data?.records?.[0]?.Email,
    });
  } else {
    dispatch({ type: GET_HO_Nsm_Email_FAIL });
  }
};
