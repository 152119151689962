import axios from "axios";
import { BASE_URL } from "../../variables";
import { arrayData, getUniqueListBy } from "../../Components/Common/Function";

export const getCustomerCodeForTownApi = async (data) => {
    if (data) {
        const options = {
            url: `${BASE_URL}/services/apexrest/getCustomerCode_api/`,
            method: "post",
            data: {
                clusterCodes: data,
            },
        };
        try {
            const response = await axios(options);
            if (response.status === 200) {
                const wssData = response?.data?.CustomerData;
                const arrayOfWss = Object.entries(wssData).map(([key, value]) => ({
                    id: key,
                    ...value
                }));
                return {
                    success: true,
                    data: arrayOfWss,
                };
            } else {
                throw Error("Something Went Wrong..!!");
            }
        } catch (err) {
            console.log(err);
            return {
                success: false,
                msg: "Somthing Went Wrong..!!",
            };
        }
    } else {
        return {
            success: true,
            data: { records: [] },
        };
    }
};
