import axios from "axios";
import { getBarGraph } from "../../../Redux/actions/homePage-actions";
import { BASE_URL } from "../../../variables";
import _ from "lodash";
import {
  getCheckInDealerDetails,
  getCheckInWSSDetails,
} from "../../../Redux/actions/checkinDetails-actions";

export const checkedInapi =
  (
    RecordtypeName,
    territoryCheckIn,
    dealer,
    dealerCode,
    wss,
    customerCode,
    currentLatitude,
    currentLongitude,
    flag,
    masterLatitude,
    masterLongitude,
    distance,
    accuracy,
    currentAccuracyFactor,
    Checked
  ) =>
  async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/apexrest/CheckIn_VisitPlan/`,
      method: "post",
      data: {
        RecordtypeName: RecordtypeName,
        territory: territoryCheckIn,
        dealer: dealer,
        dealercode: dealerCode,
        customer: wss,
        customercode: customerCode,
        Transaction_lat: currentLatitude,
        Transaction_long: currentLongitude,
        IsValidCheckIn: flag,
        Master_lat: masterLatitude,
        Master_long: masterLongitude,
        Trasanction_distance: distance,
        Master_Accuracy:accuracy,
        Current_Accuracy:currentAccuracyFactor,
        Is_Correct_CheckIn:Checked
      },
    };

    try {
      const response = await axios(options);
      if (response.status === 200) {
        dispatch(getBarGraph());
        if (RecordtypeName === "Visit Plan") {
          dispatch(getCheckInDealerDetails());
        } else {
          dispatch(getCheckInWSSDetails());
        }
        return {
          success: true,
          data: response?.data,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      return {
        success: false,
        msg: `You are already checked-in for ${
          !_.isEmpty(dealer) ? dealer : "this"
        } dealer!`,
      };
    }
  };
