import { getUpcomingTourPlansApi } from "../../services/MonthlyTourPlans/getupcomingTourPlans";
import {
  GET_UPCOMING_TOUR_PLAN_FAIL,
  GET_UPCOMING_TOUR_PLAN_SUCCESS,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

export const getUpcomingTourPlan = () => async (dispatch) => {
  const response = await getUpcomingTourPlansApi();
  if (response?.status === 200) {
    dispatch({
      type: GET_UPCOMING_TOUR_PLAN_SUCCESS,
      payload: response?.data,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_UPCOMING_TOUR_PLAN_FAIL,
    });
    // dispatch(setAlert("Something Went Wrong..!!", "error"));
  }
};
