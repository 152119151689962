import React, { useLayoutEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const Linegraph = ({ plannedData, labels }) => {
  // const plannedData = [10, 45, 40];
  // const labels = ["September", "October", "November"];

  useLayoutEffect(() => {
    Chart.register(ChartDataLabels);
    Chart.register(...registerables);
  });
  return (
    <>
      <Line
        data={{
          labels: labels?.reverse()?.map((item) => item),
          datasets: [
            {
              label: "Planned",
              data: plannedData && plannedData?.map((item) => item),
              backgroundColor: ["#FFA600"],
              pointRadius: 0,
              borderColor: "#2B2B2B",
              color: "#FFFFFF",
              borderWidth: 1,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          // responsive: false,
          plugins: {
            legend: {
              display: false,
            },
            // barPercentage: 1,
            datalabels: {
              display: false,
              // anchor: "start",

              // align: "top",
              // color: (context) => {
              //   if (context?.dataset?.label === "Planned") {
              //     // display:false,
              //     return "#fbfbfb";
              //   }
              // },
              // font: {
              //   weight: 600,
              //   size: 7.8,
              // },
            },
          },

          scales: {
            y: {
              display: false,
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              drawBorder: {
                display: false,
                // color: "black",
              },
              color: "rgb(255, 0, 0)",
            },
            x: {
              display: false,
              color: "rgb(255, 0, 0)",
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              drawBorder: {
                display: false,
                // color: "black",
              },

              // ticks: {
              //   display: false,
              //   color: (context) => {
              //     if (context?.index === 2) {
              //       return "green";
              //     }
              //   },
              //   size: 14,
              //   weight: 500,
              // },
              grid: {
                display: false,
                borderColor: "rgb(255, 255, 255)",
              },
            },
          },
        }}
        height={25}
        width={130}
      />
    </>
  );
};

export default Linegraph;
