import axios from "axios";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";

export const getMVRDealerNameForHOApi =
  (divisionGroup, salesGroup, shCode) => async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/data/v56.0/query?q=select id,Dealer_Name__c ,DealerCode__c ,TSICode__c from account where SalesGroupCode__c= '${salesGroup}' AND Division_Code__c = ${divisionGroup} AND RecordType.Name = 'Dealer' AND HierarchyLevel3ASMTerritory__c ='${shCode}'`,
      method: "get",
    };
    try {
      const response = await axios(options);
      if (response.status === 200) {
        return {
          success: true,
          data: response?.data,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        sessionExpireTimeout(dispatch);
      } else {
        return {
          success: false,
          msg: "Somthing Went Wrong..!!",
        };
      }
    }
  };
