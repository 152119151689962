import axios from "axios";
import { BASE_URL } from "../../../variables";

export const getPicklistApi = async (recordId) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/ui-api/object-info/Case/picklist-values/${recordId}`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
