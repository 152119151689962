import axios from "axios";
import { BASE_URL } from "../../../variables";

export const getTerritoryApi = async ({ UserSH_Code__c, Title }) => {
  let url;
  if (Title === "SH6") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,SH6_Code__c from Pidilite_Hierarchy__c where SH6_Code__c ='${UserSH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  } else if (Title === "SH5") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,SH6_Code__c from Pidilite_Hierarchy__c where SH5_Code__c ='${UserSH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  } else if (Title === "SH7" || Title === "HO Collaborator" || Title === "MH3") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,SH6_Code__c,SH7_Code__c from Pidilite_Hierarchy__c where SH7_Code__c in (${UserSH_Code__c}) AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  } else if (Title === "SH4") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,SH6_Code__c,SH7_Code__c from Pidilite_Hierarchy__c where SH4_Code__c ='${UserSH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  } else {
    url = `${BASE_URL}/services/data/v56.0/query?q=select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,TXNType__c,SH6_Code__c,SH3_Name__c  from Pidilite_Hierarchy__c where SH3_Code__c ='${UserSH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  }
  const options = {
    url: url,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
