import axios from "axios";
import { getMarketWorking } from "../../../Redux/actions/homePage-actions";
import { sessionExpireTimeout } from "../../../Redux/actions/utility-actions";
import { BASE_URL } from "../../../variables";

export const addMySrApi =
  ({
    recordType,
    subject,
    description,
    dealerId,
    chapterName,
    category,
    subCategory,
    fileExtension,
    fileName,
    base64,
    origin,
    MVRId,
    BMName,
    BMEmail
  }) =>
  async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/apexrest/ServiceRequest/`,
      method: "post",
      data: {
        recordType,
        subject,
        description,
        dealerId,
        chapterName,
        category,
        subCategory,
        fileExtension,
        fileName,
        base64,
        origin,
        MVRId,
        BMName,
        BMEmail
      },
    };
    try {
      const response = await axios(options);
      if (response?.status === 200) {
        dispatch(getMarketWorking());
        return {
          success: true,
          data: response?.data,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        sessionExpireTimeout(dispatch);
      } else {
        return {
          success: false,
          msg: "Somthing Went Wrong..!!",
        };
      }
    }
  };
