import { getVisitDetailsApi } from "../../services/MarketWorking/MyTeam/getVisitDetailsApi";
import { getWssVisitDetailsApi } from "../../services/wss/getWssVisitDetailsApi";
import {
  GET_CHECKIN_DETAILS_FAIL,
  GET_CHECKIN_DETAILS_SUCCESS,
  GET_WSS_CHECKIN_DETAILS_FAIL,
  GET_WSS_CHECKIN_DETAILS_SUCCESS,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

export const getCheckInDealerDetails = () => async (dispatch) => {
  const response = await getVisitDetailsApi();
  if (response?.status === 200) {
    dispatch({
      type: GET_CHECKIN_DETAILS_SUCCESS,
      payload: response?.data?.VisitPlan,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_CHECKIN_DETAILS_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};

export const getCheckInWSSDetails = () => async (dispatch) => {
  const response = await getWssVisitDetailsApi();
  if (response?.status === 200) {
    dispatch({
      type: GET_WSS_CHECKIN_DETAILS_SUCCESS,
      payload: response?.data?.VisitPlan,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_WSS_CHECKIN_DETAILS_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};
