import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getSH7CodeHoUserApi = async (divisionGroup, salesGroup) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/hoUser/get-sh7Code`,
    method: "post",
    headers: headers,
    data: { divisionGroup, salesGroup },
  };

  try {
    const res = await axios(options);
    const sh7Codes = res?.data?.data?.map(record => `'${record.sh7code}'`).join(', ');
    return sh7Codes;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
