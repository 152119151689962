export const GET_GLOBAL_ACCESS_TOKEN_SUCCESS =
  "GET_GLOBAL_ACCESS_TOKEN_SUCCESS";
export const GET_GLOBAL_ACCESS_TOKEN_FAIL = "GET_GLOBAL_ACCESS_TOKEN_FAIL";

export const GET_USER_ACCESS_TOKEN_SUCCESS = "GET_USER_ACCESS_TOKEN_SUCCESS";
export const GET_USER_ACCESS_TOKEN_FAIL = "GET_USER_ACCESS_TOKEN_FAIL";

export const GET_PBI_ACCESS_TOKEN_SUCCESS = "GET_PBI_ACCESS_TOKEN_SUCCESS";
export const GET_PBI_ACCESS_TOKEN_FAIL = "GET_PBI_ACCESS_TOKEN_FAIL";

export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";
export const GET_USER_DETAILS_EMPTY = "GET_USER_DETAILS_EMPTY";

export const LOGOUT = "LOGOUT";

export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERT = "SET_ALERT";

export const GET_QUERY_DETAILS_SUCCESS = "GET_QUERY_DETAILS_SUCCESS";
export const GET_QUERY_DETAILS_FAIL = "GET_QUERY_DETAILS_FAIL";

export const GET_TERRITORY_SUCCESS = "GET_TERRITORY_SUCCESS";
export const GET_TERRITORY_FAIL = "GET_TERRITORY_FAIL";

export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const GET_MARKET_WORKING_SUCCESS = "GET_MARKET_WORKING_SUCCESS";
export const GET_MARKET_WORKING_FAIL = "GET_MARKET_WORKING_FAIL";

export const GET_BAR_GRAPH_SUCCESS = "GET_BAR_GRAPH_SUCCESS";
export const GET_BAR_GRAPH_FAIL = "GET_BAR_GRAPH_FAIL";

export const GET_CHECKIN_DETAILS_SUCCESS = "GET_CHECKIN_DETAILS_SUCCESS";
export const GET_CHECKIN_DETAILS_FAIL = "GET_CHECKIN_DETAILS_FAIL";

export const GET_WSS_CHECKIN_DETAILS_SUCCESS =
  "GET_WSS_CHECKIN_DETAILS_SUCCESS";
export const GET_WSS_CHECKIN_DETAILS_FAIL = "GET_WSS_CHECKIN_DETAILS_FAIL";

export const GET_UPCOMING_TOUR_PLAN_SUCCESS = "GET_UPCOMING_TOUR_PLAN_SUCCESS";
export const GET_UPCOMING_TOUR_PLAN_FAIL = "GET_UPCOMING_TOUR_PLAN_FAIL";
export const GET_PASTINSIGHTS_DETAILS_SUCCESS =
  "GET_PASTINSIGHTS_DETAILS_SUCCESS";
export const GET_PASTINSIGHTS_DETAILS_FAIL = "GET_PASTINSIGHTS_DETAILS_FAIL";

export const GET_FEEDBACK_DETAILS_SUCCESS = "GET_FEEDBACK_DETAILS_SUCCESS";
export const GET_FEEDBACK_DETAILS_FAIL = "GET_FEEDBACK_DETAILS_FAIL";

export const GET_DIVISION_GROUP_SUCCESS = "GET_DIVISION_GROUP_SUCCESS";
export const GET_DIVISION_GROUP_FAIL = "GET_DIVISION_GROUP_FAIL";

export const GET_SECONDARY_SALES_ACHIEVED_SUCCESS =
  "GET_SECONDARY_SALES_ACHIEVED_SUCCESS";
export const GET_SECONDARY_SALES_ACHIEVED_FAIL =
  "GET_SECONDARY_SALES_ACHIEVED_FAIL";

export const GET_SECONDARY_SALES_TREND_SUCCESS =
  "GET_SECONDARY_SALES_TREND_SUCCESS";
export const GET_SECONDARY_SALES_TREND_FAIL = "GET_SECONDARY_SALES_TREND_FAIL";

export const GET_SECONDARY_SALES_TARGET_ACHIEVED_SUCCESS =
  "GET_SECONDARY_SALES_TARGET_ACHIEVED_SUCCESS";
export const GET_SECONDARY_SALES_TARGET_ACHIEVED_FAIL =
  "GET_SECONDARY_SALES_TARGET_ACHIEVED_FAIL";

export const GET_SECONDARY_SALES_TARGET_TREND_SUCCESS =
  "GET_SECONDARY_SALES_TARGET_TREND_SUCCESS";
export const GET_SECONDARY_SALES_TARGET_TREND_FAIL =
  "GET_SECONDARY_SALES_TARGET_TREND_FAIL";

export const GET_TEAMS_AVERAGE_TSI_SCORE_SUCCESS =
  "GET_TEAMS_AVERAGE_TSI_SCORE_SUCCESS";
export const GET_TEAMS_AVERAGE_TSI_SCORE_FAIL =
  "GET_TEAMS_AVERAGE_TSI_SCORE_FAIL";

export const GET_TEAMS_AVERAGE_TSI_SCORE_TREND_SUCCESS =
  "GET_TEAMS_AVERAGE_TSI_SCORE_TREND_SUCCESS";
export const GET_TEAMS_AVERAGE_TSI_SCORE_TREND_FAIL =
  "GET_TEAMS_AVERAGE_TSI_SCORE_TREND_FAIL";

export const GET_TEAMS_AVERAGE_TSR_SCORE_SUCCESS =
  "GET_TEAMS_AVERAGE_TSR_SCORE_SUCCESS";
export const GET_TEAMS_AVERAGE_TSR_SCORE_FAIL =
  "GET_TEAMS_AVERAGE_TSR_SCORE_FAIL";

export const GET_TEAMS_AVERAGE_TSR_SCORE_TREND_SUCCESS =
  "GET_TEAMS_AVERAGE_TSR_SCORE_TREND_SUCCESS";
export const GET_TEAMS_AVERAGE_TSR_SCORE_TREND_FAIL =
  "GET_TEAMS_AVERAGE_TSR_SCORE_TREND_FAIL";

export const GET_TEAMS_INCENTIVE_SUCCESS = "GET_TEAMS_INCENTIVE_SUCCES";
export const GET_TEAMS_INCENTIVE_FAIL = "GET_TEAMS_INCENTIVE_FAIL";

export const GET_TEAMS_INCENTIVE_TREND_SUCCESS =
  "GET_TEAMS_INCENTIVE_TREND_SUCCES";
export const GET_TEAMS_INCENTIVE_TREND_FAIL = "GET_TEAMS_INCENTIVE_TREND_FAIL";

export const GET_TEAMS_SOP_SUCCESS = "GET_TEAMS_SOP_SUCCES";
export const GET_TEAMS_SOP_FAIL = "GET_TEAMS_SOP_FAIL";

export const GET_TEAMS_SOP_TREND_SUCCESS = "GET_TEAMS_SOP_TREND_SUCCES";
export const GET_TEAMS_SOP_TREND_FAIL = "GET_TEAMS_SOP_TREND_FAIL";

export const SET_SELECTED_INSIGHTS = "SET_SELECTED_INSIGHTS";

export const GET_WSS_AUTO_VALUE_FTD_SUCCESS = "GET_WSS_AUTO_VALUE_FTD_SUCCESS";
export const GET_WSS_AUTO_VALUE_FTD_FAIL = "GET_WSS_AUTO_VALUE_FTD_FAIL";

export const GET_WSS_AUTO_VALUE_FTD_TREND_SUCCESS =
  "GET_WSS_AUTO_VALUE_FTD_TREND_SUCCESS";
export const GET_WSS_AUTO_VALUE_FTD_TREND_FAIL =
  "GET_WSS_AUTO_VALUE_FTD_TREND_FAIL";

export const GET_WSS_WSS_IN_BLOCK_SUCCESS = "GET_WSS_WSS_IN_BLOCK_SUCCESS";
export const GET_WSS_WSS_IN_BLOCK_FAIL = "GET_WSS_WSS_IN_BLOCK_FAIL";

export const GET_WSS__DARPAN_SYNC_DAYS_EXCEPTION_SUCCESS =
  "GET_WSS_DARPAN_SYNC_DAYS_EXCEPTION_SUCCESS";
export const GET_WSS_DARPAN_SYNC_DAYS_EXCEPTION_FAIL =
  "GET_WSS_DARPAN_SYNC_DAYS_EXCEPTION_FAIL";

export const GET_CLUSTER_CODE_SUCCESS = "GET_CLUSTER_CODE_SUCCESS";
export const GET_CLUSTER_CODE_FAIL = "GET_CLUSTER_CODE_FAIL";

export const CURRENT_DAY_TOUR_ASM_KEY_SUCCESS =
  "CURRENT_DAY_TOUR_ASM_KEY_SUCCESS";
export const CURRENT_DAY_TOUR_ASM_KEY_FAIL = "CURRENT_DAY_TOUR_ASM_KEY_FAIL";

export const CURRENT_DAY_TOUR_ASM_DATA_SUCCESS =
  "CURRENT_DAY_TOUR_ASM_DATA_SUCCESS";
export const CURRENT_DAY_TOUR_ASM_DATA_FAIL = "CURRENT_DAY_TOUR_ASM_DATA_FAIL";

export const GET_HO_SH7_Code_SUCCESS = "GET_HO_SH7_Code_SUCCESS";
export const GET_HO_SH7_Code_FAIL = "GET_HO_SH7_Code_FAIL";

export const GET_OUTLETS_BILLED_FTM_SUCCESS = "GET_OUTLETS_BILLED_FTM_SUCCESS";
export const GET_OUTLETS_BILLED_FTM_FAIL = "GET_OUTLETS_BILLED_FTM_FAIL";

export const GET_OUTLETS_BILLED_FTM_TREND_SUCCESS =
  "GET_OUTLETS_BILLED_FTM_TREND_SUCCESS";
export const GET_OUTLETS_BILLED_FTM_TREND_FAIL =
  "GET_OUTLETS_BILLED_FTM_TREND_FAIL";

export const GET_RSM_FTM_SUCCESS = "GET_RSM_FTM_SUCCESS";
export const GET_RSM_FTM_FAIL = "GET_RSM_FTM_FAIL";

export const GET_RSM_FTM_TREND_SUCCESS = "GET_RSM_FTM_TREND_SUCCESS";
export const GET_RSM_FTM_TREND_FAIL = "GET_RSM_FTM_TREND_FAIL";

export const GET_HO_Nsm_Email_SUCCESS = "GET_HO_Nsm_Email_SUCCESS";
export const GET_HO_Nsm_Email_FAIL = "GET_HO_Nsm_Email_FAIL";

export const GET_MY_USER_FEEDBACK_SUCCESS = "GET_MY_USER_FEEDBACK_SUCCESS";
export const GET_MY_USER_FEEDBACK_FAIL = "GET_MY_USER_FEEDBACK_FAIL";

export const GET_RMDM_TOWN_SUCCESS = "GET_RMDM_TOWN_SUCCESS";
export const GET_RMDM_TOWN_FAIL = "GET_RMDM_TOWN_FAIL";

export const GET_MVR_DRAFT_SUCCESS = "GET_MVR_DRAFT_SUCCESS";
export const REMOVE_MVR_DRAFT_SUCCESS = "REMOVE_MVR_DRAFT_SUCCESS";

export const GET_BM_WSS_SUCCESS = "GET_BM_WSS_SUCCESS";
export const GET_BM_WSS_FAIL = "GET_BM_WSS_FAIL";

export const GET_SALESGRUOP_USER_SUCCESS = "GET_SALESGRUOP_USER_SUCCESS";
export const GET_SALESGRUOP_USER_FAIL = "GET_SALESGRUOP_USER_FAIL";

export const GET_RBDM_CODE_SUCCESS = "GET_RBDM_CODE_SUCCESS";
export const GET_RBDM_CODE_FAIL = "GET_RBDM_CODE_FAIL";

export const GET_USER_TYPE_SUCCESS = "GET_USER_TYPE_SUCCESS";
export const GET_USER_TYPE_FAIL = "GET_USER_TYPE_FAIL";

export const GET_WSS_CODE_SUCCESS = "GET_WSS_CODE_SUCCESS";
export const GET_WSS_CODE_FAIL = "GET_WSS_CODE_FAIL";

export const GET_TOWN_CODE_SUCCESS = "GET_TOWN_CODE_SUCCESS";
export const GET_TOWN_CODE_FAIL = "GET_TOWN_CODE_FAIL";


export const SET_SESSIONALERT_SUCCESS = "SET_SESSIONALERT_SUCCESS";
export const SET_SESSIONALERT_FAIL = "SET_SESSIONALERT_FAIL";

export const SET_SELECTED_SALESGROUPCODE_SUCCESS = "SET_SELECTED_SALESGROUPCODE_SUCCESS";
export const SET_SELECTED_SALESGROUPCODE_FAIL = "SET_SELECTED_SALESGROUPCODE_FAIL";

export const SET_CHECK_IN_FLAG_SUCCESS = "SET_CHECK_IN_FLAG_SUCCESS";
export const SET_CHECK_IN_FLAG_FAIL = "SET_CHECK_IN_FLAG_FAIL";
