import { getOutletsBilledFtmApi } from "../../services/SalesOperation/getOutletsBilledFtmApi";
import { getOutletsBilledFtmTrendApi } from "../../services/SalesOperation/getOutletsBilledFtmTrendApi";
import { getRsmFtmApi } from "../../services/SalesOperation/getRsmFtmApi";
import { getRsmFtmTrendApi } from "../../services/SalesOperation/getRsmFtmTrendApi";
import {
  GET_OUTLETS_BILLED_FTM_FAIL,
  GET_OUTLETS_BILLED_FTM_SUCCESS,
  GET_OUTLETS_BILLED_FTM_TREND_FAIL,
  GET_OUTLETS_BILLED_FTM_TREND_SUCCESS,
  GET_RSM_FTM_FAIL,
  GET_RSM_FTM_SUCCESS,
  GET_RSM_FTM_TREND_FAIL,
  GET_RSM_FTM_TREND_SUCCESS,
} from "../constants/action-types";

export const getOutletsBilledFtm = (SH_Code__c, Title) => async (dispatch) => {
  const response = await getOutletsBilledFtmApi(SH_Code__c, Title);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_OUTLETS_BILLED_FTM_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_OUTLETS_BILLED_FTM_FAIL });
  }
};

export const getOutletsBilledFtmTrend =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getOutletsBilledFtmTrendApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_OUTLETS_BILLED_FTM_TREND_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_OUTLETS_BILLED_FTM_TREND_FAIL });
    }
  };

export const getRsmFtm = (SH_Code__c, Title) => async (dispatch) => {
  const response = await getRsmFtmApi(SH_Code__c, Title);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_RSM_FTM_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_RSM_FTM_FAIL });
  }
};

export const getRsmFtmTrend = (SH_Code__c, Title) => async (dispatch) => {
  const response = await getRsmFtmTrendApi(SH_Code__c, Title);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_RSM_FTM_TREND_SUCCESS,
      payload: response?.data?.info,
    });
  } else {
    dispatch({ type: GET_RSM_FTM_TREND_FAIL });
  }
};
