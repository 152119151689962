import {
  GET_MVR_DRAFT_SUCCESS,
  REMOVE_MVR_DRAFT_SUCCESS,
} from "../constants/action-types";

export const getMVRDraftDetails = (data) => async (dispatch) => {
  dispatch({
    type: GET_MVR_DRAFT_SUCCESS,
    payload: data,
  });
};

export const removeMVRDraftDetails = (data) => async (dispatch) => {
  dispatch({
    type: REMOVE_MVR_DRAFT_SUCCESS,
    payload: data,
  });
};
