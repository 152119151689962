import axios from "axios";
import { BASE_URL } from "../variables";

export const getUserDetailsApi = async ({ username }) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,name,SH_Code__c, Profile.Name, SH_Name__c, Email, Title, Division, Sales_Group_Code__c,MobilePhone,MConnect_Role__c,Photo__c from user where username = '${username}' `,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
