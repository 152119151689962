import React, { useState, useEffect } from "react";
import styles from "./QueryUpdate.module.css";
import { FiBox } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { queryUpdateApi } from "../../services/MarketWorking/MyTeam/queryUpdateApi";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";

const QueryUpdate = ({ queryUpdateApi, recId }) => {
  const [queryUpdate, setQueryUpdate] = useState();
  const [error, setError] = useState();
  const [allQuery, setAllQuery] = useState([]);
  const [sortQueries, setSortQueries] = useState();
  const [loading, setLoading] = useState(true);

  //Api call
  useEffect(() => {
    if (!_.isUndefined(recId)) {
      (async () => {
        const response = await queryUpdateApi(recId);
        if (response?.success) {
          setQueryUpdate(response?.data);
          setLoading(false);
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, []);

  //Creating array of object
  useEffect(() => {
    if (!_.isEmpty(queryUpdate)) {
      Object?.entries(queryUpdate)?.map(([key, value]) => {
        value?.map((data) => {
          setAllQuery((ls) => [...ls, data]);
        });
      });
    }
  }, [queryUpdate]);

  //Sort Query by Date
  useEffect(() => {
    setSortQueries(
      allQuery?.sort(function (x, y) {
        var firstDate = new Date(x.startDate),
          SecondDate = new Date(y.startDate);

        if (firstDate < SecondDate) return -1;
        if (firstDate > SecondDate) return 1;
        return 0;
      })
    );
  }, [allQuery]);

  return (
    <>
      <div className={styles.queryUpdate}>
        <div className={styles.queryUpdate__heading}>Query Update</div>
        <div className={loading && "showLoader"}>
          <div className="loaderOuterBox">
            <div className="loaderInnerBox">
              {loading && <ClipLoader loading={loading} color="#3901ff" />}
            </div>
          </div>
          <div className={styles.queryUpdate__mainbox}>
            <div className={styles.queryUpdate__innerbox}>
              <div className={styles.queryUpdate__arrow}>
                <IoIosArrowForward />
              </div>
              <div className={styles.queryUpdate__updateArea}>
                {sortQueries &&
                  sortQueries?.reverse()?.map((item) => {
                    return (
                      <div className={styles.queryUpdate__infobox}>
                        <span>
                          <FiBox className={styles.queryUpdate__icon} />
                        </span>
                        <h6 className={styles.queryUpdate__name}>
                          {item?.subHead}
                        </h6>
                        <h4 className={styles.queryUpdate__dateTime}>
                          {item?.startDate?.substring(0, 10)}
                        </h4>
                        <p className={styles.queryUpdate__dueDate}>
                          {item?.status}
                        </p>
                        <p className={styles.queryUpdate__dueDate}>
                          {item?.description}
                        </p>

                        <h6 className={styles.queryUpdate__name}>
                          {item?.subject}
                        </h6>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  queryUpdateApi,
})(QueryUpdate);
