import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./MyWssCounts.css";
import { BsChevronDown } from "react-icons/bs";
import styles from "./MarketWorking.module.css";
import { myWssLabel } from "..//Common/Constant";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { getWssCountApi } from "../../services/wss/getWssCountApi";
import CommonSnackbar from "../Common/CommonSnackbar";
import _ from "lodash";
import { setCheckInFlag } from "../../Redux/actions/wss-actions";

const InitialCount = [
  {
    Type: "Total",
    value: "0"
  },
  {
    Type: "Visited",
    value: "0"
  },
  {
    Type: "Not Visited",
    value: "0"
  },
  {
    Type: "New",
    value: "0"
  },
];

const MyWssCounts = () => {

  const [id, setId] = useState(0);
  const [selectedValue, setSelectedValue] = useState("Quaterly");
  const [wssCounts, setWssCounts] = useState();

  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [failedOpen, setFailedOpen] = useState(false);
  const [wssCountData, setWssCountData] = useState(InitialCount);
  const [wssCountFlag, setWssCountFlag] = useState(false);
  const [wssNotVisitedCount, setWssNotVisitedCount] = useState("");
  const ischeckInFlag = useSelector((state) => state?.checkInFlag?.wssCountFlag);
  const territoryList = useSelector((state) => state?.territory?.territoryDetails);
  const [filterValue, setfilterValue] = useState("3");
  
  // Label data for filters

  const countFilterLabel = [
      {
          dropdownItems: "Quaterly",
          id: 0,
      },
      {
          dropdownItems: "Bi-annual",
          id: 1,
      },

  ];
      
  // WSS count filters

  useEffect(() => {
    if (wssCounts){
      if (selectedValue == "Bi-annual"){
        setfilterValue("6");
      }else if (selectedValue == "Quaterly"){
        setfilterValue("3");
      }
    }
  }, [selectedValue])

  // API call to get Wss Counts
  useEffect(() => {
    (async () => {
      if (!_.isEmpty(filterValue) && !_.isEmpty(territoryList)){
        const response = await getWssCountApi(territoryList, filterValue);
        if (response?.success) {
          setWssCounts(response?.data);
          setWssCountData(response?.data);
          setWssNotVisitedCount(response?.data[2].value);
        } else {
          setError(response?.msg);
        }
      }
    })();
  }, [filterValue, territoryList]);

  // To show the count alert msg one home page after login
  useEffect(() => {
    if (ischeckInFlag == true && wssNotVisitedCount > 0) {
      setFailedOpen(true);
    }
  }, [wssNotVisitedCount])

  // To avoid the alert msg frequently after login 
  useEffect(() => {
    if (wssCountFlag == true){
      dispatch(setCheckInFlag(false));
    }
  }, [wssCountFlag])

  // Function call to maually close the alert 
  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailedOpen(false);
    setWssCountFlag(true);
  };

  return (
    <>
      {ischeckInFlag && wssNotVisitedCount && (
        <CommonSnackbar
          open={failedOpen}
          autoHideDuration={2000}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          severity={"warning"}
          message={`${wssNotVisitedCount} WSS pending for visit in this quarter`}
        />
      )}
      <div className={styles.marketWorking}>
        <div className={`${styles.marketWorking__header} row`}>
          <div className={`col-lg-10 col-md-8 col-6 ${styles.marketWorking__title}`}>
            My Wss
          </div>
          <div className={`col-lg-2 col-md-4 col-6 ${styles.marketWorking__label}`}>
                <Dropdown className="myWssCount__dropdownBox">
                    <Dropdown.Toggle id="dropdown-basic" className="myWssCount__drop">
                    <span className="myWssCount__selectedValue" style={{ color: "white"}}>
                        {selectedValue}
                    </span>
                    <BsChevronDown style={{ marginLeft: "6px"}} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                    className="myWssCount__dropdownMenu"
                    style={{ minWidth: "5rem", inset: "0px auto auto 75px" }}
                    >
                    <div className="myWssCount__inputradioBox">
                  {countFilterLabel.map((item, index) => {
                        return (
                            <>
                            <div>
                                <Dropdown.Item
                                className="myWssCount__dropdownItem"
                                href="#/action-1"
                                >
                                <Form.Check
                                    type="radio"
                                    name="group"
                                    aria-label="radio 1"
                                    className="myWssCount__inputRadioButton"
                                    checked={
                                    selectedValue && index === id ? true : false
                                    }
                                    value={item?.dropdownItems}
                                    id={index + 1}
                                    onChange={(e) => {
                                    setSelectedValue(e.target.value);
                                    setId(item?.id);
                                    }}
                                />
                                <label for={index + 1}>{item?.dropdownItems}</label>
                                </Dropdown.Item>
                            </div>
                            </>
                        );
                        })}
                    </div>
                    </Dropdown.Menu>
                </Dropdown>
          </div>
        </div>
        <div className={`row ${styles.marketWorking__dataBox}`}>
          <div className={`row ${styles.marketWorking__data}`}>
            <>
              {wssCountData.length !== 0 &&
                wssCountData?.map((item) => {
                    return (
                      <div
                        // onClick={() => onClickCountHandler(item)}
                        className={`col-3 ${styles.marketWorking__dataNumber}`}
                      >
                        {item.value}
                      </div>
                    );
                })}
              {myWssLabel?.map((items) => (
                <>
                  <div
                    className="col-3"
                    // onClick={() => onClickCountHandler(items)}
                  >
                    <div className={styles.marketWorking__dataHeading}>
                      {items.heading}
                    </div>
                    <div className={styles.marketWorking__dataBottomHeading}>
                      {items.heading_second}
                    </div>
                  </div>
                </>
              ))}
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyWssCounts