import axios from "axios";
import { sessionExpireTimeout } from "../../../Redux/actions/utility-actions";
import { BASE_URL } from "../../../variables";

export const queryUpdateApi = (recId) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/apexrest/Query_Update/`,
    method: "post",
    data: { recId: recId },
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err.code === "ERR_NETWORK") {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
