import axios from "axios";
import { BASE_URL } from "../../variables";

export const getHoNsmEmailApi = async (Sales_Group_Code__c) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,Username,Profile.Name,Email from user where Sales_Group_Code__c  =${Sales_Group_Code__c} AND Title ='SH7'  AND IsActive =true `,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
