import { SET_SELECTED_SALESGROUPCODE_SUCCESS, SET_SELECTED_SALESGROUPCODE_FAIL } from "../constants/action-types";

export const SaveSelectedSalesGroupCode = (salesGroupcode) => async (dispatch) => {
    if (salesGroupcode) {
        dispatch({
            type: SET_SELECTED_SALESGROUPCODE_SUCCESS,
            payload: salesGroupcode,
        });
    } else {
        dispatch({
            type: SET_SELECTED_SALESGROUPCODE_FAIL,
        });
    }
};