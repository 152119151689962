import { SET_SESSIONALERT_SUCCESS, SET_SESSIONALERT_FAIL } from "../constants/action-types";

export const SessionOutAlert = (data) => async (dispatch) => {
    if (data) {
        dispatch({
            type: SET_SESSIONALERT_SUCCESS,
            payload: data,
        });
    }else {
        dispatch({
            type: SET_SESSIONALERT_FAIL,
        });
    }
};