import axios from "axios";
import { BASE_URL } from "../../variables";

export const getCwpClusterApi = async ({ SH5_Code__c, SH6_Code__c }) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=SELECT SH2_Employee_Name__c,SH2_Name__c,SH3_Name__c,SH2_Phone__c,SH2_Code__c,SH2_Head_Quarter__c,SH3_Code__c,SH5_Code__c,SH5_Name__c,SH6_Code__c,SH6_Name__c,Sales_Group__c FROM Pidilite_Hierarchy__c WHERE SH5_Code__c='${SH5_Code__c}' AND SH6_Code__c='${SH6_Code__c}' AND RecordType.Name = 'Sales Hierarchy' AND SH3_Code__c != null`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
