import React, { useEffect, useState } from "react";
import styles from "./CWP.module.css";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { getSalesGroupCodeApi } from "../../services/Cwp/getSalesGroupCodeApi";
import { getCwpRegionApi } from "../../services/Cwp/getCwpRegionApi";
import { getCwpClusterApi } from "../../services/Cwp/getCwpClusterApi";
import { getCwpDetailsApi } from "../../services/Cwp/getCwpDetailsApi";
import { getDealerLocatorDetailsApi } from "../../services/Cwp/getDealerLocatorDetailsApi";
import { getUniqueListBy } from "../../Components/Common/Function";

function CWP({ getSalesGroupCodeApi }) {
  const [zone, setZone] = useState();
  const [error, setError] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCluster, setSelectedCluster] = useState();
  const [region, setRegion] = useState([]);
  const [cluster, setCluster] = useState([]);
  const [salesGroupValue, setSalesGroupValue] = useState();
  const [salesGroupCode, setSalesGroupCode] = useState("");
  const [cwpDetails, setCwpDetails] = useState({
    dealerNew: 0,
    dealerOverdue: 0,
    isBM: 0,
    salesExpNew: 0,
    salesExpOverdue: 0,
    salesOpsNew: 0,
    salesOpsOverdue: 0,
    wssNew: 0,
    wssOverdue: 0,
  });
  const [feedback, setFeedback] = useState({
    cluster: "",
    region: "",
    zone: "",
    salesGroup: "",
  });

  const onChangeSalesGroup = (e) => {
    setSalesGroupCode(e.target.value.split(":")?.[1]);
  };

  //to get Zone
  useEffect(() => {
    (async () => {
      const response = await getSalesGroupCodeApi({ salesGroupCode });
      if (response?.success) {
        const FilterZone = getUniqueListBy(
          response?.data?.records,
          "SH6_Name__c"
        );
        setZone(FilterZone);
      } else {
        setError(response?.msg);
      }
    })();
  }, [salesGroupCode]);

  // to get sales group
  useEffect(() => {
    (async () => {
      const response = await getDealerLocatorDetailsApi();
      if (response?.success) {
        setSalesGroupValue(response?.data?.Sales_Group);
      } else {
        setError(response?.msg);
      }
    })();
  }, []);

  const onChange = async (e) => {
    setFeedback({ ...feedback, [e.target.name]: e.target.value });
  };

  // TO get region

  useEffect(() => {
    if (feedback?.zone) {
      (async () => {
        const regionData = feedback?.zone?.split(":");
        let SH6_Name__c = regionData[0];
        let SH6_Code__c = regionData[1];
        const response = await getCwpRegionApi({ SH6_Name__c, SH6_Code__c });
        if (response?.success) {
          const FilterRegion = getUniqueListBy(
            response?.data?.records,
            "SH5_Name__c"
          );
          setRegion(FilterRegion);
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [feedback?.zone]);

  // TO get cluster

  const onChangeRegion = (e) => {
    setSelectedRegion(e.target.value);
  };

  useEffect(() => {
    if (selectedRegion) {
      (async () => {
        const data = selectedRegion?.split("||");
        const clustorData = data[0]?.split(":");
        setFeedback({ ...feedback, region: data[1] });
        let SH5_Code__c = clustorData[0];
        let SH6_Code__c = clustorData[1];
        const response = await getCwpClusterApi({ SH5_Code__c, SH6_Code__c });
        if (response?.success) {
          const FilterCluster = getUniqueListBy(
            response?.data?.records,
            "SH3_Name__c"
          );
          setCluster(FilterCluster);
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [selectedRegion]);

  // To get CWP Details

  const onChangeCluster = (e) => {
    setSelectedCluster(e.target.value);
    setFeedback({ ...feedback, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (selectedCluster) {
      submit();
    }
  }, [selectedCluster]);

  const submit = async () => {
    const clusterData = selectedRegion?.split("||");
    const data = {
      Cluster: feedback?.cluster,
      Region: feedback?.region,
      Zone: feedback?.zone,
      SalesGroup: feedback?.salesGroup,
    };
    const { Cluster, Region, Zone, SalesGroup } = data;
    const response = await getCwpDetailsApi({
      Cluster,
      Region,
      Zone,
      SalesGroup,
    });
    if (response?.success) {
      setCwpDetails(response?.data?.Cwp);
      //TODO: to show success msg popup if required
    } else {
      //TODO : to show error msg popup
    }
  };
  return (
    <>
      <div className="container p-0">
        <div className={styles.cwp}>
          <div className={styles.cwp__mainBox}>
            <div className={styles.cwp__selectableRows}>
              <label className={styles.cwp__selectLabel}>Sales Group</label>
              <Form.Select
                size="sm"
                name="salesGroup"
                onChange={onChangeSalesGroup}
                className={`required ${styles.cwp__selectFieldLarge}`}
              >
                <option>Select Sales Group</option>

                {salesGroupValue &&
                  salesGroupValue?.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
              </Form.Select>
            </div>
            <div className={styles.cwp__selectableRows}>
              <label className={styles.cwp__selectLabel}>Select Zone</label>
              <Form.Select
                aria-label="Default select example"
                size="sm"
                name="zone"
                className={`required ${styles.cwp__selectFieldLarge}`}
                onChange={(e) => onChange(e)}
                // onChange={onChangeHandler}
              >
                <option>Select Zone</option>
                {zone?.map((code) => {
                  return (
                    <option value={`${code?.SH6_Name__c}:${code?.SH6_Code__c}`}>
                      {code?.SH6_Name__c} : {code?.SH6_Code__c}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className={styles.cwp__selectableRows}>
              <label className={styles.cwp__selectLabel}>Select Region</label>
              <Form.Select
                aria-label="Default select example"
                size="sm"
                name="region"
                className={`required ${styles.cwp__selectFieldLarge}`}
                // onChange={(e) => onChange(e)}
                onChange={onChangeRegion}
              >
                <option>Select Region</option>
                {region?.map((name) => {
                  return (
                    <option
                      value={`${name?.SH5_Code__c}:${name?.SH6_Code__c}||${name?.SH5_Name__c}:${name?.SH5_Code__c}`}
                    >
                      {name?.SH5_Name__c} : {name?.SH5_Code__c}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className={styles.cwp__selectableRows}>
              <label className={styles.cwp__selectLabel}>Select Cluster</label>
              <Form.Select
                aria-label="Default select example"
                size="sm"
                name="cluster"
                className={`required ${styles.cwp__selectFieldLarge}`}
                // onChange={(e) => onChange(e)}
                onChange={onChangeCluster}
              >
                <option>Select Cluster</option>
                {cluster?.map((name) => {
                  return (
                    <option value={`${name?.SH3_Name__c}-${name?.SH3_Code__c}`}>
                      {name?.SH3_Name__c} : {name?.SH3_Code__c}
                    </option>
                  );
                })}
              </Form.Select>
            </div>

            <div className={`row ${styles.cwp__outerBox}`}>
              <>
                <div
                  className={`col-lg-2 col-md-5 col-5 p-0 ${styles.cwp__InnerBox}`}
                >
                  <div className={styles.cwp__Heading}>Sales Exeption</div>
                  <div className={styles.cwp_DetailsBox}>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Open</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.salesExpNew}
                      </span>
                    </div>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Overdue</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.salesExpOverdue}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-lg-2 col-md-5  col-5 p-0 ${styles.cwp__InnerBox}`}
                >
                  <div className={styles.cwp__Heading}>Sales Ops</div>
                  <div className={styles.cwp_DetailsBox}>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Open</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.salesOpsNew}
                      </span>
                    </div>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Overdue</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.salesOpsOverdue}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-lg-2  col-md-5 col-5 p-0 ${styles.cwp__InnerBox}`}
                >
                  <div className={styles.cwp__Heading}>Dealer Exception</div>
                  <div className={styles.cwp_DetailsBox}>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Open</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.dealerNew}
                      </span>
                    </div>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Overdue</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.dealerOverdue}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-lg-2 col-md-5 col-5 p-0 ${styles.cwp__InnerBox}`}
                >
                  <div className={styles.cwp__Heading}>WSS</div>
                  <div className={styles.cwp_DetailsBox}>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Open</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.wssNew}
                      </span>
                    </div>
                    <div className={styles.cwp_DetailsRow}>
                      <span className={styles.cwp__DetailsName}>Overdue</span>
                      <span className={styles.cwp__DetailsValue}>
                        {cwpDetails.wssOverdue}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, {
  getSalesGroupCodeApi,
})(CWP);
