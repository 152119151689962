import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import styles from "./MarketVisitTabs.module.css";
import Table from "react-bootstrap/Table";
import { connect, useSelector, useDispatch } from "react-redux";
import { getTotalFeedbackApi } from "../../services/MarketWorking/MyDiary/getTotalFeedbackApi";
import { updateSelectedInsights } from "../../Redux/actions/utility-actions";
import { tabs } from "../Common/Constant";
import Message from "../Common/Message";

const MarketVisitTabs = () => {
  const [tab, setTab] = useState("Dealer");
  const [data, setData] = useState([]);
  const [selectedInsights, setSelectedInsights] = useState([]);
  const todayreportDetails = useSelector(
    (state) => state?.getFeedback?.getFeedbackDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    var filteredInsights = todayreportDetails?.Feedback_Today?.filter(
      (item) => item?.RecordType?.Name === `My ${tab}`
    );
    filteredInsights = filteredInsights?.map((x) => ({
      ...x,
      isAttached: selectedInsights?.includes(x?.Id),
    }));
    setData(filteredInsights);
  }, [tab]);

  const onAddInsight = (e, insight) => {
    e.preventDefault();
    let currentInsights = [...selectedInsights];
    currentInsights.push(insight?.Id);
    setSelectedInsights(currentInsights);
    dispatch(updateSelectedInsights(currentInsights));

    const dataCopy = [...data];
    const objIndex = dataCopy?.findIndex((obj) => obj?.Id === insight?.Id);
    dataCopy[objIndex].isAttached = true;
    setData(dataCopy);
  };

  const onDeleteInsight = (e, insight) => {
    e.preventDefault();
    let currentInsights = [...selectedInsights];
    currentInsights = currentInsights?.filter((x) => x !== insight?.Id);
    setSelectedInsights(currentInsights);
    dispatch(updateSelectedInsights(currentInsights));

    const dataCopy = [...data];
    const objIndex = dataCopy?.findIndex((obj) => obj?.Id === insight?.Id);
    dataCopy[objIndex].isAttached = false;
    setData(dataCopy);
  };

  const insightExist = (id) => {
    const currentInsights = [...selectedInsights];
    if (currentInsights?.find((x) => x === id) !== undefined) return true;
    else return false;
  };

  return (
    <>
      <div>
        <div className={styles.MarketVisitTabs}>
          <div className={styles.MarketVisitTabs__heading}>
            Attach Observations
          </div>
          <div className={styles.MarketVisitTabs__mainbox}>
            <Tabs
              defaultActiveKey="Dealer"
              id="fill-tab-example"
              className="MarketVisitTabs__tabHeader"
              onSelect={(tab) => {
                setTab(tab);
              }}
              fill
            >
              {tabs?.map((tab) => {
                return (
                  <Tab eventKey={tab} title={tab}>
                    <div className="MarketVisitTabs__tabInnerBox">
                      <div className={styles.MarketVisitTabs__outerInputBox}>
                        <form>
                          <Table bordered hover>
                            <thead>
                              {data?.length > 0 ? (
                                <tr>
                                  <th
                                    className={
                                      styles.MarketVisitTabs__textHeader
                                    }
                                  >
                                    Insight
                                  </th>
                                  <th
                                    className={
                                      styles.MarketVisitTabs__textHeader
                                    }
                                  >
                                    Click to add
                                  </th>
                                </tr>
                              ) : (
                                <Message
                                  message={"No Observations"}
                                  className={"marketVisit__icon"}
                                />
                              )}
                            </thead>
                            <tbody>
                              {data?.map((item) => {
                                return (
                                  <tr>
                                    <td
                                      className={styles.MarketVisitTabs__Text}
                                    >
                                      {item.Observations__c}
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                      {item?.isAttached ? (
                                        <button
                                          className={
                                            styles.MarketVisitTabs__deleteBtn
                                          }
                                          onClick={(e) =>
                                            onDeleteInsight(e, item)
                                          }
                                        >
                                          Remove <strong>(-)</strong>
                                        </button>
                                      ) : (
                                        <button
                                          className={
                                            styles.MarketVisitTabs__addBtn
                                          }
                                          disabled={insightExist(item?.Id)}
                                          onClick={(e) => onAddInsight(e, item)}
                                        >
                                          {" "}
                                          Add (+){" "}
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </form>
                      </div>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getTotalFeedbackApi,
})(MarketVisitTabs);
