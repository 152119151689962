import React, { useState, useEffect } from "react";
import _ from "lodash";
import styles from "./MarketWorking.module.css";
import { useSelector, useDispatch } from "react-redux";
import { mydealerCountLabel } from "..//Common/Constant";

import { getDealerPhysicalVerificationCountApi } from "../../services/Teams/getDealerPhysicalVerificationCountApi";

const InitialCount = [
    {
        Type: "Total Validation Requests",
        value: "0"
    },
    {
        Type: "Validated",
        value: "0"
    },
    {
        Type: "Pending",
        value: "0"
    }
];

const MyDealerCount = () => {

    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [failedOpen, setFailedOpen] = useState(false);
    const [dealerCountData, setDealerCountData] = useState(InitialCount);
    const territoryList = useSelector((state) => state?.territory?.territoryDetails);

    // ------------------------------------------------ Dealer Physical Verification Count API ------------------------------------------------------

    // API call to get Wss Counts
    useEffect(() => {
        (async () => {
            if (!_.isEmpty(territoryList)) {
                const response = await getDealerPhysicalVerificationCountApi( territoryList );
                if (response?.status === 200) {
                    const updatedCount = InitialCount.map(item => {
                        if (item.Type === "Total Validation Requests") {
                            return { ...item, value: response?.data[0].total_count };
                        } else if (item.Type === "Validated") {
                            return { ...item, value: response?.data[0].validation_count };
                        } else if (item.Type === "Pending") {
                            return { ...item, value: response?.data[0].pending_count };
                        } else {
                            return item;
                        }
                    });
                    setDealerCountData(updatedCount);
                } else {
                    setError(response?.msg);
                }
            }
        })();
    }, []);

    // -------------------------------------------------------------- End ---------------------------------------------------------------

    return (
        <>
            <div className={styles.marketWorking}>
                <div className={`${styles.marketWorking__header} row`}>
                    <div className={`col-lg-10 col-md-8 col-6 ${styles.marketWorking__title}`}>
                        My Dealer
                    </div>
                </div>
                <div className={`row ${styles.marketWorking__dataBox}`}>
                    <div className={`row ${styles.marketWorking__data}`}>
                        <>
                            {dealerCountData.length !== 0 &&
                                dealerCountData?.map((item) => {
                                    return (
                                        <div
                                            className={`col-4 ${styles.marketWorking__dataNumber}`}
                                        >
                                            {item.value !== null ? item.value : '0'}
                                        </div>
                                    );
                                })}
                            {mydealerCountLabel?.map((items) => (
                                <>
                                    <div
                                        className="col-4"
                                    >
                                        <div className={styles.marketWorking__dataHeading}>
                                            {items.heading}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyDealerCount