import React, { useState, useEffect, useRef } from "react";
import styles from "./sidebar.module.css";
import { GrLocation, GrDocumentText } from "react-icons/gr";
import { FiPieChart, FiLogIn } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { SiMarketo } from "react-icons/si";
import { FaLayerGroup } from "react-icons/fa";
import { BsCalendarCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import profile from "../../Assets/Images/profile11.png";
import report from "../../Assets/Images/report.png";
import survey from "../../Assets/Images/survey.png";
import cwp from "../../Assets/Images/cwp.svg";
import Accordion from "react-bootstrap/Accordion";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { connect } from "react-redux";
import { logout } from "../../Redux/actions/utility-actions";
import { useSelector } from "react-redux";
import DivisionGroup from "../HomePage/DivisionGroup";
import packageJson from "../../../package.json";
import _ from "lodash";

const Sidebar = ({ logout, setSidebar, sidebar }) => {
  const [active, setActive] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [url, setUrl] = useState({
    path: "",
    id: "",
  });
  const [disableClass, setDisabledClass] = useState(false);
  const [isDivisionGroupClicked, setIsDivisionGroupClicked] = useState(false);

  const profileImage = useSelector(
    (state) => state?.userDetails?.details?.Photo__c
  );
  const isAuthenticated = useSelector(
    (state) => state?.tokens?.isAuthenticated
  );
  const userName = useSelector((state) => state?.userDetails?.details?.Name);
  const salesGroupCode = useSelector(
    (state) => state?.userDetails?.details?.Sales_Group_Code__c
  );
  const headOfficeUser = useSelector(
    (state) => state?.userDetails?.details?.Profile?.Name
  );

  const userType = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);
  const getUrl = (path, id) => {
    setUrl({
      path: path,
      id: id,
    });
  };

  const userDivision = useSelector((state) => state?.userDetails?.details?.Division);
  const HO_Role__c = useSelector((state) => state?.userType?.type)

  const MenuRef = useRef();
  const appVersion = packageJson.version;

  useEffect(() => {
    const handle = (e) => {
      if (!MenuRef.current.contains(e.target)) {
        setSidebar(false);
      }
    };
    document.addEventListener("mousedown", handle);
    return () => {
      document.removeEventListener("mousedown", handle);
    };
  });

  const menu = [
    {
      to: "dealer-locator",
      id: 1,
      icon: <GrLocation className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__tabBox,
      title: "Dealer Locator",
    },
    userType !== "MH3" && {
      to: "market-working",
      id: 2,
      icon: <BsCalendarCheck className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__tabBox,
      title: "Key Information",
    },
    {
      to: "monthly-tour-plan",
      id: 3,
      icon: <SiMarketo className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__tabBox,
      title: "Monthly Tour Plan",
    },
    {
      to: "market-visit-report",
      id: 4,
      icon: (
        <HiOutlineDocumentReport
          className={`${styles.sidebar__tabBoxIcon} ${styles.sidebar__marketWorking}`}
        />
      ),
      className: styles.sidebar__tabBox,
      title: "Market Visit Report",
    },
    {
      to: "key-information",
      id: 7,
      icon: <FiPieChart className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__tabBox,
      title: "Market Working",
    },
    {
      to: "my-diary",
      id: 6,
      // icon: <FiIcons.FiEdit className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__subMenuTab,
      title: "My Diary",
    },
    {
      to: "queries",
      id: 5,
      className: styles.sidebar__subMenuTab,
      title: "My Service Request",
    },
    {
      to: "my-team",
      id: 8,
      className: styles.sidebar__subMenuTab,
      title: "My Team",
    },
    {
      to: "my-dealer",
      id: 10,
      className: styles.sidebar__subMenuTab,
      title: " My Dealer",
    },
    {
      to: "my-user",
      id: 11,
      className: styles.sidebar__subMenuTab,
      title: "My User",
    },
    {
      to: "my-wss",
      id: 12,
      className: styles.sidebar__subMenuTab,
      title: "My WSS",
    },
    {
      to: "pil-Surveys",
      id: 12,
      className: styles.sidebar__subMenuTab,
      title: "Surveys",
    },
    (userType == 'SH3' && userDivision == '70') && {
      to: "DRM-Summary",
      id: 12,
      className: styles.sidebar__subMenuTab,
      title: "DRM",
    },
  
    // {
    //   to: "my-product",
    //   id: 13,
    //   className: styles.sidebar__subMenuTab,
    //   title: "Product",
    // },
    userType === "SH3" && {
      to: "current-day-tour",
      id: 9,
      className: styles.sidebar__tabBox,
      icon: <GrDocumentText className={styles.sidebar__tabBoxIcon} />,
      title: "Current day tour",
    },
    // {
    //   to: "exception-dealer",
    //   id: 11,
    //   icon: <FiIcons.FiEdit className={styles.sidebar__tabBoxIcon} />,
    //   className: styles.sidebar__tabBox,
    //   title: "Exception Dealer",
    // },
    // {
    //   to: "tour-report",
    //   id: 14,
    //   icon: (
    //     <img
    //       src={report}
    //       className={styles.sidebar__tabImgIcons}
    //       alt="tabImage_Icons"
    //     />
    //   ),
    //   className: styles.sidebar__tabBox,
    //   title: "Tour Report",
    // },
    // {
    //   to: "survey",
    //   id: 15,
    //   icon: (
    //     <img
    //       src={survey}
    //       className={styles.sidebar__tabImgIcons}
    //       alt="tabImage_Icons"
    //     />
    //   ),
    //   className: styles.sidebar__tabBox,
    //   title: "Survey",
    // },

    // headOfficeUser === "HO Colloaborator" && {
    //   to: "cwp",
    //   id: 16,
    //   icon: (
    //     <img
    //       src={cwp}
    //       className={styles.sidebar__tabImgIcons}
    //       alt="tabImage_Icons"
    //     />
    //   ),
    //   className: styles.sidebar__tabBox,
    //   title: "CWP",
    // },
    (userType === "HO Collaborator" || userType === "SH7") &&
    (!salesGroupCode || userDivision == 70) && (HO_Role__c != "RBDM Collaborator" && HO_Role__c != "MH3") && {
      to: "",
      id: 17,
      icon: <FaLayerGroup className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__tabBox,
      title: "Division/Sales Group",
    },
    {
      to: "my-profile",
      id: 18,
      icon: <CgProfile className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__tabBox,
      title: "My Profile",
    },
    {
      to: "",
      id: 19,
      icon: <FiLogIn className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__tabBox,
      title: "Logout",
    },
    {
      to: "",
      id: 20,
      // icon: <FiIcons.FiLogIn className={styles.sidebar__tabBoxIcon} />,
      className: styles.sidebar__helpBox,
      title: `M-Connect v${appVersion}`,
    },
    // {
    //   to: "help-contact",
    //   id: 17,
    //   icon: <FiIcons.FiHelpCircle className={styles.sidebar__helpBoxIcon} />,
    //   className: styles.sidebar__helpBox,
    //   title: "Help & Contact",
    // },
  ];

  const onClickHandler = (item) => {
    if (item?.title === "Logout") {
      setIsLogout(true);
    }
    if (item?.title === "Division/Sales Group") {
      localStorage.setItem("pop_status", 2);
      setIsDivisionGroupClicked(true);
    }

    if (item?.id === 7) {
      setDisabledClass(true);
    } else {
      setDisabledClass(false);
    }

    getUrl(item.to, item.id);
    setIsMenuClicked(true);
  };

  useEffect(() => {
    if (isLogout) {
      logout();
    }
  }, [isLogout]);

  useEffect(() => {
    if (disableClass) {
      setSidebar(true);
      setIsMenuClicked(false);
    } else {
      if (
        url?.path ===
        window?.location?.href?.split("/")?.slice(-1)?.toString() &&
        isAuthenticated
      ) {
        setActive(true);
        setSidebar(false);
        setIsMenuClicked(false);
      } else if (url?.path === ""){
        setActive(false);
        setSidebar(false);
        setIsMenuClicked(false);
      } else {
        setActive(false);
        setSidebar(true);
        setIsMenuClicked(false);
      }
    }
  }, [window?.location?.href, isMenuClicked]);

  return (
    <>
      <div
        className={styles.sidebar_MenuContainer}
        ref={MenuRef}
        onClick={() => setSidebar(!sidebar)}
      >
        <MenuOutlinedIcon
          className={
            sidebar ? styles.sidebar__menuIconActive : styles.sidebar__menuIcon
          }
        />
        <div
          className={
            sidebar
              ? `${styles.sidebar__menu} ${styles.sidebar__menuActive}`
              : styles.sidebar__menu
          }
        >
          <div className={styles.sidebar__header}>
            <div className="row">
              <div className={`col-4 px-0 ${styles.sidebar__userIconBox}`}>
                <Link to="/my-profile">
                  <img
                    src={
                      !_.isEmpty(profileImage)
                        ? `data:image/png;base64,${profileImage}`
                        : profile
                    }
                    alt="sidebar_userIcon"
                    className={styles.sidebar__userIcon}
                  />
                </Link>
              </div>
              <div className="col-8 py-3 px-0">
                <h5>{userName}</h5>
                <p style={{ fontWeight: "400" }}>{userType}</p>
                {/* <p>
                <HiIcons.HiOutlineLocationMarker
                  className={styles.sidebar__locationIcon}
                />
                Agra
              </p> */}
              </div>
            </div>
          </div>
          <div className={styles.sidebar__mainBox}>
            <div className={styles.sidebar__headerTitle}>Menu</div>
            <ul className={styles.sidebar__tabs}>
              {menu?.map((item) => {
                return (
                  <>
                    <Link
                      to={`/${item?.to}`}
                      onClick={() => onClickHandler(item)}
                    >
                      <li
                        className={`${item.className} ${active &&
                          url?.id === item.id &&
                          styles.sidebar__activeTab
                          }`}
                        aria-current="true"
                      >
                        {item.icon}
                        {item.title}
                      </li>
                    </Link>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {isDivisionGroupClicked && (
        <DivisionGroup setIsDivisionGroupClicked={setIsDivisionGroupClicked} />
      )}
    </>
  );
};

export default connect(null, { logout })(Sidebar);
