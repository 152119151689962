import React, { useEffect, useState } from "react";
import "./PILTable.css";
import moment from "moment";
import Message from "../Common/Message";

const PILTable = ({ pilData, brands, responseDates }) => {
  return (
    <>
      {brands.length != 0 ?
        <div className="pilTable">
          <div className="pilTable__box">
            <table responsive className="pilTable__table">
              <thead style={{ position: "sticky", top: "-11px" }}>
                <tr className="pilTable__theadRow">
                  <th className="pilTable__theadRowHeading">Response Date</th>
                  {brands.map((brand, index) => (
                    <th className="pilTable__theadRowHeading">{brand}</th>
                  ))}
                </tr>
              </thead>

              <tbody style={{ textAlign: "center" }}>
                {/* <tr>
                <td></td>
                {brands.map((brand, index) => (
                  <td>{brand}</td>
                ))}
              </tr> */}
                {responseDates.map((date) => {
                  return (
                    <tr>
                      <td>{date}</td>
                      {brands.map((brand) => {
                        return <td>{pilData?.[date]?.[brand]?.["value"] || 0}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        :
        <>
          <Message
            message={"No data found"}
            className={"tourPlan__messageConatiner"}
          />
        </>
      }
    </>
  );
};

export default PILTable;
