import axios from "axios";

import { BASE_URL } from "../../../variables";

export const getTerritory = async ({ TownCode__c , clusterCode}) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q= SELECT Id, TSI_Territory_Code__c, TSI_Territory_Name__c FROM Account where TownCode__c='${TownCode__c}' and Cluster_Code__c = '${clusterCode}' and TSI_Territory_Code__c!=null`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};