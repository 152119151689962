import React from "react";
import GoogleMapReact from "google-map-react";
import { MdLocationPin } from "react-icons/md";
import { configs } from "../../../config";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const GoogleMap = ({ lat, long, zoom }) => {
  const defaultProps = {
    center: {
      lat: lat,
      lng: long,
    },
    zoom: zoom,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "37vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: configs?.google_map_auth_key }}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={lat}
          lng={long}
          text=<MdLocationPin
            style={{ color: "#de0a26", height: "1.5rem", width: "1.5rem" }}
          />
        />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
