import axios from "axios";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";

export const getDealerNameApi = (territory) => async (dispatch) => {
  let result = "";
  if (territory?.length <= 1) {
    result = "'" + territory + "'";
  } else {
    for (let i = 0; i < territory.length; i++) {
      if (i != territory?.length - 1) {
        result = result + "'" + territory[i] + "'" + ",";
      } else {
        result = result + "'" + territory[i] + "'";
      }
    }
  }

  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=Select id,Dealer_Name__c, DealerCode__c , HierarchyLevel1WSSTerritory__c,WSS_Territory_Code__c,Town__c, DWKeyTown__c,WSS_Territory_Name__c, DealerLatitude__c, DealerLongitude__c from Account where RecordType.Name='Dealer' and HierarchyLevel1WSSTerritory__c in (${result})`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_REQUEST") {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
