import React, { useState, useEffect, useRef } from "react";
import "./Homepage.css";
import TabComponent from "../Components/HomePage/TabComponent";
import MonthlyTourPlanning from "../Components/HomePage/MonthlyTourPlanning";
import MarketWorking from "../Components/HomePage/MarketWorking";
import Header from "../Components/Header";
import DivisionGroup from "../Components/HomePage/DivisionGroup";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";
import MyWssCounts from "../Components/HomePage/MyWssCounts";
import MyDealerCount from "../Components/HomePage/MyDealerCount";

function Homepage() {

  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);
  const [showModal, setShowModal] = useState(false);

  const userType = useSelector(
    (state) => state?.userDetails?.details?.Profile?.Name
  );

  const salesGroupCode = useSelector(
    (state) => state?.userDetails?.details?.Sales_Group_Code__c
  );

  const division = useSelector(
    (state) => state?.userDetails?.details?.Division
  );

  const HO_Role__c = useSelector((state) => state?.userType?.type)
  // to show modal when user is HO

  const userTypeAndRole = useSelector((state) =>
    state?.userType?.type != "Other"
      ? state?.userType?.type
      : state?.userDetails?.details?.Title
  );

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!_.isEmpty(userType) && !_.isEmpty(HO_Role__c)) {
      if (HO_Role__c == "RBDM Collaborator" || HO_Role__c == "MH3") {
        setShowModal(false);
      }
      else if (userTypeAndRole && !pop_status && (userTypeAndRole === "HO Collaborator" || userTypeAndRole === "SH7")
        && (!salesGroupCode || division == 70 || division == 30 )) {
        setShowModal(true);
        localStorage.setItem("pop_status", 1);
      }
      else if (userType !== "HO Colloaborator") {
        setShowModal(false);;
      } else {
        setShowModal(false);
      }
    }

  }, [userType, salesGroupCode, division, HO_Role__c]);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  console.log("User type",userTypeAndRole);
  return (
    <div className="homepage container p-0">
      <Header />
      <TabComponent />
      <MonthlyTourPlanning />
      <MarketWorking />
      {userTypeAndRole == "SH3" && (
        <>
          <MyWssCounts />
          <MyDealerCount/>
        </>
      )}
      {showModal && <DivisionGroup />}
    </div>
  );
}

export default Homepage;
