import axios from "axios";
import { getNodeServerUrl } from "../../utils/getNodeUrl";
import { getUniqueListBy } from "../../Components/Common/Function";

export const getDealerPhysicalVerificationCountApi = async ( data ) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // Get tty codes only & make a list
  let TTYCodes = [];
  const TTYCodeData = getUniqueListBy(data, "SH2_Code__c");
  TTYCodeData?.map((item) => { TTYCodes.push(item?.SH2_Code__c) });
  const TTYCodesList = `(${TTYCodes.map(code => `'${code}'`).join(', ')})`;

  const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/dealer/getDealerPhysicalVerificationCount`,
    method: "post",
    headers: headers,
    data: { TTYCodesList }
  };
  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
