import axios from "axios";
import { BASE_URL } from "../../variables";




export const getAllMeeting = async ({userEmailid}) => {
  
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,CreatedDate,NSM_Status__c,ZSM_Status__c,ZSM_Feedback__c, NSM_Feedback__c,Actual_Date_of_the_Meet__c from Dealer_Relationship_Meet__c where User_Email__c='${userEmailid}'`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
