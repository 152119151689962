import React from "react";
import { connect } from "react-redux";
import Login from "../Login";

const Protected = (props) => {
  const Component = props?.component;
  return <div>{props?.isAuthenticated ? <Component /> : <Login />}</div>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state?.tokens?.isAuthenticated,
});

export default connect(mapStateToProps)(Protected);
