import { combineReducers } from "redux";
import {
  pbiTokenReducer,
  tokenReducer,
  userDetailsReducer,
  alertReducer,
  queryDetailsReducer,
  territoryDetailsReducer,
  marketWorkingReducer,
  barGraphReducer,
  visitDetailsReducer,
  upcomingTourPlanReducer,
  pastInsightReducer,
  getFeedbackReducer,
  getDivisionGroupReducer,
  getDashboardReducer,
  insightsReducer,
  clusterCodeReducer,
  currentDayTourReducer,
  HoUserSh7CodeReducer,
  productReducer,
  myUserFeedbackReducer,
  RmdmTownReducer,
  rmdmTownReducer,
  mvrDraftReducer,
  bmWssReducer,
  salesgroupUserReducer,
  RBDMCodeReducer,
  getUserTypeReducer,
  wssOptionsReducer,
  townOptionsReducer,
  setSessionAlertReducer,
  setCheckInFlagReducer,
  setSelectedSalesGroupCode
} from "./utilityReducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "tokens",
    "userDetails",
    "territory",
    "getFeedback",
    "barGraph",
    "marketWorking",
    "visitDetails",
    "upcomingTourPlan",
    "dashBoardDetails",
    "divisionGroup",
    "clusterCode",
    "currentDayTour",
    "HoUserSh7Code",
    "product",
    "myUserFeedback",
    "rmdmTown",
    "mvrDraft",
    "bmWss",
    "salesgroupUser",
    "RBDMCode",
    "userType",
    "wssOptionsData",
    "townOptionsData",
  ],
};

const reducers = combineReducers({
  tokens: tokenReducer,
  pbiToken: pbiTokenReducer,
  userDetails: userDetailsReducer,
  alerts: alertReducer,
  query: queryDetailsReducer,
  territory: territoryDetailsReducer,
  marketWorking: marketWorkingReducer,
  barGraph: barGraphReducer,
  visitDetails: visitDetailsReducer,
  upcomingTourPlan: upcomingTourPlanReducer,
  pastInsight: pastInsightReducer,
  getFeedback: getFeedbackReducer,
  divisionGroup: getDivisionGroupReducer,
  dashBoardDetails: getDashboardReducer,
  insights: insightsReducer,
  clusterCode: clusterCodeReducer,
  currentDayTour: currentDayTourReducer,
  HoUserSh7Code: HoUserSh7CodeReducer,
  product: productReducer,
  myUserFeedback: myUserFeedbackReducer,
  rmdmTown: rmdmTownReducer,
  mvrDraft: mvrDraftReducer,
  bmWss: bmWssReducer,
  wssOptionsData: wssOptionsReducer,
  townOptionsData: townOptionsReducer,
  salesgroupUser: salesgroupUserReducer,
  RBDMCode: RBDMCodeReducer,
  userType: getUserTypeReducer,
  sessionAlert: setSessionAlertReducer,
  SelectedSalesGroupCode: setSelectedSalesGroupCode,
  checkInFlag: setCheckInFlagReducer
});

export default persistReducer(persistConfig, reducers);
