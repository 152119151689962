import React, { useState } from "react";
import styles from "./Reports.module.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { CiCalendar } from "react-icons/ci";
import { Button } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { getTotalFeedbackApi } from "../../services/MarketWorking/MyDiary/getTotalFeedbackApi";
import { addTodaysTourReportApi } from "../../services/MarketWorking/addTodaysTourReportApi";
import _ from "lodash";

import { connect, useSelector } from "react-redux";
import CommonSnackbar from "../Common/CommonSnackbar";
import { getTodaysDate } from "../Common/Function";
import Loader from "../Common/Loader";

const Reports = ({ addTodaysTourReportApi }) => {
  const [observation, setObservation] = useState("");
  const [id, setId] = useState();
  const [onClick, setOnCLick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const todayreportDetails = useSelector(
    (state) => state?.getFeedback?.getFeedbackDetails
  );
  const previousObsDetails = useSelector(
    (state) => state?.getFeedback?.getFeedbackDetails?.Previous_Feedback
  );

  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  // TO Submit Today's Editable Field & Post API Call

  const onSubmit = async (id) => {
    if (!_.isEmpty(observation)) {
      const data = {
        RecordId: id,
        Observation: observation,
      };
      const { RecordId, Observation } = data;
      setLoading(true);
      const response = await addTodaysTourReportApi({
        RecordId,
        Observation,
      });
      setLoading(false);
      if (response?.success) {
        setSuccessOpen(true);
        setId();
        //TODO: to show success msg popup if required
      } else {
        //TODO : to show error msg popup
      }
      setObservation("");
    }
  };
  return (
    <>
      <>
        <CommonSnackbar
          open={successOpen}
          autoHideDuration={3000}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          severity={"success"}
          message={"Tour report edited successfully"}
        />
      </>
      <div className={styles.reports}>
        <Loader loading={loading} />
        <div className={styles.reports__headingbox}>
          <div className={styles.reports__heading}>Reports</div>
        </div>

        <div className={styles.reports__mainbox}>
          <Tabs
            defaultActiveKey="today"
            id="fill-tab-example"
            className="reports__tabHeader"
            fill
          >
            {/* Todays Tour Report Tab UI*/}
            <Tab eventKey="today" title="Today's Tour Report">
              <div className={styles.reports__todayMainBox}>
                <div className={styles.reports__todayHeaderBox}>
                  <div className={styles.reports__todayHeaderTitle}>
                    Insights Captured
                  </div>
                  <div className="d-flex">
                    <div className={styles.reports__todayCalenderBox}>
                      <CiCalendar
                        className={styles.reports__todayCalenderIcon}
                      />
                    </div>
                    <div className={styles.reports__todayDate}>
                      {getTodaysDate()}
                    </div>
                  </div>
                </div>
                <div className={styles.reports__todayInnerBox}>
                  {todayreportDetails?.Feedback_Today?.map((item) => {
                    return (
                      <>
                        <div key={item?.id}>
                          <div className={styles.reports__todayheading}>
                            {item?.RecordType?.Name}
                          </div>
                          <textarea
                            type="text"
                            onChange={(e) => setObservation(e.target.value)}
                            disabled={item?.Id === id ? false : true}
                            className={
                              onClick && item.Id === id
                                ? styles.report__todayInnerBoxEditText
                                : styles.report__todayInnerBoxText
                            }
                          >
                            {item.Observations__c}
                          </textarea>
                          <div className={styles.reports__todayBuuttonBox}>
                            <div>
                              <Button
                                className={styles.reports__todayEditBtn}
                                onClick={() => {
                                  setId(item?.Id);
                                  setOnCLick(true);
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                            <div>
                              <Button
                                className={styles.reports__todaySubmitBtn}
                                id={item?.Id}
                                onClick={() => onSubmit(item?.Id)}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {todayreportDetails?.Feedback_Today?.length === 0 && (
                    <div className={styles.reports__exclamatory_Box}>
                      <div className={styles.reports__exclamatory_icon}>
                        <AiOutlineExclamationCircle
                          style={{
                            color: "grey",
                            height: "2em",
                            width: "2em",
                          }}
                        />
                      </div>
                      <p className={styles.reports__exclamatory_Text}>
                        No reports!
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Tab>

            {/* Past Tour Report Tab UI*/}
            <Tab eventKey="past" title="Past Tour Report">
              <div className={styles.reports__pastMainBox}>
                <div className={styles.reports__pastHeader}>
                  Insights Captured
                </div>
                <div className={`${styles.reports__pastInnerBox}`}>
                  {previousObsDetails?.map((item) => {
                    return (
                      <div className={`row d-flex ${styles.reports__pastRow}`}>
                        <div className={`col-8 ${styles.reports__pastText}`}>
                          {item.Observations__c}
                        </div>

                        <div
                          className={`col-4 ${styles.reports__pastBoxalign}`}
                        >
                          <CiCalendar
                            className={styles.reports__pastCalenderIcon}
                          />
                          <div className={styles.reports__pastDate}>
                            {item?.CreatedDate?.split("T")?.[0]}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <div className={styles.reports__pastShareBox}>
                  <RWebShare
                    data={{
                      text: "Checkout below page for tour report",
                      url: window.location.href,
                      title: "Tour Report",
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <p className={`m-0 ${styles.reports__pastShareTitle}`}>
                      <AiIcons.AiOutlineShareAlt
                        className={styles.reports__pastShareIcon}
                      />
                      Share
                    </p>
                  </RWebShare>
                </div> */}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  addTodaysTourReportApi,
  getTotalFeedbackApi,
})(Reports);
