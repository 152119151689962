import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getSalesCodeApi = async (divisionGroup) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/hoUser/get-sales-group/${divisionGroup}`,
    method: "get",
    headers: headers,
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
