import axios from "axios";
import { BASE_URL } from "../../variables";

export const getUpcomingTourPlansApi = async () => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id ,Objectives_es__c,Territory_Name_es__c,RecordType.Name, Town_Name__c, MTP_Date__c,MTP_Territory_es__r.name,MTP_Territory_es__r.SH2_Name__c,MTP_Territory_es__r.SH2_Code__c,WSS_Name__c, Visit_Status_es__c, MTP_Territory_es__r.Id ,Cluster_es__c,Other_Objective__c from Monthly_Tour_Plan_es__c where  (RecordType.name='Monthly Tour Plan' OR RecordType.name ='Draft') AND CreatedDate = LAST_90_DAYS   order by createdDate Desc`,
    method: "get",
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.[0].message;
    if (errors) {
      return { error: error?.response?.data?.[0].message };
    } else if (error?.code === "ERR_NETWORK") {
      return { error: error?.code };
    } else {
      return { error: "Something went wrong" };
    }
  }
};
