import {getSalesGroupUserApi} from "../../services/MarketVisitReport/getRMDMSalesGroupUser";
import {
  GET_SALESGRUOP_USER_SUCCESS,
  GET_SALESGRUOP_USER_FAIL,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import { sessionExpireTimeout } from "./utility-actions";

export const getSalesGroupUserDetails = (salesGroupId, Division) => async (dispatch) => {
  const response = await getSalesGroupUserApi(salesGroupId, Division);
  ;
  if (response?.status === 200) {
    dispatch({
      type: GET_SALESGRUOP_USER_SUCCESS,
      payload: response?.data,
    });
  } else if (response?.error === "ERR_NETWORK") {
    sessionExpireTimeout(dispatch);
  } else {
    dispatch({
      type: GET_SALESGRUOP_USER_FAIL,
    });
    dispatch(setAlert(response?.error, "error"));
  }
};