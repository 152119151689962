import axios from "axios";
import { BASE_URL } from "../../variables";

export const getMvrSrAttachedFiles = async (mvrId) => {
    const options = {
        url: `${BASE_URL}/services/apexrest/GetMVRSRAttachFiles/${mvrId}`,
        method: "get"
    };
    try {
        const response = await axios(options);
        if (response.status === 200) {
            return {
                success: true,
                data: response?.data
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};
