import axios from "axios";
import { BASE_URL } from "../../variables";
import { arrayData, getUniqueListBy } from "../../Components/Common/Function";

export const updateCheckInDailyForm = async (data) => {
    const options = {
        url: `${BASE_URL}/services/apexrest/UpdateDailyForm/`,
        method: "put",
        data: {
            UpdateDaily: data,
        },
    };
    try {
        const response = await axios(options);
        if (response.status === 200) {
            return {
                success: true,
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};
