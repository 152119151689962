import React, { useEffect, useRef } from "react";
import VisitDetails from "../Components/MarketWorking/MyTeam/VisitDetails";
import Reports from "../Components/MarketWorking/Reports";
import PageHeader from "../Components/Common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";

function TourReport() {
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  return (
    <>
      <PageHeader title="Tour Report" path="/" />
      <div className="container p-0">
        <VisitDetails />
        <Reports />
      </div>
    </>
  );
}

export default TourReport;
