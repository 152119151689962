import axios from "axios";
import { isLocalServer } from "../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../utils/getNodeUrl";

export const getPbiAccessTokenApi = async () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
  };
  // console.log("isLocalhost --> ", isLocalServer())

const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/user/get-pbi-access-token`,
    method: "get",
    headers: headers,
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
