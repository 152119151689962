import _ from "lodash";
import React, { useState, useEffect} from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loader from "../../Common/Loader";
import ModalPopup from "../../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiCalendar } from "react-icons/fi";
import styles from "./MyWssSummary.module.css";
import MyWssCounts from "../../HomePage/MyWssCounts";
import MyWssFeedbackForm from "./MyWssFeedbackForm";
import { getWssCheckInList } from "../../../services/wss/getWssCheckInList";
import { getUniqueListBy } from "../../Common/Function";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Message from "../../Common/Message";

const territoryAutoCompletePlaceHolder = {
    attributes: null,
    Id: null,
    SH2_Name__c: null,
    SH2_Code__c: null,
    Name: null,
    SH3_Code__c: null,
};

const MyWssSummary = ({ CheckIndata, reloadList}) => {
    const dispatch = useDispatch();
    const [tab, setTab] = useState("Visited");
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [ind, setInd] = useState();
    const [active, setActive] = useState(false);
    const [updatedTourPlan, setUpdatedTourPlan] = useState(false);
    const [wssListData, setWssListData] = useState([]);
    const tname = useSelector((state) => state?.territory?.territoryDetails);
    const divisionCode = useSelector((state) => state?.userDetails?.details?.Division);
    const [selectedTerritory, setSelectedTerritory] = useState(territoryAutoCompletePlaceHolder);
    const [wssList, setWssList] = useState({});
    const [isWssData, setIsWssData] = useState("Loading");
    const [error, setError] = useState(""); 
    const [divisionFlag, setDivisionFlag] = useState(divisionCode === 70 ? true : false); 
    const [wssListCount, setWssListCount] = useState({
        visited: "0",
        not_visited: "0",
        new: "0"
    }); 
    const tabs = [
        { key: "Visited", label: `Visited (${wssListCount.visited})` },
        { key: "Not Visited", label: `Not Visited (${wssListCount.not_visited})` },
        { key: "New", label: `New (${wssListCount.new})` }
    ];

    // API call to get Wss list against Territory
    const getList = async (territoryList) => {
        setLoading(true);
        const response = await getWssCheckInList(territoryList, divisionFlag);
            if (response?.success) {
                var count = {
                    visited: response?.data?.Visited?.length,
                    not_visited: response?.data?.NotVisited?.length,
                    new: response?.data?.New?.length
                }
                setWssList(response?.data);
                setWssListCount(count)
                setLoading(false)
            } else {
                setError(response?.msg);
                setLoading(false)
            }
    }

    // To get All territory wss list when we open the page / component
    useEffect(() => {
        if (divisionCode === 70 ){
            setDivisionFlag(true);
        }
        if (!_.isEmpty(tname)){
            getList(tname);
        }
    },[])

    // To get territory wss list after successfull check-in Call
    useEffect(() => {
        if (!_.isEmpty(reloadList)) {
            setSelectedTerritory(reloadList)
        }
    }, [reloadList])

    // To set territory 
    useEffect(() => {
        setIsWssData("Loading")
        setLoading(true)
        if (selectedTerritory?.SH2_Code__c !== null && !_.isEmpty(selectedTerritory)) 
        {
            var list = [selectedTerritory];
            getList(list);
        }else{
            setLoading(false)
        }
    }, [selectedTerritory]);

    // To achieve direct check-in functionality 
    const handleAutoPopulate = (e ,item) => {
        e.preventDefault()
        CheckIndata(item)
    };

  return (
    <>
          <Loader loading={loading} />
          <div>
              {isSubmit && (
                  <Loader loading={loading} />
              )}

              <div className={styles.myWssSummary}>
                  <div className={styles.myWssSummary__heading}>My Wss Summary</div>
                  <div className={styles.myWssSummary__Outermainbox}>
                    <div className={styles.myWssSummary__dropdownSelection}>
                        <Autocomplete
                            id="grouped-demo"
                            className="myDealer__categorySelect"
                            options={
                                !_.isEmpty(tname) ? getUniqueListBy(tname, "SH2_Code__c") : []
                            }
                            getOptionLabel={(option) => {
                                if (option?.SH2_Name__c) {
                                    return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
                                } else {
                                    return "";
                                }
                            }}
                            value={selectedTerritory}
                            name="dealerId"
                            onChange={(event, newValue) => {
                                setSelectedTerritory(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Select Territory" />
                            )}
                        />
                    </div>
                    <Tabs
                        defaultActiveKey="Visited"
                        id="fill-tab-example"
                        className="newFeedback__tabHeader"
                        onSelect={(tab) => {
                            setTab(tab);
                            setIsSubmit(false);
                        }}
                        fill
                    >
                        {tabs?.map((tabItem) => {
                            return (
                                <Tab eventKey={tabItem.key} title={tabItem.label} key={tabItem.key}>
                                    <div className="newFeedback__tabInnerBox">
                                        <div className={styles.myWssSummary__outerInputBox}>
                                        { Object.keys(wssList).length !== 0 ?
                                            <>
                                                {(tabItem.key === "Visited") && (
                                                    <>
                                                        {wssList.Visited.length > 0 ?
                                                            wssList.Visited?.map((item, index) => (
                                                            <div
                                                                className={`${styles.myWssSummary__mainBox} ${active &&
                                                                    ind === index &&
                                                                    styles.myWssSummary__mainBoxActive
                                                                    }`}
                                                                id={index}
                                                            >
                                                                <div className={styles.myWssSummary__locationBox}>
                                                                    <div
                                                                        className={styles.myWssSummary__tag}
                                                                        style={{ backgroundColor:"#A2D7AF" }}
                                                                    ></div>
                                                                    <div className={styles.myWssSummary__location}>
                                                                        <HiOutlineLocationMarker
                                                                            className={styles.myWssSummary__locationIcon}
                                                                        />
                                                                        <p>
                                                                            {item?.WSS_Name__c} : {item?.WSS_Code__c}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.myWssSummary__dateBox}>
                                                                    <div className={styles.myWssSummary__tourDate}>
                                                                        <p className={styles.myWssSummary__tourDay}>
                                                                        {item.Status}
                                                                        </p>
                                                                    </div>
                                                                    <span className={styles.myWssSummary__divideLine}>
                                                                        |
                                                                    </span>
                                                                    <div className={styles.myWssSummary__edit}>
                                                                            <button className={styles.myWssSummary__downloadBtn} 
                                                                            onClick={(e) => handleAutoPopulate(e, item)}
                                                                            >Check-In</button>
                                                                    {/* <MyWssFeedbackForm
                                                                        record={""}
                                                                        // setUpdatedTourPlan={setUpdatedTourPlan}
                                                                    /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )):
                                                            <Message
                                                                message={"No data found"}
                                                                className={"tourPlan__messageConatiner"}
                                                            />
                                                        }
                                                    </>
                                                )}
                                                {tabItem.key === "Not Visited" && (
                                                    <>
                                                        {wssList.NotVisited.length > 0 ? 
                                                            wssList.NotVisited?.map((item, index) => (
                                                                <div
                                                                    className={`${styles.myWssSummary__mainBox} ${active &&
                                                                        ind === index &&
                                                                        styles.myWssSummary__mainBoxActive
                                                                        }`}
                                                                    id={index}
                                                                >
                                                                    <div className={styles.myWssSummary__locationBox}>
                                                                        <div
                                                                            className={styles.myWssSummary__tag}
                                                                            style={{ backgroundColor: "#FDF3B5" }}
                                                                        ></div>
                                                                        <div className={styles.myWssSummary__location}>
                                                                            <HiOutlineLocationMarker
                                                                                className={styles.myWssSummary__locationIcon}
                                                                            />
                                                                            <p>
                                                                                {item?.CustomerName__c} : {item?.Customer_Code__c}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.myWssSummary__dateBox}>
                                                                        <div className={styles.myWssSummary__tourDate}>
                                                                            <p className={styles.myWssSummary__tourDay}>
                                                                                {item.Status}
                                                                            </p>
                                                                        </div>
                                                                        <span className={styles.myWssSummary__divideLine}>
                                                                            |
                                                                        </span>
                                                                        <div className={styles.myWssSummary__edit}>
                                                                            <button className={styles.myWssSummary__downloadBtn} onClick={(e) => handleAutoPopulate(e, item)}>Check-In</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )) :
                                                            <Message
                                                                message={"No data found"}
                                                                className={"tourPlan__messageConatiner"}
                                                            />
                                                         }
                                                    </>
                                                )}
                                                {tabItem.key === "New" && (
                                                    <>
                                                        {wssList.New.length > 0 ?
                                                            wssList.New?.map((item, index) => (
                                                                <div
                                                                    className={`${styles.myWssSummary__mainBox} ${active &&
                                                                        ind === index &&
                                                                        styles.myWssSummary__mainBoxActive
                                                                        }`}
                                                                    //   onTouchStart={handleTouchStart}
                                                                    //   onTouchEnd={(e) => handleTouchEnd(index, e)}
                                                                    id={index}
                                                                >
                                                                    <div className={styles.myWssSummary__locationBox}>
                                                                        <div
                                                                            className={styles.myWssSummary__tag}
                                                                            style={{ backgroundColor: "#561fff" }}
                                                                        ></div>
                                                                        <div className={styles.myWssSummary__location}>
                                                                            <HiOutlineLocationMarker
                                                                                className={styles.myWssSummary__locationIcon}
                                                                            />
                                                                            <p>
                                                                            {item?.CustomerName__c} : {item?.Customer_Code__c}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.myWssSummary__dateBox}>
                                                                        <div className={styles.myWssSummary__tourDate}>
                                                                            <p className={styles.myWssSummary__tourDay}>
                                                                                {item.Status}
                                                                            </p>
                                                                        </div>
                                                                        <span className={styles.myWssSummary__divideLine}>
                                                                            |
                                                                        </span>
                                                                        <div className={styles.myWssSummary__edit}>
                                                                            <button className={styles.myWssSummary__downloadBtn} onClick={(e) => handleAutoPopulate(e, item)}>Check-In</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )) :
                                                            <Message
                                                                message={"No data found"}
                                                                className={"tourPlan__messageConatiner"}
                                                            />
                                                        }
                                                    </>
                                                )}
                                            </> :
                                            <Message
                                                message={"No data found"}
                                                className={"tourPlan__messageConatiner"}
                                            />
                                        }
                                        </div>
                                    </div>
                                </Tab>
                            );
                        })}
                    </Tabs>
                  </div>
                    
                  {isSubmitModalOpen && (
                      <ModalPopup
                          className="ModalPopup_Submitted"
                          isModalOpen={isSubmitModalOpen}
                          setIsModalOpen={setIsSubmitModalOpen}
                          title="Submitted Successfully"
                          data="New Observations form has been submitted successfully."
                          backdrop={true}
                          fade={false}
                          icon={
                              <>
                                  <div className="submittedIcons draftIcons">
                                      <FaThumbsUp className="submittedSmall" />
                                      <FaThumbsUp className="submittedLarge" />
                                      <FaThumbsUp className="submittedSmall" />
                                  </div>
                              </>
                          }
                      />
                  )}
              </div>
          </div>
    </>
  )
}

export default MyWssSummary