import { getASMDataApi } from "../../services/CurrentDayTour/getASMDataApi";
import { getAsmEncryptKeyApi } from "../../services/CurrentDayTour/getAsmEncrpytKeyApi";
import {
  CURRENT_DAY_TOUR_ASM_DATA_FAIL,
  CURRENT_DAY_TOUR_ASM_DATA_SUCCESS,
  CURRENT_DAY_TOUR_ASM_KEY_FAIL,
  CURRENT_DAY_TOUR_ASM_KEY_SUCCESS,
} from "../constants/action-types";

export const getAsmEncryptKey = (SH_Code__c) => async (dispatch) => {
  const response = await getAsmEncryptKeyApi(SH_Code__c);
  if (response?.data?.success === true) {
    getASMkey(dispatch, response).then(([key]) => {
      dispatch(getASMData(key));
    });
  } else {
    dispatch({ type: CURRENT_DAY_TOUR_ASM_KEY_FAIL });
  }
};

// to get ASM encrypt key
const getASMkey = (dispatch, response) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: CURRENT_DAY_TOUR_ASM_KEY_SUCCESS,
      payload: response?.data,
    });

    resolve([response?.data?.data?.Salesforce_EncryptDataResult?.[0]?.Result]);
  });
};

// to get the ASM data
export const getASMData = (key) => async (dispatch) => {
  const response = await getASMDataApi(key);
  if (response?.data?.success === true) {
    dispatch({
      type: CURRENT_DAY_TOUR_ASM_DATA_SUCCESS,
      payload:
        response?.data?.data?.Salesforce_ASMWiseDataResult?.[0]
          ?.GetASMWiseDataList,
    });
  } else {
    dispatch({ type: CURRENT_DAY_TOUR_ASM_DATA_FAIL });
  }
};
