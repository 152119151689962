import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./datePicker.css";

const HighlightedDay = styled(PickersDay)(({ theme }) => ({
  "&.Mui-draft": {
    backgroundColor: "#FFEE87",
    color: theme.palette.primary.contrastText,
  },
  "&.Mui-Submitted": {
    backgroundColor: "red",
    color: theme.palette.primary.contrastText,
  },
  "&.Mui-selected": {
    backgroundColor: "YYYY-MM-DD",
  }
}));

//higlight the dates in highlightedDays arra
const ServerDay = (props) => {
  const { createdDates = [], draftDates = [], day, outsideCurrentMonth, ...other } = props;
  const isCreated = !outsideCurrentMonth && createdDates.includes(day.format("YYYY-MM-DD"));
  const isDraft = !outsideCurrentMonth && draftDates.includes(day.format("YYYY-MM-DD"));

  return (
    <HighlightedDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      className={isDraft ? "Mui-draft" : isCreated ? "Mui-Submitted" : ""}
    />
  );
};

const MyDatePicker = ({ setDate, createdDates, draftDates, selectedDate }) => {
  const [value, setValue] = useState();
  const [dates, setDates] = useState();
  const [highlightedCreatedDates, setHighlightedCreatedDates] = useState(createdDates);
  const [highlightedDraftDates, setHighlightedDraftDates] = useState(draftDates);

  useEffect(() => {
    if (selectedDate) {
      setValue(dayjs(selectedDate));
    }
  }, [selectedDate])


  useEffect(() => {
    setHighlightedCreatedDates(createdDates);
    setHighlightedDraftDates(draftDates);
    var date = new Date();
    setDates(date);
  }, [createdDates, draftDates]);

  const today = dayjs();

  const handleDateChange = (e) => {
    let date = new Date(e.$d);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let newDate = year + "-" + month + "-" + day;

    setDate({ target: { value: newDate, name: "marketVisitDate" } });
  };

  return (
    <Box className="myDatePicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{
            width: "100%",
            whiteSpace: "nowrap",
            height: "1.7rem",
            marginBottom: "0.8rem",
            border: "0.692008px solid #808080",
            borderRadius: "0.3rem",
            color: "#808080",
          }}
          format="DD-MM-YYYY"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            handleDateChange(newValue);
          }}
          // defaultValue={dayjs(dates)}
          maxDate={today}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              createdDates: highlightedCreatedDates,
              draftDates: highlightedDraftDates,
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default MyDatePicker;
