import axios from "axios";
import { getBarGraph } from "../../Redux/actions/homePage-actions";
import { getUpcomingTourPlan } from "../../Redux/actions/upcomingTourPlan-actions";
import { BASE_URL } from "../../variables";

export const delUpcomingTourPlans = (id) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/sobjects/Monthly_Tour_Plan_es__c/${id}`,
    method: "delete",
  };
  try {
    const response = await axios(options);
    if (response?.status === 204) {
      dispatch(getBarGraph());
      dispatch(getUpcomingTourPlan());
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      error: {
        statusCode: err?.response?.status,
        errorCode: err?.response?.data[0]?.errorCode,
        message: err?.response?.data[0]?.message,
      },
    };
  }
};
