import axios from "axios";
import { BASE_URL } from "../../variables";
import { PROD_URL } from "../../variables"

export const getTown = async ({cluster}) => {
const options = {
  url: `${BASE_URL}/services/data/v56.0/query?q= SELECT Id, TownCode__c, Town__c FROM Account where Cluster_Code__c = '${cluster}' and TownCode__c!=null`,
  method: "get",
  };
 
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
