import React, { useState, useEffect } from "react";
import styles from "../MyTeam/VisitDetails.module.css";
import { useSelector } from "react-redux";
import Message from "../../Common/Message";

const VisitDetails = ({ name }) => {
  const [visitDetails, setVisitDetails] = useState([]);
  const visitData = useSelector((state) =>
    name === "wssPage"
      ? state?.visitDetails?.checkInWSSDetails
      : state?.visitDetails?.checkInDetails
  );

  // Shifted code to useEffect

  // visitDetails &&
  //   visitDetails?.forEach((visit, index) => {
  //     const date = new Date(visit?.Check_In_Date_Time__c);
  //     const formattedDate = date?.toLocaleString("en-US", {
  //       day: "numeric",
  //       month: "numeric",
  //       year: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //     });
  //     visitDetails[index].Check_In_Date_Time__c = formattedDate;
  //   });

  useEffect(() => {
    if (visitData?.length) {
      visitData &&
        visitData?.forEach((visit, index) => {
          const date = new Date(visit?.Check_In_Date_Time__c);
          const formattedDate = date?.toLocaleString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });
          visitData[index].Check_In_Date_Time__c = formattedDate;
        });

      setVisitDetails(
        visitData?.filter((visit) => visit?.IsValidCheckIn__c !== "Invalid")
      );
    }
  }, [visitData]);

  return (
    <>
      <div className={styles.visitDetails}>
        <div className={styles.visitDetails__heading}>Visit Details</div>
        <div className={styles.visitDetails__mainBox}>
          <div className="row m-0 p-0">
            <div
              className={` ${
                visitDetails?.length > 0
                  ? styles.visitDetails__rightBox
                  : "mt-2"
              }`}
            >
              <div className={styles.titleBox}>
                <div className={`${styles.visitDetails_title}`}>
                  {" "}
                  Check In history
                </div>
                <div>
                  <div className={styles.visitDetails__titleInnerBox}>
                    <div className={styles.visitDetails__titleBoxValue}>
                      {visitDetails?.length} Visits
                    </div>
                  </div>
                </div>
              </div>
              {visitDetails?.length > 0 ? (
                <div className={styles.visitDetails__headerBox}>
                  <div className={styles.visitDetails__headerValue}>
                    {name == "wssPage" ? "WSS Name" : "Dealer Name"}
                  </div>
                  <div className={styles.visitDetails__headerValue}>
                    Territory
                  </div>
                  <div className={styles.visitDetails__headerValue}>Date</div>
                </div>
              ) : (
                <Message
                  message={"No visit details"}
                  className="visitDetails__OuterDiv"
                />
              )}

              <div className={styles.visitDetails__plansBox}>
                {visitDetails &&
                  visitDetails?.map((detials) => (
                    <div className={styles.visitDetails__despBox}>
                      <div className={styles.visitDetails__despBoxValue}>
                        {name == "wssPage"
                          ? detials?.WSS_Name__c
                          : detials?.CheckIn_Dealer__c}
                      </div>
                      <div className={styles.visitDetails__despBoxValue}>
                        {detials?.MTP_Territory_es__r?.SH2_Name__c}
                      </div>
                      <div className={styles.visitDetails__despBoxValue}>
                        {detials?.Check_In_Date_Time__c} <br />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitDetails;
