import axios from "axios";
import { BASE_URL } from "../../variables";
import { arrayData, getUniqueListBy } from "../../Components/Common/Function";

export const getWssCodeApi = async (data) => {
  let sh3Code = [];
  if (data.data.records.length > 0) {
    const sh3data = getUniqueListBy(data.data.records, "SH3_Code__c");
    sh3data?.map((item) => {
      sh3Code.push(item?.SH3_Code__c);
    });
    // let sh3Result = arrayData(sh3Code);
    let sh3Result = sh3Code;
    const options = {
      url: `${BASE_URL}/services/apexrest/getCustomerCode_api/`,
      method: "post",
      data: {
        clusterCodes: sh3Result,
      },
    };
    try {
      const response = await axios(options);
      if (response.status === 200) {
        const wssData = response?.data?.CustomerData;
        const arrayOfWss = Object.entries(wssData).map(([key, value]) => ({
          id: key,
          ...value
        }));
        return {
          success: true,
          data: arrayOfWss,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      console.log(err);
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  } else {
    return {
      success: true,
      data: { records: [] },
    };
  }
};
