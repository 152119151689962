import React, { useEffect, useState } from "react";
import { FiFile } from "react-icons/fi";
import styles from "./MySR.module.css";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { addMySrApi } from "../../../services/MarketWorking/MyTeam/addMySrApi";
import { addDealerNameApi } from "../../../services/MarketWorking/MyTeam/addDealerNameApi";
import _ from "lodash";
import { getPicklistApi } from "../../../services/MarketWorking/MyTeam/getPicklistApi";
import ModalPopup from "../../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import { connect } from "react-redux";
import { configs } from "../../../config";
import { useSelector } from "react-redux";
import { getDealerNameApiForHOUser } from "../../../services/hoUser/getDealerNameApiForHOUser";
import { addWSSNameApiForHOUser } from "../../../services/hoUser/addWSSNameApiForHOUser";
import Loader from "../../Common/Loader";
import { compressImage, getUniqueListBy } from "../../Common/Function";
import { Autocomplete, TextField } from "@mui/material";
import { getZSMTerritoryApi } from "../../../services/zsm/getZSMTerritoryApi";
import { getZSMDealerNameApi } from "../../../services/zsm/getZSMDealerNameApi";
import { getZSMWssNameApi } from "../../../services/zsm/getZSMWssNameApi";
import { Record } from "../../Common/Constant";

const clusterCodePlaceHolder = { SH3_Name__c: "", SH3_Code__c: "" };
const territoryAutoCompletePlaceHolder = {
  attributes: {},
  Id: "",
  SH2_Name__c: "",
  SH2_Code__c: "",
  Name: "",
  SH3_Code__c: "",
};

const wssOrDelaerPlaceHoder = {
  Dealer_Name__c: "",
  DealerCode__c: "",
  CustomerName__c: "",
  Customer_Code__c: "",
  Product_Group_3_Description__c: "",
};

const MySR = ({
  addMySrApi,
  getZSMTerritoryApi,
  getZSMDealerNameApi,
  getZSMWssNameApi,
  getDealerNameApiForHOUser,
}) => {
  const [srData, setSrData] = useState({
    recordType: "",
    territory: "",
    subject: "",
    feedback: "",
    dealerId: "",
    chapterName: "",
    category: "",
    subCategory: "",
  });
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [file, setFile] = useState({
    base64: "",
    fileExtension: "",
    fileName: "",
  });
  const [dealerOption, setDealerOption] = useState([]);
  const [error, setError] = useState();
  const [chapter, setChapter] = useState();
  const [categories, setCategories] = useState();
  const [chapterSelected, setChapterSelected] = useState();
  const [subCategories, setSubCategories] = useState();
  const [recordId, setRecordId] = useState();
  const [recordType, setRecordType] = useState("");
  // const [wssPlaceHolder, setWssPlaceHolder] = useState("Select Name");
  const [loading, setLoading] = useState(false);
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [sh2Code, setSh2Code] = useState("");
  const [territory, setTerritory] = useState(territoryAutoCompletePlaceHolder);
  const [wssOrDealer, setWssOrDealer] = useState(wssOrDelaerPlaceHoder);
  const [cluster, setCluster] = useState(clusterCodePlaceHolder);
  const [sh3CodeForTerritory, setSH3CodeForTerritory] = useState("");
  const [sh3CodeForWss, setSH3CodeForWss] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  //to get territory list from redux
  const territoryOptions = useSelector(
    (state) => state?.territory?.territoryDetails
  );

  //to get Division and sales group
  const divisionGroup = territoryOptions?.[0]?.Division_Code__c;
  const salesGroup = territoryOptions?.[0]?.Sales_Group__c;

  //to get user Type and User Details.
  const userType = useSelector((state) => state?.userDetails?.details?.Title);
  const userEmail = useSelector((state) => state?.userDetails?.details?.Email);
  const userName = useSelector((state) => state?.userDetails?.details?.Name);

  // const userType = useSelector(
  //   (state) => state?.userDetails?.details?.Title
  // );

  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );

  // to get Dealer Name acc to recordType (when user is SH3)
  useEffect(() => {
    if (!_.isEmpty(recordType) && userType === "SH3") {
      (async () => {
        let response;
        if (recordType === "Dealer" && !_.isEmpty(sh2Code)) {
          response = await addDealerNameApi(recordType, sh2Code);
        } else if (recordType === "WSS") {
          response = await addDealerNameApi(recordType);
        }
        if (response?.success) {
          setDealerOption(
            getUniqueListBy(
              response?.data?.Name,
              recordType === "Dealer" ? "Dealer_Name__c" : "CustomerName__c"
            )
          );
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordType, sh2Code]);

  // to get Dealer Name acc to recordType (when user type is HO Colloaborator)
  useEffect(() => {
    if (!_.isEmpty(recordType) && userType === "HO Collaborator") {
      (async () => {
        let response;
        if (recordType === "Dealer" && !_.isEmpty(sh2Code)) {
          response = await getDealerNameApiForHOUser(
            divisionGroup,
            salesGroup,
            sh2Code
          );
        } else if (recordType === "WSS" && !_.isEmpty(sh3CodeForWss)) {
          response = await addWSSNameApiForHOUser(
            divisionGroup,
            salesGroup,
            sh3CodeForWss
          );
        }
        if (response?.success) {
          setDealerOption(
            getUniqueListBy(
              response?.data,
              recordType === "Dealer" ? "Dealer_Name__c" : "CustomerName__c"
            )
          );
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordType, sh2Code, sh3CodeForWss]);

  useEffect(() => {
    switch (srData?.recordType) {
      case "Dealer":
        setRecordId(configs?.sf_dealer_id);
        setRecordType("Dealer");
        break;

      case "WSS":
        setRecordId(configs?.sf_wss_id);
        setRecordType("WSS");
        break;
    }
  }, [srData?.recordType]);

  // to get picklist for categrory ,subcatergory and chapter Name
  useEffect(() => {
    if (!_.isEmpty(recordId)) {
      (async () => {
        const response = await getPicklistApi(recordId);
        if (response?.success) {
          setChapter(
            response?.data?.picklistFieldValues?.Category_Picklist__c
              ?.controllerValues
          );
          setCategories(
            response?.data?.picklistFieldValues?.Category_Picklist__c?.values
          );
          setSubCategories(
            response?.data?.picklistFieldValues?.Subcategory_Picklist__c
          );
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordId]);

  //to get Dealer Name when user is ZSM and NSM
  useEffect(() => {
    if (
      !_.isEmpty(recordType) &&
      (userType === "SH6" || userType === "SH5" || userType === "SH7")
    ) {
      (async () => {
        let response;
        if (recordType === "Dealer") {
          response = await getZSMDealerNameApi(
            sh3CodeForTerritory,
            srData?.territory?.split(":")?.[1]
          );
        }
        if (recordType === "WSS" && sh3CodeForWss) {
          response = await getZSMWssNameApi(sh3CodeForWss);
        }

        if (response?.success) {
          setDealerOption(response?.data);
          if (recordType === "WSS") {
            const FilterWss = getUniqueListBy(
              response?.data,
              "CustomerName__c"
            );
            setDealerOption(FilterWss);
          }
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [recordType, srData.territory, sh3CodeForWss]);

  const onChangeHandler = (e) => {
    // if (e.target.value == "Dealer") {
    //   setWssPlaceHolder("Select Dealer Name");
    // } else if (e.target.value === "WSS") {
    //   setWssPlaceHolder("Select Wss");
    // } else {
    //   setWssPlaceHolder("Select name");
    // }
    if (e.target.name === "recordType") {
      setFile({
        base64: "",
        fileExtension: "",
        fileName: "",
      });
      setCluster(clusterCodePlaceHolder);
      setTerritory(territoryAutoCompletePlaceHolder);
      setWssOrDealer(wssOrDelaerPlaceHoder);
      setFormValidation({});
      setTerritoryPickList([]);
      setDealerOption([]);
      setSH3CodeForTerritory("");
      setSH3CodeForWss("");
    }
    if (e.target.name === "clusterCodeForTerritory") {
      setSH3CodeForTerritory(e?.target?.value);
      setTerritory(territoryAutoCompletePlaceHolder);
      setWssOrDealer(wssOrDelaerPlaceHoder);
      setTerritoryPickList([]);
      setDealerOption([]);
    }
    if (e.target.name === "clusterCodeForWss") {
      setSH3CodeForWss(e?.target?.value);
      setTerritory(territoryAutoCompletePlaceHolder);
      setWssOrDealer(wssOrDelaerPlaceHoder);
      setTerritoryPickList([]);
      setDealerOption([]);
    }
    if (e.target.name === "chapterName") {
      setChapterSelected(e.target.value?.split(",")?.[1]);
    }
    if (e.target.name === "territory") {
      setWssOrDealer(wssOrDelaerPlaceHoder);
      setDealerOption([]);
      setSh2Code(e.target.value?.split(":")?.[1]);
    }
    setSrData({ ...srData, [e.target.name]: e.target.value });
  };

  // const handleFileRead = async (event) => {
  //   const file = event.target.files[0];
  //   const base64 = await convertBase64(file);
  //   setFile({
  //     base64: base64.replace("data:", "").replace(/^.+,/, ""),
  //     fileExtension: event.target.files[0]?.name?.split(".")[0],
  //     fileName: event.target.files[0]?.name,
  //   });
  //   event.target.value = "";
  // };

  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  const FileHandler = async (e) => {
    setIsUploading(true);
    setFormValidation({});
    if (e.target.files[0]?.size < 5242880) {
      let image = "";
      if (e.target.files[0]?.name?.split(".")?.slice(-1) != "pdf") {
        image = await compressImage(e.target.files[0]);
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFile({
          base64: reader.result.replace("data:", "").replace(/^.+,/, ""),
          fileExtension: e.target.files[0]?.name
            ?.split(".")
            ?.slice(-1)
            ?.toString(),
          fileName: e.target.files[0]?.name,
        });
      };
      if (!_.isEmpty(image)) {
        reader.readAsDataURL(image);
      } else {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      setFile({
        base64: "",
        fileExtension: "",
        fileName: "",
      });
      setFormValidation({
        file: "Please upload the file whose size is less than 5MB",
      });
    }
    setIsUploading(false);
  };

  //validation check
  const checkValidation = (values) => {
    const errors = {};
    if (userType === "SH6" || userType === "SH5" || userType === "SH7") {
      if (recordType === "Dealer" && !sh3CodeForTerritory) {
        errors.clusterCode = "Please select the cluster code";
      }
      if (recordType === "WSS" && !sh3CodeForWss) {
        errors.clusterCode = "Please select the cluster code";
      }
    }
    if (!values.recordType) {
      errors.recordType = "Please select the recordType";
    }
    if (recordType === "Dealer") {
      if (!values.territory) {
        errors.territory = "Please select the territory";
      }
    }
    if (!values.subject) {
      errors.subject = "Subject is required";
    }
    if (!values.feedback) {
      errors.feedback = "Feedback is required";
    }
    if (!values.dealerId) {
      errors.dealerId = "Please select the dealer Name";
    }
    if (!values.chapterName) {
      errors.chapterName = "Please select the chapterName";
    }
    if (!values.category) {
      errors.category = "Please select the category";
    }
    if (!values.subCategory) {
      errors.subCategory = "Please select the subCategory";
    }
    if (formValidation.file) {
      errors.file = "Please upload the file whose size is less than 5MB";
    }
    return errors;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setFormValidation(checkValidation(srData));
  };

  // get Terrtiroy Api call
  useEffect(() => {
    if (!_.isEmpty(sh3CodeForTerritory)) {
      (async () => {
        const response = await getZSMTerritoryApi(sh3CodeForTerritory);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList("");
        }
      })();
    }
  }, [sh3CodeForTerritory]);

  //post api call of my Diary
  useEffect(() => {
    if (Object?.keys(formValidation)?.length === 0 && isSubmit) {
      (async () => {
        const data = {
          recordType: srData?.recordType,
          subject: srData?.subject,
          description: srData?.feedback,
          dealerId: srData?.dealerId,
          chapterName: srData?.chapterName?.split(",")?.[0],
          category: srData?.category,
          subCategory: srData?.subCategory,
          fileExtension: file?.fileExtension,
          fileName: file?.fileName,
          base64: file?.base64,
          origin: "MConnect",
          MVRId: "",
          BMName: userName,
          BMEmail: userEmail,
        };
        const {
          recordType,
          subject,
          description,
          dealerId,
          chapterName,
          category,
          subCategory,
          fileExtension,
          fileName,
          base64,
          origin,
          MVRId,
          BMName,
          BMEmail
        } = data;
        setLoading(true);
        const response = await addMySrApi({
          recordType,
          subject,
          description,
          dealerId,
          chapterName,
          category,
          subCategory,
          fileExtension,
          fileName,
          base64,
          origin,
          MVRId,
          BMName,
          BMEmail
        });
        setLoading(false);
        if (response?.success) {
          setIsSubmitModalOpen(true);
          // setLoading(false);
          setIsSubmit(false);
          //TODO: to show success msg popup if required
        } else {
          setIsSubmit(false);
          //TODO : to show error msg popup
        }
        setSrData({
          recordType: "",
          subject: "",
          feedback: "",
          delearId: "",
          chapterName: "",
          category: "",
          subCategory: "",
        });
        setFile({
          base64: "",
          fileExtension: "",
          fileName: "",
        });
        setRecordType("");
        setSH3CodeForTerritory("");
        setSH3CodeForWss("");
      })();
    }
    if (Object?.keys(formValidation)?.length !== 0) {
      setIsSubmit(false);
    }
    // setLoading(false);
  }, [isSubmit]);

  return (
    <div className={styles.mySR__outerBox}>
      <Loader loading={isUploading} />
      <div className={styles.mySR__heading}>My SR</div>

      <div>
        {isSubmit && Object?.keys(formValidation)?.length === 0 && (
          <Loader loading={loading} />
        )}
        <div className={styles.mySR__innerBox}>
          <Form.Select
            aria-label="Default select example"
            className={
              !srData.recordType && formValidation?.recordType
                ? styles.mySR__categorySelectError
                : styles.mySR__categorySelect
            }
            name="recordType"
            onChange={(e) => {
              setTerritory(territoryAutoCompletePlaceHolder);
              setCluster(clusterCodePlaceHolder);
              setWssOrDealer(wssOrDelaerPlaceHoder);
              onChangeHandler(e);
            }}
          >
            <option
              value=""
              selected={_.isEmpty(srData?.recordType) ? true : false}
              className={styles.mySR__defaultOption}
            >
              Record Type
            </option>
            {Record?.map((type) => {
              return <option value={type}>{type}</option>;
            })}
          </Form.Select>
          {!srData.recordType && formValidation?.recordType && (
            <p className={styles.myDiary__validation}>
              {formValidation?.recordType}
            </p>
          )}
          {recordType === "Dealer" && (
            <>
              {(userType === "SH6" ||
                userType === "SH5" ||
                userType === "SH7" ||
                userType === "HO Collaborator") && (
                <Autocomplete
                  // key={clusterCodePlaceHolder}
                  value={cluster}
                  id="grouped-demo"
                  className="myDealer__categorySelect"
                  options={
                    clusterCodeOption?.length === 0
                      ? [
                          {
                            SH3_Name__c: "Loading...",
                            SH3_Code__c: "",
                          },
                        ]
                      : getUniqueListBy(clusterCodeOption, "SH3_Name__c")
                  }
                  getOptionLabel={(option) =>
                    `${option?.SH3_Name__c}${
                      option?.SH3_Code__c && `:${option?.SH3_Code__c}`
                    }`
                  }
                  onChange={(e, newValue) => {
                    onChangeHandler({
                      target: {
                        name: "clusterCodeForTerritory",
                        value: newValue?.SH3_Code__c,
                      },
                    });
                    setCluster(newValue);
                    // setClusterCodePlaceHolder(
                    //   `${newValue.SH3_Name__c}:${newValue.SH3_Code__c}`
                    // );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Select Cluster"} />
                  )}
                />
              )}
              {!sh3CodeForTerritory && formValidation?.clusterCode && (
                <p className={styles.myDiary__validation}>
                  {formValidation?.clusterCode}
                </p>
              )}
              {(userType === "SH6" ||
                userType === "SH5" ||
                userType === "SH7" ||
                userType === "HO Collaborator") && (
                <Autocomplete
                  // key={territoryAutoCompletePlaceHolder}
                  value={territory}
                  id="grouped-demo"
                  className="myDealer__categorySelect"
                  options={
                    territoryPickList?.length === 0
                      ? [
                          {
                            SH2_Name__c: "Loading...",
                            SH2_Code__c: "",
                          },
                        ]
                      : getUniqueListBy(territoryPickList, "SH2_Code__c")
                  }
                  getOptionLabel={(option) =>
                    `${option?.SH2_Name__c}${
                      option?.SH2_Code__c ? `:${option?.SH2_Code__c}` : ""
                    }`
                  }
                  onChange={(e, newValue) => {
                    onChangeHandler({
                      target: {
                        name: "territory",
                        value:
                          newValue &&
                          `${newValue.SH2_Name__c}:${newValue.SH2_Code__c}`,
                      },
                    });
                    setTerritory(newValue);
                    // setTerritoryAutoCompletePlaceHolder(
                    //   `${newValue.SH2_Name__c}:${newValue.SH2_Code__c}`
                    // );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Select Territory"} />
                  )}
                />
              )}
              {userType === "SH3" && (
                <Autocomplete
                  // key={territoryAutoCompletePlaceHolder}
                  value={territory}
                  id="grouped-demo"
                  className="myDealer__categorySelect"
                  options={
                    territoryOptions?.length === 0
                      ? [
                          {
                            SH2_Name__c: "Loading...",
                            SH2_Code__c: "",
                          },
                        ]
                      : getUniqueListBy(territoryOptions, "SH2_Code__c")
                  }
                  getOptionLabel={(option) =>
                    `${option?.SH2_Name__c}${
                      option?.SH2_Code__c ? `:${option?.SH2_Code__c}` : ""
                    }`
                  }
                  onChange={(e, newValue) => {
                    // setTerritoryAutoCompletePlaceHolder(
                    //   `${newValue.SH2_Name__c}:${newValue.SH2_Code__c}`
                    // );
                    onChangeHandler({
                      target: {
                        name: "territory",
                        value:
                          newValue &&
                          `${newValue.SH2_Name__c}:${newValue.SH2_Code__c}`,
                      },
                    });
                    setTerritory(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Select Territory"} />
                  )}
                />
              )}
            </>
          )}
          {recordType === "Dealer" &&
            !srData.territory &&
            formValidation?.territory && (
              <p className={styles.myDiary__validation}>
                {formValidation?.territory}
              </p>
            )}
          {recordType === "WSS" &&
            (userType === "SH6" ||
              userType === "SH5" ||
              userType === "SH7" ||
              userType === "HO Collaborator") && (
              <Autocomplete
                // key={clusterCodePlaceHolder}
                value={cluster}
                id="grouped-demo"
                className="myDealer__categorySelect"
                options={
                  clusterCodeOption?.length === 0
                    ? [
                        {
                          SH3_Name__c: "Loading...",
                          SH3_Code__c: "",
                        },
                      ]
                    : getUniqueListBy(clusterCodeOption, "SH3_Name__c")
                }
                getOptionLabel={(option) =>
                  `${option?.SH3_Name__c}${
                    option?.SH3_Code__c && `:${option?.SH3_Code__c}`
                  }`
                }
                onChange={(e, newValue) => {
                  // setClusterCodePlaceHolder(
                  //   `${newValue.SH3_Name__c}:${newValue.SH3_Code__c}`
                  // );
                  onChangeHandler({
                    target: {
                      name: "clusterCodeForWss",
                      value: newValue?.SH3_Code__c,
                    },
                  });
                  setCluster(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder={"Select CLuster"} />
                )}
              />
            )}
          {recordType === "WSS" &&
            !sh3CodeForWss &&
            formValidation?.clusterCode && (
              <p className={styles.myDiary__validation}>
                {formValidation?.clusterCode}
              </p>
            )}{" "}
          {(recordType === "WSS" || recordType === "Dealer") && (
            <Autocomplete
              // key={wssPlaceHolder}
              value={wssOrDealer}
              id="grouped-demo"
              className="myDealer__categorySelect"
              options={
                dealerOption?.length === 0
                  ? [
                      {
                        Dealer_Name__c: "Loading...",
                        CustomerName__c: "Loading...",
                      },
                    ]
                  : dealerOption
              }
              getOptionLabel={(option) => {
                if (recordType === "Dealer") {
                  return option?.Dealer_Name__c;
                } else if (recordType === "WSS") {
                  return option?.CustomerName__c;
                }
              }}
              onChange={(e, newValue) => {
                onChangeHandler({
                  target: {
                    name: "dealerId",
                    value: newValue?.Id,
                  },
                });
                setWssOrDealer(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder={`Select ${recordType}`} />
              )}
            />
          )}
          {!srData.dealerId && formValidation?.dealerId && (
            <p className={styles.myDiary__validation}>
              {formValidation?.dealerId}
            </p>
          )}
          <Form.Select
            aria-label="Default select example"
            className={
              !srData.chapterName && formValidation?.chapterName
                ? styles.mySR__categorySelectError
                : styles.mySR__categorySelect
            }
            name="chapterName"
            onChange={(e) => onChangeHandler(e)}
          >
            <option
              value=""
              selected={_.isEmpty(srData?.chapterName) ? true : false}
              className={styles.mySR__defaultOption}
            >
              Chapter Name
            </option>

            {chapter &&
              Object.entries(chapter)?.map(([key, value]) => {
                if (recordType === "Dealer" && key === "Dealer Feedback") {
                  return (
                    <>
                      <option value={`${key},${value}`}>{key}</option>
                    </>
                  );
                }
                if (recordType === "WSS" && key === "WSS Service Cell") {
                  return (
                    <>
                      <option value={`${key},${value}`}>{key}</option>
                    </>
                  );
                }
              })}
          </Form.Select>
          {!srData.chapterName && formValidation?.chapterName && (
            <p className={styles.myDiary__validation}>
              {formValidation?.chapterName}
            </p>
          )}
          <Form.Select
            aria-label="Default select example"
            className={
              !srData.category && formValidation?.category
                ? styles.mySR__categorySelectError
                : styles.mySR__categorySelect
            }
            name="category"
            onChange={(e) => onChangeHandler(e)}
          >
            <option
              value=""
              selected={_.isEmpty(srData?.category) ? true : false}
              className={styles.mySR__defaultOption}
            >
              Category
            </option>

            {srData?.chapterName &&
              categories?.map((name) => {
                return name?.validFor?.map((item) => {
                  if (
                    item === Number(chapterSelected) &&
                    name?.value !== "Feedback/Suggestion" &&
                    name?.value !== "Dealer Request" &&
                    name?.value !== "Internal Followup" &&
                    name?.value !== "TSI/TSR Servicing" &&
                    name?.value !== "Product" &&
                    name?.value !== "Process &amp; Policy Change" &&
                    name?.value !== "Feedback on Competition Activities" &&
                    // name?.value !== "Product/Packaging Feedback" &&
                    // name?.values !== "Scheme and other claims" &&
                    // name?.value !== "WSS Profile Related" &&
                    // name?.value !== "WSS Score Card" &&
                    // name?.value !== "Payment Related" &&
                    name?.value !== "Customer Appointment" &&
                    name?.value !== "Feedback Call" &&
                    name?.value !== "Insights@WSS Service Cell" &&
                    name?.value !== "Pricing" &&
                    name?.value !== "Application_Related" &&
                    name?.value !== "Market Feedback"
                  ) {
                    return <option value={name?.value}>{name?.value}</option>;
                  }
                });
              })}
          </Form.Select>
          {!srData.category && formValidation?.category && (
            <p className={styles.myDiary__validation}>
              {formValidation?.category}
            </p>
          )}
          <Form.Select
            aria-label="Default select example"
            className={
              !srData.subCategory && formValidation?.subCategory
                ? styles.mySR__categorySelectError
                : styles.mySR__categorySelect
            }
            name="subCategory"
            onChange={(e) => onChangeHandler(e)}
          >
            <option
              value=""
              selected={_.isEmpty(srData?.subCategory) ? true : false}
              className={styles.mySR__defaultOption}
            >
              Sub-category
            </option>

            {srData?.category &&
              Object.entries(subCategories?.controllerValues)?.map(
                ([key, value]) => {
                  if (key === srData?.category) {
                    return subCategories?.values?.map((item) => {
                      return item?.validFor?.map((sub) => {
                        if (sub == value) {
                          return (
                            <option value={item?.value}>{item?.value}</option>
                          );
                        }
                      });
                    });
                  }
                  return;
                }
              )}
          </Form.Select>
          {!srData.subCategory && formValidation?.subCategory && (
            <p className={styles.myDiary__validation}>
              {formValidation?.subCategory}
            </p>
          )}
          <input
            type="text"
            name="subject"
            placeholder="Enter Subject"
            className={
              !srData.subject && formValidation?.subject
                ? styles.mySR__inputBoxError
                : styles.mySR__inputBox
            }
            value={srData?.subject}
            onChange={(e) => onChangeHandler(e)}
          />
          {!srData.subject && formValidation?.subject && (
            <p className={styles.myDiary__validation}>
              {formValidation?.subject}
            </p>
          )}
          <textarea
            name="feedback"
            rows="6"
            placeholder="Enter Feedback"
            className={
              !srData.feedback && formValidation?.feedback
                ? styles.mySR__textareaError
                : styles.mySR__textarea
            }
            value={srData?.feedback}
            onChange={(e) => onChangeHandler(e)}
          ></textarea>
          {!srData.feedback && formValidation?.feedback && (
            <p>{formValidation?.feedback}</p>
          )}
        </div>
        {!_.isEmpty(file?.fileName) && (
          <>
            <div className={styles.mySR__fileUploadTitle}>File uploads</div>
            <div className={`mb-3 ${styles.mySR__displayFileName}`}>
              <div>
                <FiFile className={styles.mySR__fileIcon} />
                <span className={styles.mySR__fileUploadName}>
                  {file?.fileName}
                </span>
              </div>
              <Button
                className={styles.mySR__removeUploadFile}
                onClick={() => setFile()}
              >
                Remove
              </Button>
            </div>
          </>
        )}
        {formValidation?.file && (
          <p className={styles.mySR__fileValidation}>{formValidation?.file}</p>
        )}{" "}
      </div>

      <div className={`row ${styles.mySR__uploadFile}`}>
        <div className="col-6"></div>
        <div className="col-3">
          <div className={styles.mySR__inputWrapper}>
            Upload file
            <input
              className={`${styles.mySR__fileInput}`}
              type="file"
              accept=".jpg,.png,.pdf, .jpeg"
              onChange={FileHandler}
            />
          </div>
        </div>
        <div className="col-3">
          <Button className={styles.mySR__addBtn} onClick={onSubmitHandler}>
            Add
          </Button>
        </div>
      </div>

      {isSubmitModalOpen && (
        <ModalPopup
          className="ModalPopup_Submitted"
          isModalOpen={isSubmitModalOpen}
          setIsModalOpen={setIsSubmitModalOpen}
          title="Submitted Successfully"
          data="My SR has been submitted successfully."
          backdrop={true}
          fade={false}
          icon={
            <>
              <div className="submittedIcons draftIcons">
                <FaThumbsUp className="submittedSmall" />
                <FaThumbsUp className="submittedLarge" />
                <FaThumbsUp className="submittedSmall" />
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default connect(null, {
  addMySrApi,
  getZSMTerritoryApi,
  getZSMDealerNameApi,
  getZSMWssNameApi,
  getDealerNameApiForHOUser,
})(MySR);
