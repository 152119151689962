import { getSecondarySalesAcheivedApi } from "../../services/SecondarySales/getSecondarySalesAcheivedApi";
import { getSecondarySalesAcheivedTrendApi } from "../../services/SecondarySales/getSecondarySalesAcheivedTrendApi";
import { getSecondarySalesTargetAcheivedApi } from "../../services/SecondarySales/getSecondarySalesTargetAcheivedApi";
import { getSecondarySalesTargetAcheivedTrendApi } from "../../services/SecondarySales/getSecondarySalesTargetAcheivedTrendApi";
import {
  GET_SECONDARY_SALES_ACHIEVED_FAIL,
  GET_SECONDARY_SALES_ACHIEVED_SUCCESS,
  GET_SECONDARY_SALES_TARGET_ACHIEVED_FAIL,
  GET_SECONDARY_SALES_TARGET_ACHIEVED_SUCCESS,
  GET_SECONDARY_SALES_TARGET_TREND_FAIL,
  GET_SECONDARY_SALES_TARGET_TREND_SUCCESS,
  GET_SECONDARY_SALES_TREND_FAIL,
  GET_SECONDARY_SALES_TREND_SUCCESS,
} from "../constants/action-types";

export const getSecondarySalesAcheived =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getSecondarySalesAcheivedApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_SECONDARY_SALES_ACHIEVED_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_SECONDARY_SALES_ACHIEVED_FAIL });
    }
  };

export const getSecondarySalesAcheivedTrend =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getSecondarySalesAcheivedTrendApi(SH_Code__c, Title);
    if (response?.data?.success === true) {
      dispatch({
        type: GET_SECONDARY_SALES_TREND_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_SECONDARY_SALES_TREND_FAIL });
    }
  };

export const getSecondarySalesTargetAcheived =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getSecondarySalesTargetAcheivedApi(
      SH_Code__c,
      Title
    );
    if (response?.data?.success === true) {
      dispatch({
        type: GET_SECONDARY_SALES_TARGET_ACHIEVED_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_SECONDARY_SALES_TARGET_ACHIEVED_FAIL });
    }
  };

export const getSecondarySalesTargetAcheivedTrend =
  (SH_Code__c, Title) => async (dispatch) => {
    const response = await getSecondarySalesTargetAcheivedTrendApi(
      SH_Code__c,
      Title
    );
    if (response?.data?.success === true) {
      dispatch({
        type: GET_SECONDARY_SALES_TARGET_TREND_SUCCESS,
        payload: response?.data?.info,
      });
    } else {
      dispatch({ type: GET_SECONDARY_SALES_TARGET_TREND_FAIL });
    }
  };
