import React from "react";
import "../Components/Header.css";
import logo from "../Assets/Images/mconnect-logo.svg";
import profile from "../Assets/Images/profile11.png";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

const Header = () => {

  const profileImage = useSelector((state) => state?.userDetails?.details?.Photo__c);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-8">
          <img src={logo} alt="header_logo" className="header__logo" />
        </div>
        <div className="col-4 profileImageBox">
        <Link to="/my-profile">
          <div className="">
            <img
              src={!_.isEmpty(profileImage) ? `data:image/png;base64,${profileImage}` : profile}
              alt="profileImage"
              className="header__profileImage"
            />
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
