import React, { useEffect, useState } from "react";
import styles from "../MyTeam/MyDealer.module.css";
import { Link } from "react-router-dom";
import { checkedInapi } from "../../../services/MarketWorking/MyTeam/checkInApi";
import _ from "lodash";
import { Button } from "react-bootstrap";
import GoogleMap from "../MyTeam/GoogleMap";
import VisitDetails from "../MyTeam/VisitDetails";
import { useSelector, connect } from "react-redux";
import { RiDirectionFill } from "react-icons/ri";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../Common/SearchableSelect.css";
import CommonSnackbar from "../../Common/CommonSnackbar";
import Loader from "../../Common/Loader";
import { getZSMTerritoryApi } from "../../../services/zsm/getZSMTerritoryApi";
import { getUniqueListBy } from "../../Common/Function";
import Message from "../../Common/Message";
import { MdLocationOff } from "react-icons/md";
import { getWssApi } from "../../../services/MarketWorking/MyTeam/getWssApi";
import { getRmdmWssApi } from "../../../services/rmdm/getRmdmWssApi";
import ModalPopup from "../../Common/ModalPopup";
import { BsInfoCircle } from "react-icons/bs";
import Styles from "../MyWss/MyWss.module.css";
import PowerBi from "../../../Pages/PowerBi";
import MyWssFeedbackForm from "./MyWssFeedbackForm";
import MyWssSummary from "./MyWssSummary";
import { getQuaterlyFormFlag } from "../../../services/wss/getQuaterlyFormFlag";

const clusterCodePlaceHolder = {
  SH3_Name__c: null,
  SH3_Code__c: null,
  Cluster_Description__c: "",
  CLUSTER_CODE__c: "",
};
const territoryAutoCompletePlaceHolder = {
  attributes: null,
  Id: null,
  SH2_Name__c: null,
  SH2_Code__c: null,
  Name: null,
  SH3_Code__c: null,
};

const dealerPlaceHolder = {
  attributes: null,
  Id: null,
  CustomerName__c: null,
  DealerClass__c: null,
  DealerType1__c: null,
  WSS_latitude__c: null,
  WSS_longitude__c: null,
  PrimaryTSICode__c: null,
  Customer_Code__c: null,
  RecordTypeId: null,
};

const MyWss = ({ checkedInapi, getZSMTerritoryApi, getRmdmWssApi, getQuaterlyFormFlag }) => {
  const [wssOption, setWssOption] = useState([]);
  const [error, setError] = useState("");
  const [selectedTerritory, setSelectedTerritory] = useState(
    territoryAutoCompletePlaceHolder
  );
  const [selectLatLong, setSelectLatLong] = useState(dealerPlaceHolder);
  const [lat, setLat] = useState(18.516726);
  const [long, setLong] = useState(73.856255);
  const [wss, setWss] = useState("");
  const [mtpCheckIn, setMtpCheckIn] = useState("");
  const [sh3CodeForTerritory, setSH3CodeForTerritory] = useState("");
  const [territoryCheckIn, setTerritoryCheckIn] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [failedOpen, setFailesOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const tname = useSelector((state) => state?.territory?.territoryDetails);
  const [currentLatitude, setCurrentLatitude] = useState(0);
  const [currentLongitude, setCurrentLongitude] = useState(0);
    
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [cluster, setCluster] = useState(clusterCodePlaceHolder);
  const [locationError, setLocationError] = useState(false);
  const [customerCode, setCustomerCode] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [currentAccuracyFactor, setCurrentAccuracyFactor] = useState(0);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isWssData, setIsWssData] = useState("Loading");
  const [newCheckinId, setNewCheckinId] = useState({});
  const [isQuaterlyFlag, setIsQuaterlyFlag] = useState(true);
  const [checkinPayLoad, setCheckinPayLoad] = useState({});
  const [getSummeryList, setGetSummeryList] = useState({});

  const userType = useSelector((state) =>
    state?.userType?.type != "Other"
      ? state?.userType?.type
      : state?.userDetails?.details?.Title
  );
  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );
  const tourPlanDefaultData = useSelector(
    (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records
  );

  //snackbar
  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailesOpen(false);
    setSuccessOpen(false);
  };

  // Get Selected Territory
  useEffect(() => {
    if (selectedTerritory) {
      const checkInTerritory = selectedTerritory?.Id;
      setTerritoryCheckIn(checkInTerritory);
    }
  }, [selectedTerritory]);

  // set lattitude and longittitude
  useEffect(() => {
    if (selectLatLong) {
      setLat(selectLatLong?.WSS_latitude__c);
      setLong(selectLatLong?.WSS_longitude__c);
      setWss(selectLatLong?.CustomerName__c);
      setMtpCheckIn(selectLatLong?.Monthly_Tour_Plan_CheckIn__c);
      setCustomerCode(selectLatLong?.Customer_Code__c);
      // setAccuracy(parseInt(selectLatLong?.GPSAccuracy__c));
    }
  }, [selectLatLong]);

  // Wss Name API call
  useEffect(() => {
    setIsWssData("Loading")
    if (
      selectedTerritory?.SH2_Code__c !== null 
    ) {
      (async () => {
        let SH2_Code__c = selectedTerritory?.SH2_Code__c;
        const response = await getWssApi({ SH2_Code__c });
        if (response?.success) {
          if (response?.data?.totalSize === 0){
            setIsWssData("false")
          }else{
            setIsWssData("true")
            setWssOption(response?.data?.records);
            // setSelectedTerritory("");
          }
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [selectedTerritory]);

  // to get territory picklist when user is ZSM
  useEffect(() => {  
    if (!_.isEmpty(sh3CodeForTerritory)) {
      (async () => {
        const response = await getZSMTerritoryApi(sh3CodeForTerritory);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList("");
        }
      })();
    }
  }, [sh3CodeForTerritory]);

  // API call to getQuaterlyFormFlag
  // useEffect(() => {
  //   console.log("getQuaterlyFormFlag", customerCode)
  //   if (!_.isEmpty(customerCode)) {
  //     (async () => {
  //       const response = await getQuaterlyFormFlag(customerCode);
  //       if (response?.success) {
  //         setIsQuaterlyFlag(response?.data?.checked);
  //       } else {
  //         setIsQuaterlyFlag("");
  //       }
  //     })();
  //   }
  // }, [customerCode])

  // Wss feedback form confirmation handeled 
  const handleFormConfirm = () => {
    // Handle the response from the modal
    console.log('User confirmed the success', selectedTerritory);
    setGetSummeryList(selectedTerritory);
    setIsSubmitModalOpen(false);
    setSuccessOpen(true);
    setCheckinPayLoad({})
    // setCluster(clusterCodePlaceHolder);
    // setSelectedTerritory(territoryAutoCompletePlaceHolder);
    // setSelectLatLong(dealerPlaceHolder);
    setIsQuaterlyFlag({});
  };

  // Wss feedback form confirmation handeled 
  const handleFormError = () => {
    // Handle the response from the modal
    console.log('User error the success');
    setIsSubmitModalOpen(false);
    setError("You are already Checked-in for this WSS!");
    setCheckinPayLoad({})
    // setCluster(clusterCodePlaceHolder);
    // setSelectedTerritory(territoryAutoCompletePlaceHolder);
    // setSelectLatLong(dealerPlaceHolder);
    setFailesOpen(true);
    setIsQuaterlyFlag({});
  };

  // Function for direct checkIn if lat log is present & in accurate distance range
  const handleDirectCheckin = (isChecked, CheckValue) => {
    if (userType === "SH3") {
      const payload = prepareCheckInPayload(isChecked, CheckValue);
      setCheckinPayLoad(payload);
    } else {
      onClickYes(true, "")
    }
  }

  // On-submit checkIn button

  const checkIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (currentLatitude === 0 && currentLongitude === 0) { // Check if current lat & long is 0
      setLoading(false);
      setFailesOpen(true);
      setError("Please turn on your location");
    } else if (_.isEmpty(wss) || _.isEmpty(territoryCheckIn)) { // Check wss & territory is selected or not
      setLoading(false);
      setError("Please select cluster, territory & wss name!");
      setFailesOpen(true);
    } else if (mtpCheckIn == "Correct"){ // check whether this wss is already visited 
      var cal = 2 * (accuracy + currentAccuracyFactor) + 300;
      if (distance <= cal) {  // IF wss is already recaptured then check the distance
        // onClickYes(true, "");
        handleDirectCheckin(true, "")
      }else{
        // setIsSubmitModalOpen(true);
        // setLoading(false);
        onClickYes(false, "");
      }
    }else if (lat == null || long == null) { // check whether Master lat & long is null then show the popup
        setIsSubmitModalOpen(true);
        setLoading(false);
    } else {   // IF wss is not recaptured but it will have lat & log then check distance 
      var cal = 2 * (accuracy + currentAccuracyFactor) + 300;
      if (distance <= cal) {
        // onClickYes(true, "");
        handleDirectCheckin(true, "")
      } else {
        setIsSubmitModalOpen(true);
        setLoading(false);
        // onClickYes(false, "");
      }
    }
  };

  // Wss Check-in Payload for SH3 user
  const prepareCheckInPayload = (isChecked, CheckValue) =>{
    if (isChecked == true){
      const payload = {
           RecordtypeName: "Visit Plan WSS",
           territory: territoryCheckIn,
           dealer: "",
           dealercode: "",
           customer: wss,
           customercode: customerCode,
           Transaction_lat: currentLatitude,
           Transaction_long: currentLongitude,
           IsValidCheckIn: "Valid",
           Master_lat: lat,
           Master_long: long,
           Trasanction_distance: distance,
           Master_Accuracy:accuracy,
           Current_Accuracy:currentAccuracyFactor,
           Is_Correct_CheckIn:CheckValue
         }
      return payload;
    }else{
      return "";
    }

  }

  // API call for wss checkIn
  const onClickYes = async (isChecked, CheckValue) => {
    setLoading(true);
    if (isChecked == true) {
      let response;
      if (territoryCheckIn !== "") {
        response = await checkedInapi(
          "Visit Plan WSS",
          territoryCheckIn,
          "",           
          "",
          wss,
          customerCode,
          currentLatitude,
          currentLongitude,
          "Valid",
          lat,
          long,
          distance,
          accuracy,
          currentAccuracyFactor,
          CheckValue
        );
      }
      setLoading(false);
      if (response?.success) {
        setGetSummeryList(selectedTerritory);
        setIsSubmitModalOpen(false);
        setSuccessOpen(true);
        // setCluster(clusterCodePlaceHolder);
        // setSelectedTerritory(territoryAutoCompletePlaceHolder);
        // setSelectLatLong(dealerPlaceHolder);
      } else {
        setIsSubmitModalOpen(false);
        setError("You are already Checked-in for this WSS!");
        // setCluster(clusterCodePlaceHolder);
        // setSelectedTerritory(territoryAutoCompletePlaceHolder);
        // setSelectLatLong(dealerPlaceHolder);
        setFailesOpen(true);
      }
    } else {
      let response = await checkedInapi(
        "Visit Plan WSS",
        territoryCheckIn,
        "",
        "",
        wss,
        customerCode,
        currentLatitude,
        currentLongitude,
        "Invalid",
        lat,
        long,
        distance,
        accuracy,
        currentAccuracyFactor,
        CheckValue
      );
      if (response?.success) {
        setIsSubmitModalOpen(false);
        setError("Wss Check-in Unsuccessful");
        // setCluster(clusterCodePlaceHolder);
        // setSelectedTerritory(territoryAutoCompletePlaceHolder);
        // setSelectLatLong(dealerPlaceHolder);
      } else {
        setIsSubmitModalOpen(false);
        setError("You are already Checked-in for this WSS!");
        // setCluster(clusterCodePlaceHolder);
        // setSelectedTerritory(territoryAutoCompletePlaceHolder);
        // setSelectLatLong(dealerPlaceHolder);
      }
      setLoading(false);
      setFailesOpen(true);
    }
  };

  // open default google map
  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" + parseFloat(lat) + "," + parseFloat(long)
    );
  };

  //to get lat,lon from current location of user
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentAccuracyFactor(position.coords.accuracy);
        setCurrentLatitude(position.coords.latitude);
        setCurrentLongitude(position.coords.longitude);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  // checkIn validation
  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c * 1000; // Distance in m
    return d;
  }

  // Helper function to convert degrees to radians
  function toRad(deg) {
    return deg * (Math.PI / 180);
  }

  const distance = calcDistance(currentLatitude, currentLongitude, lat, long);
  // For checking location access yes or not

  useEffect(() => {
    checkPermission();
  }, []);

  const checkPermission = async () => {
    try {
      const { state } = await navigator.permissions.query({
        name: "geolocation",
      });
      if (state === "denied") {
        setLocationError(true);
      }
    } catch (error) {
      // console.log("Error checking location permission:", error);
    }
  };

  // To autopopulate Selected territory in MTP
  useEffect(() => {
    setDefaultValues();
  }, []);

  // To get formated date
  const formatDate = (date) => {
    const d = new Date(date);
    const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
    return d.toLocaleDateString('en-US', options);
  };

  // function to set auto-populated values++++++++++++
  const setDefaultValues = () => {

    var clusterFilterData = getUniqueListBy(clusterCodeOption, userType === "MH3" || userType === "RBDM Collaborator" ? "CLUSTER_CODE__c" : "SH3_Name__c");

    if (tourPlanDefaultData.length != 0 ) {
      const mtpCurrentDateData = tourPlanDefaultData.filter(item => formatDate(item.MTP_Date__c) === formatDate(new Date()));
      if (userType === "SH6" || userType === "SH5" || userType === "MH3" || userType === "RBDM Collaborator" ){
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].Cluster_es__c !== null){
          const originalString = mtpCurrentDateData[0].Cluster_es__c;
          const parts = originalString.split(':');
          var defaultCluster = clusterFilterData.filter(item => item.CLUSTER_CODE__c === parts[1]);
          if (_.isEmpty(defaultCluster)) {
            var defaultCluster = clusterFilterData.filter(item => item.SH3_Code__c === parts[1]);
          }
          setCluster(defaultCluster[0]);
          setSH3CodeForTerritory(parts[1])
        }
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].MTP_Territory_es__r !== null) {
          const mtpTerritoryCode = mtpCurrentDateData[0].MTP_Territory_es__r.SH2_Code__c;
          var defaultTerritory = tname.filter(item => item.SH2_Code__c === mtpTerritoryCode);
          if (_.isEmpty(defaultTerritory)) {
            var defaultTerritory = territoryPickList.filter(item => item.SH2_Code__c == mtpTerritoryCode);
          }
          setSelectedTerritory(defaultTerritory[0]);
        }
      }else{
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].MTP_Territory_es__r !== null) {
        const mtpTerritoryCode = mtpCurrentDateData[0].MTP_Territory_es__r.SH2_Code__c;
        var defaultTerritory = tname.filter(item => item.SH2_Code__c === mtpTerritoryCode);
        if (_.isEmpty(defaultTerritory)) {
          var defaultTerritory = territoryPickList.filter(item => item.SH2_Code__c == mtpTerritoryCode);
        }
        setSelectedTerritory(defaultTerritory[0]);
        }
      }
    }
  }

  // Auto Populate the value get form my wss summery
  const handleAutoPoulatedValue = (value) => {
     console.log("Checkin Data",value, selectLatLong)
     const wssData = {
       attributes: null,
       Id: value.Id,
       CustomerName__c: value.CustomerName__c ? value.CustomerName__c : value.WSS_Name__c,
       DealerClass__c: null,
       DealerType1__c: null,
       WSS_latitude__c: null,
       WSS_longitude__c: null,
       PrimaryTSICode__c: null,
       Customer_Code__c: value.Customer_Code__c ? value.Customer_Code__c : value.WSS_Code__c,
       RecordTypeId: null,
     }

    const territoryData = {
      attributes: null,
      Id: value.Territory_Id,
      SH2_Name__c: value.TerritoryName,
      SH2_Code__c: value.TSI_Territory_Code__c,
      Name: null,
      SH3_Code__c: null,
    };
    if(value){
      setSelectedTerritory(territoryData);
      // setCustomerCode(wssData);
      setSelectLatLong(wssData)
      setIsSubmitModalOpen(true);
    }else{
      setSelectedTerritory("");
    }
  }

  // Check if user is SH3 Or Other for wss check-in
  const onHandleForm = (isChecked, CheckValue) => {
    if (userType === "SH3"){
      const payload = prepareCheckInPayload(isChecked, CheckValue);
      setCheckinPayLoad(payload);
    }else{
      onClickYes(isChecked, CheckValue)
    }
  }

  return (
    <>
      <Loader loading={loading} />

      <VisitDetails name="wssPage" />
      {userType === "SH3" &&
        <MyWssSummary CheckIndata={handleAutoPoulatedValue} reloadList={getSummeryList}/>
      }
      <div className={styles.myDealer__outerBox}>
        <div className={styles.myDealer__heading}>My Wss</div>

        <Autocomplete
          id="grouped-demo"
          className="myDealer__categorySelect"
          options={
            !_.isEmpty(tname) ? getUniqueListBy(tname, "SH2_Code__c") : []
          }
          getOptionLabel={(option) => {
            if (option?.SH2_Name__c) {
              return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
            } else {
              return "";
            }
          }}
          value={selectedTerritory}
          name="dealerId"
          onChange={(event, newValue) => {
            setSelectedTerritory(newValue);
            setSelectLatLong(dealerPlaceHolder);
            setWssOption([]);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Territory" />
          )}
        />

        <Autocomplete
          id="grouped-demo2"
          className="myDealer__categorySelect"
          options={
            isWssData === "Loading"
              ? [
                  {
                    CustomerName__c: "Loading...",
                    Customer_Code__c: "",
                  },
                ]
              : isWssData === "false" ? [
                {
                  CustomerName__c: "No Data",
                  Customer_Code__c: "",
                },
              ] :wssOption
          }
          getOptionLabel={(option) => {
            if (option?.CustomerName__c) {
              return `${option?.CustomerName__c} ${
                option?.Customer_Code__c && ":"
              } ${option?.Customer_Code__c}`;
            } else {
              return "";
            }
          }}
          name="subCategory"
          value={selectLatLong}
          onChange={(event, newValue) => {
            setSelectLatLong(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Wss Name" />
          )}
        />

        {/* <PowerBi/> */}
        {/* google mao component here */}

        <div className={styles.myDealer__googleMap_Box}>
          {locationError ? (
            <Message
              message={
                "Location access denied. Please update your browser settings to grant location permission."
              }
              className="DealerLocatorForm__mapAccess"
              icon=<MdLocationOff
                style={{ color: "#808080", height: "2em", width: "2em" }}
              />
            />
          ) : (
            <GoogleMap
              lat={_.isEmpty(lat) ? currentLatitude : parseFloat(lat)}
              long={_.isEmpty(long) ? currentLongitude : parseFloat(long)}
              zoom={12}
            />
          )}
        </div>

        <div className="row m-0 p-2">
          <div className="col-4 ps-0">
            <Link to="#">
              <Button
                className={styles.myDealer__navButton}
                onClick={showInMapClicked}
              >
                Navigate
                <RiDirectionFill
                  style={{ marginLeft: "4px", marginBottom: "1px" }}
                />
              </Button>
            </Link>
          </div>
          <div className="col-4">
            <Button className={styles.myDealer__navButton} onClick={checkIn}>
              Check In
            </Button>
          </div>
          <div className="col-4 pe-0">
            <Link to="/past-sr-insight">
              <Button className={styles.myDealer__navButton}>SR/Insight</Button>
            </Link>
          </div>
        </div>
      </div>
      {isSubmitModalOpen && (
        <ModalPopup
          className="ModalPopup_Submitted"
          isModalOpen={isSubmitModalOpen}
          setIsModalOpen={setIsSubmitModalOpen}
          title="WSS Check-In Unsuccessfull"
          data="Do you want to Re-capture correct WSS location?"
          backdrop={true}
          fade={false}
          icon={
            <>
              <div className="submittedIcons draftIcons">
                <BsInfoCircle className="submittedSmall" style={{ color: '#77BFC7' }} />
                <BsInfoCircle className="submittedLarge" style={{ color: '#77BFC7' }} />
                <BsInfoCircle className="submittedSmall" style={{ color: '#77BFC7' }} />
              </div>
            </>
          }
          showButton={
            <>
              <Loader loading={loading} />
              <div className={Styles.myWss__saveBox}>
                <button className={Styles.myWss__saveButtonNo} onClick={() => { onClickYes(false , "") }}> No </button>
                <button className={Styles.myWss__saveButtonYes} onClick={() => { onHandleForm(true, "Correct") }}> Yes </button>
              </div>
            </>
          }
        />
      )}
      <CommonSnackbar
        open={failedOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"error"}
        message={error}
      />

      <CommonSnackbar
        open={successOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"success"}
        message={`   Successfully checked-in for ${
          !_.isEmpty(wss) ? wss : "this"
        }${" "}
      wss!`}
      />
      <div className="pbi__outerBox">
          <PowerBi
            id="dd6b49e0-3b63-4e27-a36d-25bfdc232607"
            pageName="105cc83822fe7fc3de5a"
            wssCode={customerCode}
          />
      </div>

      {/* ------------ Feedback Form Popup ---------------- */}
      {checkinPayLoad &&   
        <MyWssFeedbackForm record={checkinPayLoad} formSuccess={handleFormConfirm} formError={handleFormError} QuaterlyFlag={isQuaterlyFlag} />
      }
    </>
  );
};

export default connect(null, {
  checkedInapi,
  getZSMTerritoryApi,
  getRmdmWssApi,
  getQuaterlyFormFlag
})(MyWss);
