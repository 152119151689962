import axios from "axios";
import { BASE_URL } from "../../variables";
import { arrayData } from "../../Components/Common/Function";

export const getDRMZoneApi = async ( email ) => {

    const options = {
        url: `${BASE_URL}/services/data/v56.0/query?q=SELECT SH3_Email__c,SH5_Name__c FROM Pidilite_Hierarchy__c where  SH5_Name__c!=null and  SH3_Email__c='${email}' limit 1`,
        method: "get",
    };
    try {
        const response = await axios(options);
        //console.log("data --->", response);
        if (response.status === 200) {
            return {
                success: true,
                data: response?.data,
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};