import React, { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { MdHomeFilled } from "react-icons/md";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { NavLink } from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Sidebar from "./Common/Sidebar";

const BottomNavbar = () => {
  const [value, setValue] = useState(0);
  const [sidebar, setSidebar] = useState(false);

  const styleIcon = ({ isActive }) => {
    return {
      color: isActive ? "#3901FF" : "#2B2B2B",
    };
  };

  return (
    <div className="bottom-Navbar">
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            style={styleIcon}
            component={NavLink}
            to="/"
            onClick={() => sidebar && setSidebar(false)}
            icon={<MdHomeFilled style={{ fontSize: "1.5rem" }} />}
          />
          <BottomNavigationAction
            style={styleIcon}
            component={NavLink}
            to="/dealer-locator"
            onClick={() => sidebar && setSidebar(false)}
            icon={<LocationOnIcon />}
          />
          <BottomNavigationAction
            style={styleIcon}
            component={NavLink}
            to="/my-diary"
            onClick={() => sidebar && setSidebar(false)}
            icon={<DescriptionOutlinedIcon />}
          />
          <BottomNavigationAction
            style={styleIcon}
            component={NavLink}
            // onClick={() => setSidebar(!sidebar)}
            icon={<Sidebar setSidebar={setSidebar} sidebar={sidebar} />}
          />
        </BottomNavigation>
      </Paper>
    </div>
  );
};

export default BottomNavbar;
