import axios from "axios";
import { getUpcomingTourPlan } from "../../Redux/actions/upcomingTourPlan-actions";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";
import { getBarGraph } from "../../Redux/actions/homePage-actions";

export const updateUpcomingTourPlansApi =
  ({
    Objectives,
    Sh2_Name,
    Pidilite_Hierarchy_Id,
    Datefields,
    TourType,
    RecordType,
    id,
    Town,
    wssCode,
    status,
    cluster,
  }) =>
  async (dispatch) => {
    const options = {
      url: `${BASE_URL}/services/apexrest/NTP_PATCH/`,
      method: "patch",
      data: {
        MTP: [
          {
            Objectives,
            Sh2_Name,
            Pidilite_Hierarchy_Id,
            Datefields,
            TourType,
            RecordType,
            id,
            Town,
            wssCode,
            status,
            cluster,
          },
        ],
      },
    };
    try {
      const response = await axios(options);
      if (response.status === 200) {
        dispatch(getUpcomingTourPlan());
        dispatch(getBarGraph());
        return {
          success: true,
          data: response?.data,
        };
      } else {
        throw Error("Something Went Wrong..!!");
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        sessionExpireTimeout(dispatch);
      } else {
        return {
          success: false,
          msg: "Somthing Went Wrong..!!",
        };
      }
    }
  };
