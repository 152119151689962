import axios from "axios";
import { BASE_URL } from "../../variables";

export const addWSSNameApiForHOUser = async (
  divisionGroup,
  salesGroup,
  sh3Code
) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,CustomerName__c ,Customer_Code__c,Cluster_Code__c  from account where SalesGroupCode__c= '${salesGroup}' AND Division_Code__c = ${divisionGroup}  AND RecordType.Name = 'Customer' AND Cluster_Code__c='${sh3Code}'  AND DiscontinueFlag__c = false`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data?.records,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
