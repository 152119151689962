import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import ModalPopup from "../Common/ModalPopup";
import { updateUpcomingTourPlansApi } from "../../services/MonthlyTourPlans/updateUpcomingTourPlansApi";
import { addNewTourWssApi } from "../../services/MonthlyTourPlans/addNewTourWssApi";
import _ from "lodash";
import { useSelector, connect } from "react-redux";
import { getRmdmWssApi } from "../../services/rmdm/getRmdmWssApi";
import { getZSMTerritoryApi } from "../../services/zsm/getZSMTerritoryApi";
import { getZSMWssNameApi } from "../../services/zsm/getZSMWssNameApi";
import { GrCircleInformation } from "react-icons/gr";

const EditFormModal = ({ record }) => {
  const [show, setShow] = useState(false);
  const [tourPlan, setTourPlan] = useState({});
  const [date, setDate] = useState();
  const [wssRmdmOption, setWssRmdmOption] = useState("");
  const [userWssArr, setUserWssArr] = useState([]);
  const [userObjectivesArr, setUserObjectivesArr] = useState([]);
  const [independentWssOptions, setIndependentWssOptions] = useState([]);
  const [userTownArr, setUserTownArr] = useState([]);
  const [independentTownOptions, setIndependentTownOptions] = useState([]);
  const territory = useSelector((state) => state.territory.territoryDetails);
  const title = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);
  const townList = useSelector((state) => state?.rmdmTown?.townList);
  const wssOptionsFromRedux = useSelector((state) => state?.wssOptionsData?.wssCodeList);
  const [showTextField, setShowTextField] = useState(false);

  useEffect(() => {
    const date = new Date(record?.MTP_Date__c);
    setDate(date.toLocaleDateString("en-CA"));
  }, [record]);

  // to set data
  useEffect(() => {
    if (title === "HO Collaborator" || title === "SH7") {
      setIndependentTownOptions(wssOptionsFromRedux);
      setIndependentWssOptions(wssOptionsFromRedux);
    }
    if (title === "MH3" || title === "RBDM Collaborator") {
      setIndependentTownOptions(townList);
      setIndependentWssOptions(townList);
    }
    setTourPlan({
      date: date,
      town: record?.Town_Name__c,
      territory: !_.isEmpty(record?.MTP_Territory_es__r?.SH2_Name__c)
        ? `${record?.MTP_Territory_es__r?.SH2_Name__c}:${record?.MTP_Territory_es__r?.SH2_Code__c}`
        : "",
      objectives: record?.Objectives_es__c,
      wss: record?.WSS_Name__c,
      type: "Market Visit",
      recordType: "Monthly Tour Plan",
      recordId: record?.Id,
      pidiliteHierarchy: record?.MTP_Territory_es__r?.Id,
      sh2Names: record?.MTP_Territory_es__r?.SH2_Name__c,
      cluster: record?.Cluster_es__c,
      otherObjectives: record?.Other_Objective__c,
    });
    if (title === "MH3" || title === "RBDM Collaborator") {
      setWssRmdmOption(record?.WSS_Name__c);
    }
  }, [date]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };


  const StringSplitterTown = ({ inputString }) => {
    if (inputString == null) {
      return (
        <p className="UpcomigTourDetailsModal__value mb-0"> NA </p>
      );
    } else {
      const splitWithComma = inputString.split(',');
      if (splitWithComma.length <= 2) {
        return (
          <p className="UpcomigTourDetailsModal__value mb-0">{splitWithComma[0]}</p>
        );
      } else {
        const partsToDisplay = splitWithComma.slice(0, -1);

        const listItems = partsToDisplay.map((part, index) => (
          <li key={index} className="UpcomigTourDetailsModal__value_Li">{part}</li>
        ));

        return (
          <div>
            <ol>{listItems}</ol>
          </div>
        );
      }
    }
  }

  const StringSplitterObjective = ({ inputString }) => {
    if (inputString == null) {
      return (
        <p className="UpcomigTourDetailsModal__value mb-0"> NA</p>
      );
    } else {
      const parts = inputString.split(';');

      const listItems = parts.map((part, index) => (
        <li key={index} className="UpcomigTourDetailsModal__value_Li">{part}</li>
      ));

      if (parts.includes("Other")) {
        setShowTextField(true);
      } else {
        setShowTextField(false);
      }
      return (
        <div>
          <ol>{listItems}</ol>
        </div>
      );
    }
  }

  const StringSplitterWss = ({ inputString }) => {
    if (inputString == null) {
      return (
        <p className="UpcomigTourDetailsModal__value mb-0">NA</p>
      );
    } else {
      const modifiedSegments = [];
      const splitWithComma = inputString.split(",");
      if (splitWithComma.length <= 2) {
        const splitSegment = splitWithComma[0].split(':');
        const modifiedSegment = splitSegment.slice(0, 2).join(':');
        return (
          <p className="UpcomigTourDetailsModal__value mb-0">{modifiedSegment}</p>
        );
      } else {
        splitWithComma.forEach((segment, index) => {
          if (index % 2 === 0) {
            const splitSegment = segment.split(':');
            const modifiedSegment = splitSegment.slice(0, 2).join(':');
            modifiedSegments.push(modifiedSegment);
          }
        });
        const result = modifiedSegments.join(',');

        const splitWithComma2 = result.split(",");
        const partsToDisplay = splitWithComma2.slice(0, -1);
        const listItems = partsToDisplay.map((part, index) => (
          <li key={index} className="UpcomigTourDetailsModal__value_Li">{part}</li>
        ));

        return (
          <div>
            <ol>{listItems}</ol>
          </div>
        );
      }
    }
  }

  return (
    <>
      <GrCircleInformation
        className="UpcomigTourDetailsModal__infoIcon"
        onClick={() => handleShow(tourPlan?.recordId)}
      />
      <ModalPopup
        isModalOpen={show}
        setIsModalOpen={handleClose}
        ModalMainClass="UpcomigTourDetailsModal"
        backdrop={true}
        fade={false}
        data={
          <>
            <div
              className="row UpcomigTourDetailsModal__MainBox"
              style={{ textAlign: "left" }}
            >
              {/* <h6 className="UpcomigTourDetailsModal__Header">
                Upcoming Tour Details
              </h6> */}
              <div className="col-12 d-flex">
                <label>
                  Date -
                </label>
                <p className="UpcomigTourDetailsModal__value">{tourPlan?.date}</p>
              </div>

              {title !== "MH3" && title !== "RBDM Collaborator" ? (
                <>
                  {(title === "HO Collaborator" ||
                    title === "SH5" ||
                    title === "SH6" || 
                    title === "SH7") && (
                      <>
                        <div className="col-12 d-flex">
                          <label>
                            Cluster -
                          </label>
                          <p className="UpcomigTourDetailsModal__value">{tourPlan?.cluster ? tourPlan?.cluster : "NA"}</p>
                        </div>
                        {(title === "SH5" || title === "SH6") && (
                          <>
                            <div className="col-12 d-flex">
                              <label>
                                Territory -
                              </label>
                              <p className="UpcomigTourDetailsModal__value">{tourPlan?.territory ? tourPlan?.territory : "NA"}</p>
                            </div>

                            <div className="col-12 d-flex">
                              <label>
                                WSS -
                              </label>
                              <p><StringSplitterWss inputString={tourPlan?.wss} /></p>
                            </div>
                          </>
                        )}

                      {(title === "HO Collaborator" || title === "SH7") && (
                          <div className="col-12 d-flex">
                            <label>Territory -</label>
                            <p className="UpcomigTourDetailsModal__value">{tourPlan?.territory ? tourPlan?.territory : "NA"}</p>
                          </div>
                        )}
                      </>
                    )}

                  {title !== "HO Collaborator" &&
                    title !== "SH5" &&
                    title !== "SH6" && 
                    title !== "SH7" &&(
                      <div className="col-12 d-flex">
                        <label>
                          Territory -
                        </label>
                        <p className="UpcomigTourDetailsModal__value">{tourPlan?.territory ? tourPlan?.territory : "NA"}</p>
                      </div>
                    )}
                </>
              ) : (
                <>
                  <div className="col-12 d-flex">
                    <label>
                      Cluster -
                    </label>
                    <p className="UpcomigTourDetailsModal__value">{tourPlan?.cluster ? tourPlan?.cluster : "NA"}</p>
                  </div>

                  <div className="col-12 d-flex">
                    <label>
                      WSS -
                    </label>
                    <p><StringSplitterWss inputString={userWssArr.length > 0 ? "" : tourPlan?.wss} /></p>
                  </div>

                  <div className="col-12 d-flex">
                    <label>
                      Town -
                    </label>
                    <p><StringSplitterTown inputString={userTownArr.length > 0 ? "" : tourPlan?.town} />
                    </p>
                  </div>

                  <div className="col-12 d-flex">
                    <label style={{ whiteSpace: "nowrap" }}>
                      Primary <br /> Objective -
                    </label>
                    <p><StringSplitterObjective inputString={userObjectivesArr.length > 0 ? "" : tourPlan?.objectives} />
                    </p>
                  </div>
                  {showTextField && (
                    <div className="col-12 d-flex mt-2">
                      <label style={{ whiteSpace: "nowrap" }}>
                        Other Primary <br /> Objective -
                      </label>
                        <p className="UpcomigTourDetailsModal__value"> {tourPlan?.otherObjectives ? tourPlan?.otherObjectives : "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}

              {title !== "MH3" && title !== "RBDM Collaborator" && (
                <>
                  {title !== "SH5" &&
                    title !== "SH6" &&
                    title !== "SH3" &&
                    title !== "HO Collaborator" &&
                    title !== "SH7" && (
                      <div className="col-12 d-flex">
                        <label>
                          WSS -
                        </label>
                        <p>
                          <StringSplitterWss inputString={tourPlan?.wss} />
                        </p>
                      </div>
                    )}
                  {(title === "HO Collaborator" || title === "SH7") && (
                    <div className="col-12 d-flex">
                      <label>
                        WSS -
                      </label>
                      <p>
                        <StringSplitterWss inputString={userWssArr.length > 0 ? "" : tourPlan?.wss} />
                      </p>
                    </div>
                  )}
                  {title === "SH3" && (
                    <div className="col-12 d-flex">
                      <label>
                        WSS -
                      </label>
                      <p><StringSplitterWss inputString={tourPlan?.wss} /></p>
                    </div>
                  )}

                  {title !== "HO Collaborator" && title !== "SH7" &&(
                    <div className="col-12 d-flex">
                      <label>
                        Town -
                      </label>
                      <p><StringSplitterTown inputString={tourPlan?.town} /></p>
                    </div>
                  )}

                  {(title === "HO Collaborator" || title === "SH7") && (
                    <div className="col-12 d-flex">
                      <label>
                        Town -
                      </label>
                      <p><StringSplitterTown inputString={userObjectivesArr.length > 0 ? "" : tourPlan?.town} /></p>
                    </div>
                  )}

                  <div className="col-12 d-flex">
                    <label style={{ whiteSpace: "nowrap" }}>
                      Primary <br /> Objective -
                    </label>
                    <p><StringSplitterObjective inputString={userObjectivesArr.length > 0 ? "" : tourPlan?.objectives} />
                    </p>
                  </div>
                  {showTextField && (
                    <div className="col-12 d-flex mt-2">
                      <label style={{ whiteSpace: "nowrap" }}>
                        Other Primary <br /> Objective -
                      </label>
                      <p className="UpcomigTourDetailsModal__value"> {tourPlan?.otherObjectives ? tourPlan?.otherObjectives : "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default connect(null, {
  updateUpcomingTourPlansApi,
  addNewTourWssApi,
  getRmdmWssApi,
  getZSMTerritoryApi,
  getZSMWssNameApi,
})(EditFormModal);
