import axios from "axios";
import { BASE_URL } from "../../variables";

export const SPECASubmit = async ({ NextSpecaObj }) => {
   
    const headers = {
      "Content-Type": "application/json"
    //   Authorization: `Bearer ${globalAccessToken}`,
    };
  
    const options = {
      url: `https://pidilitecwp--cwpmconect.sandbox.my.salesforce.com/services/apexrest/DRMReceiveSpecaPayload`,
      method: "post",
      headers: headers,
      data: { NextSpecaObj },
    };
    try {
        
      const response = await axios(options);
      
      if (response?.data.success === true) {
        return {
          success: true,
          data: response?.data?.message,
        };
      } else {
        return {
          success: true,
          data: response?.data?.message,
        };
      }
    } catch (err) {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",err,
      };
    }
  };