import React, { useState, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import "./Query.css";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getQueryDetails } from "../../Redux/actions/queryDetails-actions";
import Message from "../Common/Message";
import Loader from "../Common/Loader";
import { FiSearch } from "react-icons/fi";
import { BsFillPlusCircleFill } from "react-icons/bs";

const Query = ({ getQueryDetails, queryInfo }) => {
  const [loading, setLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getQueryDetails();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const filteredData = queryInfo?.filter((record) => {
      return record?.recType === "Dealer"
        ? record?.dealerName?.toLowerCase()?.includes(searchBy?.toLowerCase())
        : record?.customerName?.toLowerCase()?.includes(searchBy?.toLowerCase());
    });

    const noDataFound = filteredData.length === 0;

    setFilteredData(filteredData);
    setNoDataFound(noDataFound);
  }, [searchBy]);

  return (
    <div className="query">
      <div className="row">
        <h6 className="query__name col-8">Query Details</h6>
      </div>
      <Loader loading={loading} />

      <div className="searchTour__searchBox">
        {queryInfo?.length > 0 && (
          <div className="searchTour__searchTourBox">
            <FiSearch className="searchTour__searchIcon" />
            <input
              className="form-control"
              placeholder="Search query"
              aria-label="Search"
              type="Search"
              name="search"
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </div>
        )}
        
          <Link
            variant="contained"
            className={
              queryInfo?.length > 0 ? "searchTour__newTourBtn" : "searchTour__newTourBtnNew"
            }
            to="/my-dealer"
            state={{ from: true }}
          >
            <BsFillPlusCircleFill className="searchTour__newTourIcon" />
            New SR
          </Link>
      </div>

      <div className="query__box">
        {queryInfo?.length == 0 && !loading && (
          <Message
            message={"No pending queries"}
            className={"query__messageConatiner"}
          />
        )}

        {searchBy?.toLowerCase() !== "" ? (
          noDataFound ? (
            <Message
              message={`No Queries related to "${searchBy}"`}
              className={"query__messageConatiner"}
            />
          ) : (
            filteredData?.map((data) => {
              return (
                <Link to={`/query-details/${data?.Id}`}>
                  <div className="query__innerBox">
                    <div className="query__header">
                      <>
                        <CgProfile className="query__profile" />
                        <div className="query__detailName">
                          <div className="query__profileName">
                            {data?.recType === "Dealer"
                              ? data?.dealerName
                              : data?.customerName}
                          </div>
                          <p className="query__profileSubName">
                            {data?.CaseNumber}
                          </p>
                        </div>
                      </>
                    </div>
                    <Table style={{ marginTop: "10px", marginBottom: "0px" }}>
                      <>
                        <tbody
                          className="query__details"
                          style={{ borderStyle: "hidden" }}
                        >
                          <tr className="query__subjectSection">
                            <td className="col-4 col-md-6">Subject:</td>
                            <td>{data?.Subject}</td>
                          </tr>
                          <tr>
                            <td className="col-4 col-md-6">Description: </td>
                            <td>{data?.Description}</td>
                          </tr>
                        </tbody>
                      </>
                    </Table>
                  </div>
                </Link>
              );
            })
          )
        ) : (
          queryInfo?.map((data) => {
            return (
              <Link to={`/query-details/${data?.Id}`}>
                <div className="query__innerBox">
                  <div className="query__header">
                    <>
                      <CgProfile className="query__profile" />
                      <div className="query__detailName">
                        <div className="query__profileName">
                          {data?.recType === "Dealer"
                            ? data?.dealerName
                            : data?.customerName}
                        </div>
                        <p className="query__profileSubName">
                          {data?.CaseNumber}
                        </p>
                      </div>
                    </>
                  </div>
                  <Table style={{ marginTop: "10px", marginBottom: "0px" }}>
                    <>
                      <tbody
                        className="query__details"
                        style={{ borderStyle: "hidden" }}
                      >
                        <tr className="query__subjectSection">
                          <td className="col-4 col-md-6">Subject:</td>
                          <td>{data?.Subject}</td>
                        </tr>
                        <tr>
                          <td className="col-4 col-md-6">Description: </td>
                          <td>{data?.Description}</td>
                        </tr>
                      </tbody>
                    </>
                  </Table>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  queryInfo: state?.query?.queryDetails,
});

export default connect(mapStateToProps, {
  getQueryDetails,
})(Query);
