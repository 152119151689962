import axios from "axios";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const getPilDataApi = async (dealerCode, reason) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/pil/get-pil-data`,
    method: "post",
    headers: headers,
    data: { dealerCode, reason },
  };
  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
