import React, { useState, useEffect, useLayoutEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables, _adapters } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getMonthlyTourPlanningApi } from "../../services/HomePage/getMonthlyTourPlanningApi";
import _ from "lodash";
import { useSelector } from "react-redux";
import { monthNames } from "../Common/Constant";

const Bargraph = () => {
  // const [graphData, setGraphData] = useState({});
  const [error, setError] = useState();
  const [plannedData, setPlannedData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [total, setTotal] = useState([]);
  const [labels, setLabels] = useState([]);

  useLayoutEffect(() => {
    Chart.register(ChartDataLabels);
    Chart.register(...registerables);
  });

  // to get the y-aixs labels(past three months)

  // useEffect(() => {
  //   for (let i = 0; i < 3; i++) {
  //     const current = new Date();
  //     current.setMonth(current.getMonth() - i);
  //     setLabels((ls) => [
  //       current.toLocaleString("default", {
  //         month: "long",
  //       }),
  //       ...ls,
  //     ]);
  //   }
  // }, []);

  useEffect(() => {
    for (let i = 0; i < 3; i++) {
      const current = new Date();
      setLabels((ls) => [monthNames[current.getMonth() - i], ...ls]);
    }
  }, []);

  const graphData = useSelector((state) => state?.barGraph?.graph);

  // to get the completed and planned data
  useEffect(() => {
    if (graphData) {
      Object?.keys(graphData)?.map((month) => {
        const months = graphData[month];
        let totals = 0;
        for (let i = 0; i < months?.length; i++) {
          totals += months[i].expr0;
          if (months[i].Visit_Status_es__c === "Not Visited") {
            setPlannedData((ls) => [...ls, months[i].expr0]);
          } else if (months[i].Visit_Status_es__c === "Visited") {
            setCompletedData((ls) => [...ls, months[i].expr0]);
          }

          if (months.length <= 1) {
            if (months[i].Visit_Status_es__c === "Not Visited") {
              setCompletedData((ls) => [...ls, 0]);
            } else if (months[i].Visit_Status_es__c === "Visited") {
              setPlannedData((ls) => [...ls, 0]);
            }
          }
        }
        // setTotal((ls) => [...ls, totals]);
        if (_.isEmpty(graphData[month])) {
          setCompletedData((ls) => [...ls, 0]);
          setPlannedData((ls) => [...ls, 0]);
        }
      });
    }
  }, [graphData]);

  return (
    <>
      {graphData && (
        <Bar
          data={{
            labels: labels?.map((num) => num),
            datasets: [
              // {
              //   label: "Total",
              //   data: total?.reverse()?.map((num) => num),
              //   backgroundColor: ["#FFA600"],
              //   categoryPercentage: 0.6,
              // },
              {
                label: "Planned",
                data: plannedData?.reverse()?.map((num) => num),
                backgroundColor: ["#FFA600"],
                categoryPercentage: 0.6,
                color: "#FFFFFF",
              },
              {
                label: "Completed",
                data: completedData?.reverse()?.map((num) => num),
                backgroundColor: ["#2CC26A"],
                categoryPercentage: 0.6,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },

              datalabels: {
                display: true,
                anchor: "start",
                align: "top",
                size: 40,
                padding: 0.5,
                color: (context) => {
                  if (
                    // context?.dataset?.label === "Total" ||
                    context?.dataset?.label === "Completed"
                  ) {
                    return "#000000";
                  }
                },
                font: {
                  weight: 600,
                  size: 7.9,
                },
              },
            },

            scales: {
              y: {
                display: false,
              },
              x: {
                color: " rgb(255, 0, 0)",

                drawBorder: {
                  display: false,
                },

                ticks: {
                  display: true,
                  color: (context) => {
                    if (context?.index === 2) {
                      return "green";
                    }
                  },
                  size: 14,
                  weight: 500,
                },
                grid: {
                  display: false,
                  borderColor: "rgb(255, 255, 255)",
                },
              },
            },
          }}
          height={90}
          width={130}
        />
      )}
    </>
  );
};

export default Bargraph;
