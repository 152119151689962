import axios from "axios";
import { isLocalServer } from "../../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../../utils/getNodeUrl";

export const submitFeedback = async ({formData}) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

const nodeBaseUrl = getNodeServerUrl();
  console.log("nodeBaseUrl",formData)
  const options = {
    url: `${nodeBaseUrl}/DRM_Feedback/createFeedback`,
    method: "post",
    headers: headers,
    data: formData 
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
